import React from 'react';
import { ToolbarDropdown } from './ToolbarDropdown';

export const ToolbarAlignOptions = (props) => {
  return (
    <React.Fragment>
      <ToolbarDropdown
        disabled={false}
        className='tb-mobile-align-options'
        label={
          props.env.align === 'center' ? <i className="fas fa-align-center" /> : <i className="fas fa-align-left" />
        }
      >
        <ul>
          <li
            className={'only-icon' + (props.env.align === 'left' ? ' active' : '')}
            onClick={() => {
              props.toggleEnvParam('align', 'left');
            }}
          >
            <span>
              <i className="fas fa-align-left" />
            </span>
          </li>
          <li
            className={'only-icon' + (props.env.align === 'center' ? ' active' : '')}
            onClick={() => {
              props.toggleEnvParam('align', 'center');
            }}
          >
            <span>
              <i className="fas fa-align-center" />
            </span>
          </li>
        </ul>
      </ToolbarDropdown>

      <div className={'option toggle-align' + (props.env.align === 'left' ? ' active' : '')}>
        <span
          onClick={() => {
            props.toggleEnvParam('align', 'left');
          }}
        >
          <i className="fas fa-align-left" />
        </span>
      </div>
      <div className={'option toggle-align' + (props.env.align === 'center' ? ' active' : '')}>
        <span
          onClick={() => {
            props.toggleEnvParam('align', 'center');
          }}
        >
          <i className="fas fa-align-center" />
        </span>
      </div>
    </React.Fragment>
  );
};
