export const handleUpdateChordOfLyrics = (attr) => {
    let { chords, chordSymbol, letterindex } = attr;

    let updated = false;

    if (typeof chords != 'undefined' && chords.length) {
        for (let x in chords) {
          if (chords[x].pos === letterindex) {
            if (chordSymbol !== '') {
              chords[x].symbol = chordSymbol;
            } else if (typeof chords[x] !== 'undefined') {
              chords.splice(x, 1);
            }
            updated = true;
            break;
          }
        }
      }

      if (!updated && chordSymbol !== '') {
        chords.push({
          symbol: chordSymbol,
          pos: letterindex
        });
      }

      return chords;
}