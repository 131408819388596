import { log } from '../dev.js'; // isDev
import { cloneObject } from '../cloneObject.js';
import { getRowsInGroup, getTextFromGroup, composeDocArray, newID } from '../utils.js';



export const handleDragEnd = (attr) => {
  let { result, grps, rows, updateObject, snippets } = attr;
  let docArray = composeDocArray(grps, rows);

  let draggableInfo = result.draggableId.split('-');
  let sourceGroupId = draggableInfo[1];
  let sourceRowId = typeof draggableInfo[2] != 'undefined' ? draggableInfo[2] : '';

  if (result.destination.droppableId === 'groupToSidebar') { 
    // Drag to sidebar 
    //console.log("Drag to sidebar", result);

    snippets[newID()] = getTextFromGroup(sourceGroupId, docArray);
    updateObject.next.snippets = snippets;

    return {
      updateObject: updateObject,
      dragType: 'tosidebar'
    };

  } else if (result.type === 'group') {
    let sourceIndex = result.source.index; // group index

    if (!result.destination) {
      return { updateObject: updateObject, dragType: 'group' };
    }

    let destinationIndex = result.destination.index;

    let newPos = 0;
    if (sourceIndex === destinationIndex) {
    } else if (destinationIndex === 0) {
      // Moved to first position
      let first = docArray[0];
      newPos = Math.round(first.pos / 2);
    } else if (destinationIndex >= docArray.length - 1) {
      // Moved to last position
      let last = docArray[docArray.length - 1];
      newPos = Math.round(last.pos + 65535);
    } else {
      if (destinationIndex < sourceIndex) {
        // Moving backwards
        let prev = docArray[destinationIndex - 1];
        let pos = prev.pos;
        let current = docArray[destinationIndex];
        let posNext = current.pos;
        let posSpan = Math.round((posNext - pos) / 2);
        newPos = Math.round(posNext - posSpan);
      } else {
        // Moving forward

        // Moving forward
        let current = docArray[destinationIndex];
        let pos = current.pos;
        let next = docArray[destinationIndex + 1];
        let posNext = next.pos;
        let posSpan = Math.round((posNext - pos) / 2);
        newPos = Math.round(posNext - posSpan);
      }
    }

    if (typeof grps[sourceGroupId] != 'undefined' && newPos > 0) {
      updateObject.prev.grps[sourceGroupId] = cloneObject(grps[sourceGroupId]);
      grps[sourceGroupId].pos = newPos;
      updateObject.next.grps[sourceGroupId] = cloneObject(grps[sourceGroupId]);
    }

    return {
      updateObject: updateObject,
      dragType: 'group'
    };
  } else if (result.type === 'row') {
    //log(result);
    let sourceIndex = result.source.index; // index in group
    sourceGroupId = result.source.droppableId;

    if (!result.destination) {
      return { updateObject: updateObject, dragType: 'row' };
    }

    let destinationIndex = result.destination.index;
    let destinationGroupId = result.destination.droppableId;
    let rowsInGroup = getRowsInGroup(destinationGroupId, docArray);

    log("destinationIndex", destinationIndex);
    log("rowsInGroup.length", rowsInGroup.length);
    log("rowsInGroup", rowsInGroup);

    let newPos = 0;
    if (sourceGroupId === destinationGroupId && sourceIndex === destinationIndex) {
      return { updateObject: updateObject, dragType: 'row' };
    } else if (destinationIndex === 0) {
      // Moved to first position
      let firstRow = rowsInGroup[0];
      newPos = Math.round(firstRow.pos / 2);
    } else if ((sourceGroupId === destinationGroupId && destinationIndex >= rowsInGroup.length-1) 
    || (sourceGroupId !== destinationGroupId && destinationIndex >= rowsInGroup.length)) {
      // Moved to last position
      let lastRow = rowsInGroup[rowsInGroup.length - 1];
      newPos = Math.round(lastRow.pos + 65535);
    } else {
      if (destinationIndex < sourceIndex || sourceGroupId !== destinationGroupId) {
        // Moving backwards
        let rowPrev = rowsInGroup[destinationIndex - 1];
        let pos = rowPrev.pos;
        let rowCurrent = rowsInGroup[destinationIndex];
        let posNext = rowCurrent.pos;
        let posSpan = Math.round((posNext - pos) / 2);
        newPos = Math.round(posNext - posSpan);
      } else {
        // Moving forward
        let rowCurrent = rowsInGroup[destinationIndex];
        let pos = rowCurrent.pos;
        let rowNext = rowsInGroup[destinationIndex + 1];
        let posNext = rowNext.pos;
        let posSpan = Math.round((posNext - pos) / 2);
        newPos = Math.round(posNext - posSpan);
      }
    }

    //log('sourceRowId', sourceRowId);
    //log('group', destinationGroupId);
    //log('newPos', newPos);
    if (typeof rows[sourceRowId] != 'undefined' && newPos > 0) {
      updateObject.prev.rows[sourceRowId] = cloneObject(rows[sourceRowId]);
      rows[sourceRowId].grpId = destinationGroupId;
      rows[sourceRowId].pos = newPos;
      updateObject.next.rows[sourceRowId] = cloneObject(rows[sourceRowId]);
    }

    return {
      updateObject: updateObject,
      dragType: 'row'
    };
  }

  return { updateObject: updateObject, dragType: '' };
}
