import { getApp } from 'firebase/app';
import { getFirestore, doc, deleteDoc } from "firebase/firestore";

export const removeFolderFromDB = ({uid, id}) => {
  const firebase = getApp();
  return new Promise((resolve, reject) => {
    if (uid && id) {
      const firestore = getFirestore(firebase);
      let ref = doc(firestore, "users/"+uid+ "/docs/folder-"+id);
      deleteDoc(ref).then(resolve).catch(reject);
    } else {
      reject();
    }
  })
}