import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';

export const DocumentOptions = (props) => {
  let close = useRef(0);
  let ref = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof props.close !== 'undefined' && props.close > close.current) {
      close.current = props.close;
      setOpen(false);
    }
  }, [props, close]);

  return (
    <div ref={ref} className={
      "document-options"
      + (open ? " open" : "")
      }>
      <div className=" icon dropdown">
        <i className="fas fa-ellipsis-v" onClick={(e) => {
          e.stopPropagation();
          setOpen(open ? false : true);
        }} />
        {
        open && (
          <DropdownPortal parentRef={ref} close={() => {
            setOpen(false);
          }}>{props.children}</DropdownPortal>
        )
        }
      </div>
    </div>
  )
}

const DropdownPortal = (props) => {

  let dropdownRef = null;

  useEffect(() => {
    function handleClickOutside(e) {
      if (dropdownRef && !dropdownRef.contains(e.target) && !props.parentRef.current.contains(e.target)) {
        props.close();
      }
    }

    document.addEventListener('mousedown', handleClickOutside, false);

    return function () {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, []); // eslint-disable-line

  let parentRect = ReactDOM.findDOMNode(props.parentRef.current).getBoundingClientRect();
  let leftPos = parentRect.x;
  let topPos = parentRect.y;

  let css = {
    position: 'absolute',
    transform: 'translateX(-100%)',
    left: leftPos + 40,
    top: topPos + 30,
    zIndex: 50
  };

  return ReactDOM.createPortal(
    <div ref={(node) => { dropdownRef = node; }} style={css}>
            <ul>
              {props.children}
            </ul>
          </div>,
    document.getElementById('dropdown-root')
    );
}