const allnotes = [ 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B' ];
const majorScaleIntervals = [ 0, 2, 4, 5, 7, 9, 11 ];
const minorequals = {
  'A': 'F#m',
  'B': 'G#m',
  'C': 'Am',
  'D': 'Bm',
  'E': 'C#m',
  'F': 'Dm',
  'G': 'Em',
  'Ab': 'Fm',
  'Bb': 'Gm',
  'Cb': 'Abm',
  'Db': 'Bbm',
  'Eb': 'Cm',
  'Fb': 'Dbm',
  'Gb': 'Ebm',
  'A#': 'Gm',
  'B#': 'Am',
  'C#': 'A#m',
  'D#': 'B#m',
  'E#': 'C#m',
  'F#': 'D#m',
  'G#': 'E#m'
};
const majorequals = {
  'Am': 'C',
  'Bm': 'D',
  'Cm': 'Eb',
  'Dm': 'F',
  'Em': 'G',
  'Fm': 'Ab',
  'Gm': 'Bb',
  
  'Abm': 'Cb',
  'Bbm': 'Db',
  'Cbm': 'D',
  'Dbm': 'E',
  'Ebm': 'Gb',
  'Fbm': 'G',
  'Gbm': 'A',

  'A#m': 'B',
  'B#m': 'D#',
  'C#m': 'E#',
  'D#m': 'F#',
  'E#m': 'G#',
  'F#m': 'A',
  'G#m': 'B',
};
/*
const scales = [
  {
    name: 'major',
    int: [ 0, 2, 4, 5, 7, 9, 11 ]
  },
  //{
  //  name: 'minor',
  //  int: [ 2, 3, 5, 7, 8, 11 ]
  //}
];
*/

const chordsDef = {
  major: {
    intervals: [ 0, 4, 7 ],
    reg: /^[A-G]$|[A-G](?=[#b])/
  },
  minor: {
    intervals: [ 0, 3, 7 ],
    reg: /^[A-G][#b]?[m]/
  },
  dom7: {
    intervals: [ 0, 4, 7, 10 ],
    reg: /^[A-G][#b]?[7]/
  }
};

function convertIndex(index) {
  return index < 12 ? index : index - 12;
}

function getNotesFromChords(chordString) {
  var chordType, noteIndex;
  for (let chord in chordsDef) {
    if (chordsDef[chord].reg.test(chordString)) {
      chordType = chordsDef[chord];
      break;
    }
  }

  if (chordType) {
    noteIndex = allnotes.indexOf(chordString.match(/^[A-G][#b]?/)[0]);
    return addNotesFromChord(noteIndex, chordType);
  } else {
    return [];
  }
}

// then you add the notes from the chord to your array
// this is based on the interval signature of each chord.
// By adding definitions to chordsDef, you can handle as
// many chords as you want, as long as they have a unique regexp signature
function addNotesFromChord(noteIndex, chordType) {
  let notesArray = [];
  notesArray.push(allnotes[convertIndex(noteIndex)]);
  chordType.intervals.forEach(function(int) {
    if (notesArray.indexOf(allnotes[noteIndex + int]) === -1) {
      notesArray.push(allnotes[convertIndex(noteIndex + int)]);
    }
  });
  return notesArray;
}

function compareScalesAndNotes(notesArray) {
  let result = [];
  allnotes.forEach(function(note, notePos) { // [ 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B' ];
      let score = 0;

      //score += notesArray.filter((note) => { return note===allnotes[notePos] ? true : false }).length

      for (let noteInt of majorScaleIntervals) { // [ 0, 2, 4, 5, 7, 9, 11 ];
        score += notesArray.filter((note) => { return note===allnotes[convertIndex(noteInt + notePos)] ? true : false }).length;
      }

      result.push({
        score: score,
        key: note,
        type: 'major'
      });
  });

  result = result.sort((a, b) =>
    typeof a.score !== "undefined" && b.score !== "undefined" && a.score < b.score
      ? 1
      : -1
    );
  return result;
}

function parseBaseChord(chord) {
  let chordArray = chord.match(/^([A-GH]([#b♭])?)/);
  return chordArray && chordArray[0] ? chordArray[0] : '';
}

export const detectKeyByChords = (chords) => {
  if (!chords.length) {
    return [];
  }

  for (let i in chords) {
    chords[i] = chords[i].replace("♭", "b");

    if (chords[i].indexOf("/") >= 0) {
      chords[i] = chords[i].substr(0, chords[i].indexOf("/"));
    }
  }

  let notesArray = [];
  for (let chord of chords){
    let notes = getNotesFromChords(chord);
    //console.log(chord, notes);
    notesArray = notesArray.concat(notes);
  }
  //console.log("notesArray", notesArray);
  let guesses = compareScalesAndNotes(notesArray);
  if (guesses.length) {
    let maxScore = guesses[0].score;
    guesses = guesses.filter(d => { return d.score < maxScore ? false : true });
    if (guesses.length > 1) {

      let firstChord = parseBaseChord(chords[0]);

      guesses.sort(d => {
        if (!d.key) {
          return 0;
        }
        let chord = parseBaseChord(d.key);
        let firstMinor = typeof minorequals[firstChord] !== 'undefined' ? minorequals[firstChord] : '';
        let firstMajor = typeof majorequals[firstChord] !== 'undefined' ? majorequals[firstChord] : '';
        return chord === firstChord || chord === firstMinor || chord === firstMajor ? 1 : -1
      });
    }
  }

  return guesses;
};

export const detectKey = (rows) => {

  function checkBarFragment(fragment, chords) {
    if (typeof fragment.fragments === 'object' && Array.isArray(fragment.fragments) && fragment.fragments.length) {
      for (let subFragment of fragment.fragments) {
        chords = checkBarFragment(subFragment, chords);
      }
    } else {
      chords.push(fragment.chord);
    }
    return chords;
  }

  let chords = [];
  for (let row of Object.values(rows)) {
    if (typeof row.data !== 'undefined') {
      if (row.type === 'chordbars') {
        for (let bar of Object.values(row.data.bars)) {
          for (let fragment of bar.fragments) {
            chords.concat(checkBarFragment(fragment, chords));
          }
        }
      } else if (typeof row.data.chords !== 'undefined' && row.data.chords.length) {
        for (let chord of row.data.chords) {
          chords.push(chord.symbol);
        }
      }
    }
  }
  let detectedKey = '';
  let guesses = detectKeyByChords(chords);
  if (typeof guesses[0] !== 'undefined') {
    if (guesses[0].type === 'minor') {
      detectedKey = (typeof majorequals[guesses[0].key] !== 'undefined') ? majorequals[guesses[0].key].key : ''; // Object.keys(minorequals).find(key => minorequals[key] === guesses[0].key)
    } else {
      detectedKey = guesses[0].key;
    }
  }
  return detectedKey;
}