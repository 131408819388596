import React, {useRef} from "react";
import { handle_api_error, api_patch } from '../../API';
import { addToLocalCache } from '../../components/Storage';
import { stripTags } from '../../lib/stripTags';

export const PlaylistTitle = ({docs, listId, listName, loadPlaylist}) => {

  const editorRef = useRef();
  const lastEmittedText = useRef();
  let updateTimer = null;
  let updateTimeMS = 3000;

  const handleKeyDown = (event) => {
    if (typeof event.key == 'undefined') {
      event.preventDefault();
    }
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      return;
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }

    if (updateTimer) {
      clearTimeout(updateTimer);
    }
    updateTimer = setTimeout(() => {
      emitChanges();
    }, updateTimeMS);
  }

  const emitChanges = () => {
    if (updateTimer) {
      clearTimeout(updateTimer);
    }
    let newTitle = (editorRef.current && typeof editorRef.current != 'undefined') ? editorRef.current.innerHTML : '';
    newTitle = stripTags(newTitle);
    if (newTitle !== lastEmittedText.current) {


      docs['list-'+listId].title = newTitle;
      loadPlaylist(listId, docs);
      addToLocalCache("list", docs['list-'+listId]);

      api_patch('/lists/' + listId, {
        title: newTitle
      }).then(() => {}).catch(handle_api_error);

    }
    lastEmittedText.current = newTitle;
  }

  const handleBlur = () => {
    if (editorRef.current.innerHTML !== lastEmittedText.current) {
      emitChanges();
    }
  }
  return (
    <div className="list-title">
      <div><span onClick={() => { loadPlaylist(""); }}><i className="fas fa-chevron-left"></i></span></div>
      <h1
        ref={editorRef}
        contentEditable
        dangerouslySetInnerHTML={{ __html: listName }}
        spellCheck="false"
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />
    </div>
  );
}