import React from 'react';
import ReactDOM from 'react-dom';
import { Translation } from 'react-i18next';
import { cloneObject } from '../../../lib/cloneObject';
import { SaveInSidebar } from './SaveInSidebar';



export class RowToolsLeft extends React.Component {
  render() {
    return (
      <div className={'rowtools rtl'}>
        <span className="handle" {...this.props.dragRowHandleProps}>
          <i className="fas fa-grip-horizontal" />
        </span>
        <span
          className={this.props.checked ? 'checkbox checked' : 'checkbox'}
          onClick={(event) => {
            this.props.handleRowCheckToggle(!this.props.checked);
          }}
        >
          <i className="fas fa-check" />
        </span>
      </div>
    );
  }
}

export class RowToolsRight extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false, dropdownCSS: {} };
    this.ref = React.createRef();
    this.dropdownRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.handleRowToGroup = this.handleRowToGroup.bind(this);
    this.handleGlueToGroupAbove = this.handleGlueToGroupAbove.bind(this);
    this.handleNewRowAfter = this.handleNewRowAfter.bind(this);
    this.handleRowDuplicate = this.handleRowDuplicate.bind(this);
    this.handleRowClone = this.handleRowClone.bind(this);
    this.handleRowDelete = this.handleRowDelete.bind(this);
    this.handleRowConvert = this.handleRowConvert.bind(this);
    this.pasteChords = this.pasteChords.bind(this);
    this.pasteClipboard = this.pasteClipboard.bind(this);
    this.toClipboard = this.toClipboard.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }

  handleClickOutside(e) {
    if (this.ref && !this.ref.contains(e.target)) {
      this.setState({ open: false });
      this.props.onMenuClose();
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleRowToGroup() {
    this.props.handleRowToGroup(this.props.row);
  }
  handleGlueToGroupAbove() {
    this.props.handleGlueToGroupAbove(this.props.row);
  }
  handleRowDuplicate() {
    this.props.handleRowDuplicate(this.props.row);
  }
  handleNewRowAfter() {
    this.props.handleNewRowAfter(this.props.row);
  }
  handleRowClone() {
    this.props.handleRowClone(this.props.row);
  }
  handleRowDelete() {
    this.props.handleRowDelete(this.props.row);
  }
  handleRowConvert() {
    this.props.handleRowConvert(this.props.row);
  }
  toClipboard() {
    if (this.props.lyricsReadonly) {
      let row = cloneObject(this.props.row);
      row.data.text = '';
      this.props.toClipboard(row);
    } else {
      this.props.toClipboard(this.props.row);
    }
    this.close();
  }
  pasteChords() {
    this.props.pasteChords(this.props.row);
    this.close();
  }
  pasteClipboard() {
    if (!this.props.clipboard.length) {
      return false;
    }
    this.props.pasteClipboard({ after: this.props.row });
    this.close();
  }
  toggle() {
    let newState = !this.state.open;
    if (newState) {
      this.props.onMenuOpen();
    } else {
      this.props.onMenuClose();
    }
    this.setState({ open: newState }, () => {
      if (newState) {
        // let parentRect = ReactDOM.findDOMNode(this.ref).getBoundingClientRect();
        let dropdownRect = ReactDOM.findDOMNode(this.dropdownRef).getBoundingClientRect();
        if (dropdownRect.right > window.innerWidth) {
          this.setState({ dropdownCSS: {right: 0} });
        }
      }
    });
  }
  close() {
    this.props.onMenuClose();
    this.setState({ open: false });
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className="rowtools rtr">
            {this.props.lyricsReadonly ? (
              ''
            ) : (
              <React.Fragment>
                {/*
                    this.props.getIndexInGroup() > 0
                    ? <div className="icon" onClick={this.handleRowToGroup}><i className="fas fa-level-down-alt"></i></div>
                    : this.props.getIndex() > 0 ? <div className="icon" onClick={this.handleGlueToGroupAbove}><i className="fas fa-level-up-alt"></i></div> : ''
                  */}
                <div className="icon swipeout" onClick={this.handleRowDuplicate}>
                  <i className="far fa-clone" />
                  {/* <i className="material-icons md-16">content_copy</i> */}
                </div>
                <div className="icon swipeout" onClick={this.handleRowDelete}>
                  <i className="far fa-trash-alt" />
                  {/* <i className="material-icons md-18">delete_outline</i> */}
                </div>
              </React.Fragment>
            )}

            <div
              ref={(node) => {
                this.ref = node;
              }}
              className="icon dropdown"
              //onMouseLeave={this.close}
            >
              <i className="fas fa-ellipsis-v" onClick={this.toggle} />
              {this.state.open ? (
                this.props.lyricsReadonly ? (
                  <div style={this.state.dropdownCSS} ref={(node) => {
                    this.dropdownRef = node;
                  }}>
                    <ul>
                      <li
                        onClick={() => {
                          this.toClipboard();
                          this.props.setNotification(t('Notification.RowCopied'));
                        }}
                      >
                        <span>
                          <i className="far fa-copy" />
                          {t('RowTools.CopyRowChords')}
                        </span>
                      </li>
                      {
                        this.props.row.type === 'lyrics'
                        ? (
                          <li className={!this.props.clipboard.length ? 'inactive' : ''} onClick={this.pasteChords}>
                            <span>
                            <i className="fas fa-paste" />
                            {/* <i className="material-icons-outlined">content_paste</i> */}
                              {t('RowTools.PasteChordsHere')}
                            </span>
                          </li>
                        )
                        : ''
                      }
                    </ul>
                  </div>
                  ) : (
                    <div style={this.state.dropdownCSS} ref={(node) => {
                      this.dropdownRef = node;
                    }}>
                  <ul>
                    {this.props.getIndexInGroup() > 0 ? (
                      <li onClick={this.handleRowToGroup}>
                        <span>
                          <i className="material-icons upside-down md-16">keyboard_return</i>
                          {t('RowTools.NewGroupFromThisRow')}
                        </span>
                      </li>
                    ) : this.props.getIndex() > 0 ? (
                        <li onClick={this.handleGlueToGroupAbove}>
                          <span>
                            {/* <i className="fas fa-level-up-alt" /> */}
                            <i className="material-icons md-16">vertical_align_top</i>
                            {t('RowTools.GlueToPrevousGroup')}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}
{/* 
                    <li onClick={() => {}}>
                      <span>
                        <i className="material-icons md-16">keyboard_return</i>
                        {t('RowTools.NewGroupBelow')}
                      </span>
                    </li> */}

                    <li onClick={this.handleNewRowAfter}>
                      <span>
                        <i className="material-icons md-18">playlist_add</i>
                        {t('RowTools.NewRowAfter')}
                      </span>
                    </li>
                    <li onClick={this.handleRowDuplicate}>
                      <span>
                      <i className="far fa-clone" />
                      {/* <i className="material-icons md-16">content_copy</i> */}
                        {t('RowTools.Duplicate')}
                      </span>
                    </li>
                    {
                      /*
                      !this.props.isRef
                      ? (
                      <li onClick={this.handleRowClone}>
                        <span>
                          <i className="far fa-clone" />
                          {t('RowTools.Clone')}
                        </span>
                      </li>
                      )
                      : ''
                      */
                    }
                    <li onClick={this.handleRowDelete}>
                      <span>
                        <i className="far fa-trash-alt" />
                        {/* <i className="material-icons md-18">delete_outline</i> */}
                        {t('RowTools.Delete')}
                      </span>
                    </li>

                    {}
                    {!this.props.isRef && (<li className="devider" />)}

                    {this.props.row.type === 'chordbars' && !this.props.isRef && (
                      <li onClick={this.handleRowConvert}>
                        <span>
                        <i className="material-icons md-16">sync</i>
                          {t('RowTools.ConvertToLyrics')}
                        </span>
                      </li>
                    )}
                    {this.props.row.type === 'lyrics' && !this.props.isRef && (
                      <li onClick={this.handleRowConvert}>
                        <span>
                        <i className="material-icons md-16">sync</i>
                          {t('RowTools.ConvertToBars')}
                        </span>
                      </li>
                    )}
                    {}

                    <li className="devider" />
                    <li
                      onClick={() => {
                        this.toClipboard();
                        this.props.setNotification(t('Notification.RowCopied'));
                      }}
                    >
                      <span>
                        <i className="far fa-copy" />
                        {/* <i className="material-icons md-16">content_copy</i> */}
                        {t('RowTools.CopyRow')}
                      </span>
                    </li>

                    {this.props.row.type === 'lyrics' && !this.props.isRef ? (
                      <React.Fragment>
                        <li className={!this.props.clipboard.length ? 'inactive' : ''} onClick={this.pasteChords}>
                          <span>
                          <i className="material-icons-outlined">content_paste</i>
                            {t('RowTools.PasteChordsHere')}
                          </span>
                        </li>
                        <li className={!this.props.clipboard.length ? 'inactive' : ''} onClick={this.pasteClipboard}>
                          <span>
                          <i className="material-icons-outlined">content_paste</i>
                            {t('RowTools.PasteAfter')}
                          </span>
                        </li>

                        <li className="devider" />
                        
                        <SaveInSidebar 
                          inactive={false}
                          t={t}
                          row={this.props.row}
                          getSong={this.props.getSong}
                          updateRowData={this.props.updateRowData}
                          performUpdate={this.props.performUpdate}
                          closeMenu={this.close}
                        />

                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
                  )
                
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
