import React from 'react';
import { Modal } from './Modal';
import { Translation } from 'react-i18next';
import textareaAutoHeight from '../lib/textareaAutoHeight';
import { handle_api_error, api_post } from '../API';

export class HelpMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, watchVideo: false, showContactForm: false, message: '', formSend: false };
    this.containerRef = null;
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);

    //const script = document.createElement("script");
    //script.src = "https://embed.small.chat/T018WFNDZM3G018WFPLP0D.js";
    //script.async = true;
    //script.onload = () => { };
    //document.body.appendChild(script);
    // <script src="https://embed.small.chat/T018WFNDZM3G018WFPLP0D.js" async></script>
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (this.containerRef && !this.containerRef.contains(e.target)) {
      this.setState({ open: false });
    }
  }

  handleChange(event) {
    this.setState({ message: event.target.message });
  }
  handleKeyDown(event) {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
    }
  }
  handleKeyUp(event) {
    textareaAutoHeight(this.inputRef);
  }
  send() {
    api_post('/contact', { message: this.state.message })
      .then((resp) => {
        this.setState({ formSend: true });
      })
      .catch(handle_api_error);
  }
  render() {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            {this.state.showContactForm ? (
              <Modal
                close={() => {
                  this.setState({ showContactForm: false });
                }}
                className="medium"
              >
                <div className="contact-form">
                  <h2>{t('Help.AskAQuestion')}</h2>
                  <div>
                    <textarea
                      value={this.state.message}
                      ref={(node) => {
                        this.inputRef = node;
                      }}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                      onKeyUp={this.handleKeyUp}
                    />
                  </div>
                  <div>
                    <button className="higlighted" onClick={this.send}>
                      {t('Help.Send')}
                    </button>
                  </div>
                </div>
              </Modal>
            ) : (
              ''
            )}

            {this.state.watchVideo ? (
              <Modal
                close={() => {
                  this.setState({ watchVideo: false });
                }}
                className="large"
              >
                <div className="video-wrapper">
                  <iframe
                    title="Demo"
                    width="560"
                    height="315"
                    src={'https://www.youtube.com/embed/' + this.state.watchVideo}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </Modal>
            ) : (
              ''
            )}

            <div
              ref={(node) => {
                this.containerRef = node;
              }}
              className="dropdown dropdown-right dropdown-up help-menu"
            >
              <span
                onClick={() => {
                  this.setState({
                    open: !this.state.open
                  });
                }}
              >
                ?
              </span>
              {this.state.open ? (
                <div>
                  <ul>
{/* 
                    <li>
                      <span
                        onClick={() => {
                          this.setState({ showContactForm: true });
                        }}
                      >
                        <i className="far fa-comments" />
                        {t('Help.AskAQuestion')}
                      </span>
                    </li>
                     */}
                    <li>
                      <span
                        onClick={() => {
                          this.setState({ watchVideo: 'k9n_gwjQU7w' });
                        }}
                      >
                        <i className="fab fa-youtube" />
                        {t('Help.HowToWriteLyrics')}
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          this.setState({ watchVideo: 'f-RQkTXJ17s' });
                        }}
                      >
                        <i className="fab fa-youtube" />
                        {t('Help.HowToAddChords')}
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                ''
              )}
            </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}
