//import {getCallerTree} from "./getCallerTree.js";

/**
 *
 * @param {*} focusPos (Prefered position of cursor)
 * @param {*} ref (Element to focus)
 */
export const focusElementAtPosition = (focusPos = 0, ref = null) => {

  //let callerTree = getCallerTree(5);
  //console.log("focusElementAtPosition", focusPos, ref);
  //console.log(callerTree);

  if (ref) {
    if (focusPos > 0 && typeof ref.childNodes != 'undefined') {
      if (focusPos && ref.childNodes.length) {
        let focusNode = false;
        let totLen = 0;
        let setFocusAt = 0;
        for (let current of ref.childNodes) {
          setFocusAt = focusPos - totLen;
          focusNode = current.childNodes[0] || current;
          totLen += focusNode.textContent.length;

          if (totLen > focusPos) {
            break;
          }
        }

        if (focusPos > totLen) {
          setFocusAt = focusNode.length;
        }

        let range = document.createRange();
        let sel = window.getSelection();
        range.setStart(focusNode, setFocusAt);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
    ref.focus();
  }
}
