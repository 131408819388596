
const maxWidthPhone = 576;
const maxWidthTablet = 768;
//const maxWidthSmallDesktop = 992;
const maxWidthMediumDesktop = 1200;

export const isMobile = () => {
  return window.innerWidth <= maxWidthPhone ? true : false;
}

export const isTablet = () => {
  return window.innerWidth > maxWidthPhone && window.innerWidth <= maxWidthTablet ? true : false;
}

export const isPhoneOrTablet = () => {
  return window.innerWidth <= maxWidthTablet ? true : false;
}

export const isDesktop = () => {
  return window.innerWidth > maxWidthTablet ? true : false;
}

export const isLargeDesktop = () => {
  return window.innerWidth > maxWidthMediumDesktop ? true : false;
}

export const isTouchDevice = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}
