import { getRowsInGroup, getIndexOf, getPosBetween, getNewRow } from '../utils.js';
import { cloneObject } from '../cloneObject.js';

export const handleDevideRowAtPosition = (attr) => {
  let { rowText, row, cursorPos, rows, docArray, updateObject } = attr;

  let currentGroupRows = getRowsInGroup(row.grpId, docArray);

  let htmlPreviousRow = false;
  let htmlNewRow = false;
  if (cursorPos <= rowText.length) {
    htmlPreviousRow = rowText.substr(0, cursorPos);
    htmlNewRow = rowText.substr(cursorPos);
  }

  // Current row
  let rowindex = getIndexOf(row.id, currentGroupRows);
  if (rowindex === false) {
    return { newRow:{}, updateObject };
  }

  // Prepare chords
  let newRowChords = [];
  for (let x in row.data.chords) {
    if (row.data.chords[x].pos >= cursorPos) {
      let chord = row.data.chords[x];
      chord.pos = chord.pos - cursorPos;
      newRowChords.push(chord);
      row.data.chords.splice(x, 1);
    }
  }

  // Get position for new row
  // Next row
  let nextRowindex = rowindex + 1;
  let rowNext = typeof currentGroupRows[nextRowindex] != 'undefined' ? currentGroupRows[nextRowindex] : false;
  let newRowPosition = getPosBetween(row, rowNext);

  // Create new row
  let newRow = getNewRow({
    grpId: row.grpId,
    pos: newRowPosition,
    type: 'lyrics',
    data: { 
      text: htmlNewRow || '',
      chords: newRowChords
    }
  });

  // Add new row
  rows[newRow.id] = newRow;
  updateObject.prev.rows[newRow.id] = {id: newRow.id, deleted: true};
  updateObject.next.rows[newRow.id] = cloneObject(newRow);

  // Update previous row
  updateObject.prev.rows[row.id] = cloneObject(row);
  rows[row.id] = row;
  rows[row.id].data.text = htmlPreviousRow;
  updateObject.next.rows[row.id] = cloneObject(rows[row.id]);

  return { newRow, updateObject };
}
