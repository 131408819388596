import React, { useState } from 'react';
import minorequals from "../../config/minorequals"

export const ToolbarKeySelect = (props) => {

	const keys = {
		naturals: [ 'A', 'B', 'C', 'D', 'E', 'F', 'G' ],
		flats: [ 'Ab', 'Bb', 'Cb', 'Db', 'Eb', 'Fb', 'Gb' ],
		sharps: [ 'A#', 'B#', 'C#', 'D#', 'E#', 'F#', 'G#' ]
	};

    let defaultKeyFilter = 'naturals';
    if (typeof props.env.songKey === 'string' && props.env.songKey.indexOf('b') > 0) {
        defaultKeyFilter = 'flats';
    } else if (typeof props.env.songKey === 'string' && props.env.songKey.indexOf('#') > 0) {
        defaultKeyFilter = 'sharps';
    }

	const [ keyFilter, setKeyfilter ] = useState(defaultKeyFilter);

    

	return (
		<ul className="key-select">
			<li className="tabs">
				<div
					onClick={() => {
						setKeyfilter('naturals');
					}}
				><span className={keyFilter === 'naturals' ? 'active' : ''}>♮</span></div>
				<div
				
				onClick={() => {
					setKeyfilter('sharps');
				}}
				><span className={keyFilter === 'sharps' ? 'active' : ''}>♯</span></div>
				<div
				
				onClick={() => {
					setKeyfilter('flats');
				}}
				><span className={keyFilter === 'flats' ? 'active' : ''}>♭</span></div>
			</li>
			{Object.keys(keys).map((keytype) => {
				return keyFilter === keytype
					? keys[keytype].map((songKey) => (
							<li
								className={props.env.songKey === songKey ? 'selected' : ''}
								key={songKey}
								data-songkey={songKey}
								onClick={() => {
									props.setEnvVariable('songKey', songKey);
								}}
							>
								{songKey} <span className="grayed">({minorequals[songKey]})</span>
							</li>
						))
					: '';
			})}
			<li
				className={props.env.songKey === false ? 'selected' : ''}
				data-songkey=""
				onClick={() => {
					props.setEnvVariable('songKey', false);
				}}
			>
				{props.t("EditorToolbar.AutoDetect")}
			</li>
		</ul>
	);
};
