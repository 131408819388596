import React from 'react'
import {MetaEditor} from '../editor/MetaEditor'
import { Translation } from "react-i18next";

export class ToolbarMeta extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            edit: false,
        }
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.remove = this.remove.bind(this);

    }
    save(metaId, metaData) {
      this.props.updateMeta(metaId, metaData);
      this.setState({edit: false});
    }
    remove(metaId) {
      this.props.removeMeta(metaId);
      this.setState({edit: false});
    }
    cancel() {
      this.setState({edit: false});
    }

    render() {
      if (this.state.edit !== false) {
        return (
          <div className="meta-editor">
            <MetaEditor save={this.save} cancel={this.cancel} remove={this.remove} metaId={this.state.edit} meta={this.props.meta} />
          </div>
      )
      } else {
        return (
          <Translation>
        {(t) => (
          <div className="meta-editor">
            <h2>{t("Contributers.EditorTitle")}</h2>
            {
              !Object.keys(this.props.meta).length
              ? <p>{t("Contributers.EditorDescription")}</p>
              : ''
            }
            
            <div className="meta-editor-list">  
              <ul>  
                  {
                    Object.keys(this.props.meta).length
                    ? Object.keys(this.props.meta).map((metaId) => {
                      let data = this.props.meta[metaId];
                      return <li key={metaId} onClick={() => {
                        this.setState({edit: metaId});
                      }}>
                        <span>{data.fullname}</span>
                        <span className="grayed">{t("Contributers." + data.type + '_' + data.role)}</span>
                        <span onClick={e => {
                          e.stopPropagation();
                          this.remove(metaId);
                        }}><i className="far fa-trash-alt"></i></span>
                      </li>;
                    })
                    : <li className="grayed" onClick={() => {
                      this.setState({edit: true});
                  }}>{t("Contributers.NoAuthors")}</li>
                  }
              </ul>
            </div>
            <div className="btn-group center">
              <button className="highlighted" onClick={() => {
                  this.setState({edit: true});
              }}>
                <i className="icon-plus white"></i> {t("Contributers.AddAuthorButton")}
              </button>
              </div>
          </div>
        )}
        </Translation>
        )
      }
        
    }
    
}