import React from 'react';
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ViewNotFound = (props) => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Helmet>
                <title>{'404 | ' + t("NotFound.PageNotFound") + ' | ' + t('SiteName')}</title>
                <meta property="og:title" content={t("NotFound.PageNotFound")} />
            </Helmet>

            <div className="article-page">
                <div className="article-container">
                    <article>
                        <p className="h3 branding">{t('SiteName')}</p>
                        <h1>404 | {t("NotFound.PageNotFound")}</h1>
                        <p><Link to="/" className="button">{t("NotFound.GoToStart")}</Link></p>
                    </article>
                </div>
            </div>
        </React.Fragment>
        
    );
}
