import { cloneObject } from '../cloneObject.js';
import { getRowByIndex } from '../utils.js';

export const handleMergeTextWithNextRow = (attr) => {
    let { updateObject,  row, rows, docArray } = attr;
    
    let currentRow = getRowByIndex(row.index, docArray);
    let nextRow = getRowByIndex(row.index + 1, docArray);

    if (nextRow) {
      updateObject.prev.rows[nextRow.id] = cloneObject(rows[nextRow.id]);
      rows[nextRow.id].data.text = currentRow.data.text + rows[nextRow.id].data.text;
      updateObject.next.rows[nextRow.id] = cloneObject(rows[nextRow.id]);
    }

    //TODO: Add chords from deleted row

    updateObject.prev.rows[currentRow.id] = rows[currentRow.id];
    updateObject.next.rows[currentRow.id] = { id: currentRow.id, deleted: true };

    return updateObject;
}