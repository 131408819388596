import React from 'react';

export class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownList: null
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }
  componentDidUpdate(prevProps) {
    if (this.props.closeDropdown > 0 && prevProps.closeDropdown !== this.props.closeDropdown) {
      this.setState({ dropdownOpen: false });
    }
  }
  handleClickOutside(e) {
    if (this.dropdownContainerRef && !this.dropdownContainerRef.contains(e.target)) {
      this.setState({ dropdownOpen: false });
    }
  }

  render() {
    return (
      <div 
        className={
          "dropdown-button " 
          + (this.state.dropdownOpen ? 'dropdown-open ' : '')
          + (this.props.className ? this.props.className : '')
        } 
        ref={node => { this.dropdownContainerRef = node; }}
        onMouseEnter={() => {
          this.setState({ dropdownOpen: true });
        }}
        onMouseLeave={() => {
          this.setState({ dropdownOpen: false });
        }}
        >
        <span />
        {this.props.button}
        {this.state.dropdownOpen ? <div>{this.props.children}</div> : ''}
      </div>
    );
  }
}
