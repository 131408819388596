import { getApp } from 'firebase/app';
import { getFirestore, doc, updateDoc } from "firebase/firestore";

export const moveToFolder = ({uid, doctype, docId, folderId}) => {
  const firebase = getApp();
  return new Promise((resolve, reject) => {
    if (uid && docId && (doctype === 'song' || doctype === 'list')) {
      const firestore = getFirestore(firebase);
      let ref = doc(firestore, "users/"+uid+ "/docs/"+doctype+"-"+docId);
      if (ref) {
        updateDoc(ref, { parent: folderId }).then(resolve).catch(reject);
      }
    } else {
      reject();
    }
  })
}