const fontFamilies = [
	'Quicksand',
	'Roboto',
	'Roboto Mono',
	'Lora',
	'Mali',
	'Indie Flower',
	'Dancing Script',
	'Marck Script'
];
export default fontFamilies;