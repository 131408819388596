import React from 'react';
import { Dropdown } from '../../../Dropdown';
import languages from '../../../../config/languages.js';

export class LanguageDropdown extends React.Component {
  constructor(props) {
    super();
    this.state = { closeDropdown: 0 };
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(e) {
    let target = e.currentTarget;
    this.setState({ closeDropdown: this.state.closeDropdown + 1 }, () => {
      this.props.changeLanguage(target.getAttribute("data-code"));
    });
  }

  render() {
    let currentLanguageCode = this.props.language.length === 2 ? this.props.language : 'en';
    let currentLanguage = languages.find((lang) => {
      return lang.code === currentLanguageCode;
    });

    return (
      <Dropdown
        label={<i className={'flag fl-' + currentLanguageCode}>{currentLanguage.language}</i>}
        closeDropdown={this.state.closeDropdown}
      >
        <ul>
          {languages.map((lang, index) => {
            return (
              <li key={index} data-code={lang.code} onClick={this.changeLanguage}>
                <span>{lang.language}</span>
              </li>
            );
          })}
        </ul>
      </Dropdown>
    );
  }
}
