import React from 'react';
import { HelpBubble } from '../HelpBubble';

export class ToolbarDropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false, showHelpBubble: false };
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggle = this.toggle.bind(this);
    
  }
  
  handleClickOutside(e) {
    if (this.ref && !this.ref.contains(e.target)) {
      this.setState({ open: false}); // 
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  componentDidUpdate(prevProps) {
    if (this.props.closeDropdown > 0 && prevProps.closeDropdown !== this.props.closeDropdown) {
      this.setState({ open: false });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  toggle() {
    if (!this.props.disabled) {
      this.setState({open: !this.state.open, showHelpBubble: false});
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={
            "option dropdown" 
            + (this.props.className ? ' ' + this.props.className : '') 
            + (this.props.withText ? ' with-text' : '') 
            + (this.state.open && !this.props.disabled ? ' active' : '') 
            + (this.props.active && !this.props.disabled ? ' active' : '') 
            + (this.props.disabled ? ' disabled' : '') 
            + (this.props.justifyRight ? ' dropdown-right' : '') 
          }
          ref={(node) => { this.ref = node }}>
            <span 
              onClick={this.toggle}
              onMouseEnter={() => {
                if (!this.state.open) {
                  this.setState({ showHelpBubble: true });
                }
              }}
              onMouseLeave={() => {
                this.setState({ showHelpBubble: false });
              }}
              >{this.props.label}</span>
            {
              this.state.open && !this.props.disabled
              ? <div>
                    {this.props.children}
                </div>
              : ''
            }
        </div>
        {
          this.props.helpBubble
          ? (
            <HelpBubble visible={this.state.showHelpBubble} {...this.props.helpBubble} />
          )
          : ''
        }
      </React.Fragment>
    );
  }
}