import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Translation } from 'react-i18next';
import { Row } from './Row';
import { GroupLabel } from './GroupLabel';
import { Droppable, Draggable } from 'react-beautiful-dnd'; // DragDropContext
import {
  isDeleted
} from '../../lib/utils';

export class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      focused: false,
      selectedRows: false,
      labelActivated: false
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.loading !== this.props.loading) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    if (this.props.selectedGroups.includes(this.props.group.id) && !this.state.checked) {
      this.setState({ checked: true });
    } else if (!this.props.selectedGroups.includes(this.props.group.id) && this.state.checked) {
      this.setState({ checked: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.selectedRows || this.props.selectedRows.length === 0) {
      if (this.state.selectedRows && this.state.selectedRows.length > 0) {
        this.setState({ checked: false, selectedRows: false });
      }
    }
  }

  handleGroupCheckToggle = (checked) => {
    let { selectedRows } = this.props.handleGroupCheckToggle(checked, this.props.group.id);
    this.setState({ checked: checked, selectedRows: selectedRows });
  }

  setFocused = (isFocused) => {
    if (isFocused !== this.state.focused) {
      this.setState({ focused: isFocused });
    }
  }

  activateLabel = () => {
    this.setState({labelActivated: true});
  }
  deactivateLabel = () => {
    this.setState({labelActivated: false});
  }

  render() {
    let rowindex = this.props.rowindex;
    let selectedRows = [];
    if (this.state.selectedRows !== false) {
      selectedRows = this.state.selectedRows;
    } else {
      selectedRows = this.props.selectedRows;
    }

    return (
      <Draggable
        draggableId={'DraggableGroup-' + this.props.group.id}
        index={this.props.groupIndex}
        key={this.props.group.id}
        isDragDisabled={this.props.editActive ? false : true}
      >
        {(dragGroupProvided, dragGroupSnapshot) => (
          <div ref={dragGroupProvided.innerRef} {...dragGroupProvided.draggableProps}>
            <Droppable
              droppableId={this.props.group.id}
              direction="vertical"
              type="row"
              key={this.props.groupIndex}
            >
              {(DroppableProvided, DroppableSnapshot) => (
                <div
                  className={
                    'group' +
                    (this.state.checked || this.state.focused ? ' focused' : '') +
                    (this.props.group.label || this.state.labelActivated ? ' has-label' : '')
                  }
                  ref={DroppableProvided.innerRef}
                  {...DroppableProvided.droppableProps}
                >
                  <div>

                    <div className="phead">
                      
                      <GroupToolsLeft 
                        dragHandleProps={dragGroupProvided.dragHandleProps} 
                        editActive={this.props.editActive}
                        />
                      
                      {
                        this.props.lyricsReadonly ? '' : (
                          <GroupCheckbox
                            checked={this.state.checked}
                            handleGroupCheckToggle={this.handleGroupCheckToggle}
                            editActive={this.props.editActive}
                          />
                        )
                      }
                      

                      {
                        this.props.numberOfRows>1
                        ? <GroupLabel
                            jumpToGroup={this.props.jumpToGroup}
                            updateGroup={this.props.updateGroup}
                            grpId={this.props.group.id}
                            label={this.props.group.label || ''}
                            labelActivated={this.state.labelActivated}
                            editActive={this.props.editActive}
                            deactivateLabel={this.deactivateLabel}
                          />
                          : ''
                      }
                      
                      <GroupToolsRight
                        grpId={this.props.group.id}
                        setFocused={this.setFocused}
                        activateLabel={this.activateLabel}
                        label={this.props.group.label || ''}
                        handleGroupDuplicate={this.props.handleGroupDuplicate}
                        handleGroupDelete={this.props.handleGroupDelete}
                        handleGroupCopy={this.props.handleGroupCopy}
                        pasteChords={this.props.pasteChords}
                        editActive={this.props.editActive}
                        lyricsReadonly={this.props.lyricsReadonly}
                        chordsActive={this.props.chordsActive}
                        firstRow={this.props.group.rows[0] || {}}
                        clipboard={this.props.clipboard}
                        setNotification={this.props.setNotification}
                        groupToSnippets={this.props.groupToSnippets}
                      />
                      
                    </div>
                    

                    <div className="group-inner">
                      {this.props.group.rows.map((row, rowindexInGroup) => {
                        
                        if (isDeleted(row)) {
                          return '';
                        }
                        
                        row.indexInGroup = rowindexInGroup;
                        row.index = rowindex;
                        rowindex++;

                        return (
                          <Draggable
                            draggableId={'DraggableRow-' + this.props.group.id + '-' + row.id}
                            index={rowindexInGroup}
                            key={row.id}
                            isDragDisabled={!this.props.editActive || row.lyricsReadonly ? true : false}
                          >
                            {(dragRowProvided) => (
                              <Row
                                getSong={this.props.getSong}
                                getDocArray={this.props.getDocArray}
                                forceRender={this.props.forceRender}
                                dragRowProvided={dragRowProvided}
                                env={this.props.env}
                                editActive={this.props.editActive}
                                lyricsReadonly={this.props.lyricsReadonly}
                                chordsActive={this.props.chordsActive}
                                songKey={this.props.env.songKey || this.props.env.songKeyDetected}
                                transpose={this.props.transpose}
                                row={row}
                                focused={
                                  this.props.focused === row.id || (selectedRows && selectedRows.includes(row.index))
                                }
                                parentGroupSelected={this.state.checked}
                                selectedRows={selectedRows || []}
                                dragRowHandleProps={dragRowProvided.dragHandleProps}
                                
                                pasteChords={this.props.pasteChords}
                                pasteClipboard={this.props.pasteClipboard}
                                toClipboard={this.props.toClipboard}
                                clipboard={this.props.clipboard}

                                handleFocusRow={this.props.handleFocusRow}
                                handleRowCheckToggle={(checked, rowId) => {
                                  this.props.handleRowCheckToggle(checked, rowId);
                                  if (!checked) {
                                    this.setState({ checked: false });
                                  }
                                }}
                                handlePastedText={this.props.handlePastedText}
                                updateLanguage={this.props.updateLanguage}
                                language={this.props.language}
                                
                                handleMergeTextWithNextRow={this.props.handleMergeTextWithNextRow}
                                handleRowToGroup={this.props.handleRowToGroup}
                                handlePressEnter={this.props.handlePressEnter}
                                handlePressBackspace={this.props.handlePressBackspace}
                                handleGlueToGroupAbove={this.props.handleGlueToGroupAbove}
                                handleNewRowAfter={this.props.handleNewRowAfter}
                                handleRowDuplicate={this.props.handleRowDuplicate}
                                handleRowClone={this.props.handleRowClone}
                                handleRowDelete={this.props.handleRowDelete}
                                handleRowConvert={this.props.handleRowConvert}
                                jumpToRow={this.props.jumpToRow}
                                jumpUp={this.props.jumpUp}
                                jumpDown={this.props.jumpDown}
                                setRowRef={this.props.setRowRef}
                                getRowRef={this.props.getRowRef}
                                closeChordEditor={this.props.closeChordEditor}
                                openChordEditorAt={this.props.openChordEditorAt}
                                chordEditorIsOpenAtRow={this.props.chordEditorIsOpenAtRow}
                                chordEditorIsOpenAtPos={this.props.chordEditorIsOpenAtPos}
                                handleChordDroppedOnLyrics={this.props.handleChordDroppedOnLyrics}
                                handleChordDroppedOnBar={this.props.handleChordDroppedOnBar}

                                updateRowData={this.props.updateRowData}
                                performUpdate={this.props.performUpdate}

                                setNotification={this.props.setNotification}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                    </div>

                    {DroppableProvided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    );
  }
}

function GroupCheckbox(props) {
  return (
    <div className="groupcheckbox">
      <span
        className={props.checked ? 'checkbox multiple checked' : 'checkbox multiple'}
        onClick={() => {
          props.handleGroupCheckToggle(!props.checked);
        }}
      >
        <i className="fas fa-check" />
      </span>
    </div>
  );
}

function GroupToolsLeft(props) {
  return (
    <div className="group-tools gt-left">
      <span className="handle" {...props.dragHandleProps}>
        <i className="fas fa-grip-horizontal" />
      </span>
    </div>
  );
}

function GroupToolsRight(props) {
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    props.setFocused(open);
  });
  let ref = useRef(null);

  function toggle() {
    setOpen(!open);
  }

  function close() {
    setOpen(false);
  }

  return (
    <Translation>
        {(t) => (
    <div className={open ? 'group-tools gt-right active' : 'group-tools gt-right'} ref={ref}>
      <span className="icon dropdown">
        <i className="fas fa-ellipsis-v" onClick={toggle} />
        {open ? (
          <DropdownList parentRef={ref} close={close}>
            <ul>

              {
                !props.label && (
                  <li
                    onClick={() => {
                      props.activateLabel();
                      close();
                    }}
                  >
                    <span>
                      <i className="fas fa-tag" />
                      {t("GroupTools.EditLabel")}
                    </span>
                  </li>
                )
              }
              

              <li
                onClick={() => {
                  props.handleGroupCopy(props.grpId);
                  props.setNotification(t("Notification.GroupCopied"));
                  close();
                }}
              >
                <span>
                <i className="far fa-copy" />
                {/* <i className="material-icons md-16">content_copy</i> */}
                {t("GroupTools.CopyGroup")}
                </span>
              </li>
              {
                props.firstRow !== 'chordbars' && props.chordsActive
                ? (
                  <li
                  className={!props.clipboard.length ? 'inactive' : ''}
                    onClick={() => {
                      if (props.clipboard.length) {
                        props.pasteChords(props.firstRow.id);
                        close();
                      }
                    }}
                  >
                    <span>
                    <i className="material-icons-outlined">content_paste</i>{t("GroupTools.PasteChordsHere")}
                    </span>
                  </li>
                )
                : ''
              }
              <li className="devider" />
              <li
                onClick={() => {
                  props.handleGroupDuplicate(props.grpId);
                  close();
                }}
              >
                <span>
                <i className="far fa-copy" />
                {/* <i className="material-icons md-16">content_copy</i> */}{t("GroupTools.DuplicateGroup")}
                </span>
              </li>
              <li
                onClick={() => {
                  props.handleGroupDelete(props.grpId);
                  close();
                }}
              >
                <span>
                <i className="far fa-trash-alt" />
                {/* <i className="material-icons md-18">delete_outline</i> */}{t("GroupTools.DeleteGroup")}
                </span>
              </li>

              <li className="devider" />
              
              <li
                onClick={() => {
                  props.groupToSnippets(props.grpId);
                  close();
                }}
              >
                <span>
                <i className="material-icons-outlined">exit_to_app</i>{t("GroupTools.GroupToSnippets")}
                </span>
              </li>
            </ul>
          </DropdownList>
        ) : (
          ''
        )}
      </span>
    </div>
        )}
        </Translation>
  );
}

function DropdownList(props) {
  let dropdownRef = null;

  useEffect(() => {
    function handleClickOutside(e) {
      if (dropdownRef && !dropdownRef.contains(e.target) && !props.parentRef.current.contains(e.target)) {
        props.close();
      }
    }

    document.addEventListener('mousedown', handleClickOutside, false);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  });

  function closeByLeave(e) {
    if (!props.parentRef.current.contains(e.relatedTarget)) {
      props.close();
    }
  }

  //let sub_x = 0;
  //let sub_y = 0;
  //let parentPos = ReactDOM.findDOMNode(document.getElementById("editor")).getBoundingClientRect();
  //sub_x = parentPos.x - document.getElementById("editor").scrollLeft;
  //sub_y = parentPos.y - document.getElementById("editor").scrollTop;

  let parentRect = ReactDOM.findDOMNode(props.parentRef.current).getBoundingClientRect();
  let leftPos = parentRect.x;
  let topPos = parentRect.y;

  let css = {
    position: 'absolute',
    transform: 'translateX(-100%)',
    left: leftPos + 40,
    top: topPos + 30,
    zIndex: 50
  };

  let portal = ReactDOM.createPortal(
    <div
      ref={(node) => {
        dropdownRef = node;
      }}
      onMouseLeave={closeByLeave}
      style={css}
    >
      {props.children}
    </div>,
    document.getElementById('dropdown-root')
  );

  return portal;
}
