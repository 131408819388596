import { cloneObject } from '../cloneObject.js';

export const handleAppendPrevRowWithCurrentRow = (attr) => {
  let { updateObject, prevRow, row, rows, rowText } = attr;

  updateObject.prev.rows[prevRow.id] = cloneObject(rows[prevRow.id]);
  updateObject.prev.rows[row.id] = cloneObject(rows[row.id]);

  let prevRowTextLength = prevRow.data.text.length;
  prevRow.data.text = prevRow.data.text + rowText;

  // Add chords from deleted to previous row
  for (let x in row.data.chords) {
    let chord = row.data.chords[x];
    chord.pos = chord.pos + prevRowTextLength;
    prevRow.data.chords.push(chord);
    prevRow.chordsUpdated = typeof prevRow.chordsUpdated != 'undefined' ? prevRow.chordsUpdated : 1;
  }

  rows[prevRow.id] = prevRow;
  delete rows[row.id];

  updateObject.next.rows[prevRow.id] = cloneObject(rows[prevRow.id]);
  updateObject.next.rows[row.id] = cloneObject({ id: row.id, deleted: true });

  return updateObject;
};
