import { cloneObject } from '../cloneObject.js';
import { removeLinebreaks } from '../removeLinebreaks.js';
import { stripTags } from '../stripTags.js';
import { getNewGroup, getNewRow, composeDocArray, getPosBetween, getRowByIndex, getNextGroup } from '../utils.js';

export const handleMergePastedText = (attr) => {
  let { textArray, row, cursorPos, grps, rows, updateObject } = attr;

  let docArray = composeDocArray(grps, rows);
  let nextGroup = getNextGroup(row.grpId, docArray);

  let htmlBeforeCursor = '';
  let htmlAfterCursor = '';
  let chordsBeforeCursor = [];
  let chordsAfterCursor = [];
  let rowPosition = 0;
  let rowGrpId = '';
  let lastUpdatedRowId = '';

  for (let x in textArray) {
    let pasteText = removeLinebreaks(stripTags(textArray[x]));
    x = parseInt(x);

    if (x === 0) {
      // first row
      htmlBeforeCursor = row.data.text.substr(0, cursorPos);
      htmlAfterCursor = row.data.text.substr(cursorPos);
      let firstRowHtml = htmlBeforeCursor + pasteText;

      // remove chors after cursor point
      for (let i in row.data.chords) {
        if (row.data.chords[i].pos < cursorPos) {
          chordsBeforeCursor.push(row.data.chords[i]);
        } else {
          row.data.chords[i].pos = row.data.chords[i].pos - htmlBeforeCursor.length;
          chordsAfterCursor.push(row.data.chords[i]);
        }
      }

      updateObject.prev.rows[row.id] = cloneObject(rows[row.id]);
      rows[row.id].data.chords = chordsBeforeCursor;
      rows[row.id].chordsUpdated =
        typeof rows[row.id].chordsUpdated != 'undefined' ? rows[row.id].chordsUpdated + 1 : 1;
      rows[row.id].data.text = firstRowHtml;
      updateObject.next.rows[row.id] = cloneObject(rows[row.id]);

      lastUpdatedRowId = row.id;
      rowPosition = row.pos;
      rowGrpId = row.grpId;
    } else {
      // Second row and later

      let chords = [];
      if (x === textArray.length - 1) {
        // last row
        pasteText = pasteText + htmlAfterCursor;
        chords = chordsAfterCursor;
      }

      if (pasteText === '') {
        // Create new group

        let currentGroup = grps[rowGrpId];
        let newGroup = getNewGroup({ pos: getPosBetween(currentGroup, nextGroup) });
        updateObject.prev.grps[newGroup.id] = {id: newGroup.id, deleted: 0};
        grps[newGroup.id] = newGroup;
        updateObject.next.grps[newGroup.id] = cloneObject(newGroup);
        rowGrpId = newGroup.id;

      } else {
        // Add row

        let rowAfterIndex = parseInt(row.index) + parseInt(x);
        let rowAfter = getRowByIndex(rowAfterIndex, docArray); // false if no row after

        rowPosition = getPosBetween(rowPosition, rowAfter);

        let newRow = getNewRow({
          grpId: rowGrpId,
          pos: rowPosition,
          type: 'lyrics',
          data: { 
            text: pasteText || '',
            chords: chords
          }
        });

        // Add new row
        updateObject.prev.rows[newRow.id] = {id: newRow.id, deleted: true};
        rows[newRow.id] = newRow;
        updateObject.next.rows[newRow.id] = cloneObject(newRow);
        lastUpdatedRowId = newRow.id;

        docArray = composeDocArray(grps, rows);
      }

    }
  }

  return { updateObject, lastUpdatedRowId };
}
