import React, { PureComponent } from 'react';
import { isTouchDevice } from '../../lib/deviceControl'; 
import { cloneObject } from '../../lib/cloneObject'; 

import { RowToolsLeft, RowToolsRight } from './rowtools/RowTools';
import { ChordsOnLyrics } from './lyrics/ChordsOnLyrics';
import { Chordbars } from './chordbars/Chordbars';
import { Lyrics } from './lyrics/Lyrics';

export class Row extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      checked: false,
      touchFocued: false,
    };
    this.handleRowCheckToggle = this.handleRowCheckToggle.bind(this);
    this.update = this.update.bind(this);

    this.getIndex = this.getIndex.bind(this);
    this.getIndexInGroup = this.getIndexInGroup.bind(this);
  }
  componentDidMount() {
    this.update();
  }
  componentDidUpdate() {
    this.update();
  }
  update() {
    if (this.props.parentGroupSelected && !this.state.checked) {
      this.setState({ checked: true });
    } else if (this.props.selectedRows.includes(this.props.row.id) && !this.state.checked) {
      this.setState({ checked: true });
    } else if (!this.props.selectedRows.includes(this.props.row.id) && this.state.checked) {
      this.setState({ checked: false });
    }
  }
  handleRowCheckToggle(checked) {
    this.setState({ checked: checked });
    this.props.handleRowCheckToggle(checked, this.props.row.id);
  }

  getIndex() {
    return this.props.row.index;
  }
  getIndexInGroup() {
    return this.props.row.indexInGroup;
  }

  render() {

    let row = cloneObject(this.props.row);
    let rowReadonly = false;
    if (this.props.row.type === 'ref') {
      let song = this.getSong();
      if (typeof song.rows[this.props.row.data.refId] !== 'undefined') {
        row.type = song.rows[this.props.row.data.refId].type;
        row.data = song.rows[this.props.row.data.refId].data;
        rowReadonly = true;
      } else {
        return '';
      }
    }
    
    //console.log("this.state.menuOpen", this.state.menuOpen);
    //console.log("this.state.touchFocued", this.state.touchFocued);
    return (
      <div
        className={
          "row" 
          + (row.lyricsReadonly ? ' lyrics-readonly' : '')
          + (rowReadonly ? ' row-readonly' : '')
        }
        id={this.props.row.id}
        data-row-index={row.index}
        ref={this.props.dragRowProvided.innerRef}
        {...this.props.dragRowProvided.draggableProps}
      >

        {row.type === 'lyrics' && this.props.env.chordsActive && (
          <ChordsOnLyrics

            // global 
            rowid={row.id}
            rowindex={row.index}
            editActive={this.props.editActive}
            rowReadonly={rowReadonly || false}

            // chords specific
            text={row.data.text}
            chords={row.data.chords || []}
            chordsUpdated={row.chordsUpdated || false}
            handleChordDroppedOnLyrics={this.props.handleChordDroppedOnLyrics}
            songKey={this.props.songKey}
            transpose={this.props.transpose}
            chordEditorIsOpenAtRow={this.props.chordEditorIsOpenAtRow === row.id ? true : false}
            chordEditorIsOpenAtPos={
              this.props.chordEditorIsOpenAtRow === row.id ? this.props.chordEditorIsOpenAtPos : false
            }

            // methods
            closeChordEditor={this.props.closeChordEditor}
            openChordEditorAt={this.props.openChordEditorAt}
            updateRowData={this.props.updateRowData}
            
          />
        )}

        <div className={
          'row-inner' 
          + (this.state.checked || this.state.menuOpen || this.state.touchFocued ? ' focused' : '')
          + ' ' + row.type
          + (this.props.row.type === 'ref' ? ' ref' : '') 
          }>
          {this.props.editActive ? ( //  && !this.props.lyricsReadonlys
            <RowToolsLeft
              row={row}
              isRef={this.props.row.type === 'ref' ? true : false}
              lyricsReadonly={row.lyricsReadonly || false}
              rowReadonly={rowReadonly || false}
              getIndex={this.getIndex}
              getIndexInGroup={this.getIndexInGroup}
              checked={this.state.checked}
              dragRowHandleProps={this.props.dragRowHandleProps}
              handleRowCheckToggle={this.handleRowCheckToggle}
            />
          ) : (
            ''
          )}

          {
            row.type === 'chordbars' && (
            <Chordbars 

                // global
                row={row}
                editActive={this.props.editActive}
                rowReadonly={rowReadonly}
                isRef={this.props.row.type === 'ref' ? true : false}

                // bar specific
                bars={row.data.bars} 
                //chordsUpdated={this.props.chordsUpdated}
                chordsUpdated={row.chordsUpdated || false}
                updateRowData={this.props.updateRowData}
                songKey={this.props.songKey}
                transpose={this.props.transpose}
                handleChordDroppedOnBar={this.props.handleChordDroppedOnBar}
                chordEditorIsOpenAtRow={this.props.chordEditorIsOpenAtRow === row.id ? true : false}
                chordEditorIsOpenAtPos={
                  this.props.chordEditorIsOpenAtRow === row.id ? this.props.chordEditorIsOpenAtPos : false
                }

                // methods
                openChordEditorAt={this.props.openChordEditorAt}
                closeChordEditor={this.props.closeChordEditor}
              />
            )
          }


          {
            row.type === 'lyrics' && (
            <Lyrics

                // global
                row={row}
                editActive={this.props.editActive}
                rowReadonly={rowReadonly}
                isRef={this.props.row.type === 'ref' ? true : false}

                // lyrics specific
                forceRender={this.props.forceRender}
                lyricsReadonly={this.props.lyricsReadonly}
                language={this.props.language}

                // methods
                getDocArray={this.props.getDocArray}
                getIndex={this.getIndex}
                getIndexInGroup={this.getIndexInGroup}
                handlePastedText={this.props.handlePastedText}
                handleFocusRow={(i) => {
                  if (isTouchDevice) {
                    this.setState({touchFocued: true});
                  }
                  this.props.handleFocusRow(i);
                }}
                handleBlurRow={(i) => {
                  if (isTouchDevice) {
                    this.setState({touchFocued: false});
                  }
                }}
                handleRowChange={this.props.handleRowChange}
                updateLanguage={this.props.updateLanguage}
                updateRowData={this.props.updateRowData}
                handleMergeTextWithNextRow={this.props.handleMergeTextWithNextRow}
                handleRowToGroup={this.props.handleRowToGroup}
                handlePressEnter={this.props.handlePressEnter}
                handlePressBackspace={this.props.handlePressBackspace}
                jumpToRow={this.props.jumpToRow}
                jumpUp={this.props.jumpUp}
                jumpDown={this.props.jumpDown}
                setRowRef={this.props.setRowRef}
                getRowRef={this.props.getRowRef}
              />
            )
          }

          {this.props.editActive ? ( //  && !this.props.lyricsReadonly
            <RowToolsRight
              getSong={this.props.getSong}
              row={row}
              isRef={this.props.row.type === 'ref' ? true : false}
              lyricsReadonly={row.lyricsReadonly || false}
              rowReadonly={rowReadonly}
              getIndex={this.getIndex}
              getIndexInGroup={this.getIndexInGroup}
              setNotification={this.props.setNotification}
              pasteChords={this.props.pasteChords}
              pasteClipboard={this.props.pasteClipboard}
              toClipboard={this.props.toClipboard}
              clipboard={this.props.clipboard}
              handleRowToGroup={this.props.handleRowToGroup}
              handleGlueToGroupAbove={this.props.handleGlueToGroupAbove}
              handleRowDelete={this.props.handleRowDelete}
              handleNewRowAfter={this.props.handleNewRowAfter}
              handleRowDuplicate={this.props.handleRowDuplicate}
              handleRowClone={this.props.handleRowClone}
              handleRowConvert={this.props.handleRowConvert}
              onMenuOpen={() => {
                if (isTouchDevice) {
                  this.setState({menuOpen: true});
                }
              }}
              onMenuClose={() => {
                if (isTouchDevice) {
                  this.setState({menuOpen: false});
                }
              }}

              updateRowData={this.props.updateRowData}
              performUpdate={this.props.performUpdate}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
