import React, {useState, useEffect, useRef} from 'react';
import { Dropdown } from '../Dropdown';
import { Translation } from 'react-i18next';
import textareaAutoHeight from '../../lib/textareaAutoHeight';
import { Droppable } from 'react-beautiful-dnd'; // Draggable

export class SidebarLeft extends React.Component {
  render() {
    return this.props.listBar ? (
      <Sidebar side="left" open={true}>
        {this.props.listBar}
      </Sidebar>
    ) : (
      ''
    );
  }
}

export class SidebarRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDropdown: 0,
      editing: false,
      value: ''
    };
    this.inputRef = null;
    this.closeDropdown = this.closeDropdown.bind(this);
    this.disableEdit = this.disableEdit.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleClickOutside(e) {
    if (this.inputRef && !this.inputRef.contains(e.target)) {
      if (this.state.value !== '' || this.state.editing !== '') {
        this.props.saveSnippet(this.state.editing, this.state.value);
      }
      this.disableEdit();
    }
  }
  closeDropdown() {
    this.setState({ closeDropdown: this.state.closeDropdown + 1 });
  }
  disableEdit() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.setState({ value: '', editing: false });
  }
  edit(snippetId, value) {
    this.setState({ value: value || '', editing: snippetId }, () => {
      textareaAutoHeight(this.inputRef);
      this.inputRef.focus();
      document.addEventListener('mousedown', this.handleClickOutside, false);
    });
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      this.props.saveSnippet(this.state.editing, this.state.value);
      this.disableEdit();
    }
  }
  handleKeyUp(event) {
    if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey || event.metaKey)) {
      event.preventDefault();
    } else if (event.key === 'Escape') {
      if (this.state.value !== '' || this.state.editing !== '') {
        this.props.saveSnippet(this.state.editing, this.state.value);
      }
      this.disableEdit();
    } else {
      textareaAutoHeight(this.inputRef);
    }
  }
  render() {
    return (
      <Translation>
        {(t) => (
      <Sidebar side="right" open={false} opener={this.props.opener || 0}>
        <Droppable droppableId={'groupToSidebar'} direction="vertical">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}
            className={snapshot.isDraggingOver ? 'drag-over' : ''}
            >
              {provided.placeholder}
              
              {
                !Object.keys(this.props.snippets).length
                ? (<div className="snippets-about"><p>{t("Sidebar.DescriptionIfEmpty")}</p></div>)
                : null
              }
              <div className="snippets">
                {this.props.snippets && Object.keys(this.props.snippets).length ? (
                  Object.keys(this.props.snippets).map((snippetId) => {
                    if (typeof this.props.snippets[snippetId] !== 'string') {
                      return '';
                    }
                    let snippetHTML = this.props.snippets[snippetId].replace(/[\n]/g, '<br />');
                    return (
                      <div key={snippetId}>
                        {this.state.editing === snippetId ? (
                          <div className="snippet-input">
                            <textarea
                              value={this.state.value}
                              ref={(node) => {
                                this.inputRef = node;
                              }}
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyDown}
                              onKeyUp={this.handleKeyUp}
                            />
                            {/* <button onClick={() => {
                              this.props.saveSnippet(this.state.editing, this.state.value);
                              this.disableEdit();
                            }}>Save</button> */}
                          </div>
                        ) : (
                          <React.Fragment>
                            <Dropdown
                              closeDropdown={this.state.closeDropdown}
                              className="ellipsis"
                              label={<i className="fas fa-ellipsis-v" />}
                            >
                              <ul>
                                <li
                                  onClick={() => {
                                    this.props.pasteSnippet(this.props.snippets[snippetId]);
                                    this.closeDropdown();
                                  }}
                                >
                                  {t("Sidebar.AddToSong")}
                                </li>
                                <li
                                  onClick={() => {
                                    this.edit(snippetId, this.props.snippets[snippetId]);
                                    this.closeDropdown();
                                  }}
                                >
                                  {t("Sidebar.Edit")}
                                </li>
                                <li
                                  onClick={() => {
                                    this.props.deleteSnippet(snippetId);
                                    this.closeDropdown();
                                  }}
                                >
                                  {t("Sidebar.Remove")}
                                </li>
                              </ul>
                            </Dropdown>
                            <p
                              dangerouslySetInnerHTML={{ __html: snippetHTML }}
                              onClick={() => {
                                this.edit(snippetId, this.props.snippets[snippetId]);
                              }}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })
                ) : (
                  ''
                )}
              </div>
              <div className="snippets">
                {this.state.editing === '' ? (
                  <div className="snippet-input">
                    <textarea
                      value={this.state.value}
                      ref={(node) => {
                        this.inputRef = node;
                      }}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                      onKeyUp={this.handleKeyUp}
                    />

                    {/* <button onClick={() => {
                              this.props.saveSnippet(this.state.editing, this.state.value);
                              this.disableEdit();
                            }}>Save</button> */}
                  </div>
                ) : (
                  <button
                    className="snippet-new"
                    onClick={() => {
                      this.edit('', '');
                    }}
                  >
                    <span>{t("Sidebar.New")}</span>
                  </button>
                )}
              </div>
            </div>
          )}
        </Droppable>
      </Sidebar>
      )}
      </Translation>
    );
  }
}

export const Sidebar = ({side, opener, children, open}) => {

  let [isOpen, setOpen] = useState(open);
  const openerRef = useRef(0);

  useEffect(() => {
    if (opener > 0 && opener !== openerRef.current) {
      openerRef.current = opener;
      setOpen(true);
    }
  }, [opener, openerRef]);

  return (
    <div className={'sb-container' + (side === 'left' ? ' sb-left' : ' sb-right')}>
      <div className={'sb-opener' + (isOpen ? ' hidden' : '')} onClick={() => {
        setOpen(true);
      }}>
        <span>
          {side === 'left' ? <i className="fas fa-caret-right" /> : <i className="fas fa-caret-left" />}
        </span>
      </div>

      <div className={'sidebar' + (isOpen ? ' open' : '')}>
        <div className="sb-head">
          <span onClick={() => {
            setOpen(false);
          }}>
            {side === 'left' ? <i className="fas fa-caret-left" /> : <i className="fas fa-caret-right" />}
          </span>
        </div>
        <div className="sb-body">{children}</div>
      </div>
    </div>
  );
}
