import React, { useState, useEffect, useRef, useContext } from "react";
import { Translation } from "react-i18next";
import { handle_api_error } from "../../API";
import { createSongByMusixmatchId } from '../../lib/createSongByMusixmatchId';
import { getSongsFromLocalCache } from '../../components/Storage';
//import { Loading } from '../../components/Loading';
import UserContext from "../../UserContext";
import { HelpBubble } from '../HelpBubble';

let apiSearchTimeout = null;

export const ToolbarSearchMusic = (props) => {

  let containerRef = useRef(null);
  let inputRef = useRef(null);

  let [inputIsFocued, setInputIsFocued] = useState(false);
  let [bubbleVisible, setBubbleVisible] = useState(false);
  let [show, setShow] = useState(false);
  let [focusSearch, setFocusSearch] = useState(false);
  let [searchingExternalAPI, setSearchingExternalAPI] = useState(false);
  let [query, setQuery] = useState("");
  let [searchResults, setSearchResults] = useState({internal: [], external: [], visible: false});
  let user = useContext(UserContext);

  const onChange = (event) => {

    let searchQuery = event.target.value || '';
    setQuery(searchQuery);

    searchQuery = searchQuery.toLowerCase();

    clearTimeout(apiSearchTimeout);
    setSearchingExternalAPI(false);
    
    if (searchQuery  === '') {
      setSearchResults({internal: [], external: [], visible: false});
      return;
    }

    getSongsFromLocalCache()
      .then((songs) => {
        let filteredSongs = [];
        for (let song of Object.values(songs)) {
          if (searchQuery.length === 1 && song.title.toLowerCase().indexOf(searchQuery) === 0) {
            filteredSongs.push(song);
          } else if (searchQuery.length > 1 && song.title.toLowerCase().indexOf(searchQuery) >= 0) {
            filteredSongs.push(song);
          }
        }
        filteredSongs.sort((s1, s2) => { 
          if (s1.title.toLowerCase().indexOf(searchQuery) === 0 && s2.title.toLowerCase().indexOf(searchQuery) === 0) {
            return 0;
          }
          if (s1.title.toLowerCase().indexOf(searchQuery) === 0 && s2.title.toLowerCase().indexOf(searchQuery) !== 0) {
            return -1;
          }
          if (s1.title.toLowerCase().indexOf(searchQuery) !== 0 && s2.title.toLowerCase().indexOf(searchQuery) === 0) {
            return 1;
          }
          return 0;
        });
        setSearchResults({internal: filteredSongs, external: searchResults.external, visible: true});
      })
    

    if (searchQuery.length > 2) {
      setSearchingExternalAPI(true);
      apiSearchTimeout = setTimeout(() => {
        searchMusic();
      }, 1000)
    } else {
      setSearchResults({internal: searchResults.internal, external: [], visible: searchResults.visible});
    }
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      searchMusic();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setShow(false);
      hideSearchResults();
    }
  }



  const searchMusic = () => {

    clearTimeout(apiSearchTimeout);
    /*
    api_get("/music/search?q=" + encodeURI(inputRef.current.value))
        .then((resp) => {
          setSearchResults({internal: searchResults.internal, external: resp, visible: true});
          setSearchingExternalAPI(false);
        })
        .catch(handle_api_error);
        */
    
  }
  const hideSearchResults = () => {
    setSearchResults({internal: searchResults.internal, external: searchResults.external, visible: false});
  }
  const loadSong = (track) => {
    createSongByMusixmatchId(track, user)
      .then((song) => { 
        props.redirect('/song/' + song.id); 
      })
      .catch(handle_api_error);
  };

  function handleClickOutside(e) {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setBubbleVisible(false); 
      hideSearchResults();
    }
  }
  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
    if (props.focusSearch > focusSearch) {
      setShow(true);
      setFocusSearch(props.focusSearch);
      setBubbleVisible(true);
    }
  }, [props.focusSearch, focusSearch, setFocusSearch, show, setShow, inputRef]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  }, []); // eslint-disable-line

  let hasSearchResults = searchResults.internal.length || (searchResults.external.length && searchResults.visible);
  return (
    <Translation>
      {(t) => (
        <div>
          <div className={
            "search-music" 
            + (searchingExternalAPI ? ' searching' : '')
            + (show ? ' d-block' : '')
            }  ref={containerRef}>
            <input
              ref={inputRef}
              type="search"
              value={query}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              placeholder={t("ListToolbar.Search")}
              onMouseEnter={() => { 
                setBubbleVisible(true); 
              }}
              onMouseLeave={() => { 
                if (!inputIsFocued) {
                  setBubbleVisible(false); 
                }
              }}
              onFocus={() => { 
                setInputIsFocued(true);
                //setBubbleVisible(false); 
              }}
              onBlur={() => { setInputIsFocued(false); }}
            />
            <HelpBubble
              visible={bubbleVisible && !hasSearchResults}
              html={t('ListToolbar.ImportInputTooltip')}
              closeOnClickOutside={false}
              arrowPos={10}
            />
            {
              hasSearchResults
              ? <ToolbarSearchMusicResults 
                  searchResults={searchResults} 
                  loadSong={loadSong} 
                  hideSearchResults={hideSearchResults}
                  query={query}
                  searchingExternalAPI={searchingExternalAPI}
                  redirect={props.redirect} 
                /> 
              : ''
            }
          </div>
        </div>
      )}
    </Translation>
  );
};


const ToolbarSearchMusicResults = (props) => {

  return (
    <Translation>
      {(t) => (
    <div className="search-results">
        {
          props.searchResults.internal.length
          ? (
            <React.Fragment>
              <label>{t("ListToolbar.SearchMySongs")}</label>
              {
                props.searchResults.internal.map((result, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => { 
                        props.redirect("/song/" + result.id);
                      }}
                    >
                      <p>{result.title}</p>
                    </div>
                  );
                }
                )
              }
            </React.Fragment>
          )
          : ''
        }
        {
          /*
          props.searchResults.external.length && props.query.length > 2
          ? (
            <React.Fragment>
              <label>
                {t("ListToolbar.ImportLyricsFrom")}
              </label>
              {props.searchResults.external.map((result, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      props.loadSong(result.track);
                    }}
                  >
                    <p className="artist-name">{result.track.artist_name}</p>
                    <p>{result.track.track_name}</p>
                  </div>
                );
              })}
            
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <label>Import lyrics from <a href="https://www.musixmatch.com" target="_blank" rel="noopener noreferrer">Musixmatch</a></label>
              {
                props.searchingExternalAPI
                ? (<Loading />)
                : (
                  <div className="no-result">
                    <p>
                    No results
                    {props.query.length < 3 ? <span> (Be more specific)</span> : ''}
                    </p>
                  </div>
                )
              }
              
              
            </React.Fragment>
          )
          */
        }
    </div>
    )}
    </Translation>
  );
}