export const getWordPositions = (text) => {
    let wordpositions = [];
    let letters = text.split('');
    let isWhitespace = true;
    for (let x in letters) {
      if (letters[x] !== ' ' && isWhitespace) {
        wordpositions.push(parseInt(x));
      }
      if (letters[x] === ' ') {
        isWhitespace = true;
      } else {
        isWhitespace = false;
      }
    }
    return wordpositions;
  }