import React, { useState, useContext } from "react";
import { Dropdown } from '../Dropdown';
import ViewListContext from '../../ViewListContext';
import { useTranslation } from "react-i18next";

export const ListPageOptions = (props) => {
  const context = useContext(ViewListContext);
  let {t} = useTranslation();
  let [closeDropdown, setCloseDropdown] = useState(0);
  
  return (
    <div className="browser-options">
      <div>

        <div onClick={props.openNewSongModal}>
          <span><i className="icon-song"></i> {t("ListOptions.NewSong")}</span>
        </div>
        {/* 
        <div onClick={props.openWizardModal}>
          <span><i className="material-icons-outlined md-16">auto_fix_high</i>  {t("ListOptions.Wizard")}</span>
        </div> 
        */}

        <div onClick={props.openNewListModal} className={
          context.listId ? 'disabled' : ''
        }><span><i className="material-icons md-16">playlist_play</i> {t("ListOptions.NewList")}</span></div>

        {
          !context.listId && (
            <div onClick={props.openNewFolderModal} className={
              context.listId ? 'disabled' : ''
            }><span><i className="material-icons-outlined md-16">folder</i> {t("ListOptions.NewFolder")}</span></div>
          )
        }

        {context.listId && (
          <Dropdown 
            className={!context.listId ? 'disabled' : ''}
            closeDropdown={closeDropdown}
            label={<React.Fragment><i className="material-icons md-16">add</i> {t("ListOptions.AddSongToList")}</React.Fragment>}>
            <ul>
              {
                props.docsArray.map((doc) => {
                  if (doc.type === 'song') {
                    return <li key={doc.id} onClick={() => {
                      props.addSongToPlaylist(doc.id, context.listId);
                      setCloseDropdown(closeDropdown+1);
                    }}>{doc.title}</li>
                  } else {
                    return null;
                  }
                })
              }
            </ul>
          </Dropdown>
        )}
        
      </div>
      
      <div>
        <Dropdown 
            closeDropdown={closeDropdown}
            className={
              context.listId ? 'disabled' : ''
            }
            label={<React.Fragment><i className="material-icons md-16">sort</i> {t("ListOptions.Sort")}</React.Fragment>}>
            <ul>
              <li className={context.sortBy==='alpha' ? 'selected' : ''} onClick={() => { props.setSortBy("alpha"); setCloseDropdown(closeDropdown+1); }}>{t("ListOptions.SortAlpha")}</li>
              <li className={context.sortBy==='latest-viewed' ? 'selected' : ''} onClick={() => { props.setSortBy("latest-viewed"); setCloseDropdown(closeDropdown+1); }}>{t("ListOptions.SortLatest")}</li>
            </ul>
        </Dropdown> 

      </div>
    </div>
  );
}