import React from "react";
import { SmallEditorPortal } from "./../SmallEditorPortal";
import { useTranslation } from 'react-i18next';

export class ChordEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chord: "",
      page: 1,
    };
    this.updateTimer = null;
    this.inputRef = React.createRef();
    this.close = this.close.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
  }

  componentDidMount() {
    this.setState({ chord: this.props.chord },() => {
      setTimeout(() => {
        this.inputRef.current.focus();
      }, 100);
    });
  }

  close(e) {
    if (typeof e != "undefined" && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    this.props.close();
  }

  handleKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      event.stopPropagation();

      if (event.shiftKey) {
        this.props.jumpto("JumpBack");
      } else {
        this.props.jumpto("JumpNext");
      }
    } else if (event.key === "ArrowRight") {
      if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        this.props.jumpto(1);
      }
    } else if (event.key === "ArrowLeft") {
      if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        this.props.jumpto(-1);
      }
    } else if (event.key === "ArrowDown") {
      if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        this.props.jumpto("JumpDown");
      }
    } else if (event.key === "ArrowUp") {
      if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        this.props.jumpto("JumpUp");
      }
    }
  }

  handleKeyUp(event) {
    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      this.close();
    }
    this.inputRef.current.value = this.inputRef.current.value.replace("b", "♭");
  }

  handleChange(event) {
    clearTimeout(this.updateTimer);

    let newChordValue = event.target.value;
    newChordValue = newChordValue.replace("b", "♭");
    newChordValue = newChordValue.replace("#", "♯");
    this.setState({ chord: newChordValue }, () => {
      this.updateTimer = setTimeout(() => {
        this.props.updateChord(newChordValue);
      }, 100);
    });
  }

  handleSuggestionClick(event) {
    let nextPage = this.state.page;
    //if (this.state.page === 1) {
    //nextPage = 2;
    //}
    let newChordValue = this.state.chord + event.target.innerHTML;
    this.setState({ chord: newChordValue, page: nextPage }, () => {
      this.inputRef.current.focus();
      this.updateTimer = setTimeout(() => {
        this.props.updateChord(newChordValue);
      }, 100);
    });
  }

  render() {
    return (
      <SmallEditorPortal
        parentId={this.props.parentId}
        close={this.close}
        arrowPos={20}
      >
        <div className="chord-input-wrapper">
          <div><span className="material-icons-outlined" onClick={() => { this.props.jumpto("JumpBack"); }}>
          keyboard_double_arrow_left
          </span></div>
          <div><span className="material-icons-outlined" onClick={() => { this.props.jumpto(-1); }}>
          keyboard_arrow_left
          </span></div>
          <input
            ref={this.inputRef}
            type="text"
            className="chord-input"
            value={this.state.chord}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            spellCheck="false"
          />
          <div><span className="material-icons-outlined" onClick={() => { this.props.jumpto(1); }}>
          keyboard_arrow_right
          </span></div>
          <div><span className="material-icons-outlined" onClick={() => { this.props.jumpto("JumpNext"); }}>
          keyboard_double_arrow_right
          </span></div>
        </div>
        

        <div className="sm-editor-tabs">
          <div>
            <span
              className={this.state.page === 1 ? "active" : ""}
              onClick={() => {
                this.setState({ page: 1 });
              }}
            >
              CFG
            </span>
          </div>
          <div>
            <span
              className={this.state.page === 2 ? "active" : ""}
              onClick={() => {
                this.setState({ page: 2 });
              }}
            >
              m7♭5
            </span>
          </div>
          <div>
            <span
              className={this.state.page === 3 ? "active" : ""}
              onClick={() => {
                this.setState({ page: 3 });
              }}
            >
              <i className="material-icons-outlined md-14">help_outline</i>
            </span>
          </div>
        </div>

        <div className="chords-panel">
          {this.state.page === 1 && (
            <SuggestionPanel1
              handleSuggestionClick={this.handleSuggestionClick}
            />
          )}
          {this.state.page === 2 && (
            <SuggestionPanel2
              handleSuggestionClick={this.handleSuggestionClick}
            />
          )}
          {this.state.page === 3 && (
            <SuggestionPanel3
              handleSuggestionClick={this.handleSuggestionClick}
            />
          )}
        </div>
      </SmallEditorPortal>
    );
  }
}

const ChordButtonsRow = ({chords,  handleSuggestionClick}) => {
  return (
    <div className="chords-panel-row">
      {
        chords.map((chord, index) => (
          <div key={index+chord}>
            {chord ? <span onClick={handleSuggestionClick}>{chord}</span> : null}
          </div>
        ))
      }
    </div>
  );
}

const SuggestionPanel1 = ({handleSuggestionClick}) => {
  return (
    <div className="chords-panel-page">
      <ChordButtonsRow chords={['C', 'D', 'E', 'F', 'G', 'A', 'B']} handleSuggestionClick={handleSuggestionClick} />
      <ChordButtonsRow chords={['C♯', 'D♯', '', 'F♯', 'G♯', 'A♯']} handleSuggestionClick={handleSuggestionClick} />
      <ChordButtonsRow chords={['D♭', 'E♭', '', 'G♭', 'A♭', 'B♭']} handleSuggestionClick={handleSuggestionClick} />
      <ChordButtonsRow chords={['m', '△', '7', '/', '♭', '♯']} handleSuggestionClick={handleSuggestionClick} />
    </div>
  );
};

const SuggestionPanel2 = ({handleSuggestionClick}) => {
  return (
    <div className="chords-panel-page">
      <ChordButtonsRow chords={['m', 'Δ', 'o', 'ø', '/', '♭', '♯']} handleSuggestionClick={handleSuggestionClick} />
      <ChordButtonsRow chords={['6', '7', '9', '11', '13', '(', ')']} handleSuggestionClick={handleSuggestionClick} />
      <ChordButtonsRow chords={['♭9', '♯9', '♭5', '♯5', 'sus', 'add']} handleSuggestionClick={handleSuggestionClick} />

      <div className="chords-panel-row">

      </div>
    </div>
  );
};

const SuggestionPanel3 = (props) => {
  const {t} = useTranslation();
  return (
    <div className="chords-panel-page">
      <div className="chord-description">

        <h4>{t("ChordEditor.WriteChords")}</h4>
        <p>{t("ChordEditor.WriteChordsText")}</p>
        <h4>{t("ChordEditor.Navigate")}</h4>
        <p>{t("ChordEditor.NavigateText")}</p>

        <div className="d-flex justify-content-between">
          <div>Shift + <span className="material-icons-outlined md-16">arrow_forward</span></div>    
          <div className="text-right">{t("ChordEditor.GoForward")}</div>      
        </div>

        <div className="d-flex justify-content-between">
          <div>Shift + <span className="material-icons-outlined md-16">arrow_back</span></div>    
          <div className="text-right">{t("ChordEditor.GoBackward")}</div>      
        </div>

        <div className="d-flex justify-content-between">
          <div>Shift + <span className="material-icons-outlined md-16">arrow_upward</span></div>    
          <div className="text-right">{t("ChordEditor.GoPrev")}</div>      
        </div>

        <div className="d-flex justify-content-between">
          <div>Shift + <span className="material-icons-outlined md-16">arrow_downward</span></div>    
          <div className="text-right">{t("ChordEditor.GoNext")}</div>      
        </div>

        <div className="d-flex justify-content-between">
          <div>Tab</div>    
          <div className="text-right">{t("ChordEditor.JumpNext")}</div>      
        </div>

        <div className="d-flex justify-content-between">
          <div>Shift + Tab</div>    
          <div className="text-right">{t("ChordEditor.JumpBack")}</div>      
        </div>
      </div>
    </div>
  );
};