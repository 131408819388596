import React from 'react';
import ReactDOM from 'react-dom';
//import { i18next } from 'react-i18next';
import i18next from 'i18next';

const Confirm = (props) => {
  // headline, icon, message, method

  //const { t } = useTranslation();

  function close() {
    ReactDOM.unmountComponentAtNode(document.querySelector('#alert-root'));
  }

  let html = (
    <div id="alert-popup">
      <div className="alert-overlay" />
      <div
        className="alert-wrapper"
        onClick={() => {
          close();
        }}
      >
        <div className="alert" onClick={(e) => {
          e.stopPropagation();
        }}>
          <div className="alert-icon">
            <i className="fas fa-question-circle" />
          </div>
          {typeof props.headline != 'undefined' && props.headline ? <h2>{props.headline}</h2> : ''}
          {typeof props.message != 'undefined' && props.message ? <p>{props.message}</p> : ''}

          <div className="btn-group">
            <button
              className="btn-turquoise"
              onClick={() => {
                if (typeof props.onConfirm !== 'undefined') {
                  props.onConfirm();
                  close();
                } else {
                  close();
                }
              }}
            >
              {i18next.t("Alert.Okay")}
            </button>
            <button
              className="btn-gray"
              onClick={() => {
                close();
              }}
            >
              {i18next.t("Alert.Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  ReactDOM.render(html, document.querySelector('#alert-root'));
}

export default Confirm;