import React from 'react';
import { removeLinebreaks } from '../../../lib/removeLinebreaks';
import { getCursorOffsetInElement } from '../../../lib/getCursorOffsetInElement';
import { focusElementAtPosition } from '../../../lib/focusElementAtPosition';
import { getSelecedText } from '../../../lib/getSelecedText';
import { cloneObject } from '../../../lib/cloneObject';
import { stripTags } from '../../../lib/stripTags';
import { updateChordsPositionFromCursorPosition, getFirstUpdatedPosition } from '../../../lib/utils';

export class LyricsInput extends React.Component {
  constructor(props) {
    super();
    this.editorRef = React.createRef();
    this.rhymeButtonTimer = null;
    this.updateTimer = null;
    this.lastEmittedText = '';
    this.clickTimer = null;
    this.clicked = false;
    this.showAltWordOn = false;
    this.rowIsFocused = false;

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.emitChanges = this.emitChanges.bind(this);
    this.getHTML = this.getHTML.bind(this);
    this.getText = this.getText.bind(this);
    this.clearText = this.clearText.bind(this);
  }

  componentDidMount() {
    this.lastEmittedText = this.props.text;
    this.props.setRowRef(this.props.rowid, this.editorRef);
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside(e) {
    let wb = document.getElementById("word-browser");
    let clickedInWordBrowser = (wb && wb.contains(e.target)) ? true : false;
    let clickedOutsideCurrentRow = (this.editorRef.current && !this.editorRef.current.contains(e.target)) ? true : false;
    if (clickedOutsideCurrentRow && !clickedInWordBrowser) {
      let inputText = this.getText();
      this.editorRef.current.innerHTML = inputText;
		}
  }
  
  shouldComponentUpdate(nextProps, nextState) {

    //if (typeof this.updateTimer === 'number' && this.updateTimer >= 0) {
      //return false;
    //}

    let currentText = this.getText();
    let nextText = removeLinebreaks(stripTags(nextProps.text));

    if (nextText !== currentText) {
      if (!this.rowIsFocused) {
        return true;
      }
    } else if (this.props.editActive !== nextProps.editActive ) {
      return true;
    } else if (nextProps.showAltWordOn === false && this.showAltWordOn !== nextProps.showAltWordOn) {
      return true;
    }
    
    return false;
  }
  

  componentDidUpdate(prevProps) {
    //let currentHTML = this.getHTML();
    let currentText = this.getText();
    let newText = removeLinebreaks(stripTags(this.props.text));

    if (this.props.showAltWordOn === false && this.showAltWordOn !== false) {
      this.clearText();
    }
    this.showAltWordOn = this.props.showAltWordOn;

    if (newText !== currentText) {
      this.lastEmittedText = newText;
      this.editorRef.current.innerHTML = newText;
    }
    this.props.setRowRef(this.props.rowid, this.editorRef);
  }

  handleKeyDown(event) {
    
    this.props.clearWordBrowserTimer();
    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    let inputText = this.getText();
    let inputHTML = this.getHTML();
    let inputLength = inputText.length;
    let rowindex = this.props.getIndex();

    if (this.rhymeButtonTimer) {
      clearTimeout(this.rhymeButtonTimer);
    }
    
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }

    if (inputText !== inputHTML 
        && !['Control', 'Shift', 'Meta', 'Alt'].includes(event.key)
        && !event.shiftKey
        && !event.metaKey
        && !event.altKey
        && !event.controlKey) {
      this.editorRef.current.innerHTML = inputText;
      this.showAltWordOn = false;
      this.props.clearWordBrowser();
      //this.clearText();
      focusElementAtPosition(cursorPos, this.editorRef.current);
    }
    
    if (event.key === 'ArrowLeft' && cursorPos === 0 && rowindex > 0) {
      event.preventDefault();
      this.emitChanges();
      this.props.jumpUp(999999);
    } else if (event.key === 'ArrowRight' && cursorPos === inputLength) {
      event.preventDefault();
      if (event.shiftKey) {
        this.props.showRhymeSuggestions();
      } else {
        this.emitChanges();
        this.props.jumpDown(0);
      }
    } else if (event.key === 'ArrowRight' && event.shiftKey) {
      event.preventDefault();
      this.props.showRhymeSuggestions();
    } else if (event.key === 'ArrowUp') {
      this.emitChanges();
      this.props.jumpUp(cursorPos);
      return;
    } else if (event.key === 'ArrowDown') {
      if (event.shiftKey) {
        event.preventDefault();
        this.props.showAltSuggestions(0, true);
      } else {
        this.emitChanges();
        this.props.jumpDown(cursorPos);
        return;
      }
      
    } else if (event.key === 'Enter') {
      event.preventDefault();
      return;
    } else if (event.key === 'Delete') {
      if (cursorPos >= inputLength) {
        if (this.updateTimer) {
          clearTimeout(this.updateTimer);
        }
        this.props.handleMergeTextWithNextRow(inputText, this.props.row);
        event.preventDefault();
        return;
      }
      
    } else if (event.key === 'Backspace') {
      if (inputText === '' || cursorPos === 0) {
        if (this.updateTimer) {
          clearTimeout(this.updateTimer);
        }
        this.props.handlePressBackspace(inputText, this.props.row, cursorPos);
        event.preventDefault();
        return;
      }
    }
    
  }

  handleKeyUp(event) {
    
    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    let inputText = this.getText();
    let inputLength = inputText.length;
    //let inputTrimedLength = inputText.trim().length;

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      return;
    } else if (event.key === 'ArrowRight' && event.shiftKey) {
      event.preventDefault();
      return;
    } else if (event.key === 'ArrowRight') {
      if (inputLength > 10 && cursorPos >= inputLength) {
        if (this.rhymeButtonTimer) {
          clearTimeout(this.rhymeButtonTimer);
        }
        this.rhymeButtonTimer = setTimeout(() => {
          this.props.showRhymeSuggestionTip();
        }, 0);
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();

      clearTimeout(this.updateTimer);
      this.showAltWordOn = false;

      if (!inputText || inputText === '') {
        // create new group
        this.props.handleRowToGroup(this.props.row);
      } else {
        // create new row
        let newText = this.props.handlePressEnter(inputText, this.props.row, cursorPos);
        inputText = newText;
        this.editorRef.current.innerHTML = inputText;
      }

      this.lastEmittedText = inputText;
      return;

    } else if (event.key === 'Backspace') {
      if (inputText === '' || cursorPos === 0) {
        event.preventDefault();
        return;
      }
    } else if (event.key === ' ' && inputLength > 10 && cursorPos >= inputLength) {

      if (this.rhymeButtonTimer) {
        clearTimeout(this.rhymeButtonTimer);
      }
      this.rhymeButtonTimer = setTimeout(() => {
        this.props.showRhymeSuggestionTip();
      }, 0);
      
    }

    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
    this.updateTimer = setTimeout(() => {
      this.emitChanges();
    }, 500);
  }

  handleBlur() {
    if (this.getText() !== this.lastEmittedText) {
      this.emitChanges();
    }
    this.rowIsFocused = false;
    this.props.handleBlurRow(this.props.getIndex());
  }

  handleFocus() {
    this.rowIsFocused = true;
    this.props.handleFocusRow(this.props.row.id);
  }

  handleClick(e) {
    
    let selectedText = getSelecedText();
    if (selectedText && selectedText.length > 0) {
      this.props.handleFocusRow(this.props.row.id);
      return;
    }

    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    if (cursorPos === this.getText().length) {
      this.props.clearWordBrowser();
      this.clearText();
      return;
    }
    
    setTimeout(() => { this.clicked = false; }, 0);
    if (this.clicked === true) {
      return;
    }
    this.clicked = true;

    this.clearText();
    this.props.showAltSuggestions();
  }

  handlePaste(e) {
    e.stopPropagation();
    e.preventDefault();
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('Text');
    let pastedDataArray = pastedData.split('\n');
    let cursorPos = getCursorOffsetInElement(this.editorRef.current);

    let row = this.props.row;
    row.data.text = this.getText();
    this.props.handlePastedText(pastedDataArray, row, cursorPos);
  }

  emitChanges() {
    if (this.updateTimer) { clearTimeout(this.updateTimer); }
    let newText = this.getText();

    if (newText !== this.lastEmittedText) {
      let firstUpdatedPosition = getFirstUpdatedPosition(newText, this.lastEmittedText);
      let diffLength = newText.length - this.lastEmittedText.length;
      let row = cloneObject(this.props.row);
      row.data.chords = updateChordsPositionFromCursorPosition(row.data.chords, firstUpdatedPosition, diffLength);
      this.props.updateRowData(row.id, {text: newText, chords: row.data.chords}, 'lyrics-update');
      this.lastEmittedText = newText;
    }

  }

  getHTML() {
    let currentText =
      this.editorRef.current && typeof this.editorRef.current != 'undefined' 
        ? this.editorRef.current.innerHTML 
        : '';
    currentText = removeLinebreaks(currentText.replace(/(&[a-z]{2,4};)/g, " "));
    return currentText;
  }
  getText() {
    //let currentText = this.editorRef.current.textContent;
    let currentText =
      this.editorRef.current && typeof this.editorRef.current != 'undefined' 
        ? this.editorRef.current.innerHTML 
        : '';
    return removeLinebreaks(stripTags(currentText));
  }
  clearText() {
    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    let inputText = this.getText();
    if (inputText !== this.editorRef.current.innerHTML) {
      this.editorRef.current.innerHTML = inputText;
      focusElementAtPosition(cursorPos, this.editorRef.current);
    }
  }

  render() {
    return (
        <div
          id={"l-"+this.props.row.id}
          ref={this.editorRef}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onClick={this.handleClick}
          onPaste={this.handlePaste}
          contentEditable
          dangerouslySetInnerHTML={{ __html: this.props.text }}
          spellCheck="false"
        />
    );
  }
}
