import React from 'react'
import { Translation } from "react-i18next";
import { api_get, handle_api_error } from '../../API';

export class ToolbarThemeSelect extends React.Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
          images: [],
          keyword: '',
          page: 1,
        }
        this.showColorOptions = this.showColorOptions.bind(this);
        this.searchUnsplash = this.searchUnsplash.bind(this);
        this.setTheme = this.setTheme.bind(this);

        this.colorOptions = [
          '#f8f9fa', 
          '#edf5e1',
          '#cafafe',
          '#f78888',
          '#282828',
          '#45a29e',
          '#d1e8e2',
          '#2aafa9',
        ];
    }
    componentDidMount() {
      this.showColorOptions();
    }
    showColorOptions(keyword) {
      this.setState({images: [], keyword: '', page: 1});
    }
    searchUnsplash(keyword) {

      let page = 1;
      let url = "/backgrounds"
      if (typeof keyword !== 'undefined' && keyword !== '' && keyword !== 'reset') {
        url += '?keyword=' + keyword;
        if (keyword === this.state.keyword) {
          page = this.state.page+1;
          url += '&page=' + page;
        }
      } else {
        keyword = '';
      }
      
      api_get(url)
        .then((resp) => { 
          this.setState({images: resp, keyword: keyword, page: page});
        })
        .catch(handle_api_error)
    }

    setTheme(setup) {
      let theme = {backgroundColor:'', backgroundImage: '', info: setup};
      if (typeof setup.color !== 'undefined') {
        theme.backgroundColor = setup.color;
      }
      if (typeof setup.urls !== 'undefined' && typeof setup.urls.full !== 'undefined') {
        theme.backgroundImage = setup.urls.full;
        theme.thumbnail = setup.urls.small;
        theme.unsplashId = setup.id;
      }
      this.props.setEnvVariable("theme", theme);
    }


    render() {

        let searchCategories = [
          {label: 'Paper', keyword: 'paper texture'}, 
          {label: 'Textures', keyword: 'wooden texture flora'}, 
          {label: 'Nature', keyword: 'nature green background texture'}, 
          {label: 'Landscape', keyword: 'landscape'}, 
          {label: 'Wood', keyword: 'wood texture'},
          {label: 'Abstract', keyword: 'abstract background'}, 
          {label: 'White', keyword: 'white texture background'}, 
          {label: 'Water', keyword: 'water texture'},
          {label: 'Floral', keyword: 'floral background'},
          {label: 'Dark', keyword: 'dark texture'},
          {label: 'City', keyword: 'city skyline'},
          {label: 'Grunge', keyword: 'grunge texture'},
          {label: 'Love', keyword: 'love romance background'},
          {label: 'Christmas', keyword: 'christmas background'},
          {label: 'Ink', keyword: 'ink'},
          {label: 'Red', keyword: 'red texture'},
          {label: 'Blue', keyword: 'blue texture'},
          {label: 'Green', keyword: 'green texture'},
          {label: 'Pink', keyword: 'pink texture'},
        ];

        return (
          <Translation>
        {(t) => (
          <div className="theme-editor">
            {
              this.props.theme.backgroundImage
              ? <div className="current-theme">
                  <h2 className="mb-3">{t("EditorToolbar.CurrentTheme")}</h2>
                  <div>
                    <div style={
                      this.props.theme.backgroundImage
                      ? {backgroundImage: "url("+this.props.theme.thumbnail+")"}
                      : this.props.theme.backgroundColor ? {backgroundColor: this.props.theme.backgroundColor} : {}
                    }>
                    </div>
                    
                      {
                        this.props.theme.backgroundImage && this.props.theme.info
                        ? <div>
                            <h4>{t("EditorToolbar.Photographer")}</h4>
                            <div className="unsplash-user">
                              {
                                this.props.theme.info.user.profile_image
                                ? <span style={{backgroundImage: "url("+this.props.theme.info.user.profile_image.medium+")"}}></span>
                                : ''
                              }
                              <a href={this.props.theme.info.user.links.html} target="_blank" rel="noopener noreferrer">{this.props.theme.info.user.name}</a>
                            </div>
                            <p>{this.props.theme.info.description || this.props.theme.info.alt_description}</p>
                          </div>
                        : <div>

                          </div>
                      }
                    
                  </div>
                </div>
              : ''
            }
            
            {
              /*
              <h2 className="mb-3">Featured Themes</h2>
              <div className="unsplash-results">
                {
                  FeaturedThemes.map((img, index) => {
                    let style = {};
                    if (typeof img.urls !== 'undefined') {
                      style.backgroundImage = "url("+img.urls.small+")";
                    } else if (typeof img.color !== 'undefined') {
                      style.backgroundColor = img.color;
                    }
                    return (
                      <div onClick={() => { this.setTheme(img) }} key={index} style={style}>
                        {
                          img.user
                          ? <div>
                              {
                                img.user.profile_image
                                ? <span className="uimg" style={{backgroundImage:  "url("+img.user.profile_image.medium+")"}}></span>
                                : ''
                              }
                              <a href={img.user.links.html} target="_blank" rel="noopener noreferrer">{img.user.name}</a>
                            </div>
                          : ''
                        }
                      </div>
                    );
                  })
                }
              </div>
              */
            }
            

            <h2 className="mt-4 mb-2">{t("EditorToolbar.FindTheme")}</h2>
            <p className="mb-3">{t("EditorToolbar.PoweredByUnsplash")}</p>
            <div className="tags">
            <span onClick={() => {
                  this.showColorOptions();
                }} className={!this.state.keyword ? 'active' : ''}>{t("EditorToolbar.Colors")}</span>
              {
                searchCategories.map((setup, index) => {
                return <span key={index} onClick={() => {
                  this.searchUnsplash(setup.keyword);
                }} className={setup.keyword === this.state.keyword ? 'active' : ''}>{setup.label}</span>
              })
              }
            </div>
            <div className="unsplash-results">
              {
                this.state.keyword
                ? this.state.images.map((img, index) => {
                  return (
                    <div onClick={() => { this.setTheme(img) }} key={index} data-id={img.id} style={
                      {backgroundImage: "url("+img.urls.small+")"}
                    }>
                      {
                        img.user
                        ? <div className="unsplash-user">
                            {
                              img.user.profile_image
                              ? <span style={{backgroundImage: "url("+img.user.profile_image.medium+")"}}></span>
                              : ''
                            }
                            <a href={img.user.links.html} target="_blank" rel="noopener noreferrer">{img.user.name}</a>
                          </div>
                        : ''
                      }
                    </div>
                  );
                })
                : this.colorOptions.map((color, index) => {
                  return (
                    <div key={index} style={{backgroundColor: color}} onClick={() => {
                      this.setTheme({
                        color: color
                      });
                    }}></div>
                  );
                })
              }
            </div>
            {
              this.state.images.length ===  24
              ? <div className="load-more">
                  <button className="small" onClick={() => {
                  this.searchUnsplash(this.state.keyword);
                }}><i className="fas fa-sync-alt"></i>{t("EditorToolbar.LoadMore")}</button>
                </div>
              : ''
            }
          </div>
          )}
          </Translation>
        )
        
    }
    
}