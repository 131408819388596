import { cloneObject } from './cloneObject.js';

export const getSelectedRows = (attr) => {
    let {selectedRows, rows, grps, filter} = attr;
    let rowsResult = [];
    for (let rowId of selectedRows) {
      let copiedRow = {};

      if (typeof filter !== 'undefined' && Object.values(filter).length) {

        copiedRow.index = rows[rowId] || 0;

        let grpId = rows[rowId].grpId;
        let group = grps[grpId];
        copiedRow.pos = parseFloat(group.pos + '.' + rows[rowId].pos);

        if (typeof filter.chords !== 'undefined' && filter.chords) {
          copiedRow.data.chords = cloneObject(rows[rowId].data.chords);
        }
        if (typeof filter.text !== 'undefined' && filter.text) {
          copiedRow.data.text = rows[rowId].data.text;
        }
        if (typeof filter.bars !== 'undefined' && filter.bars) {
          copiedRow.data.bars = cloneObject(rows[rowId].data.bars);
        }
        rowsResult.push(copiedRow);
      } else {
        copiedRow = cloneObject(rows[rowId]);
        let grpId = rows[rowId].grpId;
        let group = grps[grpId];
        copiedRow.pos = parseFloat(group.pos + '.' + rows[rowId].pos);

        rowsResult.push(copiedRow);
      }
    }

    rowsResult = rowsResult.sort((a, b) =>
      typeof a.index !== "undefined" && b.index !== "undefined" && a.index > b.index
      ? 1
      : -1
    );
    return rowsResult;
}