import React, {useContext} from "react";
import { Droppable } from "react-beautiful-dnd";
import { SongItem, ListItem, FolderItem, InviteItem } from "./Items";
import ViewListContext from '../../ViewListContext';


export const DocumentList = (props) => {

  const context = useContext(ViewListContext);

  if (!props.docsArray.length) {
    return null;
  }

  let folderId = "";
  if (typeof props.folderId !== "undefined") {
    folderId = props.folderId;
  }

  //let depth = 0;
  //if (typeof props.depth !== 'undefined') {
  //  depth = props.depth;
  //}
/*

      renderClone={
        <li>
          <label></label>
        </li>
      }
*/
  return (
    <Droppable
      droppableId="docslist"
      isDropDisabled={true}
      isCombineEnabled={true}
    >
      {(dropProvided, dropSnapshot) => (
        <ul
          className="document-list"
          ref={dropProvided.innerRef}
        >
          {props.docsArray.map((doc, index) => {

            if (context.sortBy === 'alpha' && doc.parent && doc.parent !== folderId) {
              return null;
            }

            return (
              <React.Fragment  key={doc.type + doc.id}>
                    {doc.type === "invite" && (
                      <InviteItem
                        index={index}
                        doc={doc}
                      />
                    )}

                    {doc.type === "folder" && context.sortBy === 'alpha' && (
                      <FolderItem doc={doc} docsArray={props.docsArray || []} />
                    )}

                    {doc.type === "song" && (
                      <SongItem
                        index={index}
                        listIndex={context.listId ? index + 1 : false}
                        doc={doc}
                      />
                    )}

                    {doc.type === "list" && (
                      <ListItem
                        index={index}
                        doc={doc}
                      />
                    )}
              </React.Fragment>
            );
          })}
          { dropProvided.placeholder }
        </ul>
      )}
    </Droppable>
  );
};