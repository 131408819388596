import React from 'react';
import i18next from 'i18next';
import localforage from 'localforage';
//import { api_patch, handle_api_error } from './API';
import UserContext from './../UserContext';
import songdataKeys from './../config/songdataKeys';

import { getDatabase, 
        ref as dbRef, 
        update as dbUpdate, 
        serverTimestamp } from "firebase/database";

const updateIntervalMS = 1000;

export const updateLocalCache = (updates, songId) => {
  getLocalCache()
    .then((docs) => {
      if (docs && typeof docs[songId] !== 'undefined') {
        let contexShouldUpdate = false;
        if (typeof updates.title !== 'undefined') {
          docs[songId].title = updates.title;
          document.title = updates.title + ' | ' + i18next.t('SiteName');
          contexShouldUpdate = true;
        }
        if (typeof updates.meta !== 'undefined') {
          docs[songId].meta = updates.meta;
          contexShouldUpdate = true;
        }
        if (contexShouldUpdate) {
          setLocalCache(docs);
        }
      }
    })
    .catch();
  
}

export const getLocalCache = () => {
  return new Promise((resolve) => {
    localforage.getItem('docs')
      .then((docs) => {
        resolve(docs);
      });
  })
};

export const getListFromLocalCache = (id) => {
  return new Promise((resolve) => {
    localforage.getItem('docs')
      .then((docs) => {
        resolve(typeof docs["list-" + id] !== 'undefined' ? docs["list-" + id] : false);
      });
  })
};

export const getListsFromLocalCache = () => {
  return new Promise((resolve) => {
    localforage.getItem('docs')
      .then((docs) => {
        let listArray = Object.values(docs).filter(r => (r.type === 'list'));
        let lists = {};
        for (let list of listArray) {
          lists[list.id] = list;
        }
        resolve(lists);
      });
  })
};

export const getSongsFromLocalCache = () => {
  return new Promise((resolve) => {
    localforage.getItem('docs')
      .then((docs) => {
        let songsArray = Object.values(docs).filter(r => (r.type === 'song'));
        let songs = {};
        for (let song of songsArray) {
          songs[song.id] = song;
        }
        resolve(songs);
      });
  })
};

export const setLocalCache = (docs) => {
  return new Promise((resolve) => {
    localforage.setItem('docs', docs)
      .then(resolve);
  })
};

export const addToLocalCache = (type, doc) => {
  return new Promise((resolve) => {
    getLocalCache()
      .then(docs => {
        if (typeof docs === 'undefined' || !docs) {
          docs = {};
        }
        doc.type = type;
        docs[type+"-"+doc.id] = doc;
        setLocalCache(docs).then(() => { 
          resolve(docs);
        });
      });
  })
};
export const removeFromLocalCache = (type, doc) => {
  let docId = (typeof doc === 'object' && doc.id) ? doc.id : doc;
  return new Promise((resolve) => {
    getLocalCache()
      .then(docs => {
        delete docs[type+"-"+docId];
        setLocalCache(docs).then(() => { 
          resolve(docs);
        });
      });
  })
};
export const removeCachedSongs = () => {
  return new Promise((resolve, reject) => {
    localforage.removeItem('docs')
    .then(resolve)
    .catch(reject);
  })
};

export class Storage extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.updateTimer = null;
  }

  componentWillUnmount() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
      this.storeData();
    }
  }

  componentDidUpdate() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }

    this.updateTimer = setTimeout(() => {
      this.storeData();
    }, updateIntervalMS);
  }

  storeData() {
    
    if (!this.props.song.id) {
      localforage.setItem('draft', this.props.song);
      return;
    }

    if (typeof this.context.user.uid === 'undefined') {
      return;
    }

    let songDbRef = 'songdata/' + this.props.song.id + '/';
    let songUpdates = {};
    //let groupUpdates = {};
    //let rowUpdates = {};
    let updates = this.props.updates;

    /*
    songUpdates.title = this.props.song.title;
    songUpdates.grps = this.props.song.grps;
    songUpdates.rows = this.props.song.title;
    songUpdates.snippets = this.props.song.title;
    songUpdates.meta = this.props.song.title;
    songUpdates.env = this.props.song.title;
    songUpdates.language = this.props.song.title;
    songUpdates.public = this.props.song.public;
    songUpdates.listed = this.props.song.listed;
    */

    // Cleanup the group object
    if (typeof updates.grps === 'object') {
      for (let grpId of Object.keys(updates.grps)) {
        if (typeof updates.grps[grpId].rows !== 'undefined') {
          delete updates.grps[grpId].rows;
        }
      }
    }

    // Cleanup the rows object
    if (typeof updates.rows === 'object') {
      for (let rowId of Object.keys(updates.rows)) {
        if (typeof updates.rows[rowId].index !== 'undefined') {
          delete updates.rows[rowId].index;
        }
      }
    }

    for (let mainKey of Object.keys(updates)) {
      if (songdataKeys.includes(mainKey)) {

        if (mainKey === 'rows' || mainKey === 'grps') {
          for (let subKey of Object.keys(updates[mainKey])) {

            // Add each updated parameter to songUpdates
            //if (typeof songUpdates[mainKey] === 'undefined') { songUpdates[mainKey] = {}; }
            songUpdates[songDbRef + mainKey + '/' + subKey] = updates[mainKey][subKey];

            // Add time reference on all objects
            if (typeof songUpdates[songDbRef + mainKey + '/' + subKey] === 'object' && !Array.isArray(songUpdates[mainKey + '/' + subKey])) {
              songUpdates[songDbRef + mainKey + '/' + subKey].ut = serverTimestamp(); // firebase.firestore.Timestamp.now().seconds;
              songUpdates[songDbRef + mainKey + '/' + subKey].ue = this.props.editorId;
              songUpdates[songDbRef + mainKey + '/' + subKey].uuid = this.context.user.uid;
            }

          }

        } else {
          songUpdates[songDbRef + mainKey] = updates[mainKey];
        }

      }
    }

    if (!Object.keys(songUpdates).length) {
      return;
    }
    songUpdates[songDbRef + 'ut'] = serverTimestamp();
    songUpdates[songDbRef + 'ue'] = this.props.editorId;
    songUpdates[songDbRef + 'uu'] = this.context.user.uid;
    //console.log('Will write to database', songUpdates);

    
    updateLocalCache(updates, this.props.song.id);
    
    this.props.clearUpdates();
    this.props.updateUpdateTimes(songUpdates);

    //const database = firebase.database();
    const db = getDatabase(this.context.firebase);
    dbUpdate(dbRef(db), songUpdates);
    //ref(set(database, 'songdata/' + this.props.song.id), songUpdates); // v9
    /*
    this.props.database.collection('songs').doc(this.props.song.id).update(songUpdates).then((resp) => {
      //console.log("Song stored in db", songUpdates);
    });
    */
    /*
    this.props.pauseLiveUpdates();
    api_patch("/songs/" + this.props.song.id, updates)
      .then(resp => {
        this.props.unpauseLiveUpdates();
      })
      .catch(handle_api_error);
      */
  }

  render() {
    return this.props.children;
  }
}
