import {apiHost} from "./config/env.js";
import { getAuth } from "firebase/auth";

export function handle_api_error(err) {
    if (typeof err.message != 'undefined') {
        alert(err.message);
    }
}

export function api_get(path) {
    return new Promise(async (resolve, reject) => {

        let idToken = '';
        const auth = getAuth();
        if (auth.currentUser) {
            idToken = await auth.currentUser.getIdToken() || '';
        }

        let options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Id-Token": idToken || ""
            }
        };

        fetch(apiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    })
                }
            })
            .catch((err) => {
                reject({code: "UnknownError", message: "Unknown error", details: ""})
            });
    });
}
export function api_patch(path, data) {
    return new Promise(async (resolve, reject) => {

        let idToken = '';
        const auth = getAuth();
        if (auth.currentUser) {
            idToken = await auth.currentUser.getIdToken() || '';
        }

        let options = {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Id-Token": idToken || ""
            }
        };

        fetch(apiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    })
                }
            })
            .catch((err) => {
                reject({code: "UnknownError", message: "Unknown error", details: ""})
            });
    });
}
export function api_post(path, data) {
    return new Promise(async (resolve, reject) => {

        let idToken = '';
        const auth = getAuth();
        if (auth.currentUser) {
            idToken = await auth.currentUser.getIdToken() || '';
        }

        let options = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Id-Token": idToken || ""
            }
        };

        fetch(apiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    })
                }
            })
            .catch((err) => {
                reject({code: "UnknownError", message: "Unknown error", details: ""})
            });
    });
}
export function api_delete(path) {
    return new Promise(async (resolve, reject) => {

        let idToken = '';
        const auth = getAuth();
        if (auth.currentUser) {
            idToken = await auth.currentUser.getIdToken() || '';
        }

        let options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Id-Token": idToken || ""
            }
        };

        fetch(apiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    })
                }
            })
            .catch((err) => {
                reject({code: "UnknownError", message: "Unknown error", details: ""})
            });
    });
}