import React from 'react';

export class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      visible: false 
    };
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    if (!this.state.visible) {
      setTimeout(() => {
        this.setState({visible: true}, this.hide);
      }, 200);
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.state.visible && this.props.html !== prevProps.html) {
      setTimeout(() => {
        this.setState({visible: true}, this.hide);
      }, 200);
    }
  }
  hide() {
    setTimeout(() => {
      this.setState({visible: false});
    }, 3000);
  }

  render() {
    return (
      <div className={'notification ' + (this.state.visible ? 'is-visible ': '') + (this.props.className || '')} style={this.props.style || {}}>
        <div
          className="notif-close"
          onClick={(e) => {
            e.stopPropagation();
            this.props.setNotification("");
          }}
        >
          <i className="fas fa-times" />
        </div>
        <p>{this.props.html}</p>
      </div>
    );
  }
}