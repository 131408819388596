import uniqid from "uniqid";
import { log } from './dev.js'; // isDev
import { cloneObject } from "./cloneObject.js";
import { stripTags } from "./stripTags.js";

export {
  newID,
  isID,
  isDeleted,
  getId,
  newSongModel,
  getNewGroup,
  getDeletedRowData,
  getNewRow,
  getDefaultBars, 
  getNewBar,
  composeDocArray,
  getTextFromGroup,
  sortByPos,
  songHas,
  updateChordsPositionFromCursorPosition,
  getPosBetween,
  getIndexOf,
  getRowIndex,
  getRowByIndex,
  getRowIdByIndex,
  getGroupById,
  getRowsInGroup,
  getRowInGroupByIndex,
  getLastOf,
  getFirstUpdatedPosition,
  getNextGroup,
  getPreviousRow, 
  getNextRow,
  filterGroups,
};

/*
function hashCode(str) {
  var hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
*/

function newID() {
  return uniqid();
}

function newSongModel(params, config) {

  let song = {
    title: 'Untitled song',
    owner: '',
    team: {},
    language: 'en',
    snippets: {},
    grps: {},
    rows: {},
    meta: {},
    flags: []
  };

  let group = getNewGroup();

  let row = getNewRow({ 
    grpId: group.id,
    type: (config && config.songType && config.songType === 'chordbars') ? 'chordbars': 'lyrics' 
  });

  song.grps[group.id] = group;
  song.rows[row.id] = row;

  if (typeof params === 'object') {
    let paramskeys = Object.keys(params);
    for (let x in paramskeys) {
      if (paramskeys[x] !== 'id') {
        song[paramskeys[x]] = (typeof params[paramskeys[x]] === 'object') ? cloneObject(params[paramskeys[x]]) : params[paramskeys[x]];
      }
    }
  }
  return song;
}

function getNewGroup(params) {
  let group = {
    id: newID(),
    pos: 65535,
    label: ''
  };
  if (typeof params === 'object') {
    let paramskeys = Object.keys(params);
    for (let x in paramskeys) {
      if (paramskeys[x] !== 'rows' && paramskeys[x] !== 'id') {
        group[paramskeys[x]] = (typeof params[paramskeys[x]] === 'object') ? cloneObject(params[paramskeys[x]]) : params[paramskeys[x]];
      }
    }
  }
  return group;
}

function getDeletedRowData(rowid) {
  return { id: rowid, deleted: true, type: false, data: {} };
}

function getNewRow(params) {
  let type = 'lyrics';
  if (typeof params.data !== 'undefined' && typeof params.data.bars !== 'undefined' && params.data.bars) {
    type = 'chordbars';
  }
  let row = {
    id: newID(),
    grpId: '',
    pos: 65535,
    type: type,
    data: { 
      text: '',
      chords: [],
      bars: false,
    },
    chordsUpdated: 0
  };
  if (typeof params === 'object') {
    let paramskeys = Object.keys(params);
    for (let x in paramskeys) {
      if (paramskeys[x] !== 'id') {
        row[paramskeys[x]] = (typeof params[paramskeys[x]] === 'object') ? cloneObject(params[paramskeys[x]]) : params[paramskeys[x]];
      }
    }
  }
  if (row.type === 'chordbars' && row.data.bars === false) {
    row.data = { bars: getDefaultBars() };
  }
  return row;
}

function getDefaultBars() {
  let bar1 = getNewBar({pos: 65535});
  let bar2 = getNewBar({pos: 65535*2});
  let bar3 = getNewBar({pos: 65535*3});
  let bar4 = getNewBar({pos: 65535*4});
  let bars = {};
  bars[bar1.id] = bar1;
  bars[bar2.id] = bar2;
  bars[bar3.id] = bar3;
  bars[bar4.id] = bar4;
  return bars;
}

function getNewBar(params) {
  let bar = {
    id: newID(),
    pos: 65535,
    beats: 4,
    fragments: [{chord:''},{chord:''}],
  };
  if (typeof params === 'object') {
    let paramskeys = Object.keys(params);
    for (let x in paramskeys) {
      if (paramskeys[x] !== 'id') {
        bar[paramskeys[x]] = (typeof params[paramskeys[x]] === 'object') ? cloneObject(params[paramskeys[x]]) : params[paramskeys[x]];
      }
    }
  }
  return cloneObject(bar);
}

function composeDocArray(grps, rows) {
  if (typeof grps == 'undefined' || typeof rows == 'undefined') {
    log("composeDocArray error");
    return false;
  }

  let newDocArray = sortByPos(removeDeleted(Object.values(grps)));
  let _rows = removeDeleted(Object.values(rows));

  for (let x in newDocArray) {
    newDocArray[x].rows = sortByPos(getRowsOfGroup(_rows, newDocArray[x].id));
  }

  newDocArray = removeEmptyGroup(newDocArray);

  return newDocArray;
}

function getRowsOfGroup(rows, parid) {
  return rows.filter(row => row.grpId===parid );
}

function removeDeleted(arr) {
  return arr.filter(obj => !isDeleted(obj));
}

function removeEmptyGroup(grps) {
  return grps.filter(p => typeof p.rows !== 'undefined' && p.rows.length > 0);
}

function getTextFromGroup(grpId, docArray) {
  let rowsInGroup = getRowsInGroup(grpId, docArray);
  let text = '';
  for (let x in rowsInGroup) {
    if (x > 0) {
      text += '\n';
    }
    text += rowsInGroup[x].data.text;
  }
  return text;
}

function sortByPos(arrArg) {
  arrArg.sort((a, b) =>
    typeof a.pos !== "undefined" && b.pos !== "undefined" && a.pos > b.pos
      ? 1
      : -1
  );
  return arrArg;
}

function songHas(rows) {
  
  let has = {chords: false, text: false, bars: false};

  if (typeof rows === 'undefined') {
    return has;
  }

  for (let row of Object.values(rows)) {
    if (typeof row.data !== 'undefined' && typeof row.data.chords !== 'undefined' && row.data.chords.length) {
      has.chords = true;
    }
    if (typeof row.data !== 'undefined' && typeof row.data.text !== 'undefined' && row.data.text && row.data.text !== '') {
      has.text = true;
    }
    if (typeof row.data !== 'undefined' && typeof row.data.bars !== 'undefined' && Object.keys(row.data.bars).length > 0) {
      has.bars = true;
    }
  }

  return has;
}

function updateChordsPositionFromCursorPosition(chords, firstUpdatedPosition, diffLength) {
  for (let x in chords) {
    if (chords[x].pos >= firstUpdatedPosition) {
      log("will change", chords[x].symbol, chords[x].pos);
      let canRemoveMax = parseInt(chords[x].pos) - parseInt(firstUpdatedPosition);
      if (diffLength < -canRemoveMax) {
        diffLength = -canRemoveMax;
      }
      chords[x].pos = chords[x].pos + diffLength;
    }
  }
  return chords;
}

function getPosBetween(relatedRow1=false, relatedRow2 = false) {
  
  if (relatedRow1 === false) {
    return 65535;
  }

  let basePos = 0;
  let nextPos = false;

  if (typeof relatedRow1 === 'object' && typeof relatedRow1.pos !== 'undefined') {
    basePos = relatedRow1.pos;
  } else if (typeof relatedRow1 === 'number') {
    basePos = relatedRow1;
  }

  if (typeof relatedRow2 === 'object' && typeof relatedRow2.pos !== 'undefined') {
    nextPos = relatedRow2.pos;
  } else if (typeof relatedRow2 === 'number') {
    nextPos = relatedRow2;
  }

  if (nextPos !== false && nextPos > 0) {
    if (nextPos > basePos) {
      let rowSpan = nextPos - basePos;
      return Math.round(nextPos - rowSpan / 2);
    }
  }

  return basePos + 65535;
}

function isID(id) {
  return (typeof id == 'string' && id.length >= 6) ? true : false;
}

function isDeleted(row) {
  return (typeof row === 'boolean' && row === false) 
          || (typeof row.type !== 'undefined' && row.type === false)
          || (typeof row.deleted !== 'undefined' && row.deleted === true);
}

function getId(input) {
  if (typeof input == 'string' && input.length >= 6) {
    return input;
  } else if (typeof input == 'object' && typeof input.id == 'string' && input.id.length >= 6) {
    return input.id;
  } else {
    return false;
  }
}

function getIndexOf(id, rows) { // renamed from getIndex
  for (let x in rows) {
    if (rows[x].id === id) {
      return parseInt(x);
    }
  }
  return false;
}

function getRowIndex(row, docArray) {
  if (typeof docArray === 'undefined') {
    console.error("Parameter docArray missing");
    return false;
  }

  let grpId = false;
  let rowId = false;

  if (typeof row === 'object' && typeof row.id !== 'undefined') {
    grpId = row.grpId || false;
    rowId = row.id;
  } else if (typeof row === 'string') {
    rowId = row;
  }

  let rowindex = 0;
  for (let group of docArray) {
    if (grpId && grpId !== group.id) {
      rowindex = rowindex + group.rows.length;
      continue;
    }
    for (let x in group.rows) {
      if (group.rows[x].id === rowId) {
        return parseInt(rowindex);
      }
      rowindex++;
    }
  }
  return false;
}


function getRowIdByIndex(rowindex, docArray) {
  if (typeof docArray === 'undefined') {
    console.error("Parameter docArray missing");
    return false;
  }
  if (isID(rowindex)) {
    return rowindex;
  }
  let row = getRowByIndex(rowindex, docArray)
  if (typeof row.id != 'undefined') {
    return row.id;
  } else {
    return false;
  }
}
function getRowByIndex(rowindex, docArray) {
  if (typeof docArray === 'undefined') {
    console.error("Parameter docArray missing");
    return false;
  }
  let currentindex = 0;
  for (let group of docArray) {
    for (let x in group.rows) {
      if (currentindex === rowindex) {
        return group.rows[x];
      }
      currentindex++;
    }
  }
  return false;
}

function getNextGroup(grpId, docArray) {
  if (typeof grpId === 'object' && typeof grpId.id !== 'undefined') {
    grpId = grpId.id;
  }
  let returnNext = false;
  for (let group of docArray) {
    if (returnNext) {
      return group;
    }
    if (grpId === group.id) {
      returnNext = true;
    }
  }
  return false;
}

function getGroupById(grpId, docArray) {
  if (typeof docArray === 'undefined') {
    console.error("Parameter docArray missing");
    return false;
  }
  let group = docArray.filter(p => p.id === grpId);
  return group[0];
}

function getRowsInGroup(grpId, docArray) {
  if (typeof docArray === 'undefined') {
    console.error("Parameter docArray missing");
    return false;
  }
  let group = getGroupById(grpId, docArray);
  return group.rows;
}

function getRowInGroupByIndex(rowindex, grpId, docArray) {
  if (typeof docArray === 'undefined') {
    console.error("Parameter docArray missing");
    return false;
  }
  let group = getGroupById(grpId, docArray);
  return (typeof group.rows[rowindex] != 'undefined') ? group.rows[rowindex] : false;
}

function getLastOf(id, checkArray) {
  if (typeof checkArray === 'undefined') {
    console.error("Array parameter missing");
    return false;
  }
  let elements = checkArray.filter(p => p.id === id);
  return elements[0].rows[elements[0].rows.length-1];
}

function getPreviousRow({rows, currentRowId, docArray}) {
  let currentRowIndex = getRowIndex(currentRowId, docArray);
  let rowId = getRowIdByIndex(currentRowIndex-1, docArray);
  if (rowId && typeof rows[rowId] !== 'undefined') {
    return rows[rowId];
  } else {
    return false;
  }
}

function getNextRow({rows, currentRowId, docArray}) {
  let currentRowIndex = getRowIndex(currentRowId, docArray);
  let rowId = getRowIdByIndex(currentRowIndex+1, docArray);
  if (rowId && typeof rows[rowId] !== 'undefined') {
    return rows[rowId];
  } else {
    return false;
  }
}

function getFirstUpdatedPosition(newHtml, oldHtml) {
  let newHtmlPlainText = stripTags(newHtml);
  let oldHtmlPlainText = stripTags(oldHtml);
  for (let x in oldHtmlPlainText) {
    if (newHtmlPlainText[x] !== oldHtmlPlainText[x]) {
      return x;
    }
  }
}

function filterGroups(grps, rows) {
  let remainingGroups = {};
  for (let row of Object.values(rows)) {
    if (typeof row.deleted === 'undefined' || !row.deleted) {
      if (typeof grps[row.grpId] !== 'undefined' && !grps[row.grpId].deleted) {
        remainingGroups[row.grpId] = grps[row.grpId];
      }
    }

    
  }
  
  let deletedGroups = Object.values(grps).filter(p => typeof remainingGroups[p.id] === 'undefined');

  return {remainingGroups, deletedGroups};
}