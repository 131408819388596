import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Modal } from './components/Modal';
//import { Link } from 'react-router-dom';
import { LoginForm } from './components/LoginForm';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import { ToolbarUser } from './components/toolbar/ToolbarUser';
import UserContext from './UserContext';
import {Loading} from './components/Loading';

export const ViewStart = (props) => {
  let [ isLoginModalVisible, showLoginModal ] = useState(false);
  let userContext = useContext(UserContext);

  return (
    <Translation>
      {(t) => (
        <div className="start-page">
          {props.loggedIn ? (
            <div className="toolbar">
              <div className="tb-right">
                <ToolbarUser t={t} user={userContext.user} loggedIn={userContext.loggedIn} />
              </div>
            </div>
          ) : (
            ''
          )}

          <Helmet>
            <title>{i18next.t('SiteName')}</title>
            <meta property="og:title" content={i18next.t('SiteName')} />
            <meta property="og:locale" content={i18next.language.substring(0, 2) || 'en'} />
            <meta property="og:url" content={'https://' + window.location.host + '/'} />
          </Helmet>
          {isLoginModalVisible ? (
            <Modal
              close={() => {
                showLoginModal(false);
              }}
            >
              <LoginForm
                onLoginSuccess={() => {
                  props.redirect('/songs');
                }}
                onLoginFailure={() => {}}
              />
            </Modal>
          ) : (
            ''
          )}
          <section className="hero">
            <div className="hero-inner">
              <h1 className="branding">
                {t('SiteName')}
                <span className="beta-flag">{t('Common.Beta')}</span>
              </h1>
              <p>
                {t('StartPage.HeroTextRow1')}
                <br />
                {t('StartPage.HeroTextRow2')}
              </p>
              {props.doingAutoLogin ? (
                <div className="hero-buttons">
                  <Loading text={t('Common.Loading')} />
                </div>
              ) : (
                <div className="hero-buttons">
                  <button
                    className="button highlighted"
                    onClick={() => {
                      showLoginModal(true);
                    }}
                  >
                    {t('StartPage.HeroButtonSignIn')}
                  </button>
                  {/* 
                  <button
                    className="button"
                    onClick={() => {
                      props.redirect('/write');
                    }}
                  >
                    {t('StartPage.HeroButtonStartWriting')}
                  </button>
                   */}
                </div>
              )}
            </div>
            <div className="hero-more">
              <div>
                <p>{t('StartPage.MoreInfo')}</p>
                <i className="fas fa-chevron-down" />
              </div>
            </div>
          </section>
          <section className="text-section">
            <div className="container">
              <div className="row justify-content-center mb-4">
                <div className="col-10 col-lg-8">
                  <div className="pb-4">
                    <h2>{t('StartPage.SummaryHeadline')}</h2>
                    <p>{t('StartPage.SummaryDescription')}</p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mb-4">
                <div className="col-12 col-sm-8 col-lg-4 mb-4 mb-lg0">
                  <div>
                    <h3>{t('StartPage.SummaryPoint1Headline')}</h3>
                    <p className="text-small">{t('StartPage.SummaryPoint1Description')}</p>
                  </div>
                </div>
                <div className="col-12 col-sm-8 col-lg-4">
                  <div>
                    <h3>{t('StartPage.SummaryPoint2Headline')}</h3>
                    <p className="text-small">{t('StartPage.SummaryPoint2Description')}</p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-lg-4 mb-4 mb-lg0">
                  <div>
                    <h3>{t('StartPage.SummaryPoint3Headline')}</h3>
                    <p className="text-small">{t('StartPage.SummaryPoint3Description')}</p>
                  </div>
                </div>
                <div className="col-12 col-sm-8 col-lg-4">
                  <div>
                    <h3>{t('StartPage.SummaryPoint4Headline')}</h3>
                    <p className="text-small">{t('StartPage.SummaryPoint4Description')}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="large-video bg-fill">
            <div className="container mobile-full-width">
              <div className="row justify-content-center">
                <div className="col-12 col-xl-10">
                  <div>
                    <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true}>
                      <source
                        src="https://storage.googleapis.com/swt-files/SongwritersTools-StartWriting.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="columns">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5">
                  <div>
                    <h2>{t('StartPage.ChordsHeadline')}</h2>
                    <p>{t('StartPage.ChordsDescription')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-stretched-column">
              <div>
                <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true}>
                  <source
                    src="https://storage.googleapis.com/swt-files/SongwritersTools-StartChords.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </section>

          <section className="screenshot-gallery bg-fill">
            <div className="container">
              <div className="row  justify-content-center">
                    <div className="col">
                      <img src="/static/images/screenshots-gray-bg.jpg" alt="Screenshots" />
                    </div>
              </div>
            </div>
          </section>

          <footer className="bg-gradient">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h1 className="branding">Songwriter's Tools</h1>
                  <p>hello@songwriterstools.com</p>
                  {/* 
                  <div className="service-branding">
                    <img src="/static/images/google-cloud-branding.png" alt="Google Cloud" />
                    <img src="/static/images/musixmatch-branding.svg" alt="Musixmatch" />
                  </div>
                   */}
                </div>
              </div>
            </div>
          </footer>
        </div>
      )}
    </Translation>
  );
};
