import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { getAuth, applyActionCode } from "firebase/auth";

export const UserManagement = (props) => {

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {

    const auth = getAuth();

    
    if (params.get("mode") === 'verifyEmail') {

      applyActionCode(auth, params.get("oobCode")).then((resp) => {
        props.updateUserState({ emailVerified: true });
        props.redirect("/songs");
      }).catch((err) => {
        props.redirect("/songs");
      });
      
    }

  }, []); // eslint-disable-line

  return <React.Fragment></React.Fragment>;
}