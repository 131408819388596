export const getCursorOffsetInElement = (element) => {
    var caretOffset = 0;
    if (element) {
      var docu = element.ownerDocument || element.document;
      var win = docu.defaultView || docu.parentWindow;
      var sel;
      if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel = docu.selection) && sel.type !== 'Control') {
        var textRange = sel.createRange();
        var preCaretTextRange = docu.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
      }
    }
    return caretOffset;
  }