/*
https://ddex.net/
http://service.ddex.net/dd/MWN12/dd/index.html
https://kb.ddex.net/display/HBK/Contributors%2C+Artists+and+Writers
https://kb.ddex.net/display/HBK/Writer+Roles

Contributor:
    Role:
        - Lyricist
        - Composer
        - ComposerLyricist
        - ComposerAndOrLyricist

Artist
    ArtistRole:
        - MainArtist
        - FeaturedArtist

Publisher

*/
import React from 'react';
import {DisplayArtist, DisplayLyricist, DisplayComposer, DisplayComposerLyricist} from './ContributersDisplay';

import { ToolbarMeta } from './toolbar/ToolbarMeta';
import {Modal} from "./Modal";

export class Contributers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MainArtist: [],
      FeaturedArtist: [],
      Lyricist: [],
      Composer: [],
      ComposerLyricist: [],
      showModal: false
    };
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.update();
  }
  componentDidUpdate() {
    this.update();
  }
  update() {
    let state = this.state;
    let updated = false;
    for (let data of Object.values(this.props.meta)) {
      if (typeof state[data.role] !== 'undefined') {
        if (!state[data.role].includes(data.fullname)) {
          state[data.role].push(data.fullname);
          updated = true;
        }
      }
    }
    if (updated) {
      this.setState(state);
    }
  }

  render() {
    return (
      <ul className="contributers" onClick={() => { 
        if (this.props.editActive) {
          this.setState({showModal:'meta-editor'}) 
        }
      }}>
      {
        Object.keys(this.props.meta).length
        ? <React.Fragment>
            <DisplayArtist meta={this.props.meta} />
            <DisplayLyricist meta={this.props.meta} />
            <DisplayComposer meta={this.props.meta} />
            <DisplayComposerLyricist meta={this.props.meta} />
          </React.Fragment>
        : <span className="icon">
            <i className="fas fa-pen"></i>
          </span>
      }
      {
        this.state.showModal === 'meta-editor' && this.props.isTeamMember && !this.props.readerMode
        ? <Modal close={() => { this.setState({showModal:false}) }} className="medium">
            <ToolbarMeta updateMeta={this.props.updateMeta} removeMeta={this.props.removeMeta} meta={this.props.meta} />
          </Modal>
        : ''
      }
      </ul>
    );
  }
}

