import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { validateEmail } from './validateEmail';

import { 
  getAuth, 
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  updateProfile
} from "firebase/auth";

export const LoginWithEmail = () => {
  const {t} = useTranslation();
  const [invalid, setInvalid] = useState({
    email: false,
    password: false,
    firstName: false,
    lastName: false,
  });
  const [step, setStep] = useState("start");
  const [signupError, setSignupError] = useState(""); // eslint-disable-line
  const [loginError, setLoginError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleButtonClick = () => {
    if (step === "start" || step === "use-another-method") {
      checkEmail();
    } else if (step === "register") {
      register();
    } else if (step === "login") {
      login();
    }
  }

  const checkEmail = () => {
    if (email.length > 3 && validateEmail(email)) {
      setInvalid({...invalid, email: false});
      const auth = getAuth();
      fetchSignInMethodsForEmail(auth, email)
        .then((resp) => {
          if (Array.isArray(resp) && resp.includes("password")) {
            // Can login
            setStep("login");
          } else if (Array.isArray(resp) && resp.length > 0) {
            // Logged in with another method
            setStep("use-another-method");
          } else {
            // Create account/password
            setStep("register");
          }
        })
        .catch((err) => {
          //console.log("fetchSignInMethodsForEmail err", err);
        });
    } else {
      setInvalid({...invalid, email: true});
    }
  }

  const register = () => {
    
    let anyEmpty = [email, password, firstName, lastName].find(val => { return val==='' ? true : false; });
    let anyInvalid = Object.values(invalid).find(isInvalid => { return isInvalid; });

    if (anyEmpty || anyInvalid) {
      return;
    }

    setSignupError("");
    
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {

        // Signed in 
        await updateProfile(auth.currentUser, {
          displayName: firstName + " " + lastName
        });

        await sendEmailVerification(auth.currentUser);
      })
      .catch((error) => {
        setSignupError(error.code);
      });
  }
  const login = () => {

    if (!email || !password) {
      return;
    }

    setLoginError("");

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //const user = userCredential.user;
      })
      .catch((error) => {
        setLoginError(error.code);
      });
  }

  return (
    <div className="email-login">

      {
        (step==='login' || step==='register') && (
          <div>
            {
              step==='register'
              ? <h4>{t("Login.Register")}</h4>
              : <h4>{t("Login.LoginInWithEmailAndPassword")}</h4>
            }
            <p>{email}</p>
          </div>
        )
      }

      {
        (step==='start' || step==='use-another-method') && (
          <div>
            <label>{t("Login.LoginInWithEmailAndPassword")}</label>
            <input 
              type="text" 
              className={invalid.email ? 'invalid': ''}
              placeholder={t("Login.EmailAddress")} 
              value={email} 
              onChange={(event) => {
                setEmail(event.target.value);
                if (validateEmail(event.target.value)) {
                  setInvalid({...invalid, email: false});
                } else {
                  setInvalid({...invalid, email: true});
                }
              }}  />
          </div>
        )
      }

      {
        step==='use-another-method' && (
          <div>
            {t("Login.EmailAlreadyUsedWithAnotherMethod", {loginmethods: ""})}
          </div>
        )
      }

      {
        loginError
        ? (
          <div>
            {t("Login.Error."+loginError)}
          </div>
        )
        : null
      }

      {
        (step==='register' || step==='login') && (
          <div>
            <input 
              type="password" 
              className={invalid.password ? 'invalid': ''}
              placeholder={t("Login.YourPassword")} 
              title={t("Login.YourPassword")} 
              value={password} 
              onChange={(event) => {
                setPassword(event.target.value);
                setLoginError("");
                setSignupError("");
                if (event.target.value.length > 5) {
                  setInvalid({...invalid, password: false});
                } else {
                  setInvalid({...invalid, password: true});
                }
              }} />
          </div>
        )
      }

      {
         step==='register' && (
          <>
            <div>
              <input 
                type="firstName" 
                className={invalid.firstName ? 'invalid': ''}
                placeholder={t("Login.FirstName")} 
                title={t("Login.FirstName")} 
                value={firstName} 
                onChange={(event) => {
                  setFirstName(event.target.value);
                  if (event.target.value.length > 0) {
                    setInvalid({...invalid, firstName: false});
                  } else {
                    setInvalid({...invalid, firstName: true});
                  }
                }} />
            </div>

            <div>
              <input 
                type="lastName" 
                className={invalid.lastName ? 'invalid': ''}
                placeholder={t("Login.LastName")} 
                title={t("Login.LastName")} 
                value={lastName} 
                onChange={(event) => {
                  setLastName(event.target.value);
                  if (event.target.value.length > 0) {
                    setInvalid({...invalid, lastName: false});
                  } else {
                    setInvalid({...invalid, lastName: true});
                  }
                }} />
            </div>
          </>
        )
      }
      
      {
        true
        ? (
          <div>
            <button className="email-login-button" onClick={handleButtonClick}>
              <i className="material-icons-outlined md-18">email</i>
              <span>{t('Login.Continue')}</span>
            </button>
          </div>
        )
        : null
      }
      

      {
        (step==='register') && (
          <div>
            <span className="cursor-pointer" onClick={(event) => {
                event.preventDefault();
                setStep("start");
                setEmail("");
              }}>{t("Buttons.Cancel")} </span>
          </div>
        )
      }
      
      
    </div>
  );
}