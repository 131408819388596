import React from 'react'
import { useTranslation } from 'react-i18next';
import {Loading} from './components/Loading.js';

export const ViewLoadingProgress = (props) => {
    const {t} = useTranslation();
    return (
        <div className="loading-container"><Loading text={t("Common.Loading")} /></div>
    );
}
