import React from 'react';

export const BarElementsAbove = (props) => {
    
    return (
        <div className="bar-above" id={"above-" + props.rowid + '-' + props.barId}>
            {
                props.bar.rehearsal && props.bar.rehearsal !== ''
                ? <div className="rehearsal-mark">{props.bar.rehearsal}</div>
                : ''
            }
                {
                props.bar.repeatEnding && props.bar.repeatEnding !== ''
                ? <div className="repeat-ending">{props.bar.repeatEnding}</div>
                : ''
            }
        </div>
    );
}