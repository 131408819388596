export const apiHost = () => {
  if (window.location.host === "app-dot-songwriters-tools.appspot.com") {
    return "https://api-dot-songwriters-tools.appspot.com";
  } else if (
    window.location.host === "songwriterstools.com" ||
    window.location.host === "www.songwriterstools.com"
  ) {
    return "https://api.songwriterstools.com";
  } else {
    return "http://localhost:3451";
  }
};

export const isTest = () => {
  if (window.location.host.indexOf("songwriterstools.com") >= 0) {
      return false;
  } else {
      return true;
  }
}