import { cloneObject } from '../cloneObject.js';
import { getRowByIndex, getRowsInGroup, getPosBetween } from '../utils.js';

export const handleGlueToGroupAbove = (attr) => {
    let {updateObject, row, grps, docArray} = attr;

    let prevRowindex = row.index - 1;
    let prevRow = getRowByIndex(prevRowindex, docArray);
    if (!prevRow) {
      return false;
    }

    let currentRowGroupId = row.grpId;

    let rowsInP = getRowsInGroup(row.grpId, docArray);
    let prevPos = prevRow.pos;
    for (let modifyRow of rowsInP) {
      updateObject.prev.rows[modifyRow.id] = cloneObject(modifyRow);
      modifyRow.grpId = prevRow.grpId;
      modifyRow.pos = getPosBetween(prevPos, false);
      prevPos = modifyRow.pos;
      updateObject.next.rows[modifyRow.id] = cloneObject(modifyRow);
    }

    updateObject.prev.grps[currentRowGroupId] = cloneObject(grps[currentRowGroupId]);
    updateObject.next.grps[currentRowGroupId] = { id: currentRowGroupId, deleted: true };

    return updateObject;
};