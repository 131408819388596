import React, {useContext} from 'react';
import {  Draggable, Droppable } from 'react-beautiful-dnd';
import { SongItemInPlaylist } from './Items';
import ViewListContext from '../../ViewListContext';

function getListAnimationStyle(style, snapshot, listId) {
  if (!snapshot.isDropAnimating || listId) {
    return style;
  }
  return { ...style, transitionDuration: `0.001s` };
}

export const ListPlaylist = (props) => {

  const context = useContext(ViewListContext);

  return (
    <Droppable droppableId={'playlist'} isDropDisabled={false}>
      {(provided, snapshot) => (
        <ul className="document-list" ref={provided.innerRef}>
          {props.listArray.map((doc, index) => {

            //if (doc.deleted || typeof doc.type === 'undefined') {
            //  return null;
            //}

            return (
              <Draggable
                draggableId={'playlist-'+doc.type+'-'+doc.itemId+'-'+index}
                index={index}
                key={'playlist-'+doc.type+'-'+doc.itemId+'-'+index}
                isDragDisabled={false}
              >
                {(provided, snapshot) => (
                    <li
                      key={doc.itemId}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={"doctype-" + doc.type}
                      style={getListAnimationStyle(provided.draggableProps.style, snapshot, context.listId)}
                    >
                      {
                        doc.type === 'song'
                        && (
                          <SongItemInPlaylist
                            index={index}
                            doc={doc}
                            listIndex={context.listId ? index + 1 : false}
                          />
                        )
                      }
                      
                    </li>

                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
}