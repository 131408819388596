import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { DocumentOptions } from "./DocumentOptions";
import { addToLocalCache, removeFromLocalCache } from "../Storage";
import { api_post, api_delete, handle_api_error } from "../../API";
import ViewListContext from "../../ViewListContext";
import Confirm from "../Confirm";

function getListAnimationStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return { ...style, transitionDuration: `0.001s` };
}

export const SongItem = ({ doc, index }) => {
  const { t } = useTranslation();
  const context = useContext(ViewListContext);

  const [closeDropdown, setCloseDropdown] = useState(0);

  let deleted = typeof doc.deleted !== "undefined" ? doc.deleted : false;

  let linkURL = "";
  if (deleted) {
    linkURL = "#";
  } else {
    linkURL = "/song/" + doc.id;
  }

  return (
    <Draggable
      draggableId={"docslist-" + doc.type + "-" + doc.id + "-" + index}
      index={index}
      key={"docslist-" + doc.type + "-" + doc.id + "-" + index}
      isDragDisabled={doc.type === "folder" ? true : false}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <li
            className={"doctype-" + doc.type}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getListAnimationStyle(provided.draggableProps.style, snapshot)}
          >
            <div>
              <Link to={linkURL} className={deleted ? "deleted" : ""}>
                {/*index + 1*/}
                <i className="icon-song"></i>
                <span>
                  {doc.title}
                  <Flags doc={doc} />
                </span>
              </Link>

              <DocumentOptions close={closeDropdown}>
                <li
                  onClick={() => {
                    context.duplicateSong(doc.id);
                    setCloseDropdown(closeDropdown+1);
                  }}
                ><span><i className="far fa-copy" />{t("List.DuplicateSong")}</span></li>
                <li
                  onClick={() => {
                    context.removeSong(doc.id);
                    setCloseDropdown(closeDropdown+1);
                  }}
                ><span><i className="far fa-trash-alt" />{t("List.DeleteSong")}</span></li>
              </DocumentOptions>
            </div>
          </li>

          {snapshot.isDragging && (doc.type === "song" || doc.type === "list") && (
            <li className={"dnd-clone doctype-" + doc.type}>
              <label></label>
            </li>
          )}
        </React.Fragment>
      )}
    </Draggable>
  );
};

export const SongItemInPlaylist = ({ doc, listIndex, index }) => {
  const { t } = useTranslation();
  const context = useContext(ViewListContext);
  const [closeDropdown, setCloseDropdown] = useState(0);
  let deleted = typeof doc.deleted !== "undefined" ? doc.deleted : false;

  let linkURL = "";
  if (deleted) {
    linkURL = "#";
  } else {
    linkURL =
      context.listId && listIndex
        ? "/list/" + context.listId + "/" + listIndex
        : "/song/" + doc.id;
  }

  return (
    <div>
      <Link to={linkURL} className={deleted ? "deleted" : ""}>
        <i className="icon-song"></i>
        <span>
          {doc.title}
          <Flags doc={doc} />
        </span>
      </Link>

      <DocumentOptions close={closeDropdown}>
          <li
            onClick={() => {
              context.removeSong(doc.id);
              setCloseDropdown(closeDropdown+1);
            }}
          ><span><i className="far fa-trash-alt" />{t("List.RemoveFromPlaylist")}</span></li>
        </DocumentOptions>

    </div>
  );
};

export const ListItem = ({ index, doc }) => {
  const { t } = useTranslation();
  const context = useContext(ViewListContext);
  const [closeDropdown, setCloseDropdown] = useState(0);
  let deleted = typeof doc.deleted !== "undefined" ? doc.deleted : false;
  //let linkURL = "/songs/list/" + doc.id;

  return (
    <Draggable
      draggableId={"docslist-" + doc.type + "-" + doc.id + "-" + index}
      index={index}
      key={"docslist-" + doc.type + "-" + doc.id + "-" + index}
      isDragDisabled={doc.type === "folder" ? true : false}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <li
            className={"doctype-" + doc.type}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getListAnimationStyle(provided.draggableProps.style, snapshot)}
          >
            <div>
              <label
                onClick={() => {
                  context.loadPlaylist(doc.id);
                }}
                className={deleted ? "deleted" : ""}
              >
                {/* <i className="material-icons">format_list_bulleted</i> */}
                <i className="material-icons-outlined md-22">playlist_play</i>
                <span>
                  {doc.title}
                  <em>
                    {" (" +
                    Object.keys(doc.items).length +
                      //t("List.NumSongs", {
                      //  count: Object.keys(doc.items).length,
                      //}) +
                      ")"}
                  </em>
                  <Flags doc={doc} />
                </span>
              </label>

              <DocumentOptions close={closeDropdown}>
                <li
                  onClick={() => {
                    context.removePlaylist(doc.id);
                    setCloseDropdown(closeDropdown+1);
                  }}
                ><span><i className="far fa-trash-alt" />{t("List.DeletPlaylist")}</span></li>
              </DocumentOptions>

            </div>
          </li>

          {snapshot.isDragging && (
            <li className="dnd-clone">
              {doc.type === "song" && (
                <li className={"doctype-" + doc.type}>
                  <label></label>
                </li>
              )}
              {doc.type === "list" && (
                <li className={"doctype-" + doc.type}>
                  <label></label>
                </li>
              )}
            </li>
          )}
        </React.Fragment>
      )}
    </Draggable>
  );
};

export const FolderItem = ({ doc, docsArray }) => {
  const { t } = useTranslation();
  const context = useContext(ViewListContext);
  const [closeDropdown, setCloseDropdown] = useState(0);
  const folderIsOpen = typeof context.openFolders[doc.id] !== 'undefined' && context.openFolders[doc.id] === true ? true : false;
  const docsInFolder = docsArray.filter((d) => d.parent === doc.id);
  return (
    <li className={
      "doctype-folder" 
      + (folderIsOpen ? " open" : "")
      }>
      <Droppable droppableId={"folder-" + doc.id}>
        {(provided, snapshot) => (
          <div className={snapshot.isDraggingOver ? "folder-drag-over" : ""} ref={provided.innerRef} {...provided.droppableProps}>
            <label onClick={() => { context.toggleFolder(doc.id) }}>
              <i className="folder-icon material-icons-outlined md-22">folder</i>
              <span>{doc.title} <em>({docsInFolder.length})</em></span>
            </label>

            <DocumentOptions close={closeDropdown}>
                <li
                  onClick={() => {
                    context.editFolder(doc.id);
                    setCloseDropdown(closeDropdown+1);
                  }}
                ><span><i className="material-icons-outlined">edit</i>{t("List.RenameFolder")}</span></li>

                <li
                  onClick={() => {
                    context.removeFolder(doc.id);
                    setCloseDropdown(closeDropdown+1);
                  }}
                ><span><i className="far fa-trash-alt" />{t("List.DeleteFolder")}</span></li>
                
              </DocumentOptions>

            {/* provided.placeholder */}

            {docsInFolder.length && folderIsOpen ? (
              <ul>
                {docsInFolder.map((subdoc, index) => {
                  return (
                    <React.Fragment key={subdoc.type + subdoc.id}>
                      {subdoc.type === "song" && (
                        <SongItem index={index} doc={subdoc} />
                      )}

                      {subdoc.type === "list" && (
                        <ListItem index={index} doc={subdoc} />
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            ) : null}
          </div>
        )}
      </Droppable>
    </li>
  );
};

export const InviteItem = ({ doc }) => {
  const { t } = useTranslation();
  const context = useContext(ViewListContext);

  const acceptInvite = () => {
    api_post("/users/invites/" + doc.id + "/accept")
      .then(async (newSong) => {
        newSong.flags.push("new");
        await addToLocalCache("song", newSong);
        await removeFromLocalCache("invite", doc.id);
        await context.updateListItems();
      })
      .catch(handle_api_error);
  };

  const declineInvite = () => {
    Confirm({
      headline: t('List.ConfirmDeclineInvite'),
      message: t('List.ConfirmDeclineInviteText'),
      onConfirm: () => {
        
        api_delete("/users/invites/" + doc.id + "/decline")
          .then(async () => {
            await removeFromLocalCache("invite", doc.id);
            await context.updateListItems();
          })
          .catch(handle_api_error);
          
      }
    });


  };

  if (typeof doc.flags === "undefined") {
    doc.flags = [];
  }
  doc.flags.push("invite");

  return (
    <li className="doctype-invite">
      <div>
        <label>
        <i className="icon-song"></i>
          <span>
            {doc.title}
            <Flags doc={doc} />
          </span>
        </label>
        <div className="document-options">
          <button className="small highlighted" onClick={acceptInvite}>
            {t("List.AcceptInvite")}
          </button>
          <button className="small highlighted" onClick={declineInvite}>
            {t("List.DeclineInvite")}
          </button>
        </div>
      </div>
    </li>
  );
};

const Flags = ({ doc }) => {
  const { t } = useTranslation();

  return doc.flags && doc.flags.length > 0 ? (
    <span className="flags">
      {doc.flags && doc.flags.includes("invite") && (
        <span className="invite">{t("List.Invite")}</span>
      )}
      {doc.flags && doc.flags.includes("demo") && (
        <span className="demo">{t("List.FlagDemo")}</span>
      )}
      {doc.flags &&
        doc.flags.includes("new") &&
        !doc.flags.includes("demo") && (
          <span className="new">{t("List.FlagNew")}</span>
        )}
    </span>
  ) : null;
};
