import React from 'react';
import { isTouchDevice } from './../../../lib/deviceControl'; 
import { BarElementsAbove } from './BarElementsAbove.js';
import { BarOptions } from './BarOptions.js';
import { Barline } from './Barline.js';
//import { Dropdown } from './Dropdown.js';
import { BarFragment, getFragmentClass } from './BarFragment';
import { sortByPos, getNewBar } from './../../../lib/utils';
import { cloneObject } from './../../../lib/cloneObject.js';
import { Translation } from 'react-i18next';

export class Chordbars extends React.Component {
  constructor(props) {
    super();
    this.state = {
      bars: {},
      barArray: [],
      focused: false,
      barRowUpdated: 0,
      closeDropdown: 0,
      editBarOptionsFor: false
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateBar = this.updateBar.bind(this);
    this.setBarline = this.setBarline.bind(this);
    this.newBar = this.newBar.bind(this);
    this.removeBar = this.removeBar.bind(this);
    this.devideFragment = this.devideFragment.bind(this);
    this.openBarOptions = this.openBarOptions.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
    let barArray = sortByPos(Object.values(this.props.bars));
    this.setState({ bars: this.props.bars, barArray: barArray, barRowUpdated: this.state.barRowUpdated + 1 });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chordsUpdated !== this.props.chordsUpdated || this.props.row.ut > prevProps.row.ut) {
      let barArray = sortByPos(Object.values(this.props.bars));
      this.setState({ bars: this.props.bars, barArray: barArray, barRowUpdated: this.state.barRowUpdated + 1});
    }
  }

  handleClickOutside(e) {
    if (this.containerRef && !this.containerRef.contains(e.target)) {
      this.setState({ focused: true });
    }
  }

  updateBar(attr) {
    return new Promise((resolve, reject) => {
      let findexSplit = typeof attr.findex !== 'undefined' ? attr.findex.split('.') : [];
      let bars = this.state.bars;

      if (findexSplit.length === 4) {
        bars[attr.barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]]['fragments'][findexSplit[3]] = attr.content;
      } else if (findexSplit.length === 3) {
        bars[attr.barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]] = attr.content;
      } else if (findexSplit.length === 2) {
        bars[attr.barId]['fragments'][findexSplit[1]] = attr.content;
      } else if (findexSplit.length < 2) {
        for (let key of Object.keys(attr.content)) {
          bars[attr.barId][key] = attr.content[key];
        }
      }

      let barArray = sortByPos(Object.values(bars));
      this.setState({ bars: bars, barArray: barArray, barRowUpdated: this.state.barRowUpdated + 1 }, () => {
        //this.props.updateBarRow(this.state.bars, this.props.row.id);
        this.props.updateRowData(this.props.row.id, {bars: bars}, 'bar-update');
        resolve();
      });
    });
  }

  setBarline(attr) {
    let bars = this.state.bars;
    let barlineKey = attr.location === 'left' ? 'barlineLeft' : 'barlineRight';
    bars[attr.barId][barlineKey] = { style: attr.style, repeat: attr.repeat };

    let barArray = sortByPos(Object.values(bars));
    this.setState({ bars: bars, barArray: barArray, barRowUpdated: this.state.barRowUpdated + 1 }, () => {
      //this.props.updateBarRow(bars, this.props.row.id);
      this.props.updateRowData(this.props.row.id, {bars: bars}, 'bar-barline');
    });
  }

  newBar(attr) {
    let bars = this.state.bars;
    let barArray = sortByPos(Object.values(bars));
    let currentPos = bars[attr.barId].pos;
    let closestPos = 0;

    let newBar = {};
    let newPos = 0;

    if (attr.location === 'right') {

      for (let bar of barArray) {
        if (bar.pos > currentPos && (!closestPos || bar.pos < closestPos)) {
          closestPos = bar.pos;
        }
      }

      if (closestPos > currentPos) {
        newPos = closestPos - (closestPos - currentPos) / 2;
      } else {
        newPos = currentPos + 65535;
      }

      newBar = getNewBar({ pos: newPos });
    } else if (attr.location === 'left') {

      for (let bar of barArray) {
        if (bar.pos < currentPos && bar.pos > closestPos) {
          closestPos = bar.pos;
        }
      }

      if (closestPos < currentPos) {
        newPos = currentPos - (currentPos - closestPos) / 2;
      }

      newBar = getNewBar({ pos: newPos });
    }

    if (Object.keys(newBar).length) {

      bars[newBar.id] = cloneObject(newBar);
      barArray = sortByPos(Object.values(bars));
      this.setState({ bars: bars, barArray: barArray, barRowUpdated: this.state.barRowUpdated + 1 }, () => {
        //this.props.updateBarRow(bars, this.props.row.id);
        this.props.updateRowData(this.props.row.id, {bars: bars}, 'bar-add');
      });
      
    }
    
  }

  removeBar(barId) {
    let bars = cloneObject(this.state.bars);
    if (typeof bars[barId] !== 'undefined') {
      //console.log("removeBar", barId, bars[barId]);
      delete bars[barId];
    }
    let barArray = sortByPos(Object.values(bars));
    this.setState({ bars: bars, barArray: barArray, barRowUpdated: this.state.barRowUpdated + 1 }, () => {
      //this.props.updateBarRow(bars, this.props.row.id);
      this.props.updateRowData(this.props.row.id, {bars: bars}, 'bar-remove');
    });
  }

  devideFragment(attr) {
    let findexArray = attr.findex.split('.');
    if (attr.currentLength === 1) {
      findexArray.pop();
    }
    let truefindex = findexArray.join('.');
    let newFragment = { fragments: [ { chord: attr.chord }, { chord: '' } ] };
    this.updateBar({ barId: attr.barId, findex: truefindex, content: newFragment }).then(() => {
      this.props.openChordEditorAt(0, this.props.row.id, truefindex + '.1');
    });
  }

  openBarOptions(barId) {
    this.setState({ editBarOptionsFor: barId, closeDropdown: this.state.closeDropdown + 1 });
  }

  render() {
    let hasElementsAboveBar = false;
    for (let b of this.state.barArray) {
      if (b.repeatEnding || b.rehearsal) {
        hasElementsAboveBar = true;
      }
    }

    return (
      <Translation>
        {(t) => (
          <div
            className={'bars-row ' + getFragmentClass(this.state.barArray) + (hasElementsAboveBar ? ' elem-above' : '')}
            ref={(node) => {
              this.containerRef = node;
            }}
          >
            {!this.state.barArray.length ? (
              <div />
            ) : (
              this.state.barArray.map((bar, index) => {
                let barId = bar.id;

                //console.log("bar", barId, index, bar);
                //console.log("chordsUpdated", this.props.chordsUpdated);

                return (
                  <div 
                    className={
                        'bar ' 
                        + getFragmentClass(bar.fragments)
                        + (this.state.focused===this.props.row.id+'-'+barId ? ' bar-focused' : '')
                    } 
                    id={this.props.row.id+'-'+barId} 
                    key={index} 
                    onClick={(e) => {
                      if (isTouchDevice()) {
                        if (this.state.focused === this.props.row.id+'-'+barId) {
                          this.openBarOptions(barId);
                        } else {
                          this.setState({focused: this.props.row.id+'-'+barId});
                        }
                      } else {
                        this.openBarOptions(barId);
                      }
                    }}
                  >

                    {index === 0 ? (
                      <Barline
                        barId={barId}
                        barline={bar.barlineLeft || {}}
                        location="left"
                        elementId={this.props.row.id + '-' + barId + '-' + index + '-left'}
                        setBarline={this.setBarline}
                        newBar={this.newBar}
                      />
                    ) : (
                      ''
                    )}

                    {bar.repeatEnding || bar.rehearsal ? (
                      <BarElementsAbove
                        bar={bar}
                        barId={bar.id}
                        rowid={this.props.row.id}
                      />
                    ) : (
                      ''
                    )}

                    {
                      this.state.editBarOptionsFor === barId ? (
                        <BarOptions 
                          bar={bar}
                          barId={bar.id}
                          barIndex={index}
                          rowid={this.props.row.id}
                          updateBar={this.updateBar}
                          newBar={this.newBar}
                          removeBar={this.removeBar}
                          openBarOptions={this.openBarOptions}
                          editBarOptionsFor={
                            this.state.editBarOptionsFor === barId ? this.state.editBarOptionsFor : false
                          }
                          />
                      ) : (
                        ''
                      )
                    }

{/*                     
                    <Dropdown
                      closeDropdown={this.state.closeDropdown}
                      className="ellipsis"
                      label={<i className="fas fa-ellipsis-v" />}
                    >
                      <ul>
                        <li
                          onClick={() => {
                            this.newBar({ barId: barId, location: 'right' });
                          }}
                        >
                          <span>
                            <i className="fas fa-plus" />
                            {t('Bars.AddBar')}
                          </span>
                        </li>
                        {
                          index===0
                            ? (
                              <li
                            onClick={() => {
                              this.newBar({ barId: barId, location: 'left' });
                            }}
                          >
                            <span>
                              <i className="fas fa-plus" />
                              {t('Bars.AddBarBefore')}
                            </span>
                          </li>
                          ) : ('')
                        }
                        <li
                          onClick={() => {
                            this.openBarOptions(barId);
                          }}
                        >
                          <span>
                            <i className="fas fa-cog" />
                            {t('Bars.EditBarOptions')}
                          </span>
                        </li>
                        <li
                          onClick={() => {
                            this.removeBar(barId);
                          }}
                        >
                          <span>
                            <i className="far fa-trash-alt" />
                            {t('Bars.RemoveBar')}
                          </span>
                        </li>
                      </ul>
                    </Dropdown> */}

                    {typeof bar.fragments !== 'undefined' &&
                    Array.isArray(bar.fragments) &&
                    bar.fragments.length > 0 ? (
                      bar.fragments.map((fragment, findex) => (
                        <BarFragment
                          rowid={this.props.row.id}
                          key={findex}
                          barId={barId}
                          findex={barId + '.' + findex}
                          depth={1}
                          currentLength={bar.fragments.length}
                          updateBar={this.updateBar}
                          devideFragment={this.devideFragment}
                          fragment={fragment}
                          chordsUpdated={this.props.chordsUpdated}
                          editActive={this.props.editActive && !this.props.rowReadonly}
                          songKey={this.props.songKey}
                          transpose={this.props.transpose}
                          chordEditorIsOpenAtPos={this.props.chordEditorIsOpenAtPos}
                          openChordEditorAt={this.props.openChordEditorAt}
                          closeChordEditor={this.props.closeChordEditor}
                          handleChordDroppedOnBar={this.props.handleChordDroppedOnBar}
                          barRowUpdated={this.state.barRowUpdated}
                        />
                      ))
                    ) : (
                      <BarFragment
                        rowid={this.props.row.id}
                        barId={barId}
                        findex={barId + '.0'}
                        depth={1}
                        updateBar={this.updateBar}
                        devideFragment={this.devideFragment}
                        fragment={{ chord: '' }}
                        chordsUpdated={this.props.chordsUpdated}
                        editActive={this.props.editActive && !this.props.rowReadonly}
                        songKey={this.props.songKey}
                        transpose={this.props.transpose}
                        barRowUpdated={this.state.barRowUpdated}
                        openChordEditorAt={this.props.openChordEditorAt}
                        closeChordEditor={this.props.closeChordEditor}
                        handleChordDroppedOnBar={this.props.handleChordDroppedOnBar}
                      />
                    )}
                    <Barline
                      barId={barId}
                      barline={bar.barlineRight || {}}
                      location="right"
                      elementId={this.props.rowid + '-' + barId + '-' + index + '-right'}
                      setBarline={this.setBarline}
                      newBar={this.newBar}
                    />
                  </div>
                );
              })
            )}
          </div>
        )}
      </Translation>
    );
  }
}
