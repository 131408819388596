import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { isMobile } from '../lib/deviceControl';

export const SmallEditorPortal = (props) => {

  let containerRef = useRef();
  let [leftPos, setLeftPos] = useState(0);
  let [rightPos, setRightPos] = useState('auto');
  let [topPos, setTopPos] = useState(0);
  let [arrowLeftPos, setArrowLeftPos] = useState(0);
  let [arrowRightPos, setArrowRightPos] = useState('auto');

  const handleClickOutside = (e) => {
    //console.log("click", containerRef.current);
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      props.close();
    }
  }
  

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    window.addEventListener('resize', setPosition, false);

    setTimeout(() => {
      setPosition();
    }, 100);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
      window.removeEventListener('resize', setPosition, false);
    }

  }, []); // eslint-disable-line


  const setPosition = () => {

    let winWidth = document.body.clientWidth || document.body.scrollWidth || window.innerWidth;
    // Get position of parent element
    let parentPos = ReactDOM.findDOMNode(document.getElementById(props.parentId)).getBoundingClientRect();
    // let documentPos = ReactDOM.findDOMNode(document.getElementById("document-area")).getBoundingClientRect();
    let containerPos = ReactDOM.findDOMNode(containerRef.current).getBoundingClientRect();
    //console.log("parentPos.x", parentPos.x);
    //console.log("containerPos.width", containerPos.width);

    // Calculate top pos
    let scrollTop = document.getElementById("scrollable").scrollTop;
    setTopPos(parentPos.y + parentPos.height + 25 + (props.addTop || 0) + scrollTop);

    // Calculate left pos
    let arrowLeft = 0;
    let arrowRight = 'auto';
    let left = 0;
    let right = 'auto';
    if (isMobile()) {

      left = 10;
      right = 10;

      arrowLeft = parentPos.x - 17 - Math.floor(parentPos.width/2);
      
    } else {

      let offset = 25;

      left = parentPos.x - offset;
      if (left < 0) {
        left = 0;
      }
      arrowLeft = 10 + Math.floor(parentPos.width/2);

      if (left > winWidth/2) {

        //left = 'auto';
        left = parentPos.x - containerPos.width + offset + offset;

        arrowLeft = 'auto';
        arrowRight = offset + offset + 17 - Math.floor(parentPos.width/2);
      }

      
    }
    
    
    setLeftPos(left);
    setRightPos(right);

    setArrowLeftPos(arrowLeft);
    setArrowRightPos(arrowRight);


  }

  

  return ReactDOM.createPortal(
    <div
      className={
        "sm-editor" 
        + (props.className ? props.className  : '')
      }
      style={{ 
        visibility: (leftPos && topPos ? "visible" : "hidden"),
        left:  leftPos, 
        right:  rightPos, 
        top: topPos
      }}
      ref={containerRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="sm-editor-arrow" style={{
        left: arrowLeftPos,
        right: arrowRightPos
      }}>▲</div>

      <div
        className="sm-editor-close"
        onClick={(e) => {
          e.stopPropagation();
          props.close();
        }}
      >
        <i className="fas fa-times" />
      </div>
      <div className="sm-editor-inner">{props.children}</div>
    </div>,
    document.getElementById('sm-editor-root')
  );

}
