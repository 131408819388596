import React from 'react';
import {SmallEditorPortal} from '../../SmallEditorPortal';
//import ReactDOM from 'react-dom';

export class Barline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }
  toggle(e) {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
  }
  close(e) {
    this.setState({ open: false });
  }
  render() {
    return (
      <span
        className={
          'bl-' +
          this.props.location +
          (this.props.barline.style ? ' ' + this.props.barline.style : '') +
          (this.props.barline.repeat ? ' repeat-' + this.props.barline.repeat : '')
        }
        onClick={this.toggle}
        id={this.props.elementId}
      >
        <span />
        {
        this.state.open 
        ? (
          <SmallEditorPortal parentId={this.props.elementId} close={this.close}>
            <BarlineEditor  {...this.props} />
          </SmallEditorPortal>
          ) 
        : ''
        }
      </span>
    );
  }
}

const BarlineEditor = (props) => {
  return (
    <div className="barline-editor">
        <button
          onClick={() => {
            props.setBarline({barId:props.barId, location:props.location, style:'none', repeat:false});
          }}
        >
        &nbsp;  
        </button>
        <button
          onClick={() => {
            props.setBarline({barId:props.barId, location:props.location, style:'light', repeat:false});
          }}
        >
          |
        </button>
        <button
          onClick={() => {
            props.setBarline({barId:props.barId, location:props.location, style:'light-light', repeat:false});
          }}
        >
          ||
        </button>
        <button
          onClick={() => {
            props.setBarline({barId:props.barId, location:props.location, style:'heavy-light', repeat:'forward'});
          }}
        >
          ||:
        </button>
        <button
          onClick={() => {
            props.setBarline({barId:props.barId, location:props.location, style:'light-heavy', repeat:'backward'});
          }}
        >
          :||
        </button>
    </div>
  )
}
