export {transposeChords};
function transposeChords(chordInput, transpose = 0, originKey = false) {

  // Make sure we have the correct symbols for the regex to work
    chordInput = chordInput.replaceAll('b', '♭');
    chordInput = chordInput.replaceAll('#', '♯');


    // Here we figure out if we want the transposed chords to be # or b
    let transposedKey = 'C';
    let destinationAccidentalType = transpose > 0 ? 'sharps' : 'flats';
    if (originKey) {
      transposedKey = transposeChords(originKey, transpose, false);
      if (['D♭', 'E♭', 'F', 'G♭', 'A♭', 'B♭'].includes(transposedKey)) {
        destinationAccidentalType = 'flats';
      } else {
        destinationAccidentalType = 'sharps';
      }
    }
  
    let chords = {
      sharps: [
        'C', 'C♯', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯', 'A', 'A♯', 'B',
        'C', 'C♯', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯', 'A', 'A♯', 'B', 'C'
      ],
      flats: [
        'C', 'D♭', 'D', 'E♭', 'E', 'F', 'G♭', 'G', 'A♭', 'A', 'B♭', 'B',
        'C', 'D♭', 'D', 'E♭', 'E', 'F', 'G♭', 'G', 'A♭', 'A', 'B♭', 'B', 'C'
      ]
    }
  
    let chordRegex = /C♯|D♯|F♯|G♯|A♯|D♭|E♭|G♭|A♭|B♭|C|D|E|F|G|A|B/g;
  
    let parts = chordInput.split(chordRegex); // Get everything between chords

    let output = parts[0];
    let index = 1;
    let matches = [...chordInput.matchAll(chordRegex)]; // Get all chords in chordInput
    for (let match of matches) {
  
      // Transpose current chord
      // If transposing down, use the last matching chord index
      let chordIndex = 0;
      if (match[0].indexOf("♯") >= 0) {
        chordIndex = (transpose > 0) ? chords.sharps.indexOf(match[0]) : chords.sharps.lastIndexOf(match[0]);
      } else {
        chordIndex = (transpose > 0) ? chords.flats.indexOf(match[0]) : chords.flats.lastIndexOf(match[0]);
      }
  
      output += chords[destinationAccidentalType][chordIndex + transpose];
  
      // Add the part after chord 
      output += parts[index];
  
      index++;
    }
    if (typeof parts[index] != 'undefined') {
      output += parts[index];
    }
  
    return output;
  
  }