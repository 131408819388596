import { cloneObject } from '../cloneObject.js';

export const handleGroupDelete = (attr) => {
  //console.log("handleGroupDelete");
  let { grpId, rows, grps, docArray, updateObject } = attr;

  let currentGroup = grps[grpId];

  // Move rows to new group
  for (let group of docArray) {
    if (group.id === currentGroup.id && typeof group.rows !== 'undefined' && group.rows.length) {
      for (let currentRow of group.rows) {
          updateObject.prev.rows[currentRow.id] = cloneObject(currentRow);
          delete rows[currentRow.id];
          updateObject.next.rows[currentRow.id] = {id: currentRow.id, deleted: true};
      }
    }
  }

  updateObject.prev.grps[currentGroup.id] = cloneObject(currentGroup);
  delete grps[grpId];
  updateObject.next.grps[grpId] = {id: grpId, deleted: true};

  return { updateObject };
}
