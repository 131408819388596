import {cloneObject} from "../cloneObject.js"

const setCordsUpdated = (obj) => {
  obj.chordsUpdated = (typeof obj.chordsUpdated === 'number') ? obj.chordsUpdated+1 : 1;
  return obj;
}

const getCordIndexInArrayByPosition = (searchPosition, chords) => {
  for (let x in chords) {
    if (parseInt(chords[x].pos) === parseInt(searchPosition)) {
      return x;
    }
  }
  return false;
}

const setChordToFindex = (row, findex, chord) => {
  let findexSplit = (typeof findex !== 'undefined') ? findex.split('.') : [];
  let barId = findexSplit[0];
  if (findexSplit.length === 4) {
    row.data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]]['fragments'][findexSplit[3]].chord = chord;
  } else if (findexSplit.length === 3) {
    row.data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]].chord = chord;
  } else if (findexSplit.length === 2) {
    row.data.bars[barId]['fragments'][findexSplit[1]].chord = chord;
  }
  return row;
}

export function handleChordDrop({
  updateObject, 
  rows, 
  chord, 
  toRowId, 
  toPos, 
  toFindex,
  fromRowId, 
  fromPos, 
  fromFindex,
  altKey
}) {
  
  // Handle source

  if (typeof rows[fromRowId] != 'undefined') {
    // If source is row of lyrics

    if (fromPos !== '' && fromPos !== false) { 
      // If lyrics source row i Lyrics

      if (typeof rows[fromRowId].data.chords != 'undefined' && rows[fromRowId].data.chords.length) {
        let chordIndex = getCordIndexInArrayByPosition(fromPos, rows[fromRowId].data.chords);
        if (chordIndex !== false) {

          if (!altKey || altKey === 'false') { // Remove chord if altKey not pressed
            updateObject.prev.rows[fromRowId] = cloneObject(rows[fromRowId]); // for history
            rows[fromRowId].data.chords.splice(chordIndex, 1);
            rows[fromRowId] = setCordsUpdated(rows[fromRowId]);
            updateObject.next.rows[fromRowId] = cloneObject(rows[fromRowId]); // new
  
          }
        }
      }

    } else if (fromFindex !== '' && fromFindex !== false) {
      // If source is row of bars and chords

      updateObject.prev.rows[fromRowId] = cloneObject(rows[fromRowId]);
      if (fromRowId !== toRowId) {
        updateObject.prev.rows[toRowId] = cloneObject(rows[toRowId]);
      }
      
      if (!altKey || altKey === 'false') {
        rows[fromRowId] = setChordToFindex(rows[fromRowId], fromFindex, '');
        /*
        let findexSplit = typeof fromFindex !== 'undefined' ? fromFindex.split('.') : [];
        let barId = findexSplit[0];
        if (findexSplit.length === 4) {
          rows[fromRowId].data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]]['fragments'][findexSplit[3]].chord = '';
        } else if (findexSplit.length === 3) {
          rows[fromRowId].data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]].chord = '';
        } else if (findexSplit.length === 2) {
          rows[fromRowId].data.bars[barId]['fragments'][findexSplit[1]].chord = '';
        }
        */
        if (fromRowId !== toRowId) {
          rows[fromRowId] = setCordsUpdated(rows[fromRowId]);
          updateObject.next.rows[fromRowId] = cloneObject(rows[fromRowId]);
        }
      }
      
    }
    

  }

  // Handle drop
  if (typeof rows[toRowId] != 'undefined' && chord !== '') {
    

    if (toPos !== false) { 
      // If destination is row of lyrics

      updateObject.prev.rows[toRowId] = cloneObject(rows[toRowId]); // for history
      if (typeof rows[toRowId].data.chords !== 'object') {
        rows[toRowId].data.chords = [];
      }
      rows[toRowId].data.chords.push({
        symbol: chord,
        pos: toPos
      });
      rows[toRowId] = setCordsUpdated(rows[toRowId]);
      updateObject.next.rows[toRowId] = cloneObject(rows[toRowId]);

    } else { 
      // If destination is row of bars and chords

      rows[toRowId] = setChordToFindex(rows[toRowId], toFindex, chord);

      /*
      let findexSplit = typeof toFindex !== 'undefined' ? toFindex.split('.') : [];
      let barId = findexSplit[0];
      if (findexSplit.length === 4) {
        rows[toRowId].data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]]['fragments'][findexSplit[3]].chord = chord;
      } else if (findexSplit.length === 3) {
        rows[toRowId].data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]].chord = chord;
      } else if (findexSplit.length === 2) {
        rows[toRowId].data.bars[barId]['fragments'][findexSplit[1]].chord = chord;
      }
      */
      rows[toRowId] = setCordsUpdated(rows[toRowId]);
      updateObject.next.rows[toRowId] = cloneObject(rows[toRowId]);

    }
    

  }

  return updateObject;
}




/*
export const handleChordDropOnBar = ({
  updateObject,
  rows,
  toRowId,
  toFindex,
  chord,
  fromRowId,
  fromFindex,
  altKey
}) => {

    findexSplit = typeof toFindex !== 'undefined' ? toFindex.split('.') : [];
    barId = findexSplit[0];
    if (findexSplit.length === 4) {
      rows[toRowId].data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]]['fragments'][findexSplit[3]].chord = chord;
    } else if (findexSplit.length === 3) {
      rows[toRowId].data.bars[barId]['fragments'][findexSplit[1]]['fragments'][findexSplit[2]].chord = chord;
    } else if (findexSplit.length === 2) {
      rows[toRowId].data.bars[barId]['fragments'][findexSplit[1]].chord = chord;
    }
    rows[toRowId] = setCordsUpdated(rows[toRowId]);
    updateObject.next.rows[toRowId] = cloneObject(rows[toRowId]);

    return updateObject;
}
*/