import React from 'react'

export const ViewArticle = (props) => {
    return (
        <div className="article-page">
            <div className="article-container">
                <article>
                    {props.children}
                </article>
            </div>
        </div>
    );
}
