const isEqual = (a1, a2) => {

    if (a1 === null && a2 !== null) {
      return false;
    } else if (a2 === null && a1 !== null) {
      return false;
    } else if (a1 === null && a2 === null) {
      return true;
    }

    if (typeof a1 === 'object' && Array.isArray(a1) && typeof a2 === 'object' && Array.isArray(a2)) {
      // Both is arrays
  
      if (a1.length !== a2.length) {
        return false;
      }
  
      for (let i in a1) {
        if (typeof a2[i] === 'undefined') {
          return false;
        }
        if (!isEqual(a1[i], a2[i])) {
          return false;
        }
      }
  
      return true;
  
    } else if (typeof a1 === 'object' && typeof a2 === 'object') {
      // Both is objects
      
      if (Object.keys(a1).length !== Object.keys(a2).length) {
        return false;
      }
  
      for (let i of Object.keys(a1)) {
        if (typeof a2[i] === 'undefined') {
          return false;
        }
        if (!isEqual(a1[i], a2[i])) {
          return false;
        }
      }
  
      return true;
  
    } else if (typeof a1 !== typeof a2) {
      // Different types
  
      return false;
  
    } else {
      // Check if values are equal
  
      return a1 === a2;
      
    }
  };
 export default isEqual;