import { getWordPositions } from "./getWordPositions.js";
import { getRowIdByIndex, getRowIndex, sortByPos, getPreviousRow, getNextRow } from "./utils.js";
export { getNewChordPosition };



function getPreviousBar({rows, currentRowId, currentBarId, docArray}) {
  let barArray = sortByPos(Object.values(rows[currentRowId].data.bars));
  let currentBarIndex = barArray.findIndex((r) => { return r.id === currentBarId});
  if (typeof barArray[currentBarIndex-1] !== 'undefined') {
    let barId = barArray[currentBarIndex-1].id;
    let findex = barArray[currentBarIndex-1].fragments.length-1;
    return {
      rowId: currentRowId, 
      position: barId + "." + findex
    }
  } else {
    let row = getPreviousRow({rows, currentRowId, docArray});
    if (row && row.type === 'chordbars') {

      let barArray = sortByPos(Object.values(rows[row.id].data.bars));
      let lastBar = barArray[barArray.length-1];
      let findex = lastBar.fragments.length-1;
      return {
        rowId: row.id, 
        position: lastBar.id + "." + findex
      }

    } else if (row && row.type === 'lyrics') {
      return {
        rowId: row.id, 
        position: getTextEndPosition(rows[row.id])
      }
    } else {
      return {
        rowId: false, 
        position: false
      }
    }
  }
}

function getNextBar({rows, currentRowId, currentBarId, docArray}) {
  let bars = rows[currentRowId].data.bars;
  let barArray = sortByPos(Object.values(bars));
  let currentBarIndex = barArray.findIndex((r) => { return r.id === currentBarId});
  if (typeof barArray[currentBarIndex+1] !== 'undefined') {
    return {
      rowId: currentRowId, 
      position: barArray[currentBarIndex+1].id + ".0"
    }
  } else {
    let row = getNextRow({rows, currentRowId, docArray});
    if (row && row.type === 'chordbars') {
      let barArray = sortByPos(Object.values(rows[row.id].data.bars));
      let firstBar = barArray[0];
      return {
        rowId: row.id, 
        position: firstBar.id + ".0"
      }
    } else if (row && row.type === 'lyrics') {
      return {
        rowId: row.id, 
        position: 0
      }
    } else {
      return {
        rowId: false, 
        position: false
      }
    }
  }
}

function getNewChordPosition({goto, currentRowId, currentPosition, docArray, rows}) {

  let currentRowIndex = false;
  let gotoRow = '';
  let gotoPosition = '';

  if (typeof currentRowId === 'number') {
    currentRowIndex = currentRowId;
    currentRowId = getRowIdByIndex(currentRowId, docArray);
  } else {
    currentRowIndex = getRowIndex(currentRowId, docArray);
  }

  if (rows[currentRowId].type === 'chordbars') {
    //
    // Current row is bars
    //

    //gotoRow = currentRowId;
    //gotoPosition = currentPosition;

    let [currentBarId, currentFindex] = currentPosition.split(".");
    let bars = rows[currentRowId].data.bars;
    let bar = bars[currentBarId];
    let barArrayCurrentRow = sortByPos(Object.values(bars));
    let currentBarIndex = barArrayCurrentRow.findIndex(r => r.id === currentBarId);

    if (typeof goto === 'number') {
      //
      // We jump back or forward
      //

      currentFindex = parseInt(currentFindex);
      let destinationFindex = currentFindex + goto;
      
      if (destinationFindex >= bar.fragments.length) {
        // Go to next bar

        const {rowId, position} = getNextBar({rows, currentRowId, currentBarId, docArray});
        gotoRow = rowId;
        gotoPosition = position;

      } else if (destinationFindex < 0) {
        // Go to previous bar

      const {rowId, position} = getPreviousBar({rows, currentRowId, currentBarId, docArray});
        gotoRow = rowId;
        gotoPosition = position;

      } else {
        gotoRow = currentRowId;
        gotoPosition = currentBarId + "." + destinationFindex;
      }

    } else if (goto === 'JumpUp') {
      let row = getPreviousRow({rows, currentRowId, docArray});
      if (row && row.type === 'chordbars') {
        let barArray = sortByPos(Object.values(row.data.bars));
        gotoRow = row.id;

        if (typeof barArray[currentBarIndex] !== 'undefined') {
          let gotoFragment = currentFindex < barArray[currentBarIndex].fragments.length ? currentFindex : barArray[currentBarIndex].fragments.length-1;
          gotoPosition = barArray[currentBarIndex].id + "." + gotoFragment;
        } else {
          gotoPosition = barArray[0].id + ".0";
        }

      } else if (row && row.type === 'lyrics') {
        gotoRow = row.id;
        let positionPercentageOfRow = !currentBarIndex ? 0 : currentBarIndex / barArrayCurrentRow.length;
        gotoPosition = positionPercentageOfRow ? Math.round(rows[row.id].data.text.length*positionPercentageOfRow) : 0;
      }
    } else if (goto === 'JumpDown') {
      let row = getNextRow({rows, currentRowId, docArray});
      if (row && row.type === 'chordbars') {
        gotoRow = row.id;
        let barArray = sortByPos(Object.values(row.data.bars));
        if (typeof barArray[currentBarIndex] !== 'undefined') {
          let gotoFragment = currentFindex < barArray[currentBarIndex].fragments.length ? currentFindex : barArray[currentBarIndex].fragments.length-1;
          gotoPosition = barArray[currentBarIndex].id + "." + gotoFragment;
        } else {
          gotoPosition = barArray[0].id + ".0";
        }
        
      } else if (row && row.type === 'lyrics') {
        gotoRow = row.id;
        gotoPosition = 0;

        let positionPercentageOfRow = !currentBarIndex ? 0 : currentBarIndex / barArrayCurrentRow.length;
        gotoPosition = positionPercentageOfRow ?  Math.round(rows[row.id].data.text.length*positionPercentageOfRow) : 0;

      }
    } else if (goto === 'JumpBack') {
      const {rowId, position} = getPreviousBar({rows, currentRowId, currentBarId, docArray});
      if (rowId) {
        gotoRow = rowId;
        gotoPosition = (typeof position === 'number') ? position : position.split(".")[0] + ".0";
      }
    } else if (goto === 'JumpNext') {
      const {rowId, position} = getNextBar({rows, currentRowId, currentBarId, docArray});
      if (rowId) {
        gotoRow = rowId;
        gotoPosition = (typeof position === 'number') ? position : position.split(".")[0] + ".0";
      }
    }

  } else {
    //
    // Current row is lyrics
    //

    gotoRow = currentRowId;
    let rowText = rows[currentRowId].data.text;
    if (typeof goto === 'number') {
      gotoPosition = currentPosition + goto;
    } else if (goto === "JumpUp") {

      let row = getPreviousRow({rows, currentRowId, docArray});

      if (row && row.type === 'chordbars') {
        gotoRow = row.id;
        let positionPercentageOfRow = currentPosition===0 ? 0 : currentPosition/rows[currentRowId].data.text.length;
        let barArray = sortByPos(Object.values(row.data.bars));
        let gotoBarNum = Math.floor(barArray.length*positionPercentageOfRow);
        if (gotoBarNum > barArray.length-1) {
          gotoPosition = barArray[barArray.length-1].id + ".0";
        } else {
          gotoPosition = barArray[gotoBarNum].id + ".0";
        }

      } else if (row && row.type === 'lyrics') {
        gotoRow = row.id;
        gotoPosition = currentPosition<row.data.text.length ?  currentPosition : getTextEndPosition(row);
      }

    } else if (goto === "JumpDown") {

      let row = getNextRow({rows, currentRowId, docArray});
      if (row && row.type === 'chordbars') {
        gotoRow = row.id;
        let positionPercentageOfRow = currentPosition===0 ? 0 : currentPosition/rows[currentRowId].data.text.length;
        let barArray = sortByPos(Object.values(row.data.bars));
        let gotoBarNum = Math.floor(barArray.length*positionPercentageOfRow);
        if (gotoBarNum > barArray.length-1) {
          gotoPosition = barArray[barArray.length-1].id + ".0";
        } else {
          gotoPosition = barArray[gotoBarNum].id + ".0";
        }
        //gotoPosition = barArray[0].id + ".0";

      } else if (row && row.type === 'lyrics') {
        gotoRow = row.id;
        gotoPosition = currentPosition<row.data.text.length ?  currentPosition : getTextEndPosition(row);
      }
      
    } else if (goto === "JumpBack") {
      gotoPosition = getPreviousLetterindexForWord(rowText, currentPosition);
      if (gotoPosition === false) {
        let row = getPreviousRow({rows, currentRowId, docArray});
        if (row && row.type === 'chordbars') {
    
          let barArray = sortByPos(Object.values(rows[row.id].data.bars));
          let lastBar = barArray[barArray.length-1];
          gotoRow = row.id; 
          gotoPosition = lastBar.id + ".0";
    
        } else if (row && row.type === 'lyrics') {
          gotoRow = row.id; 
          let wordPositions = getWordPositions(rows[row.id].data.text);
          gotoPosition = (wordPositions.length>0) ? wordPositions[wordPositions.length-1] : getTextEndPosition(rows[row.id]);
        }
      }
    } else if (goto === "JumpNext") {
      gotoPosition = getNextLetterindexForWord(rowText, currentPosition);
      if (gotoPosition === false) {
        let row = getNextRow({rows, currentRowId, docArray});
        if (row && row.type === 'chordbars') {
    
          let barArray = sortByPos(Object.values(rows[row.id].data.bars));
          let firstBar = barArray[0];
          gotoRow = row.id; 
          gotoPosition = firstBar.id + ".0";
    
        } else if (row && row.type === 'lyrics') {
          gotoRow = row.id; 
          gotoPosition = 0; // getTextEndPosition(rows[row.id]);
        }
      }
    } 

    if (gotoRow === currentRowId && typeof gotoPosition === 'number') {
      let rowTextLength = rowText.length;
      if (gotoPosition < 0) {
        gotoPosition = 0;
        // Go to previous row
        gotoRow = getRowIdByIndex(currentRowIndex-1, docArray);
        if (gotoRow) {
          if (rows[gotoRow].type === 'lyrics') {
            gotoPosition = getTextEndPosition(rows[gotoRow]);
          } else if (rows[gotoRow].type === 'chordbars') {
            let barArray = sortByPos(Object.values(rows[gotoRow].data.bars));
            let lastBar = barArray[barArray.length-1];
            let findex = lastBar.fragments.length-1;
            gotoPosition = lastBar.id + "." + findex;
          }
        }
      } else if (gotoPosition >= rowTextLength) {
        //gotoPosition = rowTextLength;
        // Go to next row
        gotoRow = getRowIdByIndex(currentRowIndex+1, docArray);
        gotoPosition = 0;
        if (gotoRow) {
          if (rows[gotoRow].type === 'lyrics') {
            gotoPosition = 0;
          } else if (rows[gotoRow].type === 'chordbars') {
            let barArray = sortByPos(Object.values(rows[gotoRow].data.bars));
            let firstBar = barArray[0];
            gotoPosition = firstBar.id + ".0";
          }
        }
      }
    }
    
  }
  
  return {gotoRow, gotoPosition};
}

function getTextEndPosition(row) {
  if (typeof row.data.text !== 'undefined') {
    return row.data.text.length-1
  } else {
    return false;
  }
}

function getPreviousLetterindexForWord(rowText, letterindex) {
  let wordPositions = getWordPositions(rowText);
  wordPositions = wordPositions.reverse();

  for (let x in wordPositions) {
    if (wordPositions[x] < letterindex) {
      return wordPositions[x];
    }
  }
  return false;
}

function getNextLetterindexForWord(rowText, letterindex) {
  let wordPositions = getWordPositions(rowText);
  for (let x in wordPositions) {
    if (wordPositions[x] > letterindex) {
      return wordPositions[x];
    }
  }
  return false;
}
