import React from 'react';
import defaultUpdatesObject from '../../../config/updatesObject';
import {cloneObject} from '../../../lib/cloneObject';
import {newID} from '../../../lib/utils';

export const SaveInSidebar = (props) => {

  const saveInSidebar = () => {
    let song = props.getSong();
    let snippets = song.snippets || {};
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.snippets = cloneObject(snippets);
    let newSnippet = props.row.data.text;
    snippets[newID()] = newSnippet;
    updateObject.next.snippets = snippets;
    props.performUpdate({
      updateObject,
      updateType: 'update-snippets',
      state: { hasSelectedRows: false, rightSidebarOpener: Math.random(9999999) }
    });
    props.closeMenu();
  }

  return (
    <li className={props.inactive ? "inactive" : ""} onClick={saveInSidebar}>
      <span>
      <i className="material-icons-outlined">exit_to_app</i>
        {props.t('RowTools.SaveInSidebar')}
      </span>
    </li>
  );
};
