import React, {useContext} from 'react'; // eslint-disable-line
import { isMobile } from '../../lib/deviceControl';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import UserContext from '../../UserContext';

import { 
  getAuth, 
  signInWithRedirect, 
  signInWithPopup, 
  GoogleAuthProvider
} from "firebase/auth";


export const LoginWithGoogle = (props) => {
  const context =  useContext(UserContext);
  const {t} = useTranslation();
  return (
    <button
      onClick={() => {
        const auth = getAuth();
        auth.languageCode = i18next.language;
        const provider = new GoogleAuthProvider();
        
        // provider.addScope('https://www.googleapis.com/auth/drive');

        if (isMobile()) {
          signInWithRedirect(auth, provider).catch(context.handleSignInError);
        } else {
          signInWithPopup(auth, provider)
            .then((result) => {
              //const user = result.user;
              if (props.onLoginSuccess) {
                props.onLoginSuccess();
              }
            })
            .catch(context.handleSignInError);
        }
      }}
      className="google-login-button"
    >
      <span>{t('Login.SignInWithGoogle')}</span>
    </button>
  );
}