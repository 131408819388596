import React from 'react';
import UserContext from './UserContext';
import ViewListContext from './ViewListContext';
import {Loading} from './components/Loading';
import Confirm from './components/Confirm';
import {Modal} from './components/Modal';
import { ListToolbar } from './components/toolbar/ListToolbar';
import { EmailVerificationModal } from './components/list/EmailVerificationModal';
import { ListPageOptions } from './components/list/ListPageOptions';
import { AddToListDropdown } from './components/list/AddToListDropdown';
import { DocumentList } from './components/list/DocumentList';
import { PlaylistTitle } from './components/list/PlaylistTitle';
import { ListPlaylist } from './components/list/ListPlaylist';
import { FlexSlider } from './components/FlexSlider';
import { NewSongModal } from './components/list/NewSongModal';
import { NewListModal } from './components/list/NewListModal';
import { FolderModal } from './components/list/FolderModal';
import { DragDropContext } from 'react-beautiful-dnd'; // Draggable
import { handle_api_error, api_post, api_patch, api_delete } from './API'; // api_get
import { moveToFolder } from './lib/com/moveToFolder';
import { sortByPos, newID } from './lib/utils';
import { removeFolderFromDB } from './lib/com/removeFolder'; 
import { setLocalCache, getLocalCache, addToLocalCache, removeFromLocalCache, getListFromLocalCache } from './components/Storage';
// import localforage from 'localforage';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import { getFirestore, query, collection, onSnapshot } from "firebase/firestore";
import Alert from './components/Alert';
import { WizardModal } from './components/list/WizardModal';


export class ViewList extends React.Component {
  static contextType = UserContext;
  

  constructor(props) {
    super(props);
    
    this.state = {
      sortBy: (typeof props.settings !== 'undefined' && typeof props.settings.sortBy === 'string') ? props.settings.sortBy : 'alpha',
      openFolders: (typeof props.settings !== 'undefined' && typeof props.settings.openFolder === 'object') ? props.settings.openFolder : {},
      docs: {},
      docsArray: [],
      listArray: [],
      songsTotal: 0,
      loading: true,

      listId: '',
      listName: '',

      editListId: false,
      editListName: false,

      newListModal: false,
      showFolderModal: false,

      wizardModal: false,
      newSongModal: false,
      newSongType: '',
      newSongCopyId: '',
      updated: 0
    };

    this.newListContainerRef = null;
    this.listNameInputRef = null;
    this.unsubscribe = null;
  }

  componentDidMount() {
    document.querySelector(':root').style.fontSize = "16px";
    document.title = i18next.t('List.MySongs') + ' | ' + i18next.t('SiteName');

    if (!this.props.loggedIn && !this.props.doingAutoLogin) {
      this.props.redirect('/');
      return;
    }

    //if (this.props.params.listId) {
    //  this.listId = this.props.params.listId;
    //} 

    //localforage.getItem('swtSortBy').then((sortBy) => { 
    //  if (sortBy) {
    //    this.setState({sortBy}); 
    //  }
    //});
    
    

    //this.updateListItems();
    this.startDocListSubscription();
    
  }

  startDocListSubscription = () => {
    
    if (this.unsubscribe === null && this.context.uid) {
      const firestore = getFirestore(this.context.firebase);
      this.unsubscribe = onSnapshot(query(collection(firestore, "users/"+this.context.uid+ "/docs")), (querySnapshot) => {
  
        let timeNow = Math.floor(Date.now() / 1000);
        let docs = this.state.docs;
  
        let changes = querySnapshot.docChanges();
        for (let change of changes) {
          if (change.type === "added" || change.type === "modified") {
            docs[change.doc.id] = change.doc.data();
            if (typeof docs[change.doc.id].ct !== "undefined" && docs[change.doc.id].ct > timeNow-3600) {
              if (typeof docs[change.doc.id].flags === 'undefined') {
                docs[change.doc.id].flags = [];
              }
              docs[change.doc.id].flags.push('new');
            }
          }
          if (change.type === "removed") {
            delete docs[change.doc.id];
          }
        }
  
        let docsArray = this.createDocArray(docs);
        
        this.setState({
          docs: docs,
          docsArray: docsArray,
          totalSongs: Object.keys(docs).length,
          updated: this.state.updated+1,
          loading: false
        }, () => {
          setLocalCache(docs);
        });
  
        

      });

    }
  }

  componentWillUnmount() {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
  }

  componentDidUpdate(prevProps) {
    //this.startDocListSubscription();
  }

  createDocArray = (docs) => {
    let docsArray = Object.values(docs);

    docsArray = docsArray.filter((doc) => {
      if (typeof doc.deleted !== 'undefined' && doc.deleted) {
        return false;
      } else if (typeof doc.type === 'undefined' || !doc.type) {
        return false;
      } else if (typeof doc.type === 'undefined' || !doc.type) {
        return false;
      } else {
        return true;
      }
    });

    if (this.state.sortBy === 'alpha') {

      docsArray = docsArray.sort((a, b) => { return (a.title < b.title) ? -1 : 1; });
      docsArray = [...docsArray.filter(el => el.type==='folder'), ...docsArray.filter(el => el.type!=='folder')]; // folders first
      docsArray = [...docsArray.filter(el => el.type==='invite'), ...docsArray.filter(el => el.type!=='invite')]; // invites first
      
    } else if (this.state.sortBy === 'latest-viewed') {

      docsArray = docsArray.sort((a, b) => { return (a.vt > b.vt) ? -1 : 1});
      
     } else {

      docsArray = docsArray.sort((a, b) => { return (a.pos < b.pos) ? -1 : 1});
      docsArray = [...docsArray.filter(el => el.type==='folder'), ...docsArray.filter(el => el.type!=='folder')]; // folders first
      docsArray = [...docsArray.filter(el => el.type==='invite'), ...docsArray.filter(el => el.type!=='invite')]; // invites first
    
    }

    return docsArray;
  }
  
  updateListItems = () => {
    
    return new Promise(async (resolve, reject) => {
      
      let docs = await getLocalCache(); 
      if (typeof docs === 'undefined') {
        docs = {};
      }

      let docsArray = this.createDocArray(docs);
      
      this.setState({
        loading: false,
        docs: docs,
        docsArray: docsArray,
        sortBy: this.state.sortBy || 'alpha',
        totalSongs: Object.keys(docs).length,
        updated: this.state.updated+1
      }, () => {
        resolve();
      });
    })
  }

  loadPlaylist = async (listId, docs) => {
    if (typeof docs === 'undefined') {
      docs = await getLocalCache(); 
    }

    let listName = '';
    let listArray = [];

    if (listId && typeof docs["list-"+listId] !== 'undefined') {
      let list = docs["list-"+listId];
      listName = list.title;
      if (typeof list.items !== 'undefined') {
        for (let item of Object.values(list.items)) {
          if (typeof docs['song-'+item.songId] !== 'undefined') {
            let doc = {};
            doc.title = docs['song-'+item.songId].title;
            doc.meta = docs['song-'+item.songId].meta;
            doc.id = docs['song-'+item.songId].id;
            doc.type = docs['song-'+item.songId].type;
            doc.pos = item.pos;
            doc.itemId = item.id;
            listArray.push(doc);
          }
        }
      }
    } else {
      listId = '';
    }

    listArray = listArray.filter((doc) => {
      if (typeof doc.deleted !== 'undefined' && doc.deleted) {
        return false;
      } else if (typeof doc.type === 'undefined' || !doc.type) {
        return false;
      } else if (typeof doc.type === 'undefined' || !doc.type) {
        return false;
      } else {
        return true;
      }
    });

    listArray = sortByPos(listArray);
    
    let docsArray = this.createDocArray(docs);

    if (listId) {
      this.setState({
        docs: docs,
        docsArray: docsArray,
        loading: false,
        listArray: listArray,
        listId: listId,
        listName: listName,
        updated: this.state.updated+1
      });
    } else {
      this.setState({
        docs: docs,
        docsArray: docsArray,
        listId: '',
        loading: false,
        updated: this.state.updated+1
      });
    }
    
  }
  openWizardModal = () => {
    this.setState({
      wizardModal: true
    });
  }
  openNewListModal = () => {
    this.setState({
      newListModal: true
    });
  }
  openNewFolderModal = () => {
    this.setState({
      showFolderModal: true
    });
  }
  openNewSongModal = (newSongType) => {
    this.setState({
      newSongModal: true, 
      newSongType: (typeof newSongType === 'string') ? newSongType : 'lyrics'
    });
  }
  closeModal = () => {
    this.setState({
      wizardModal: false,
      newListModal: false,
      showFolderModal: false,
      newSongModal: false,
      newSongType: '',
      newSongCopyId: ''
    });
  }

  duplicateSong = (newSongCopyId) => {
    this.setState({
      newSongModal: true, 
      newSongType: '',
      newSongCopyId: newSongCopyId
    });
  }

  removeSong = (id) => {
    Confirm({
      headline: i18next.t('List.ConfirmRemoveSongHeadline'),
      message: i18next.t('List.ConfirmRemoveSongText'),
      onConfirm: () => {
        api_delete('/songs/' + id)
          .then((resp) => {
            removeFromLocalCache("song", id).then(() => { this.updateListItems(); });
          })
          .catch(handle_api_error);
      }
    });
  }

  removePlaylist = (id) => {
    Confirm({
      headline: i18next.t('List.ConfirmRemoveListHeadline'),
      message: i18next.t('List.ConfirmRemoveListText'),
      onConfirm: () => {
        api_delete('/lists/' + id)
          .then((resp) => {
            removeFromLocalCache("list", id)
              .then(() => {
                if (this.props.params.listId && this.props.params.listId === id) {
                  this.props.redirect('/songs');
                } else {
                  this.updateListItems();
                }
              });
            
            
          })
          .catch(handle_api_error);
      }
    });
  }

  editFolder = (id) => {
    this.setState({
      showFolderModal: id
    });
  }

  removeFolder = (id) => {
    let hasDocsInFolder = false;
    for (let doc of this.state.docsArray) {
      if (typeof doc.parent !== 'undefined' && doc.parent === id) {
        hasDocsInFolder = true;
      }
    }
    if (hasDocsInFolder) {
      Alert(i18next.t('List.RemoveFolderNotEmpty'));
    } else {
      Confirm({
        headline: i18next.t('List.ConfirmRemoveFolderHeadline'),
        message: i18next.t('List.ConfirmRemoveFolderText'),
        onConfirm: () => {
          removeFolderFromDB({uid: this.context.uid, id});
          removeFromLocalCache("folder", id).then(() => { this.updateListItems(); });
        }
      });
    }
    
  }

  handleDragToList = (result, provided) => {
    let draggableIdArray = result.draggableId.split("-");
    let draggableId = draggableIdArray[2];
    let docs = this.state.docs; // await getLocalCache();

    if (result.destination && result.destination.droppableId === 'playlist') {
      // reorder
      
      let listId = this.state.listId;
      if (!listId) {
        return;
      }
      let list = docs['list-'+listId];
      let items = sortByPos(Object.values(list.items));
      if (!items.length) {
        return;
      }
      let sourceIndex = result.source.index; // group index
      let destinationIndex = result.destination.index;

      let newPos = 0;
      if (sourceIndex === destinationIndex) {
        // Do nothing
      } else if (destinationIndex === 0) {
        // Moved to first position
        let first = items[0];
        newPos = Math.round(first.pos / 2);
      } else if (destinationIndex >= items.length - 1) {
        // Moved to last position
        let last = items[items.length - 1];
        newPos = Math.round(last.pos + 65535);
      } else {
        if (destinationIndex < sourceIndex) {
          // Moving backwards
          let prev = items[destinationIndex - 1];
          let pos = prev.pos;
          let current = items[destinationIndex];
          let posNext = current.pos;
          let posSpan = Math.round((posNext - pos) / 2);
          newPos = Math.round(posNext - posSpan);
        } else {
          // Moving forward
          let current = items[destinationIndex];
          let pos = current.pos;
          let next = items[destinationIndex + 1];
          let posNext = next.pos;
          let posSpan = Math.round((posNext - pos) / 2);
          newPos = Math.round(posNext - posSpan);
        }
      }

      if (newPos>0) {
        list.items[draggableId].pos = newPos;
      }

      docs['list-'+listId] = list;

      this.loadPlaylist(listId, docs);
      addToLocalCache("list", list);
      api_patch('/lists/' + listId, {
        items: list.items
      }).then(() => {}).catch(handle_api_error);
      
    } else if (result.destination && result.destination.droppableId.indexOf('folder') === 0) {

      const [, folderId] = result.destination.droppableId.split("-");
      const [, doctype, docId, ] = result.draggableId.split("-");
      moveToFolder({uid: this.context.uid, doctype, docId, folderId})
        .then(() => {
          let doc = docs['list-'+docId];
          doc.parent = folderId;
          docs[doctype+'-'+docId] = doc;
          setLocalCache(docs)
            .then(() => { 
              this.updateListItems(); 
              this.toggleFolder(folderId, true);
            });
          
        })
        .catch(() => {
          this.updateListItems(); 
              this.toggleFolder(folderId, true);
        });

    } else if (result.destination === null) {

      const [, doctype, docId, ] = result.draggableId.split("-");
      moveToFolder({uid: this.context.uid, doctype, docId, folderId: ''})
        .then(() => {
          let doc = docs['list-'+docId];
          doc.parent = '';
          docs[doctype+'-'+docId] = doc;
          setLocalCache(docs)
            .then(() => { this.updateListItems(); });
          
        })
        .catch(() => { });

    } else {
      //let songId = result.draggableId || false;
      //let listId = result.destination && result.destination.droppableId ? result.destination.droppableId : false;
      //this.addSongToPlaylist(songId, listId);
    }
  }


  addSongToPlaylist = (songId, listId) => {
    if (songId && listId && listId !== '') {
      getListFromLocalCache(listId)
        .then((list) => {
          let maxPos = 0;
          for (let item of Object.values(list.items)) {
            if (item.pos > maxPos) {
              maxPos = item.pos;
            }
          }
          let pos = maxPos + 65535;
          let saveData = { 
            id: newID(), 
            songId: songId, 
            pos: pos 
          };
          api_post('/lists/' + listId + '/item', saveData)
            .then(async (resp) => {
              if (typeof resp.id !== 'undefined' && resp.id) {
                list.items[resp.id] = resp;
                await addToLocalCache("list", list);
                this.loadPlaylist(listId);
              }
            })
            .catch(handle_api_error);
        });
      
    }
  }

  duplicateSongInPlaylist = (itemId) => {

  }

  removeSongInPlaylist = (itemId) => {
    api_delete('/lists/' + this.state.listId + '/item/' + itemId).then((resp) => {
      getListFromLocalCache(this.state.listId)
        .then(async (list) => {
          delete list.items[itemId];
          await addToLocalCache("list", list);
          this.loadPlaylist(this.state.listId);
        });
    });
  }

  setSortBy = async (sortBy) => {
    this.setState({"sortBy": sortBy}, () => {
      this.updateListItems();
    });
  }

  toggleFolder = (folderId, forceState) => {
    
    if (folderId) {

      let openFolders = this.state.openFolders || {};

      if (typeof forceState === 'boolean') {
        openFolders[folderId] = forceState;
      } else if (typeof openFolders !== 'undefined' && typeof openFolders[folderId] !== 'undefined') {
        if (openFolders[folderId] === true) {
          openFolders[folderId] = false;
        } else {
          openFolders[folderId] = true;
        }
      } else {
        openFolders[folderId] = true;
      }

      this.setState({"openFolders": openFolders});

    }
  }

  render() {

    //if (this.props.doingAutoLogin) {
    //  return <div className="loading-container"><Loading text={i18next.t("Common.Loading")} /></div>;
    //}


    let backgroundStyle = {
      //backgroundImage: 'url(https://images.unsplash.com/photo-1582738411706-bfc8e691d1c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2187&q=80)'
      //backgroundImage: 'url(https://images.unsplash.com/photo-1571434976902-a6e3e1eb0d51?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=662&q=80)'
      //backgroundImage: 'url(https://images.unsplash.com/photo-1547125696-2b8e7f18c1da?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80)'
      //backgroundImage: 'url(https://images.unsplash.com/photo-1601379329542-31c59347e2b8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80)'
      //backgroundImage: 'url(https://images.unsplash.com/photo-1619263719761-165c773ee5df?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80)'
      backgroundImage: 'url(https://images.unsplash.com/photo-1544833058-e70f9ca25c17?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80)'
      //backgroundImage: 'url()'
      //, backgroundPosition: 'center bottom'
    };

    let numCreatedSongs = this.state.docsArray.filter((r) => {return typeof r.flags === 'object' && r.flags.includes("demo") ? false : true; }).length;
    
    return (
      <Translation>
        {(t) => (
          <DragDropContext onDragEnd={this.handleDragToList}>
          <ViewListContext.Provider value={{
            docArray: this.state.docArray,
            sortBy: this.state.sortBy,
            toggleFolder: this.toggleFolder,
            openFolders: this.state.openFolders,
            listId: this.state.listId,
            duplicateSong: this.duplicateSong,
            removeSong: this.removeSong,
            addSongToPlaylist: this.addSongToPlaylist,
            loadPlaylist: this.loadPlaylist,
            removePlaylist: this.removePlaylist,
            updateListItems: this.updateListItems,
            editFolder: this.editFolder,
            removeFolder: this.removeFolder,
            duplicateSongInPlaylist: this.duplicateSongInPlaylist,
            removeSongInPlaylist: this.removeSongInPlaylist,
          }}>
            {!this.context.user.emailVerified && <EmailVerificationModal />}

            <div className="browser-page" style={backgroundStyle}>

              {
                this.state.newSongModal===true && (
                <Modal close={this.closeModal}>
                  <NewSongModal 
                    redirect={this.props.redirect}
                    songType={this.state.newSongType}
                    copyId={this.state.newSongCopyId} 
                    />
                  </Modal>
                )
              }

{
              this.state.wizardModal===true && (
                <Modal close={this.closeModal}>
                  <WizardModal 
                    redirect={this.props.redirect}
                    />
                  </Modal>
                )
              }

              {
                this.state.newListModal===true && (
                <Modal close={this.closeModal}>
                  <NewListModal 
                    redirect={this.props.redirect}
                    />
                  </Modal>
                )
              }

              {
                this.state.showFolderModal!==false && (
                <Modal close={this.closeModal}>
                  <FolderModal 
                    closeModal={this.closeModal}
                    folderId={this.state.showFolderModal}
                    />
                  </Modal>
                )
              }

              <ListToolbar 
                openNewSongModal={this.openNewSongModal} 
                openNewListModal={this.openNewListModal} 
                openNewFolderModal={this.openNewFolderModal} 
                redirect={this.props.redirect} 
                />

              <div className="doc-list-wrapper">

                <ListPageOptions 
                  openNewSongModal={this.openNewSongModal}
                  openWizardModal={this.openWizardModal}
                  openNewListModal={this.openNewListModal}
                  openNewFolderModal={this.openNewFolderModal}
                  addSongToPlaylist={this.addSongToPlaylist}
                  setSortBy={this.setSortBy}
                  docsArray={this.state.docsArray}
                  listArray={this.state.listArray}
                  />

                {this.state.loading ? (
                  <div className="loading-container">
                    <Loading text={t("List.LoadingSongs")} />
                  </div>
                ) : (
                  ''
                )}

                <FlexSlider slide={this.state.listId ? 2 : 1}>
                  <div>

                    <DocumentList 
                        docsArray={this.state.docsArray} 
                        folderId=""
                        depth={0}
                      />

                    {!this.state.docsArray.length && !this.state.loading ? (
                      <div className="no-songs">
                        <span>{t("List.NoSongsYet")}</span>
                        {/* <button onClick={() => { this.openNewSongModal(); }}>{t("List.CreateSongButton")}</button> */}
                      </div>
                    ) : (
                      ''
                    )}

                    {
                      !numCreatedSongs && !this.state.loading ? (
                        <div className="welcome">
                          <div>
                            <h3>{t("Welcome.Headline")}</h3>
                            <p>{t("Welcome.WelcomeText1")}</p>
                            <p dangerouslySetInnerHTML={{__html: t("Welcome.EarlyMemberText")}}></p>
                            <p>{t("Welcome.BestRegards")}</p>
                          </div>
                        </div>
                      ) : null
                    }
                  </div>
                  <div>
                      
                      <PlaylistTitle 
                        docs={this.state.docs} 
                        listName={this.state.listName} 
                        loadPlaylist={this.loadPlaylist}
                        listId={this.state.listId}  />

                      <ListPlaylist 
                        docsArray={this.state.docsArray} 
                        listArray={this.state.listArray} 
                        />
                      
                      

                      {!this.state.listArray.length && !this.state.loading ? (
                        <div className="no-songs">
                          <span>{t("List.NoSongsYet")}</span>
                          <AddToListDropdown />
                        </div>
                      ) : (
                        ''
                      )}

                      {this.state.listArray.length && !this.state.loading ? (
                        <div className="list-buttons">
                          <Link className="button" to={"/list/"+this.state.listId+"/1"}>{t("List.OpenListButton")}</Link>
                        </div>
                      ) : null}
                    
                  </div>
                </FlexSlider>


              </div>
            </div>
          </ViewListContext.Provider>
          </DragDropContext>
        )}
      </Translation>
    );
  }
}
