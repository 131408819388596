import React from 'react';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
//import i18next from "i18next";
import { isDev } from '../../lib/dev'; // log
import { isMobile } from '../../lib/deviceControl';
import { ToolbarDropdown } from './ToolbarDropdown';
import { ToolbarAlignOptions } from './ToolbarAlignOptions';
import { ToolbarKeySelect } from './ToolbarKeySelect';
import { ToolbarTranspose } from './ToolbarTranspose';
import { ToolbarMeta } from './ToolbarMeta';
import { ToolbarCollab } from './ToolbarCollab';
import { ToolbarThemeSelect } from './ToolbarThemeSelect';
//import { ToolbarHelp } from './ToolbarHelp';
import { ToolbarUser } from './ToolbarUser';
import UserContext from '../../UserContext';
import { Modal } from '../Modal';
import { HelpBubble } from '../HelpBubble';
import { LoginForm } from '../LoginForm';
import fontFamilies from '../../config/fontfamilies.js';
import fontsizes from '../../config/fontsizes.js';
import minorequals from "../../config/minorequals"

export class EditorToolbar extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      chordsActive: false,
      align: 'center',
      selectOptionsOpen: false,
      //keyFilter: 'naturals',
      showModal: false,
      showEditActiveBubbleFirst: false,
      closeDropdown: 0,
      showLogin: false,
      saveDraftAfterLogin: false
    };

    this.standardFontSize = 20;
    this.closeDropdowns = this.closeDropdowns.bind(this);
    this.toggleEnvParam = this.toggleEnvParam.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
  }

  componentDidMount() {
    //console.log('ToolbarMount', this.props.songId);
    if (!this.props.editActive) {
      this.setState({ showEditActiveBubbleFirst: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.env.songKey !== prevProps.env.songKey) {
      if (this.props.env.songKey.indexOf('b') >= 0) {
        this.setState({ keyFilter: 'flats' });
      } else if (this.props.env.songKey.indexOf('#') >= 0) {
        this.setState({ keyFilter: 'sharps' });
      }
    }
  }

  closeDropdowns() {
    if (this.props.env.toggleFontSize) {
      this.toggleEnvParam('toggleFontSize', false);
    }
    if (this.props.env.toggleSelectOptions) {
      this.toggleEnvParam('toggleSelectOptions', false);
    }
  }

  toggleEnvParam(key, value) {
    if (typeof value !== 'undefined') {
      this.props.setEnvVariable(key, value);
    } else {
      this.props.setEnvVariable(key, typeof this.props.env[key] != 'undefined' ? !this.props.env[key] : true);
    }
  }

  onLoginSuccess() {
    if (this.state.saveDraftAfterLogin) {
      setTimeout(() => {
        this.props.saveDraft();
      }, 2000);
    }
  }

  render() {
    // log(this.props.env);

    let canUndo = false;
    if (this.props.history.length > 1) {
      canUndo = this.props.historyPosition === false || this.props.historyPosition > 1 ? true : false;
    }

    let canRedo = false;
    if (this.props.history.length > 0) {
      canRedo =
        this.props.historyPosition !== false && this.props.historyPosition < this.props.history.length ? true : false;
    }

    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <div className="toolbar">
              
              <div className="tb-left">
                <div className="option border">
                  <Link to={this.props.loggedIn ? '/songs' : '/'}>
                    <i className="fas fa-chevron-left" />
                  </Link>
                </div>

                <div className="separator">
                  <span />
                </div>

                <div className={canUndo ? 'tb-undo option' : 'tb-undo option disabled'}>
                  <span
                    onClick={() => {
                      if (canUndo) {
                        this.props.undo();
                      }
                    }}
                  >
                    <i className="fas fa-undo-alt" />
                  </span>
                </div>

                <div className={canRedo ? 'tb-redo option' : 'tb-redo option disabled'}>
                  <span
                    onClick={() => {
                      if (canRedo) {
                        this.props.redo();
                      }
                    }}
                  >
                    <i className="fas fa-redo-alt" />
                  </span>
                </div>

                {!this.props.songId && !this.state.saved ? (
                  <React.Fragment>
                    <div className="separator">
                      <span />
                    </div>
                    <div className="option with-text">
                      <span
                        onClick={() => {
                          if (this.context.loggedIn) {
                            this.props.saveDraft();
                            this.setState({ saved: true });
                          } else {
                            this.setState({ showLogin: true, saveDraftAfterLogin: true });
                          }
                        }}
                      >
                        {t('EditorToolbar.Save')}
                      </span>
                    </div>
                  </React.Fragment>
                  
                ) : (
                  ''
                )}

                {isDev() && !isMobile() && (
                  <React.Fragment>
                    <div className="separator">
                      <span />
                    </div>
                    <div className="option border">
                      <span
                        onClick={() => {
                          this.props.debug();
                        }}
                      >
                        <i className="fas fa-bug" />
                      </span>
                    </div>

                    {/* 
                  <div className="option border">
                    <span onClick={() => { Alert("This is a message!") }}><i className="fas fa-exclamation-triangle"></i></span>
                  </div>
                  <div className="option border">
                    <span onClick={() => { Confirm({headline: "Hey!", message: "Are you sure?"}) }}><i className="fas fa-question-circle"></i></span>
                  </div> 
                  */}
                  </React.Fragment>
                )}

              </div>



              <div className="tb-center">
                {/*
                Import song from 
                https://www.lyricfind.com/index.php?id=63
                https://www.musixmatch.com/
                https://developer.musixmatch.com/documentation
                */}
                <div
                  className={'option toggle-edit' + (this.props.editActive ? ' active' : '')}
                  onMouseEnter={() => {
                    this.setState({ showBubbleEditActive: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ showBubbleEditActive: false });
                  }}
                >
                  <span
                    onClick={() => {
                      this.props.setEditActive(!this.props.editActive);
                      this.setState({ showEditActiveBubbleFirst: false });
                    }}
                  >
                    <i className="fas fa-pen" />
                  </span>
                  {this.state.showEditActiveBubbleFirst ? (
                    <HelpBubble
                      visible={this.state.showEditActiveBubbleFirst}
                      html={t('EditorToolbar.EditActiveBubbleFirst')}
                      closeOnClickOutside={true}
                      arrowPos={10}
                    />
                  ) : (
                    <HelpBubble
                      visible={this.state.showBubbleEditActive}
                      label={t('EditorToolbar.EditActiveBubbleLabel')}
                      html={t('EditorToolbar.EditActiveBubble')}
                      arrowPos={10}
                    />
                  )}
                </div>
                
                <div className="separator">
                  <span />
                </div>

                <ToolbarAlignOptions env={this.props.env} toggleEnvParam={this.toggleEnvParam} />

                <div>
                  <ToolbarDropdown disabled={false} label={<i className="fas fa-font" />} helpBubble={{
                      arrowPos:20,
                      label:t('EditorToolbar.FontBubbleLabel'),
                      html:t('EditorToolbar.FontBubble')
                    }}>
                    <ul>
                      {fontFamilies.map((fontName, index) => {
                        return (
                          <li
                            className={
                              this.props.env.font === fontName || (!this.props.env.font && fontName === 'Quicksand') ? (
                                'active'
                              ) : (
                                ''
                              )
                            }
                            key={index}
                            onClick={() => {
                              this.props.setFont(fontName);
                            }}
                            style={{ fontFamily: fontName }}
                          >
                            {fontName}
                          </li>
                        );
                      })}
                    </ul>
                  </ToolbarDropdown>
                </div>

                <div>
                  <ToolbarDropdown disabled={false} label={<i className="fas fa-text-height" />} helpBubble={{
                      arrowPos:20,
                      label:t('EditorToolbar.FontSizeBubbleLabel'),
                      html:t('EditorToolbar.FontSizeBubble')
                    }}>
                    <ul>
                      {fontsizes.map((fontSize, index) => {
                        return (
                          <li
                            className={this.props.env.fontSize === fontSize ? 'active' : ''}
                            key={index}
                            onClick={() => {
                              this.toggleEnvParam('fontSize', fontSize);
                            }}
                          >
                            <FontSizeDisplay fontSize={fontSize} />
                          </li>
                        );
                      })}
                    </ul>
                  </ToolbarDropdown>
                </div>

                {this.props.hasText || this.props.hasChords || this.props.hasBars ? (
                  <div className="separator">
                    <span />
                  </div>
                ) : (
                  ''
                )}

                {this.props.hasText ? (
                  <div
                    className={'option toggle-chords with-text' + (this.props.env.chordsActive ? ' active' : '')}
                    onMouseEnter={() => {
                      this.setState({ showBubbleToolbarChords: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ showBubbleToolbarChords: false });
                    }}
                  >
                    <span
                      onClick={() => {
                        this.toggleEnvParam('chordsActive');
                        this.setState({ showBubbleToolbarChords: false });
                      }}
                    >
                      A7<span>♭9</span>
                    </span>
                    <HelpBubble
                      visible={this.state.showBubbleToolbarChords}
                      arrowPos={10}
                      label={t('EditorToolbar.ChordsActiveBubbleLabel')}
                      html={
                        <React.Fragment>
                          {t('EditorToolbar.ChordsActiveBubble')}
                          <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true}>
                            <source
                              src="https://storage.googleapis.com/swt-files/ToolbarChords240.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </React.Fragment>
                      }
                    />
                  </div>
                ) : (
                  ''
                )}

                {this.props.hasChords || this.props.hasBars ? (
                  <div
                    onClick={() => {
                      this.setState({ showKeySelectBubble: false });
                    }}
                  >
                    <ToolbarDropdown className={"key-dropdown"} withText={true} active={this.props.transpose !== 0 ? true : false} label={t('EditorToolbar.Key')} helpBubble={{
                      arrowPos:10,
                      label:t('EditorToolbar.KeySelectBubbleLabel'),
                      html:t('EditorToolbar.KeySelectBubble')
                    }}>
                      <ul>
                        <li
                          onMouseEnter={() => {
                            this.setState({ showSongKeySubMenu: true });
                          }}
                          onMouseLeave={() => {
                            this.setState({ showSongKeySubMenu: false });
                          }}
                        >
                          <span>
                          {t('EditorToolbar.OriginalKey')} 
                            <span className="value-selected">
                              {' '}
                              {this.props.env.songKey ? (
                                this.props.env.songKey + ' / ' + minorequals[this.props.env.songKey]
                              ) : this.props.env.songKeyDetected ? (
                                this.props.env.songKeyDetected + ' / ' + minorequals[this.props.env.songKeyDetected]
                              ) : (
                                ''
                              )}
                            </span>
                          </span>
                          {this.state.showSongKeySubMenu ? (
                            <div>
                              <ToolbarKeySelect
                                setEnvVariable={this.props.setEnvVariable}
                                keys={this.props.keys}
                                env={this.props.env}
                                t={t}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </li>
                        <li
                          onMouseEnter={() => {
                            this.setState({ showTransposeSubMenu: true });
                          }}
                          onMouseLeave={() => {
                            this.setState({ showTransposeSubMenu: false });
                          }}
                        >
                          <span>
                            {t('EditorToolbar.Transpose')} 
                            {' '}
                            {this.props.transpose !== 0 ? (
                              <span className="value-selected">
                                ({this.props.transpose > 0 ? (
                                  '+' + this.props.transpose
                                ) : (
                                  this.props.transpose
                                )})
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                          {this.state.showTransposeSubMenu ? (
                            <div>
                              <ToolbarTranspose 
                                setTranspose={this.props.setTranspose} 
                                transpose={this.props.transpose} 
                                env={this.props.env}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </li>
                      </ul>
                    </ToolbarDropdown>
                  </div>
                ) : (
                  ''
                )}

                {this.props.editActive ? (
                  <div className="separator">
                    <span />
                  </div>
                ) : (
                  ''
                )}
                <div
                  className="option tb-meta"
                  onMouseEnter={() => {
                    this.setState({ showBubbleToolbarMeta: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ showBubbleToolbarMeta: false });
                  }}
                >
                  <span
                    onClick={() => {
                      this.setState({ showModal: 'meta-editor', showBubbleToolbarMeta: false });
                    }}
                  >
                    <i className="fas fa-user-edit" />
                  </span>
                  {this.state.showModal === 'meta-editor' ? (
                    <Modal
                      className="medium"
                      close={() => {
                        this.setState({ showModal: false });
                      }}
                    >
                      <ToolbarMeta
                        updateMeta={this.props.updateMeta}
                        removeMeta={this.props.removeMeta}
                        meta={this.props.meta}
                      />
                    </Modal>
                  ) : (
                    ''
                  )}
                  <HelpBubble
                    visible={this.state.showBubbleToolbarMeta}
                    arrowPos={10}
                    label={t('EditorToolbar.MetaEditorBubbleLabel')}
                    html={t('EditorToolbar.MetaEditorBubble')}
                  />
                </div>

                <div className="separator">
                  <span />
                </div>

                <div
                  className="option pb-theme"
                  onMouseEnter={() => {
                    this.setState({ showBubbleToolbarThemeSelect: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ showBubbleToolbarThemeSelect: false });
                  }}
                >
                  <span
                    onClick={() => {
                      this.setState({ showModal: 'background-editor', showBubbleToolbarThemeSelect: false });
                    }}
                  >
                    <i className="far fa-image" />
                  </span>
                  {this.state.showModal === 'background-editor' ? (
                    <Modal
                      close={() => {
                        this.setState({ showModal: false });
                      }}
                    >
                      <ToolbarThemeSelect
                        setEnvVariable={this.props.setEnvVariable}
                        theme={this.props.env.theme || {}}
                      />
                    </Modal>
                  ) : (
                    ''
                  )}
                  <HelpBubble
                    visible={this.state.showBubbleToolbarThemeSelect}
                    arrowPos={10}
                    label={t('EditorToolbar.ThemeSelectBubbleLabel')}
                    html={t('EditorToolbar.ThemeSelectBubble')}
                  />
                </div>

              </div>

              <div className="tb-right">
                <div
                  className="option tb-print"
                  onMouseEnter={() => {
                    this.setState({ showBubblePrint: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ showBubblePrint: false });
                  }}
                >
                  <span
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <i className="fas fa-print" />
                  </span>
                  <HelpBubble
                    visible={this.state.showBubblePrint}
                    arrowPos={-10}
                    label={t('EditorToolbar.PrintBubbleLabel')}
                    html={t('EditorToolbar.PrintBubble')}
                    style={{ right: 0 }}
                  />
                </div>

                {this.context.loggedIn ? (
                  <div
                    className={'option tb-collab'}
                    onMouseEnter={() => {
                      this.setState({ showBubbleCollab: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ showBubbleCollab: false });
                    }}
                  >
                    <span
                      onClick={() => {
                        this.setState({ showModal: 'collab-editor', showBubbleCollab: false });
                      }}
                    >
                      <i className="fas fa-users" />
                    </span>
                    {this.state.showModal === 'collab-editor' ? (
                      <Modal
                        close={() => {
                          this.setState({ showModal: false });
                        }}
                      >
                        <ToolbarCollab
                          public={this.props.public}
                          setPublic={this.props.setPublic}
                          listed={this.props.listed}
                          setListed={this.props.setListed}
                          addTeamMember={this.props.addTeamMember}
                          removeTeamMember={this.props.removeTeamMember}
                          songId={this.props.songId}
                          title={this.props.title}
                          owner={this.props.owner}
                          team={this.props.team || {}}
                        />
                      </Modal>
                    ) : (
                      ''
                    )}
                    <HelpBubble
                      visible={this.state.showBubbleCollab}
                      arrowPos={-10}
                      label={t('EditorToolbar.CollabBubbleLabel')}
                      html={t('EditorToolbar.CollabBubble')}
                      style={{ right: 0 }}
                    />
                  </div>
                ) : (
                  ''
                )}

{/* 
                <ToolbarDropdown
                  withText={true}
                  justifyRight={true}
                  label={
                    <span>
                      <i className="far fa-question-circle " />
                    </span>
                  }
                >
                  <ToolbarHelp />
                </ToolbarDropdown> */}


                <div className="separator">
                  <span />
                </div>

                <ToolbarUser
                  t={t}
                  user={this.context.user}
                  loggedIn={this.context.loggedIn}
                  showLogin={() => {
                    this.setState({ showLogin: true });
                  }}
                />
              </div>
            </div>
            {this.state.showLogin ? (
              <Modal
                close={() => {
                  this.setState({ showLogin: false });
                }}
              >
                <LoginForm onLoginSuccess={this.onLoginSuccess} onLoginFailure={() => {}} />
              </Modal>
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const FontSizeDisplay = ({fontSize}) => {
  let fontSizeInt = parseInt(fontSize);
  let standardFontSize = 20;
  //if (fontSizeInt === standardFontSize) {
  //  return i18next.t("Original");
  //} else {
    return (fontSizeInt>standardFontSize ? '+' : '') + (fontSizeInt - standardFontSize);
  //}
}