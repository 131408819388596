export const addTagInTextNode = (tagName, attr, parentNode, left, right) => {

    // validate left
    if (left < 0) {
      left = 0;
    } else if (left > parentNode.length) {
      left = parentNode.length;
      right = parentNode.length;
    }

    // validate right
    if (right > parentNode.length) {
      right = parentNode.length;
    }

    // Create the tag around the word
    var range = document.createRange();
    range.setStart(parentNode, left);
    range.setEnd(parentNode, right);

    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    var selectionText = selection.toString();

    // create tag
    let tag = document.createElement(tagName.toUpperCase());
    tag.textContent = selectionText;
    for (let keys of Object.keys(attr)) {
      tag[keys] = attr[keys];
    }

    // insert tag
    range.deleteContents();
    range.insertNode(tag);
  }