import React from 'react';
import { Link } from 'react-router-dom';
import { ToolbarDropdown } from './ToolbarDropdown';
import { LanguageList } from './ToolbarLanguageList';


export const ToolbarUser = (props) => {
  return (
    <React.Fragment>
      {props.loggedIn ? (
        <ToolbarDropdown withText={true} justifyRight={true} label={<span>{props.user.shortname}</span>}>
          <ul>
            <LanguageList />
            <li className="devider" />
            <li>
              <Link to="/songs">{props.t('Toolbar.MySongs')}</Link>
            </li>
            {/* 
						<li><Link to="/privacy-policy">{props.t('Toolbar.PrivacyPolicy')}</Link></li>
						<li><Link to="/terms">{props.t('Toolbar.TermsOfUse')}</Link></li>
						*/}
            <li>
              <Link to="/logout">{props.t('Toolbar.LogOut')}</Link>
            </li>
          </ul>
        </ToolbarDropdown>
      ) : (
        <div className="option with-text">
          <span onClick={() => {
            props.showLogin();
          }}>{props.t('Toolbar.LogIn')}</span>
        </div>
      )}
    </React.Fragment>
  );
};
