import React,{ useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ViewEditor } from "./ViewEditor";
import { getSongsFromLocalCache, getLocalCache } from './components/Storage';
import { sortByPos } from './lib/utils';

export const ViewEditorInList = (props) => {

  const updateView = () => { // eslint-disable-line
    getLocalCache()
      .then((docs) => {
        let _list = docs['list-'+params.listId];
        if (_list && typeof _list.items !== 'undefined') {
          _list.items = sortByPos(Object.values(_list.items));
          let songId = _list.items[params.listIndex-1].songId || '';
          listIndexRef.current = params.listIndex;
          setSongId(songId || false);
          setList(_list);
        }
      });
  }

  let params = useParams();
  let [songId, setSongId] = useState(false);
  let [list, setList] = useState({});
  let listIndexRef = useRef(false);

  useEffect(() => {
    updateView();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (listIndexRef.current !== params.listIndex) {
      updateView();
    }
  }, [updateView, params, listIndexRef]); 

  
  
  return (
    songId
    ? <ViewEditor 
      listId={props.listId} 
      listItems={list.items}
      songId={songId} 
      user={props.user} 
      loggedIn={props.loggedIn} 
      doingAutoLogin={props.doingAutoLogin} 
      redirect={props.redirect} 
      listBar={<ListBar listId={params.listId} listIndex={params.listIndex} songId={songId} title={list.title} items={list.items} />}
      firebase={props.firebase}
      />
    : ''
  );
}

const ListBar = (props) => {
  const { t } = useTranslation();
  let [songs, setSongs] = useState(false);
  useEffect(() => {
    getSongsFromLocalCache()
      .then(resp => {
        setSongs(resp);
      })
  }, [])
  
  if (!songs) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <React.Fragment>
      <div>
        <h3>{props.title}</h3>
        <ol type="1" className="song-list">
          {props.items.map((item, index) => {
            let songIndex = parseInt(index)+1;
            if (typeof songs[item.songId] === 'undefined') {
              return <li className="deleted">{songIndex}.&nbsp;&nbsp;{t("List.SongDeleted")}</li>;
            }
            
            return <li key={index} className={
              songIndex === parseInt(props.listIndex) ? "active" : ""
            }>
              <Link to={"/list/" + props.listId + "/" + songIndex}>
              {songIndex}.&nbsp;&nbsp;{songs[item.songId].title}
              </Link>
            </li>;
          })}
        </ol>
      </div>
      
    </React.Fragment>
  );
}