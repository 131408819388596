import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

export const SelectedRowsToolbar = (props) => {
  const { t } = useTranslation();
  const [stickyClass, setStickyClass] = useState('');
  
  const onScroll = (e) => {
    let docTop = document.getElementById("document-area").offsetTop;
    let scrollTop = document.getElementById("scrollable").scrollTop;
    if (scrollTop > docTop) {
      setStickyClass('sticky');
    } else {
      setStickyClass('');
    }
  };

  useEffect(() => {
    onScroll();
    document.getElementById("scrollable").addEventListener('scroll', onScroll);
    return () => {
      document.getElementById("scrollable").removeEventListener('scroll', onScroll);
    }
  }, []);


  return (
    <div className={"selected-rows-toolbar " + stickyClass}>
      <div>
        <div className="checkbox checked" //style={{cursor: "default"}}
          onClick={props.deselectAllRows}
          >
          <i className="fas fa-check" />
        </div>
      </div>
      <div>
        <span onClick={() => {
                            props.selectedToClipboard();
                            props.setNotification(t('Notification.RowsCopied'));
                          }}>
          <i className="far fa-copy" />
          {t("EditorToolbar.Copy")}
        </span>
      </div>

      <div>
        <span onClick={() => {
                            props.createTrailingGroup(true);
                          }}>
          <i className="far fa-clone" />
          {t("EditorToolbar.Duplicate")}
        </span>
      </div>

      <div>
        <span onClick={() => {
                            props.selectedDelete();
                          }}>
          <i className="far fa-trash-alt" />
          {t("EditorToolbar.Delete")}
        </span>
      </div>

      <div>
        <span onClick={() => {
                            props.selectedToSnippets();
                          }}>
          <i className="fas fa-file-export" />
          {t("EditorToolbar.SaveInSidebar")}
        </span>
      </div>

    </div>
  );
};
