import React from 'react';
import { Navigate  } from "react-router-dom";
import UserContext from './UserContext';
import { getAuth } from "firebase/auth";

export class Logout extends React.Component {
  static contextType = UserContext;
  componentDidMount() {
    const auth = getAuth();
    auth.signOut();
  }
  render() {
    return (<Navigate to="/" />);
  }
}