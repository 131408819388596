import React from 'react';

export const FlexSlider = ({children, slide}) => {
  return (
    <div className="flex-slider" data-fs-slide={slide || 1}>
      <div className="fs-body">
        <div className="fs-inner">
          {children}
        </div>
      </div>
    </div>
  );

}