import React from 'react';
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const LanguageList = () => {
    const { t, i18n } = useTranslation();
    return (
      <React.Fragment>
        <li className="optionlabel">
          <span>{t("Toolbar.ChangeLanguage")}</span>
        </li>
        <li className={
          "indented"
          + (i18next.language.substr(0,2)==='en' ? ' active' : '')
          }>
          <span onClick={() => {
              i18n.changeLanguage("en");
          }}>English</span>
        </li>
        <li className={
          "indented"
          + (i18next.language.substr(0,2)==='sv' ? ' active' : '')
          }>
          <span onClick={() => {
              i18n.changeLanguage("sv");
          }}>Svenska</span>
        </li>
      </React.Fragment>
    );
  }