import React from 'react';
import minorequals from "../../config/minorequals"
import { transposeChords } from './../../lib/transposeChords';

const TransposedKey = ({chord, transpose, originalKey}) => {
	chord = chord.replace('♭', 'b');
	chord = chord.replace('♯', '#');
	let newKey = transposeChords(chord, transpose, originalKey);
	newKey = newKey.replace('♭', 'b');
	newKey = newKey.replace('♯', '#');
	return (
		<React.Fragment>
			{newKey}
			<span className="grayed"> ({minorequals[newKey]})</span>
		</React.Fragment>
	);
}

export const ToolbarTranspose = (props) => {

	let currentKey = '';

	if (props.env.songKey) {
		currentKey = props.env.songKey ;
	} else if (props.env.songKeyDetected) {
		currentKey = props.env.songKeyDetected;
	}
	
	return (
		<ul className="content-between-dropdown">
			<li
				onClick={() => {
					props.setTranspose(5);
				}}
				className={props.transpose === 5 ? 'selected' : ''}
			>
				<span>+5</span> <span><TransposedKey chord={currentKey} transpose={5} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(4);
				}}
				className={props.transpose === 4 ? 'selected' : ''}
			>
				<span>+4</span> <span><TransposedKey chord={currentKey} transpose={4} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(3);
				}}
				className={props.transpose === 3 ? 'selected' : ''}
			>
				<span>+3</span> <span><TransposedKey chord={currentKey} transpose={3} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(2);
				}}
				className={props.transpose === 2 ? 'selected' : ''}
			>
				<span>+2</span> <span><TransposedKey chord={currentKey} transpose={2} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(1);
				}}
				className={props.transpose === 1 ? 'selected' : ''}
			>
				<span>+1</span> <span><TransposedKey chord={currentKey} transpose={1} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(0);
				}}
				className={props.transpose === 0 ? 'selected' : ''}
			>
				<span>0</span> <span><TransposedKey chord={currentKey} transpose={0} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(-1);
				}}
				className={props.transpose === -1 ? 'selected' : ''}
			>
				<span>-1</span> <span><TransposedKey chord={currentKey} transpose={-1} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(-2);
				}}
				className={props.transpose === -2 ? 'selected' : ''}
			>
				<span>-2</span> <span><TransposedKey chord={currentKey} transpose={-2} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(-3);
				}}
				className={props.transpose === -3 ? 'selected' : ''}
			>
				<span>-3</span> <span><TransposedKey chord={currentKey} transpose={-3} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(-4);
				}}
				className={props.transpose === -4 ? 'selected' : ''}
			>
				<span>-4</span> <span><TransposedKey chord={currentKey} transpose={-4} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(-5);
				}}
				className={props.transpose === -5 ? 'selected' : ''}
			>
				<span>-5</span> <span><TransposedKey chord={currentKey} transpose={-5} originalKey={currentKey} /></span>
			</li>
			<li
				onClick={() => {
					props.setTranspose(-6);
				}}
				className={props.transpose === -6 ? 'selected' : ''}
			>
				<span>-6</span> <span><TransposedKey chord={currentKey} transpose={-6} originalKey={currentKey} /></span>
			</li>
		</ul>
	);
};
