import React from 'react';
import { Dropdown } from "../Dropdown";
import { Translation } from "react-i18next";

export class ToolbarCollabMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDropdown: 0,
      edit: true,
      read: true,
      write: true
    };
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    this.setState({
      edit: this.props.edit || true,
      read: this.props.editData.read || this.state.read,
      write: this.props.editData.write || this.state.write,
    });
  }

  save() {
    if (this.props.editId && this.props.editId !== '') {
      this.props.saveTeamMember(this.props.editId, this.state.read, this.state.write);
    }
  }

  remove() {
    if (this.props.editId && this.props.editId !== '') {
      this.props.removeTeamMember(this.props.editId);
    }
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  handleKeyUp(event) {
    if (event.key === 'Enter') {
      this.save();
    }
  }

  render() {
    return (
      <Translation>
        {(t) => (
      <div className="collab-editor">

        <h4>{this.props.editData.name}</h4>

        <Dropdown label={this.state.write===true ? t('CollabEditor.UserReadAndEdit') : t('CollabEditor.UserReadOnly')} closeDropdown={this.state.closeDropdown}>
          <ul ref={(node) => { this.dropdownRef = node }}>
              <li className={this.state.write===false?'active':''} onClick={() => { this.setState({read: true, write: false, closeDropdown: this.state.closeDropdown+1}); } }>
                <span>{t('CollabEditor.UserReadOnly')}</span>
              </li>
              <li className={this.state.write===true?'active':''} onClick={() => { this.setState({read: true, write: true, closeDropdown: this.state.closeDropdown+1}); } }>
                <span>{t('CollabEditor.UserReadAndEdit')}</span>
              </li>
          </ul>
        </Dropdown>
        
        <div className="btn-group">
        <button onClick={this.save}>{t("Buttons.Save")}</button>
          <button onClick={this.props.cancel}>{t("Buttons.Cancel")}</button>
          {
            this.props.editId && this.props.editId !== ''
              ? <button onClick={this.remove} className="ml-auto">
                  <i className="far fa-trash-alt" />
                </button>
                : ''
          }
          
        </div>
      </div>
        )}
        </Translation>
    );
  }
}
