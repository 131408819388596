import React from 'react';
import { Translation } from "react-i18next";
import { Dropdown } from "../Dropdown";
import validator from 'validator';

export class ToolbarCollabInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDropdown: 0,
      edit: true,
      email: '',
      read: true,
      write: true
    };
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    this.setState({
      edit: this.props.edit || true,
      read: this.props.editData.read || this.state.read,
      write: this.props.editData.write || this.state.write,
      email: this.props.editData.email || this.state.email
    });
  }

  save() {
    if (!validator.isEmail(this.state.email)) {
      alert('Please enter a valid email address');
      return;
    }
    if (this.props.editId && this.props.editId !== '') {
      this.props.saveInvite(this.props.editId, this.state.read, this.state.write);
    } else {
      this.props.sendInvite(this.state.email);
    }
  }

  remove() {
    if (this.props.editId && this.props.editId !== '') {
      this.props.removeInvite(this.props.editId);
    }
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  handleKeyUp(event) {
    if (event.key === 'Enter') {
      this.save();
    }
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className="collab-editor">

            <Dropdown label={this.state.write === true ? t("CollabEditor.UserReadAndEdit") : t("CollabEditor.UserReadOnly")} closeDropdown={this.state.closeDropdown}>
              <ul>
                  <li
                    className={this.state.write === false ? 'active' : ''}
                    onClick={() => {
                      this.setState({ read: true, write: false, closeDropdown: this.state.closeDropdown+1 });
                    }}
                  >
                    <span>{t("CollabEditor.UserReadOnly")}</span>
                  </li>
                  <li
                    className={this.state.write === true ? 'active' : ''}
                    onClick={() => {
                      this.setState({ read: true, write: true, closeDropdown: this.state.closeDropdown+1 });
                    }}
                  >
                    <span>{t("CollabEditor.UserReadAndEdit")}</span>
                  </li>
                </ul>
            </Dropdown>


            <input
              type="text"
              onChange={this.handleChange}
              onKeyUp={this.handleKeyUp}
              value={this.state.email}
              placeholder={t("Common.Email")}
              spellCheck="false"
            />

            <div className="btn-group">
              <button onClick={this.save}>
                {this.props.editId && this.props.editId !== '' ? t("Buttons.Save") : t("CollabEditor.ButtonSendInvitation")}
              </button>
              <button onClick={this.props.cancel}>{t("Buttons.Cancel")}</button>
              {this.props.editId && this.props.editId !== '' ? (
                <button onClick={this.remove} className="ml-auto">
                  <i className="far fa-trash-alt" />
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        </Translation>
    );
  }
}
