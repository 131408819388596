import { cloneObject } from '../cloneObject.js';
import { getIndexOf, getPosBetween, getNewGroup } from '../utils.js';

export const handleConvertRowToNewGroup = (attr) => {
  let { row, rows, grps, docArray, updateObject } = attr;

  let currentGroup = grps[row.grpId];
  let currentGroupIndex = getIndexOf(row.grpId, docArray);
  if (currentGroupIndex === false) {
    return { updateObject };
  }

  // Find next group
  let nextGroupIndex = currentGroupIndex + 1;
  let nextGroup = typeof docArray[nextGroupIndex] != 'undefined' ? docArray[nextGroupIndex] : false;

  // Find position of next pargraph
  let newGroupPosition = getPosBetween(currentGroup, nextGroup);

  // Create new group after current and before next
  let newGroup = getNewGroup({ pos: newGroupPosition });
  grps[newGroup.id] = newGroup;
  updateObject.prev.grps[newGroup.id] = { id: newGroup.id, deleted: true };
  updateObject.next.grps[newGroup.id] = cloneObject(grps[newGroup.id]);

  // Move rows to new group
  for (let group of docArray) {
    if (group.id === row.grpId && typeof group.rows !== 'undefined' && group.rows.length) {
      for (let currentRow of group.rows) {
        if (currentRow.pos >= row.pos) {
          updateObject.prev.rows[currentRow.id] = cloneObject(currentRow);

          rows[currentRow.id] = currentRow;
          rows[currentRow.id].grpId = newGroup.id;

          updateObject.next.rows[currentRow.id] = cloneObject(rows[currentRow.id]);
        }
      }
    }
  }

  //console.log(updateObject);

  return { updateObject };
}
