import React, {PureComponent} from 'react';
import { ChordEditor } from './../ChordEditor';
import { Chord } from './../Chord';
import { transposeChords } from './../../../lib/transposeChords';
import { handleUpdateChordOfLyrics } from './../../../lib/handlers/handleUpdateChordOfLyrics';

export class ChordContainer extends PureComponent {

	constructor(props) {
		super();
		this.state = {
      editChord: false,
      dragover: false,
      chord : props.chord
    };
    this.openChordEditor = this.openChordEditor.bind(this);
    this.updateChord = this.updateChord.bind(this);

    this.jumpto = this.jumpto.bind(this);

  }

  componentDidMount() {
    let newState = {};
    if (this.props.chord !== this.state.chord) {
      newState.chord = this.state.chord;
    }
    if (this.props.chordEditorIsOpen && !this.state.editChord) {
      newState.editChord = true;
    } else if (!this.props.chordEditorIsOpen && this.state.editChord) {
      newState.editChord = false;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let newState = {};
    if (this.props.chord !== prevProps.chord || this.props.chordsUpdated !== prevProps.chordsUpdated) {
      newState.chord = this.props.chord;
    } else if (this.props.chord !== this.state.chord) {
      newState.chord = this.state.chord;
    }
    if (this.props.chordEditorIsOpen && !this.state.editChord) {
      newState.editChord = true;
    } else if (!this.props.chordEditorIsOpen && this.state.editChord) {
      newState.editChord = false;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  updateChord(chord) {
    this.setState({chord: chord});
    let chords = handleUpdateChordOfLyrics({
      chords: this.props.chords, 
      chordSymbol: chord, 
      letterindex: this.props.letterindex
    });
    //let chordsUpdated = typeof this.props.row.chordsUpdated != 'undefined' ? this.props.row.chordsUpdated + 1 : 1;
    this.props.updateRowData(this.props.rowid, {chords: chords}, 'chord');
  }
  
  openChordEditor(e) {
    this.props.openChordEditorAt(0, this.props.rowid, this.props.letterindex);
  }

  jumpto(goto) {
    this.props.closeChordEditor();
    this.props.openChordEditorAt(goto, this.props.rowid, this.props.letterindex);
    /*
    if (location === 'Down') {
      this.props.openChordEditorAt(0, this.props.rowindex+1, this.props.letterindex);
    } else if (location === 'Up') {
      let prevRow = (this.props.row.index>0) ? this.props.rowindex-1 : 0;
      this.props.openChordEditorAt(location, prevRow, this.props.letterindex);
    } else {
      this.props.openChordEditorAt(location, this.props.rowid, this.props.letterindex);
    }
    */
  }

	render() {
    let chordDisplayed = this.state.chord;
    let id = this.props.rowid + '-' + this.props.letterindex;

    if (this.props.transpose !== 0 && typeof this.props.transpose === "number" && chordDisplayed !== '') {
      chordDisplayed = transposeChords(chordDisplayed, this.props.transpose, this.props.songKey);
    }

    if (!this.props.editActive) {
      return <ChordNotEditable chord={chordDisplayed} letter={this.props.letter} />;
    }    

		return (
			<span
        id={id}
        data-chord={chordDisplayed}
				data-index={this.props.letterindex}
        onClick={this.openChordEditor}
        className={
          (this.state.dragover ? "dragover" : "")
          + (this.state.editChord ? " editing" : "")
        }
        onDragEnter={(e) => {
          this.setState({dragover: true});
        }}
        onDragLeave={(e) => {
          this.setState({dragover: false});
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({dragover: false});
          this.props.handleChordDroppedOnLyrics(e, this.props.rowid, this.props.letterindex);
        }}
        
			>
        {
          chordDisplayed && chordDisplayed !== ''
          ? (<Chord 
            chord={chordDisplayed || ''} 
            draggable={true} 
            rowid={this.props.rowid} 
            letterindex={this.props.letterindex}
            findex={false}
            editing={this.state.editChord}
          />)
          : ''
        }
        
        
				{this.props.letter === ' ' ? '\u00a0' : this.props.letter}
				{this.state.editChord ? (
          <ChordEditor
            parentId={id}
            chord={this.state.chord}
            jumpto={this.jumpto}
            close={this.props.closeChordEditor}
            updateChord={this.updateChord}
          />
				) : (
					''
				)}
      </span>
		);
	}
}

const ChordNotEditable = (props) => {
  return <span data-chord={props.chord}>
          <Chord chord={props.chord || ''} draggable={false} />
          {props.letter === ' ' ? '\u00a0' : props.letter}
        </span>;
}
