import React, { useEffect, useRef, useState } from "react";
import { api_get, handle_api_error } from "../../../../API";
import { t } from "i18next";
import { WordList } from "./Word";
import { WordBrowser } from "./WordBrowser";
import { removeLinebreaks } from "../../../../lib/removeLinebreaks";
import { stripTags } from "../../../../lib/stripTags";
import { Loading } from "../../../Loading";

export const AltWord = (props) => {
  
  let [language, setLanguage] = useState(props.language || 'en');
  let [loading, setLoading] = useState(false);
  let [results, setResults] = useState({});
  let [count, setCount] = useState(0);

  const initialized = useRef(false);
  const query = useRef('');
  const wordInLyrics = "";
  
  const categories = [
    "synonyms",
    "rhymes",
    "soundalikes",
    "related"
  ];

  useEffect(() => {
    if (initialized.current) return; 
    initialized.current = true;

    let wordsString = removeLinebreaks(stripTags(props.words.join(',')));
    query.current = wordsString;

  }, []); // eslint-disable-line

  useEffect(() => {
    if (Object.keys(results).length > 0) {
      search();
    }
  }, [language]); // eslint-disable-line

  const search = () => {
    setLoading(true);
    
    api_get('/words/alt?l='+language+'&q='+query.current)
      .then((resp) => {
        let _result = {
          synonyms: [],
          rhymes: [],
          soundalikes: [],
          related: [],
        };
        let _count = 0;
        for (let k of Object.keys(resp)) {
          if (typeof _result[k] !== 'undefined') {
            _result[k] = resp[k];
            _count += resp[k].length;
          }
        }
        setResults(_result);
        setCount(_count);
        setLoading(false);

      })
      .catch((err) => {
        handle_api_error(err);
      });
  }

  const insertWord = (e) => {
    let clickedWord = e.currentTarget.parentNode.getAttribute("data-word");
    let atPos = props.cursorPosition + wordInLyrics.length;
    props.insertWord(clickedWord, atPos);
  }

  const replaceWord = (e) => {
    if (wordInLyrics && wordInLyrics !== '') {
      let clickedWord = e.currentTarget.parentNode.getAttribute("data-word");
      props.replaceWord(clickedWord, wordInLyrics, props.cursorPosition);
      //this.wordInLyrics = clickedWord;
    }
  }

  return (
    <WordBrowser 
      {...props} 
      loading={loading} 
      language={language} 
      setLanguage={setLanguage} 
      search={search}
      searchType="alt"
    >

      {
        loading
        ? <Loading text="" />
        : null
      }

      {
        (!loading && count === 0) ? (
          <div className="no-result"><span>{t('WordBrowser.NoResult')}</span></div>
        ) : null
      }

      <div className="word-result">
        {categories.map((category, index) => {
          return (
            <WordList 
              words={results[category]} 
              label={t('WordBrowser.' + category)}
              searchType="alt"
              wordInLyrics={wordInLyrics}
              insertWord={insertWord} 
              replaceWord={replaceWord} 
              key={index}
              />
          )
        })}
      </div>
    </WordBrowser>
  );
}

