import React from 'react';
import i18next from "i18next";
import { getCursorOffsetInElement } from '../../../lib/getCursorOffsetInElement';
import { focusElementAtPosition } from '../../../lib/focusElementAtPosition';
import { getSelecedText } from '../../../lib/getSelecedText';
import { removeLinebreaks } from '../../../lib/removeLinebreaks';
import { stripTags } from '../../../lib/stripTags';
import { TitleSuggestions } from './TitleSuggestions';

export class TitleEditor extends React.Component {
  constructor(props) {
    super(props);

    this.updateTimer = null;
    this.lastEmittedText = '';

    this.editorRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.emitChanges = this.emitChanges.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.getText = this.getText.bind(this);

    this.state = {
      showWizardIcon: false,
      showSuggestions: false
    };
  }

  componentDidMount() {
    this.lastEmittedText = this.props.html;
    this.props.setTitleRef(this.editorRef);
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let currentText = this.getText();
    if (nextProps.html !== currentText || this.state.showWizardIcon !== nextState.showWizardIcon || this.state.showSuggestions !== nextState.showSuggestions) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    let currentText = this.getText();
    if (this.props.html !== currentText) {
      this.editorRef.current.innerHTML = this.props.html;
      this.lastEmittedText = this.props.html;
    }
    this.props.setTitleRef(this.editorRef);
  }

  handleClickOutside = (e) => {
    let clickedOutside = (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) ? true : false;
    if (clickedOutside) {
      this.setState({showWizardIcon: false});
		}
  }

  showSuggestions = () => {
    this.setState({showSuggestions: true});
  }
  hideSuggestions = () => {
    this.setState({showSuggestions: false});
  }

  handleKeyDown(event) {
    if (typeof event.key == 'undefined') {
      event.preventDefault();
    }
    let currentText = this.getText();
    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    let inputLength = currentText.length;
    let selectedText = getSelecedText();

    if (event.key === 'ArrowRight' && cursorPos === inputLength && !selectedText) {
      event.preventDefault();
      this.emitChanges();
      this.props.jumpToRow(0, 0);
      this.setState({showWizardIcon: false});
    } else if (event.key === 'ArrowUp') {
      return;
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.props.jumpToRow(0, cursorPos);
      this.setState({showWizardIcon: false});
      return;
    } else if (event.key === 'Tab') {
      event.preventDefault();
      return;
    } else if (event.key === 'Enter') {
      event.preventDefault();
      return;
    }
  }

  handleKeyUp(event) {

    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' ) {
      this.emitChanges();
      return;
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      return;
    } else if (event.key === 'Tab') {
      this.props.jumpToRow(0, 0);
      this.setState({showWizardIcon: false});
    } else if (event.key === 'Enter') {
      this.props.jumpToRow(0, 0);
      this.setState({showWizardIcon: false});
    } 

    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
    this.updateTimer = setTimeout(() => {
      this.emitChanges();
    }, 500);
  }

  emitChanges() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
    var newHtml = this.getText();
    if (newHtml !== this.lastEmittedText) {
      this.props.handleTitleChange(stripTags(newHtml));
    }
    this.lastEmittedText = newHtml;
  }

  handleFocus(event) {
    if (this.getText() === i18next.t("NewSong.DefaultSongTitle")) {
      var range = document.createRange();
      range.setStart(event.target.firstChild, 0);
      range.setEnd(event.target.firstChild, event.target.firstChild.length);
      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
    this.setState({showWizardIcon: true});
  }

  handleBlur() {
    if (this.getText() !== this.lastEmittedText) {
      this.emitChanges();
    }
  }

  handlePaste(e) {

    e.stopPropagation();
    e.preventDefault();
    let clipboardData = e.clipboardData || window.clipboardData;
    let pasteText = stripTags(clipboardData.getData('Text'));

    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    let currentTitle = this.editorRef.current.innerHTML;

    let htmlBeforeCursor = currentTitle.substr(0, cursorPos);
    let htmlAfterCursor = currentTitle.substr(cursorPos);

    let newTitle = htmlBeforeCursor + pasteText + htmlAfterCursor;
    this.editorRef.current.innerHTML = newTitle;
    focusElementAtPosition(cursorPos+pasteText.length, this.editorRef.current);
    this.emitChanges();
    
  }

  getText() {
    let currentText = this.editorRef.current && typeof this.editorRef.current != 'undefined' 
        ? this.editorRef.current.innerHTML 
        : '';
    return removeLinebreaks(stripTags(currentText));
  }

  setTitle = (newTitle) => {
    // console.log(newTitle, this.editorRef.current);
    this.editorRef.current.innerHTML = newTitle;
    
    this.emitChanges();
    setTimeout(() => {
      this.setState({showSuggestions: false});
    }, 10);
  }
  
  render() {

    return this.props.editActive && !this.props.lyricsReadonly ? (
      <div className="title-wrapper" ref={this.wrapperRef}>

        {
          /*
          this.state.showWizardIcon
          ? <i className="material-icons-outlined" onClick={() => {
            this.showSuggestions();
          }}>auto_fix_high</i>
          : null
          */
        }
        
        <h1
          id="title"
          ref={this.editorRef}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onPaste={this.handlePaste}
          contentEditable
          dangerouslySetInnerHTML={{ __html: this.props.html }}
          spellCheck="false"
        />

        {
          this.state.showSuggestions
          ? (
            <TitleSuggestions 
              songId={this.props.songId} 
              setTitle={this.setTitle}
              close={() => { this.hideSuggestions() }} 
              />
          )
          : null
        }
        
      </div>
    ) : (
      <h1 id="title" ref={this.editorRef}>{this.props.html}</h1>
    );
  }
}
