import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from '../Modal.js';
import UserContext from '../../UserContext';
import { 
  getAuth, 
  sendEmailVerification,
} from "firebase/auth";

export const EmailVerificationModal = (props) => {

  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  let [show, setShow] = useState(false);
  let [resent, setResent] = useState(false);

  const close = () => {
    window.sessionStorage.setItem('emailVerificationModalShown', 1);
    setShow(false);
  }

  const resend = () => {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser);
    setResent(true)
  }

  useEffect(() => {
    let emailVerificationModalShown = window.sessionStorage.getItem('emailVerificationModalShown');
    if (!userContext.user.emailVerified && !emailVerificationModalShown) {
      setShow(true);
    }
  }, []); // eslint-disable-line

  return show ? (
    <Modal close={close}>
      <h1>{t("Welcome.VerifyEmailHeadline")}</h1>
      <p>
        {t("Welcome.VerifyEmailText")} <span> </span>
        
      </p>
      
      <div className="button-group mb-3">
        <button onClick={close}>{t("Alert.Okay")}</button>        
      </div>

      <div>
      {!resent && <span className="color-blue cursor-pointer" onClick={resend}>{t("Welcome.ResendVerificationEmail")}</span>}
      {resent && <span>{t("Welcome.VerificationEmailSent")}</span>}
      </div>
    </Modal>
  ) : null;
}