import React, {useRef, useEffect} from 'react'; // , { useState }
import { BrowserRouter, Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom'; // , withRouter
import localforage from 'localforage';
import i18next from 'i18next';
import ReactGA from 'react-ga';
//import { log } from './lib/dev.js'; // isDev
import { isTouchDevice } from './lib/deviceControl.js';
import { Logout } from './Logout.js';
import { handle_api_error, api_post } from './API';
import { removeCachedSongs } from './components/Storage.js';
import { PrivacyPolicy } from './pages/PrivacyPolicy.js';
import { Terms } from './pages/Terms.js';
import { ViewStart } from './ViewStart.js';
import { ViewEditor } from './ViewEditor.js';
import { ViewList } from './ViewList.js';
import { ViewEditorInList } from './ViewEditorInList.js';
import { ViewLoadingProgress } from './ViewLoadingProgress.js';
import { ViewAdmin } from './ViewAdmin.js';
import { ViewNotFound } from './ViewNotFound.js';
import { UserManagement } from './UserManagement.js';
import { HelpMenu } from './components/HelpMenu.js';
//import { DriveOpen, DriveNew } from './GoogleDrive';
import Alert from './components/Alert.js';
import UserContext from './UserContext.js';
import './scss/App.scss';


import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, getRedirectResult } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";


const firebase = initializeApp({
  apiKey: 'AIzaSyAvwrF6ebEOsUNaVjF127HAxIUy8U1zbew',
  authDomain: 'songwriters-tools.firebaseapp.com',
  databaseURL: 'https://songwriters-tools-eu.europe-west1.firebasedatabase.app/',
  projectId: 'songwriters-tools',
  storageBucket: 'songwriters-tools.appspot.com',
  messagingSenderId: '798668440015',
  appId: '1:798668440015:web:37a90a3c8d257d3d5f52ba',
  measurementId: 'G-C3TP8JL55L'
});
//const analytics = getAnalytics();

if (window.location.host === 'www.songwriterstools.com' || window.location.host === 'songwriterstools.com') {
  ReactGA.initialize('UA-174948095-1');
  //ReactGA.pageview(window.location.pathname + window.location.search);
}

/*
Regex for find old bind functions and make them to variables:
(\(([a-z,\s]*)\)\s\{)
*/

const TrackingHandler = () => {
  
  let currentLocation = useLocation();
  let refLocation = useRef({pathname: "", search: ""});
  
  useEffect(() => {
    
    if (typeof refLocation.current === 'undefined' || currentLocation.pathname !== refLocation.current.pathname) { 
      // Location is changed changed
      if (window.location.host === 'www.songwriterstools.com' || window.location.host === 'songwriterstools.com') {
        ReactGA.pageview(currentLocation.pathname + currentLocation.search);
      }
    }

    refLocation.current = currentLocation;

  }, [currentLocation, refLocation]);

  return null;
}

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      invite: '',
      user: {},
      settings: {},
      idToken: '',
      doingAutoLogin: true,
      redirect: false,
      rerender: 0,
      docs: {},
      //invites: []
    };
    this.loggedIn = false;
    this.auth = null;
  }

  componentDidMount() {
    if (isTouchDevice()) {
      document.body.classList.add('touch');
      document.body.classList.remove('no-touch');
    } else {
      document.body.classList.remove('touch');
      document.body.classList.add('no-touch');
    }

    document.title = i18next.t('SiteName');
    if (window.location.host === 'songwriterstools.com') {
      window.location.href = 'https://www.songwriterstools.com' + window.location.pathname;
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    const urlParams = new URLSearchParams(window.location.search);
    const invite = urlParams.get('invite');
    if (invite) {
      this.setState({ invite: invite, doingAutoLogin: false });
    }

    this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      //console.log("onAuthStateChanged");
      if (user) {
        this.handleUserSignedIn(user);
      } else {
        this.handleUserSignedOut();
      }
    });

    setTimeout(() => {
      getRedirectResult(this.auth).then(() => {}).catch(this.handleSignInError);
    }, 100);
  }

  componentDidUpdate() {
    if (this.state.redirect) {
      this.setState({redirect: false});
    }
  }

  handleUserSignedIn = (user) => {
    //console.log('User is signed in', user);

    let loggedInSession = window.sessionStorage.getItem('loggedIn');

    if (!this.state.loggedIn) {
      user.getIdToken().then((idToken) => {
        let data = {
          uid: user.uid,
          displayName: (typeof user.displayName !== 'undefined') ? user.displayName : '',
          idToken: idToken,
          isOngoingSession: (loggedInSession) ? true : false,
          providerId: user.providerData[0].providerId,
          providerUid: user.providerData[0].uid,
          userAgent: navigator.userAgent,
          screenWidth: window.screen.width,
        };
        api_post('/users/login', data)
          .then(async (resp) => {

            await localforage.setItem('docs', resp.docs);
            window.sessionStorage.setItem('loggedIn', "1");

            let shortname = user.displayName
              .split(' ')
              .map((str) => {
                return str.substr(0, 1);
              })
              .join('');

            this.setState(
              {
                user: {
                  displayName: user.displayName,
                  shortname: shortname,
                  email: user.email,
                  emailVerified: user.emailVerified,
                  photoURL: user.photoURL,
                  uid: user.uid
                },
                settings: typeof resp.user.settings !== 'undefined' ? resp.user.settings : {},
                idToken: idToken,
                docs: resp.docs,
                loggedIn: true,
                doingAutoLogin: false
              },
              () => {
                if (window.location.pathname === '/') {
                  this.redirect('/songs');
                }
              }
            );
          })
          .catch((err) => {
            this.setState({ doingAutoLogin: false });
            handle_api_error(err);
          });
      });
    }
  }
  handleUserSignedOut = () => {
    window.sessionStorage.removeItem('loggedIn');
    removeCachedSongs();
    this.setState({
      user: {},
      docs: {},
      loggedIn: false,
      doingAutoLogin: false
    });
  }

  
  handleSignInError = (error) => {
    if (error.code === 'auth/account-exists-with-different-credential') {
      Alert(i18next.t('Errors.' + error.code));
    } else if (error.code === 'auth/popup-blocked') {
      Alert(i18next.t('Errors.' + error.code));
    }
    /*
    auth/auth-domain-config-required
    auth/cancelled-popup-request
    auth/operation-not-allowed
    auth/operation-not-supported-in-this-environment
    auth/popup-closed-by-user
    auth/unauthorized-domain
    */
  }

  updateUserState = (updateUserState) => {
    let userState = this.state.user;
    for (let x of Object.keys(updateUserState)) {
      userState[x] = updateUserState[x];
    }
    this.setState({user : userState});
  }

  redirect = (path) => {
    this.setState({ redirect: path, rerender: this.state.rerender + 1 });
  }

  render() {
    //if (this.state.doingAutoLogin) {
    //  return '';
    //}

    let UserContextProvider = {
      firebase: firebase,
      handleUserSignedIn: this.handleUserSignedIn,
      handleSignInError: this.handleSignInError,

      uid: this.state.user.uid,
      user: this.state.user,
      settings: this.state.settings,
      idToken: this.state.idToken,
      loggedIn: this.state.loggedIn,
      invite: this.state.invite,

      docs: this.state.docs,

      autoLogin: this.autoLogin
    };

    return (
      <UserContext.Provider value={UserContextProvider}>
      <BrowserRouter>
        {this.state.redirect ? <Navigate to={this.state.redirect} /> : ''}
        <TrackingHandler />
        <div id="modal-root" />
        <div id="alert-root" />
        <div id="dropdown-root" className="dropdown" />
        <header />
        <div className="main">
            <Routes>
              <Route
                exact
                path="/"
                element={ 
                  <ViewStart
                    user={this.state.user}
                    loggedIn={this.state.loggedIn}
                    doingAutoLogin={this.state.doingAutoLogin}
                    redirect={this.redirect}
                  />
                }
              />
              <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
              <Route path="/terms" element={ <Terms /> } />
              <Route path="/start" element={ <ViewStart redirect={this.redirect} /> } />
              <Route path="/logout" element={ <Logout /> } />
              <Route path="/usermgmt" element={ <UserManagement redirect={this.redirect} updateUserState={this.updateUserState} /> } />
              {/*
              <Route path="/drive/open" element={ <DriveOpen redirect={this.redirect} />} />
              <Route path="/drive/new" element={ <DriveNew redirect={this.redirect} />} />
              */}
              <Route
                exact
                path="/write"
                element={
                  this.state.doingAutoLogin
                  ? <ViewLoadingProgress />
                  : (
                      <ViewEditor
                      songId={false}
                      user={this.state.user}
                      loggedIn={this.state.loggedIn}
                      doingAutoLogin={this.state.doingAutoLogin}
                      redirect={this.redirect}
                      firebase={firebase}
                    />
                    )
                }
              />
              {
              <Route
                exact
                path="/songs/list/:listId"
                element={ 
                    this.state.doingAutoLogin
                    ? <ViewLoadingProgress />
                    : (
                    <ViewListContainer
                        user={this.state.user}
                        loggedIn={this.state.loggedIn}
                        doingAutoLogin={this.state.doingAutoLogin}
                        redirect={this.redirect}
                      />
                    )
                  }
              />
              }
              <Route
                exact
                path="/song/:songId"
                element={
                  this.state.doingAutoLogin
                    ? <ViewLoadingProgress />
                    : (
                      <ViewEditorContainer
                        user={this.state.user}
                        loggedIn={this.state.loggedIn}
                        doingAutoLogin={this.state.doingAutoLogin}
                        redirect={this.redirect}
                        firebase={firebase}
                      />
                    )
                }
              />
              <Route
                exact
                path="/songs"
                element={ 
                  this.state.doingAutoLogin
                    ? <ViewLoadingProgress />
                    : (
                      <ViewListContainer
                        user={this.state.user}
                        loggedIn={this.state.loggedIn}
                        settings={this.state.settings}
                        doingAutoLogin={this.state.doingAutoLogin}
                        redirect={this.redirect}
                      />
                    )
                }
              /> 
              <Route
                exact
                path="/list/:listId/:listIndex"
                element={
                  <ViewEditorInList
                      user={this.state.user}
                      loggedIn={this.state.loggedIn}
                      doingAutoLogin={this.state.doingAutoLogin}
                      redirect={this.redirect}
                      firebase={firebase}
                    />
                }
              />
              <Route path="/admin/:adminPage" element={ 
                <ViewAdmin 
                  user={this.state.user}
                  loggedIn={this.state.loggedIn}
                  doingAutoLogin={this.state.doingAutoLogin}
                  redirect={this.redirect}
                /> 
              } />
              <Route path="*" element={<ViewNotFound />} />

            </Routes>
        </div>
        <footer>
          <HelpMenu / >
        </footer>
      </BrowserRouter>
      </UserContext.Provider>
    );
  }
}

const ViewListContainer = (props) => {
  let params = useParams();
  return <ViewList {...props} {...params} />;
}

const ViewEditorContainer = (props) => {
  let params = useParams();
  return <ViewEditor {...props} {...params} />;
}