import React, { useEffect, useState, useContext } from "react";
import {getNewGroup, getNewRow, getPosBetween, newSongModel} from "../../lib/utils";
import {addToLocalCache} from "../Storage";
import UserContext from '../../UserContext';
import { api_get, api_post } from "../../API";
import { t } from "i18next";

export const WizardModal = (props) => {

  //const userContext = useContext(UserContext);
  //const listContext = useContext(ViewListContext);
  //const { t } = useTranslation();
  let [config, setConfig] = useState([]);
  let [step, setStep] = useState(0);
  let [mainTopic, setMainTopic] = useState(false);
  let [subTopic, setSubTopic] = useState(false);
  let [description, setDescription] = useState('');
  let [language, setLanguage] = useState(props.language || "");
  let user = useContext(UserContext);

  let [creating, setCreating] = useState(false);
  let [loadingTopics, setLoadingTopics] = useState(true);
  
  useEffect(() => {

    api_get("/wizard").then((resp) => {
      setConfig(resp);
      setLoadingTopics(false);
    });

  }, []); // eslint-disable-line


  const submit = (e) => {
    e.preventDefault();
    if (creating) {
      return;
    }

    let topic = mainTopic.title;
    if (subTopic && subTopic.title) {
      topic += ", " + subTopic.title;
    }
    setCreating(true);
    api_post("/wizard/song", {topic: topic, description: description}).then((resp) => {

      let team = {};
      team[user.uid] = {read:true, write:true, displayName: user.displayName, photoURL:  user.photoURL};
      let song = newSongModel({
        title: resp.title.replaceAll('"', ''),
        owner: user.uid,
        team: team,
        flags: [ 'gpt' ],
        grps: {},
        rows: {},
        language: 'en',
        imported: {
          source: 'gpt',
        }
      });

      
      let grps = {};
      let rows = {};
      let currentGroup = {};
      let currentRow = {};
      let textArray = resp.lyrics.split("\n");
      for (let x in textArray) {
        x = parseInt(x);
        let text = textArray[x];
        //console.log(textArray[x]);

        if (x === 0) { // first row, create new group
          currentGroup = getNewGroup({ pos: getPosBetween(currentGroup, false) });
          grps[currentGroup.id] = currentGroup;

        } else if (text === '') { // extra row break, create new group
          currentGroup = getNewGroup({ pos: getPosBetween(currentGroup, false) });
          grps[currentGroup.id] = currentGroup;
          continue;
        }

        if (text.match(/^Verse \d|^Bridge|^Chorus|^Intro|^Outro/)) {
          grps[currentGroup.id].label = text;
          continue;
        }
        
        currentRow = getNewRow({
          grpId: currentGroup.id,
          pos: getPosBetween(currentRow, false),
          type: 'lyrics', 
          data: {
            text: text || '',
            chords: []
          },
        });
        rows[currentRow.id] = currentRow;
      }

      song.grps = grps;
          song.rows = rows;

          api_post('/songs', song)
            .then((song) => {
              song.flags.push("new");
              addToLocalCache("song", song).then(() => { 
                props.redirect('/song/' + song.id); 
              });
            })
            .catch(() => {});
    });
  }

  return (
    <div className={creating ? "wizard creating": "wizard"}>

      {
        step === 0
        ? (
          <div>
            <h2 className="mb-1">{t("Wizard.Headline1")}</h2>
            <h3 className="mb-3">{t("Wizard.Headline2")}</h3>
            <p>{t("Wizard.Description1")}</p>
            <p>{t("Wizard.Description2")}</p>

            
            <div className="new-song-language-holder mt-4">
              <div>
                <h4>
                  {t("Wizard.Language")}
                </h4>
              </div>
              <div className="new-song-language mt-2">
                <div className="language-button">
                  <div
                    className={
                      !language || language === "en"
                        ? "selected"
                        : ""
                    }
                    name="language"
                    value="en"
                    onClick={() => { setLanguage("en"); }}
                  >
                    <span>English</span>
                  </div>
                  <div
                    className={language === "sv" ? "selected" : ""}
                    name="language"
                    value="sv"
                    onClick={() => { 
                      // setLanguage("sv"); 
                    }}
                  >
                    <span>Svenska</span>
                  </div>
                </div>
              </div>
            </div>
            

            <div>
              <button className="highlighted mt-3" onClick={() => {
                setStep(1);
              }}>{t("Wizard.StartButton")}</button>
            </div>
          </div>
        ): null
      }

      {step === 1 ? <h2 className="mb-3">{t("Wizard.Step1Headline")}</h2> : null}

        {
          step === 1
          ? (
            <div className="topics mt-4 mb-4">
            {config.map((resp, i) => {
                return (
                  <div key={i} onClick={() => {
                    setMainTopic(resp);
                    setSubTopic(false);
                    setStep(2);
                  }}>{resp.title} {resp.emoji}</div>
                );
              }
            )}
            </div>
          ) : null
        }


        {step > 1 && loadingTopics ? (<p>{t("Wizard.LoadingTopics")}</p>) : null}


        {step === 2 ? (
          <React.Fragment>
            <div className="emoji mb-2">{mainTopic.emoji}</div>
            <h4 className="mt-0 mb-2">{mainTopic.title}</h4>
            {
              typeof mainTopic.description !== 'undefined' && mainTopic.description
              ? (<p>{mainTopic.description}</p>) 
              : null
            }
          </React.Fragment>
        ) : null}

        {
          step === 2
          ? (
            <div className="topics mt-4 mb-4">
            {
            mainTopic.topics.map((resp, i) => {
              return (
                <div key={i} onClick={() => {
                  setSubTopic(resp);
                  setStep(3);
                }}>{resp.title}</div>
              );
            })
            }
            <div onClick={() => {
                  setSubTopic(false);
                  setStep(3);
                }}>{t("Wizard.OtherTopic")}</div>
            </div>
          ) : null
        }
      
      
      {step === 3 ? (
        <React.Fragment>
          <div className="emoji mb-2">{mainTopic.emoji}</div>
          <h4 className="mt-0 mb-2">{mainTopic.title}</h4>
          <h2 className="mt-0 mb-2">{subTopic.title}</h2>
        </React.Fragment>
      ) : null}

      {
        step === 3
        ? (
          <div className="form mt-3">
            <div className="form-group text-center">
              <label>{t("Wizard.Step3DescriptionLabel")}</label>
              <textarea 
                onChange={(e) => {
                  if (!creating) {
                    setDescription(e.target.value);
                  }
                }} 
                disabled={creating ? true : false} 
                value={description} />
            </div>
            <div className="form-group mt-4 text-center">
              <button className="highlighted" onClick={submit} disabled={creating ? true : false}>{t("Wizard.Step3CreateButton")}</button>
            </div>
          </div>
        ) 
        : null
      }

      {
        step > 0
        ? (
          <div className="mt-3">
            <span className="back" onClick={() => {
              if (!creating) {
                setStep(step-1);
              }
            }}>&lt; {t("Wizard.Back")}</span>
          </div>
        )
        : null
      }

    </div>
  );
}