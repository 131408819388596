import React from 'react'

export const Loading = (props) => {
    let text = props.text || '';
    return (
        <div className="loading">
            <div className="ripple"><div></div><div></div></div>
            {text.length ? <div><span>{text}</span></div> : ''}
        </div>
    );
}