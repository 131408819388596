import React from 'react';
import { useTranslation } from 'react-i18next';

export function DisplayArtist(props) {
  const { t } = useTranslation();

  let MainArtist = [];
  let FeaturedArtist = [];

  for (let data of Object.values(props.meta)) {
    if (data.role === 'MainArtist') {
      if (!MainArtist.includes(data.fullname)) {
        MainArtist.push(data.fullname);
      }
    }
  }
  for (let data of Object.values(props.meta)) {
    if (data.role === 'FeaturedArtist') {
      if (!FeaturedArtist.includes(data.fullname)) {
        FeaturedArtist.push(data.fullname);
      }
    }
  }

  if (MainArtist.length) {
    return (
      <li>
        <span>{t("Contributers.MainArtistIs")} </span>
        {MainArtist.map((fullname, index) => {
          if (index > 0) {
            fullname = ', ' + fullname;
          }
          return <span key={index}>{fullname}</span>;
        })}
      </li>
    );
  } else {
    return '';
  }
}
export function DisplayLyricist(props) {
  const { t } = useTranslation();
  let Lyricist = [];
  for (let data of Object.values(props.meta)) {
    if (data.role === 'Lyricist') {
      if (!Lyricist.includes(data.fullname)) {
        Lyricist.push(data.fullname);
      }
    }
  }

  if (Lyricist.length) {
    return (
      <li>
        <span>{t("Contributers.LyricistIs")} </span>
        {Lyricist.map((fullname, index) => {
          if (index > 0) {
            fullname = ', ' + fullname;
          }
          return <span key={index}>{fullname}</span>;
        })}
      </li>
    );
  } else {
    return '';
  }
}
export function DisplayComposer(props) {
  const { t } = useTranslation();
  let Composer = [];
  for (let data of Object.values(props.meta)) {
    if (data.role === 'Composer') {
      if (!Composer.includes(data.fullname)) {
        Composer.push(data.fullname);
      }
    }
  }

  if (Composer.length) {
    return (
      <li>
        <span>{t("Contributers.ComposerIs")} </span>
        {Composer.map((fullname, index) => {
          if (index > 0) {
            fullname = ', ' + fullname;
          }
          return <span key={index}>{fullname}</span>;
        })}
      </li>
    );
  } else {
    return '';
  }
}
export function DisplayComposerLyricist(props) {
  const { t } = useTranslation();
  let ComposerLyricist = [];
  for (let data of Object.values(props.meta)) {
    if (data.role === 'ComposerLyricist') {
      if (!ComposerLyricist.includes(data.fullname)) {
        ComposerLyricist.push(data.fullname);
      }
    }
  }

  if (ComposerLyricist.length) {
    return (
      <li>
        <span>{t("Contributers.ComposerLyricistIs")} </span>
        {ComposerLyricist.map((fullname, index) => {
          if (index > 0) {
            fullname = ', ' + fullname;
          }
          return <span key={index}>{fullname}</span>;
        })}
      </li>
    );
  } else {
    return '';
  }
}
