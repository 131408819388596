import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { LanguageDropdown } from './LanguageDropdown';
import { isMobile, isTablet } from '../../../../lib/deviceControl.js';

export const WordBrowser = (props) => {

  const containerRef = useRef();
  const initialized = useRef(false);
  let [expanded, setExpanded] = useState(props.expanded || false);
  let [style, setStyle] = useState([]);


  useEffect(() => {

    if (initialized.current) return; 
    initialized.current = true;

    if (!props.words.length) {
      return;
    }
    
    if (isMobile() || isTablet()) {
      placeForMobile();
    } else {

      if (props.searchType === 'alt') {
        placeAtFirstMarkedWord();
        adjustIfOverlappingRight();
      } else {
        placeToTheFarRight();
      }
      
    }
    

  }, []); // eslint-disable-line

  const placeForMobile = () => {
    this.setState({style: { left: -25, right: -25, width: "auto", minWidth: 0 }});
  }

  const placeAtFirstMarkedWord = () => {
    let leftPos = 0;

    let rowEl = document.getElementById("l-" + props.rowid);
    if (typeof rowEl.children[0] !== 'undefined') { // under same row
      let wordEl = ReactDOM.findDOMNode(rowEl.children[0]);
      leftPos = wordEl.offsetLeft;
    } else {
      let parentEl = ReactDOM.findDOMNode(document.getElementById('document-area'));
      let parentPos = parentEl.getBoundingClientRect();
      leftPos = parentPos.width-450;
    }

    setStyle({ left: leftPos });
  }

  const placeToTheFarRight = () => {
    setStyle({left: "auto", right: 0});
  }

  const adjustIfOverlappingRight = () => {
    let containerRect = ReactDOM.findDOMNode(containerRef.current).getBoundingClientRect();
    if (containerRect.right > window.innerWidth) {
      placeToTheFarRight();
    }
  }

  const changeLanguage = (code) => {
    props.updateLanguage(code);
    props.setLanguage(code);
    setTimeout(() => {
      props.search();
    }, 100);
  }
  
  return (
    <div
      ref={containerRef}
      style={style}
      id="word-browser"
      className={
        'word-browser'
        + (props.loading ? ' wb-loading' : '')
        + (expanded ? ' wb-expanded' : ' wb-preview')
      }
    >
      <span className="word-close" onClick={props.close}>
        <i className="fas fa-times" />
      </span>

      <LanguageDropdown language={props.language} changeLanguage={changeLanguage} />

      <span className="word-label" onClick={() => {
        if (!expanded) {
          setExpanded(true);
          props.search();
        }
      }}>
        <React.Fragment>
          <i className="material-icons-outlined">auto_fix_high</i><span>(<code>Shift</code>+<code>↓</code>)</span>
        </React.Fragment>
        
      </span>

      {expanded ? props.children : null}

    </div>
  );
}
