import React from 'react'
import { Translation } from "react-i18next";
import { isID } from "../../lib/utils";
import contributerTypes from "../../config/contributerTypes";

export class MetaEditor extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            dropdownOpen: false,
            contributerType: '',
            fullname: '',
        }
        this.template = {
          ref: '',
          fullname : '',
          type : '',
          role : '',
        }
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.updateFullname = this.updateFullname.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        this.dropdownRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleClickOutside(e) {
      if (this.state.dropdownOpen && this.dropdownRef && !this.dropdownRef.contains(e.target)) {
        this.setState({ dropdownOpen: false}); // 
      }
    }
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside, false);
      if (isID(this.props.metaId) && typeof this.props.meta[this.props.metaId] != 'undefined') {
        this.setState({
            contributerType: this.props.meta[this.props.metaId].type + '_' + this.props.meta[this.props.metaId].role,
            fullname: this.props.meta[this.props.metaId].fullname,
        });
      }
    }
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    save() {
      if (!this.state.contributerType) {
        alert("Please select type");
        return;
      }
      if (!this.state.fullname) {
        alert("Please enter name");
        return;
      }
      let contributerType = this.state.contributerType.split('_');
      let metaData = this.template;
      metaData.type = contributerType[0];
      metaData.role = contributerType[1];
      metaData.fullname = this.state.fullname;

      this.props.save(this.props.metaId, metaData);
    }

    remove() {
      this.props.remove(this.props.metaId);
    }

    updateFullname(e) {
      this.setState({fullname: e.target.value})
    }

    handleKeyUp(e) {
      if (e.key === 'Enter') {
        this.save();
      } 
    }

    render() {
        return (
          <Translation>
        {(t) => (
          <div>
            
              <div className="dropdown">
                <label onClick={() => { 
                    this.setState({dropdownOpen: !this.state.dropdownOpen});
                }}>{this.state.contributerType!=='' ? t("Contributers." + this.state.contributerType) : t("Contributers.TypeOfContributer")}
                </label>
                {
                  this.state.dropdownOpen
                  ? <div><ul ref={(node) => { this.dropdownRef = node }}>
                    {
                      Object.keys(contributerTypes).map((type) => {
                      return (
                        <li key={type} className={
                          this.state.contributerType===type
                          ? 'active'
                          : ''
                        } onClick={() => {
                            this.setState({contributerType: type, dropdownOpen: false});
                          }
                        }><span>{t("Contributers." + type)}</span></li>
                      );
                      })
                    }
                  </ul>
                  </div>
                  : ''
                }
              </div>

              <input 
                type="text" 
                onChange={this.updateFullname} 
                onKeyUp={this.handleKeyUp} 
                value={this.state.fullname} 
                placeholder={t("Contributers.FullName")} 
                spellCheck="false"
              />
              
              <div className="btn-group">
              <button onClick={this.save}>{t("Buttons.Save")}</button>
                <button onClick={this.props.cancel}>{t("Buttons.Cancel")}</button>
                <button onClick={this.remove} className="ml-auto"><i className="far fa-trash-alt"></i></button>
              </div>
              
          </div>
        )}
        </Translation>
      )
    }
    
}