const textareaAutoHeight = (event) => {
    let target = event.target || event.srcElement || event;
    if (!target || target.type !== 'textarea') {
        return;
    }
    let style = getComputedStyle(target || event);
    let lineHeight = style.getPropertyValue('line-height');
    let paddingTop = style.getPropertyValue('padding-top');
    let paddingBottom = style.getPropertyValue('padding-bottom');
    let borderWidth = style.getPropertyValue('border-width');
    let minHeight = parseInt(lineHeight)*2 || 0;
    let scrollHeight = target.scrollHeight;
    if (scrollHeight > minHeight) {
        target.style.height = "auto";
        target.style.height = (
            parseInt(target.scrollHeight)
            + (parseInt(borderWidth) * 2)
            ) + "px";
    } else {
        target.style.height = "auto";
        target.style.height = (
            parseInt(minHeight)
            + parseInt(paddingTop)
            + parseInt(paddingBottom)
            + (parseInt(borderWidth) * 2)
            ) + "px";
    }
}
export default textareaAutoHeight;