import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {newID} from "../../lib/utils";
import {getLocalCache, addToLocalCache,removeFromLocalCache} from "../Storage";
//import { handle_api_error, api_post, api_patch } from '../../API';

import { getApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from "firebase/firestore";

import UserContext from '../../UserContext';
import ViewListContext from '../../ViewListContext';

export const FolderModal = (props) => {

  const userContext = useContext(UserContext);
  const listContext = useContext(ViewListContext);
  const { t } = useTranslation();

  let inputRef = null;

  let [title, setTitle] = useState(props.title || t("NewFolder.DefaultFolderTitle"));
  let [folder, setFolder] = useState(false);
  let [loading, setLoading] = useState(false);

  useEffect(() => {

    if (props.folderId) {
      getLocalCache()
      .then(docs => {
        if (typeof docs["folder-" + props.folderId] !== 'undefined') {
          setTitle(docs["folder-" + props.folderId].title);
          setFolder(docs["folder-" + props.folderId]);
        }
      });
    }

    inputRef.focus();
  }, []); // eslint-disable-line

  const create = () => {

    if (!title || loading) {
      return;
    }

    setLoading(true);

    let uid = userContext.user.uid;
    let id = '';
    let data = {};
    if (folder && folder.id) {
      id = folder.id;
      data = folder;
      data.title = title;
    } else {
      id = newID(); // Create random
      data = { id: id, title: title, parent: "", type: "folder" };
    }
    
    //console.log(data);

    const firebase = getApp();

    if (uid && id) {
      try {
          addToLocalCache("folder", data)
            .then(() => {
              listContext.updateListItems();
            });
          const firestore = getFirestore(firebase);
          let ref = doc(firestore, "users/"+uid+ "/docs/folder-" + id);
          setDoc(ref, data)
            .then(() => {
              props.closeModal();
            })
            .catch(() => { 
              if (!folder) { // We have tried to create a new
                removeFromLocalCache("folder", data);
              }
              setLoading(false);
            });
      } catch(err) { 
        setLoading(false);
      }
    }
  }

  return (
    <div className="modal-create-new">

      <div>
        <input
          ref={(node) => {
            inputRef = node;
          }}
          type="text"
          placeholder={t("NewFolder.FolderTitle")}
          name="title"
          value={title}
          onChange={(event) => { 
            if (event.key === 'Enter') {
              return;
            } 
            setTitle(event.target.value); 
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              return;
            } 
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              create();
              return;
            } 
          }}
          spellCheck="false"
        />
      </div>

      <div className="mb-3">
        <p>{t("ListToolbar.FolderDescription")}</p>
      </div>

      <div>
        <button
          className="highlighted"
          onClick={() => {
            create();
          }}
        >
          {
            folder && folder.id 
            ?  t("NewFolder.SaveFolderButton")
            : t("NewFolder.CreateNewFolderButton")
          }
        </button>
      </div>

    </div>
  );
}