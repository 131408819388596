import React, { useState } from "react";
import { Modal } from "../Modal";
import { Translation } from "react-i18next";
//import { Link } from 'react-router-dom';

export const ToolbarHelp = (props) => {
  let [watchVideo, setWatchVideo] = useState(false);

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <ul>
            <li>
              <span
                onClick={() => {
                  setWatchVideo("k9n_gwjQU7w");
                }}
              >
                <i className="fab fa-youtube"></i> {t("Help.HowToWriteLyrics")}
              </span>
            </li>
            <li>
              <span
                onClick={() => {
                  setWatchVideo("c8nZYUEmnsA");
                }}
              >
                <i className="fab fa-youtube"></i> {t("Help.HowToAddChordsToLyrics")}
              </span>
            </li>
            <li className="support-channels-row">
              <span>

                  <a href="https://www.facebook.com/songwriterstools" target="_blank" rel="noreferrer"><i class="fab fa-facebook"></i></a>

                  <a href="https://m.me/johan.lingvall" target="_blank" rel="noreferrer"><i class="fab fa-facebook-messenger"></i></a>
                  
                  <a href="https://twitter.com/SongwritersT" target="_blank" rel="noreferrer"><i class="fab fa-twitter"></i></a>
                  
                  <a href="mailto:hello@songwriterstools.com"><i class="far fa-envelope"></i></a>
                  
              </span>
            </li>
          </ul>
          {watchVideo ? (
            <Modal
              close={() => {
                setWatchVideo(false);
              }}
              className="large"
            >
              <div className="video-wrapper">
                <iframe
                  title="Demo"
                  width="560"
                  height="315"
                  src={"https://www.youtube.com/embed/" + watchVideo}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Modal>
          ) : (
            ""
          )}
        </React.Fragment>
      )}
    </Translation>
  );
};
