import React from 'react';
import ReactDOM from 'react-dom';
import { Translation } from 'react-i18next';
import { removeLinebreaks } from './../../../lib/removeLinebreaks';
import { getCursorOffsetInElement } from './../../../lib/getCursorOffsetInElement';
import { getSelecedText } from './../../../lib/getSelecedText';
import { LyricsInput } from './LyricsInput';
import { focusElementAtPosition } from './../../../lib/focusElementAtPosition';
import { addTagInTextNode } from './../../../lib/addTagInTextNode';
import { getRowByIndex } from './../../../lib/utils';
import { stripTags } from '../../../lib/stripTags';
import { AltWord } from './suggestions/AltWord';
import { RowCompletion } from './suggestions/RowCompletion';

export class Lyrics extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showAltWordOn: false,
      wordSelectedLeft: false,
      wordBrowserAutoSearch: false,
      showAltEndingIcon: false,
      showAltEndingOn: false,
      showPlacehoder: false,
      focused: false,
    };

    this.updateTimer = null;
    this.wordBrowserTimer = null;
    this.clicked = false;

    // this.containerRef = React.createRef();
    this.editorRef = null;
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.insertWord = this.insertWord.bind(this);
    this.replaceWord = this.replaceWord.bind(this);
    this.showAltSuggestions = this.showAltSuggestions.bind(this);
    this.clearWordBrowserTimer = this.clearWordBrowserTimer.bind(this);
    this.clearWordBrowser = this.clearWordBrowser.bind(this);
    this.closeWordBrowser = this.closeWordBrowser.bind(this);
    this.getHTML = this.getHTML.bind(this);
    this.getText = this.getText.bind(this);
    this.showRhymeSuggestions = this.showRhymeSuggestions.bind(this);
    this.showRhymeSuggestionTip = this.showRhymeSuggestionTip.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
    if (this.props.getIndex() === 0) {
      this.setState({showPlacehoder: true});
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside(e) {
    let wb = document.getElementById('word-browser');
    if (!wb) {
      return;
    }

    if (typeof this.containerRef === 'undefined') {
      return;
    }

    if (!this.containerRef.contains(e.target) && !wb.contains(e.target)) {
      // click outside
      this.setState({
        showAltWordOn: false,
        showAltEndingIcon: false,
        showAltEndingOn: false
      });
    }
  }

  insertWord(word, atPos) {
    let text = this.getText();
    if (atPos > text.length) {
      atPos = text.length;
    }
    let textBefore = text.substr(0, atPos);
    let spaceBefore = !textBefore.length || textBefore.substr(-1).match(/\s/) ? '' : ' ';
    let textAfter = text.substr(atPos);
    let spaceAfter = !textAfter.length || textAfter.substr(0, 1).match(/\s/) ? '' : ' ';

    let newText = textBefore + spaceBefore + word + spaceAfter + textAfter;
    this.props.updateRowData(this.props.row.id, { text: newText, chords: this.props.row.data.chords });
  }

  replaceWord(word, fromWord, fromPos) {
    let text = this.getText();
    let textBefore = text.substr(0, fromPos);
    let textAfter = text.substr(fromPos + fromWord.length);
    let newText = textBefore + word + textAfter;
    this.props.updateRowData(this.props.row.id, { text: newText, chords: this.props.row.data.chords });
    //this.closeWordBrowser();
  }

  showAltSuggestions(timeoutMS=0, autoSearch=false) {
    this.clearWordBrowserTimer();

    //let selectedText = getSelecedText();
    //if (selectedText && selectedText.length > 0) {
    //  return;
    //}

    this.clearWordBrowser()
      .then(() => {
        this.wordBrowserTimer = setTimeout(() => {
          let left = 0;
          let right = 0;

          //let cursorPos = getCursorOffsetInElement(this.editorRef.current);
          //this.editorRef.current.innerHTML = this.editorRef.current.innerText;
          //focusElementAtPosition(cursorPos, this.editorRef.current);
          let cursorPos = getCursorOffsetInElement(this.editorRef.current);

          if (cursorPos === 0) {
            return;
          }
          let text = this.getText();
          let word = '';
          let selectedText = getSelecedText();

          if (selectedText && selectedText.length > 0) {
            // There is a selection, just skip this
            return;
          } else {
            focusElementAtPosition(cursorPos, this.editorRef.current);

            if (typeof text === 'string' && text !== '' && cursorPos >= 0) {
              // There is text and the cursor is set

              // Get position of first letter (left) of the clicked word
              let textReversed = text.split('').reverse().join('');
              let charsToLeftSpace = textReversed.slice(textReversed.length - cursorPos).search(/[\s,.!?]/);
              left = charsToLeftSpace >= 0 ? cursorPos - charsToLeftSpace : 0;

              // Get position of last character (right) of the clicked word
              let charsToRightSpace = text.slice(cursorPos).search(/[\s,.!?]/);
              right = charsToRightSpace >= 0 ? cursorPos + charsToRightSpace : text.length;

              // Get the word
              word = charsToRightSpace < 0 ? text.slice(left) : text.slice(left, charsToRightSpace + cursorPos);
            }

            if (typeof this.editorRef.current != 'undefined' && this.editorRef.current) {
              if (typeof this.editorRef.current.firstChild != 'undefined' && this.editorRef.current.firstChild) {
                let textNode = this.editorRef.current.firstChild;
                addTagInTextNode('EM', {}, textNode, left, right);
              }
            }
          }

          // remove all non alpha characters of the word
          word = word.replace(/[^\w\såäö]/gi, '');

          if (word === this.state.showAltWordOn) {
            this.clearWordBrowser(cursorPos);
          } else {
            this.setState(
              {
                showAltEndingIcon: false,
                showAltEndingOn: false,
                showAltWordOn: word,
                wordBrowserAutoSearch: autoSearch,
                wordSelectedLeft: left
              },
              () => {
                focusElementAtPosition(cursorPos, this.editorRef.current);
              }
            );
          }
        }, timeoutMS);
      })
      .catch(() => {});
  }

  clearWordBrowserTimer() {
    clearTimeout(this.wordBrowserTimer);
  }
  clearWordBrowser(skip = false) {
    return new Promise((resolve, reject) => {
      if (skip === true) {
        resolve();
      }
      if (this.state.showAltWordOn !== false || this.state.showRhymes !== false || this.state.showAltEndingIcon !== false) {
        this.setState({ showAltWordOn: false, showAltEndingOn: false, showAltEndingIcon: false }, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  closeWordBrowser() {
    this.clearWordBrowser();
  }

  getHTML() {
    let currentText =
      (this.editorRef && this.editorRef.current && typeof this.editorRef.current != 'undefined') ? this.editorRef.current.innerHTML : '';
    currentText = removeLinebreaks(currentText.replace(/(&[a-z]{2,4};)/g, ' '));
    return currentText;
  }
  getText() {
    let currentText = (
      this.editorRef 
      && this.editorRef.current 
      && typeof this.editorRef.current != 'undefined') ? this.editorRef.current.innerHTML : '';
    return removeLinebreaks(stripTags(currentText));
  }

  showRhymeSuggestions() {

    if (this.props.row.index > 0) {
      let previosRow = getRowByIndex(this.props.row.index - 1, this.props.getDocArray());
      let words = previosRow.data.text.trim().split(' ');
      if (words.length) {
        let lastWord = words[words.length - 1];
        lastWord = lastWord.replace(/[^\w\såäö]/gi, '');
        this.clearWordBrowser().then(() => {
          this.setState({
            showAltEndingIcon: false,
            showAltEndingOn: [ lastWord ]
          }, () => {
            let prevRowRef = this.props.getRowRef(previosRow.id);
            let textNode = prevRowRef.current.firstChild;
            let text = prevRowRef.current.firstChild.textContent.trim();

            // Use last position as right
            let right = text.length;

            // Get position of first letter (left) of the clicked word
            let textReversed = text.split('').reverse().join('');
            let charsToLeftSpace = textReversed.slice(textReversed.length - right).search(/[\s,.!?]/);
            let left = charsToLeftSpace >= 0 ? right - charsToLeftSpace : 0;

            addTagInTextNode('SPAN', {className: 'rhyme-base'}, textNode, left, right);

            let currentTextLength = (this.editorRef.current.firstChild) ? this.editorRef.current.firstChild.length : 0;
            focusElementAtPosition(currentTextLength, this.editorRef.current);
          });
        });
      }
    }
  }
  showRhymeSuggestionTip() {
    if (this.editorRef && this.editorRef.current && this.props.row.index > 0) {
      let text = this.getText();
      let textNode = this.editorRef.current.firstChild;
      addTagInTextNode('SPAN', {}, textNode, 0, text.length);
      focusElementAtPosition(text.length, this.editorRef.current);
      let rowParent = ReactDOM.findDOMNode(document.getElementById("l-"+this.props.row.id).children[0]);
      let tagLeftPos = rowParent.offsetLeft + rowParent.getBoundingClientRect().width + 20;
      this.setState({ showAltEndingIcon: { left: tagLeftPos + 'px' } });
    }
  }
/*
bottom: 350.28125
height: 22.5
left: 572.5
right: 1360.5
top: 327.78125
width: 788
x: 572.5
y: 327.78125
*/
  render() {
    return this.props.editActive && !this.props.row.lyricsReadonly && !this.props.rowReadonly ? (
      <Translation>
        {(t) => (
          <div
            className="lyrics-outer"
            ref={(node) => {
              this.containerRef = node;
            }}
            onMouseEnter={() => { 
              if (this.props.getIndex() === 0) {
                this.setState({showPlacehoder: false});
              }
            }}
            onMouseLeave={() => {
              if (this.props.getIndex() === 0) {
                this.setState({showPlacehoder: true});
              }
            }}
          >
            {
              this.props.getIndex() === 0 
                && this.getText() === '' 
                && this.props.row.data.text === '' 
                && this.state.showPlacehoder 
                && !this.state.focused
              ? (<span className="placeholder">{t("Lyrics.FirstRowPlaceholder")}</span>)
              : null
            }
            <LyricsInput
              forceRender={this.props.forceRender}
              editActive={this.props.editActive}
              row={this.props.row}
              text={this.props.row.data.text}
              rowid={this.props.row.id}
              getIndex={this.props.getIndex}
              getIndexInGroup={this.props.getIndexInGroup}
              handlePastedText={this.props.handlePastedText}
              handleFocusRow={(rowId) => {
                this.setState({focused: true});
                this.props.handleFocusRow(rowId);
              }}
              handleBlurRow={(rowId) => {
                this.setState({focused: false});
                this.props.handleBlurRow(rowId);
              }}
              handleRowChange={this.props.handleRowChange}
              updateRowData={this.props.updateRowData}
              clearWordBrowserTimer={this.clearWordBrowserTimer}
              clearWordBrowser={this.clearWordBrowser}
              handleMergeTextWithNextRow={this.props.handleMergeTextWithNextRow}
              handleRowToGroup={this.props.handleRowToGroup}
              handlePressEnter={this.props.handlePressEnter}
              handlePressBackspace={this.props.handlePressBackspace}
              showAltSuggestions={this.showAltSuggestions}
              showRhymeSuggestions={this.showRhymeSuggestions}
              showRhymeSuggestionTip={this.showRhymeSuggestionTip}
              jumpToRow={this.props.jumpToRow}
              jumpUp={this.props.jumpUp}
              jumpDown={this.props.jumpDown}
              setRowRef={(rowId, editorRef) => {
                this.props.setRowRef(rowId, editorRef);
                this.editorRef = editorRef;
              }}
              showAltWordOn={this.state.showAltWordOn}
            />

            {this.state.showAltWordOn ? (
                <AltWord 
                  insertWord={this.insertWord}
                  replaceWord={this.replaceWord}
                  cursorPosition={this.state.wordSelectedLeft}

                  rowid={this.props.row.id}
                  words={[this.state.showAltWordOn]}
                  autoSearch={this.state.wordBrowserAutoSearch}
                  
                  close={this.closeWordBrowser}
                  language={this.props.language || ''}
                  updateLanguage={this.props.updateLanguage}
                />
            ) : (
              ''
            )}

            {this.state.showAltEndingIcon ? (
              <span className="rhyme-tag" style={this.state.showAltEndingIcon} onClick={this.showRhymeSuggestions}>
                <i className="material-icons-outlined">auto_fix_high</i><span>(<code>Shift</code>+<code>→</code>)</span>
              </span>
            ) : (
              ''
            )}

            {this.state.showAltEndingOn !== false && this.state.showAltEndingOn.length > 0 ? (
              
              <RowCompletion
                insertWord={this.insertWord}
                replaceWord={this.replaceWord}
                cursorPosition={this.props.row.data.text.length}
                
                rowid={this.props.row.id}
                words={this.state.showAltEndingOn}
                autoSearch={true}

                close={this.closeWordBrowser}
                language={this.props.language || ''}
                updateLanguage={this.props.updateLanguage}
                
              />
            ) : (
              ''
            )}
          </div>
        )}
      </Translation>
    ) : (
      <div className="lyrics-outer"><div ref={this.editorRef}>{this.props.row.data.text}</div></div>
    );
  }
}
