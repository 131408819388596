import React, {useState} from 'react';
import { Translation } from 'react-i18next';
import {Dropdown} from '../../../Dropdown'

const wordsVisibleDefault = 15;

export const WordList = ({words, label, searchType, wordInLyrics, insertWord, replaceWord}) => {

  let [showAll, setShowAll] = useState(false);

  if (words && words.length) {
    return (
      <div className="word-list">
        <h3>{label}</h3>
        {words.map((result, wordindex) => {
          if (wordindex < wordsVisibleDefault || showAll) {
            return (
              <Word 
                key={wordindex} 
                searchType={searchType}
                word={result.word || result.rhyme} 
                wordInLyrics={wordInLyrics} 
                insertWord={insertWord} 
                replaceWord={replaceWord} 
                />
            );
          } else {
            return null;
          }
        })}

        {words.length > wordsVisibleDefault && !showAll ? (
          <div className="show-more" onClick={() =>{ setShowAll(true); }}>
            <span>+{words.length - wordsVisibleDefault}</span>
          </div>
        ) : null}

      </div>
    );
  } else {
    return null;
  }
}

export const Word = (props) => {

  let [closeDropdownCounter, closeDropdownHandler] = useState(0);
  const closeDropdown = () => { closeDropdownHandler(closeDropdownCounter+1); }

  const copyToClipboard = (event) => {
    let wordEl = event.target.parentNode.firstChild.firstChild;
    let range = document.createRange();
    range.setStart(wordEl, 0);
    range.setEnd(wordEl, wordEl.length);
    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
  }

  return (
    <Translation>
      {(t) => (
        <div key={props.wordindex} data-word={props.word}>
          <label onClick={(e) => {
            if (props.searchType === 'rhymes') {
              props.insertWord(e);
            } else {
              props.replaceWord(e);
            }
          }}>{props.word}</label>
            <Dropdown
              closeDropdown={closeDropdownCounter}
              className="ellipsis"
              label={<i className="fas fa-ellipsis-v" />}
              >
              <ul className="dropdown-list" data-word={props.word}>
                <li onClick={(e) => { 
                  props.insertWord(e);
                  closeDropdown();
                }}><span><i className="fas fa-level-up-alt" />{t("WordBrowser.insert")}</span></li>
                <li onClick={(e) => { 
                  if (props.wordInLyrics !== '') {
                    props.replaceWord(e);
                    closeDropdown();
                  }
                }} className={props.wordInLyrics === '' ? 'inactive' : ''}><span><i className="fas fa-exchange-alt" />{t("WordBrowser.replace")}</span></li>
                <li onClick={copyToClipboard}><span><i className="far fa-copy" />{t("WordBrowser.copy")}</span></li>
              </ul>
              
            </Dropdown>
        </div>
      )}
    </Translation>
  );
}
