import { api_get, api_post } from "../API.js";
import { newSongModel, getPosBetween, getNewGroup, getNewRow, newID } from "./utils.js";
import { addToLocalCache } from '../components/Storage.js';
import { removeLinebreaks } from './removeLinebreaks.js';
import { stripTags } from './stripTags.js';

export const createSongByMusixmatchId = (track, user) => {
    return new Promise((resolve, reject) => {
        
        api_get("/music/lyrics?id=" + encodeURI(track.track_id))
        .then((resp) => {
          
          let team = {};
          team[user.uid] = {read:true, write:true, displayName: user.displayName, photoURL:  user.photoURL};
          let song = newSongModel({
            title: track.track_name,
            owner: user.uid,
            team: team,
            flags: [ 'musixmatch' ],
            grps: {},
            rows: {},
            language: 'en',
            lyricsReadonly: true,
            imported: {
              source: 'musixmatch',
              track_id: track.track_id,
              lyrics_copyright: resp.lyrics_copyright,
              pixel_tracking_url: resp.pixel_tracking_url,
              explicit: track.explicit
            }
          });

          if (track.artist_name) {
            let metaId = newID();
            song.meta[metaId] = {
              ref: '',
              fullname : track.artist_name,
              type : 'Artist',
              role : 'MainArtist',
            }
          }

          let grps = {};
          let rows = {};
          let textArray = resp.lyrics_body.split("\n");
          let currentGroup = {};
          let currentRow = {};
          for (let x in textArray) {

            let pasteText = removeLinebreaks(stripTags(textArray[x]));
            x = parseInt(x);

            if (x === 0) { // first row, create new group
              currentGroup = getNewGroup({ pos: getPosBetween(currentGroup, false) });
              grps[currentGroup.id] = currentGroup;

            } else if (pasteText === '') { // extra row break, create new group
              currentGroup = getNewGroup({ pos: getPosBetween(currentGroup, false) });
              grps[currentGroup.id] = currentGroup;
              continue;
            }

            currentRow = getNewRow({
              grpId: currentGroup.id,
              pos: getPosBetween(currentRow, false),
              type: 'lyrics', 
              data: {
                text: pasteText || '',
                chords: []
              },
              lyricsReadonly: true
            });
            rows[currentRow.id] = currentRow;

          }

          song.grps = grps;
          song.rows = rows;

          api_post('/songs', song)
            .then((song) => {
              song.flags.push("new");
              addToLocalCache("song", song).then(() => { 
                  resolve(song);
              });
            })
            .catch(reject);
        })
        .catch(reject);

    })
}