import React from 'react';

export class HelpBubble extends React.Component {

  constructor(props) {
    super(props);
    this.state = { closed: false };
    this.hasCloseOnClickOutsideEvent = false;
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.closeOnClickOutside) {
      document.addEventListener('mousedown', this.handleClickOutside, false);
      this.hasCloseOnClickOutsideEvent = true;
    }
    if (this.props.arrowPos === 'middle') {
      this.className = ' apos-middle';
    } else if (this.props.arrowPos < 0) {
      this.className = 'apos-right' + this.props.arrowPos;
    } else {
      this.className = 'apos-' + (this.props.arrowPos || 20);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.closeOnClickOutside && !this.hasCloseOnClickOutsideEvent) {
      document.addEventListener('mousedown', this.handleClickOutside, false);
    }
  }
  
  componentWillUnmount() {
    if (this.props.closeOnClickOutside) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleClickOutside(e) {
    if (this.containerRef && !this.containerRef.contains(e.target)) {
      this.setState({ closed: true });
    }
  }
  
  render() {
    return this.props.visible && !this.state.closed ? (
      <div
        className={'help-bubble ' + this.className}
        ref={(node) => {
          this.containerRef = node;
        }}
        style={this.props.style || {}}
      >
        <div>
          <div>
            {this.props.label ? <label>{this.props.label}</label> : ''}
            <p>{this.props.html}</p>
          </div>
        </div>
      </div>
    ) : (
      null
    );
  }
}