import React, {useEffect, useState} from 'react'
import { api_get } from './API.js';
import { useParams } from 'react-router-dom'; // , withRouter

export const ViewAdmin = (props) => {

  let params = useParams();

    return ( 
      props.loggedIn
        ? (
        <div className="article-page">
            <div className="article-container">
                <div className="article">
                  {params.adminPage === 'users' && <Users {...props} />}
                </div>
            </div>
        </div>
        ) : null
    );
}

const Users = (props) => {

  let [users, setUsers] = useState([]);

  useEffect(() => {
    api_get("/admin/users")
      .then((resp) => {
        console.log(resp);
        setUsers(resp);
      })
  }, []);

  return (
    <div className="text-left">
      <h1 className="mb-4">New users</h1>
      <div>
        {
          users.map((user) => {
            return (
              <div key={users.uid} className="my-4 d-flex justify-content-between">
                <div>
                  <h3>{user.displayName}</h3>
                  <span className="d-block text-small">{user.email}</span>
                </div>
                <div className="text-right">
                  <span className="d-block text-small">{user.created}</span>
                  {
                    user.loginLatest !== user.created
                    ? <span className="d-block text-small">{user.loginLatest}</span> : null
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}