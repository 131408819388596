import React from 'react';
import { ChordContainer } from './ChordContainer';
import { stripTags } from '../../../lib/stripTags';

export class ChordsOnLyrics extends React.Component {

    constructor(props) {
      super();
      this.chordsString = false;
      this.text = false;
      this.transpose = false;
      this.chords = [];
    }

    shouldComponentUpdate(prevProps) {
      if (prevProps.text !== this.props.text) {
        return true;
      } else if (prevProps.transpose !== this.props.transpose) {
        return true;
      } else if (JSON.stringify(prevProps.chords) !== JSON.stringify(this.props.chords)) {
        return true;
      } else if (prevProps.chordEditorIsOpenAtPos !== this.props.chordEditorIsOpenAtPos) {
        return true;
      } else if (prevProps.rowindex !== this.props.rowindex) {
        //return true;
      }
      return false;
    }

    componentDidMount() {
      this.text = this.props.text;
      this.transpose = this.props.transpose;
    }

    componentDidUpdate() {
      this.text = this.props.text;
      this.transpose = this.props.transpose;
    }

  
	render() {
    let text = this.props.text;
    let plainText = stripTags(text);
    let letterArray = plainText.split('');

    let chords = {};
    for (let chord of this.props.chords) {
      if (chord.pos >= 0 && chord.symbol !== null && chord.symbol !== '') {
        chords[parseInt(chord.pos)] = chord.symbol;
      }
    }

		return (
      <div className="chords-row">
        {letterArray.map((letter, letterindex) => {
          return (
            <ChordContainer
              
              rowid={this.props.rowid}
              rowindex={this.props.rowindex}
              chords={this.props.chords}

              letterArray={letterArray}

              editActive={this.props.editActive && !this.props.rowReadonly} 
              songKey={this.props.songKey} 
              transpose={this.props.transpose} 

              chord={
                (typeof chords[letterindex] != 'undefined')
                ? chords[letterindex]
                : ''
              }
              letter={letter}
              letterindex={letterindex}
              key={letterindex}

              updateRowData={this.props.updateRowData}
              closeChordEditor={this.props.closeChordEditor}
              openChordEditorAt={this.props.openChordEditorAt}

              handleChordDroppedOnLyrics={this.props.handleChordDroppedOnLyrics}
              
              chordEditorIsOpen={
                (this.props.chordEditorIsOpenAtPos === letterindex)
                ? true
                : false
              }
            />
          );
        })}
      </div>
		);
	}
}
