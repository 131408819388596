import React, { useEffect, useState } from "react";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
//import UserContext from "../../UserContext";
import {addToLocalCache} from "../Storage";
import { handle_api_error, api_post, api_patch } from '../../API';

export const NewListModal = (props) => {
  //const context = useContext(UserContext);
  const { t } = useTranslation();

  let inputRef = null;

  let [title, setTitle] = useState(props.title || i18next.t("NewList.DefaultListTitle"));

  useEffect(() => {
    inputRef.focus();
  }, []); // eslint-disable-line

  const create = () => {
    if (!title) {
      return;
    }

    let saveData = { title: title, items: [] };

    if (props.listId && props.listId !== '') {
      api_patch('/lists/' + props.listId, saveData)
        .then((resp) => {
          addToLocalCache("list", resp)
            .then(() => {
              props.redirect("/songs/list/" + resp.id);
            });
        })
        .catch(handle_api_error);
    } else {
      api_post('/lists', saveData)
        .then((resp) => {
          addToLocalCache("list", resp)
            .then(() => {
              props.redirect("/songs/list/" + resp.id);
            });
        })
        .catch(handle_api_error);
    }
  }

  return (
    <div className="modal-create-new">

      <div>
        <input
          ref={(node) => {
            inputRef = node;
          }}
          type="text"
          placeholder={t("NewList.ListTitle")}
          name="title"
          value={title}
          onChange={(event) => { setTitle(event.target.value); }}
          spellCheck="false"
        />
      </div>

      <div className="mb-3">
        <p>{t("ListToolbar.PlaylistDescription")}</p>
      </div>

      <div>
        <button
          className="highlighted"
          onClick={() => {
            create();
          }}
        >
          {t("NewList.CreateNewListButton")}
        </button>
      </div>

    </div>
  );
}