import { cloneObject } from '../cloneObject.js';
import { getIndexOf, getPosBetween, getNewGroup, getNewRow } from '../utils.js';

export const handleGroupDuplicate = (attr) => {
  let { grpId, rows, grps, docArray, updateObject } = attr;

  let currentGroup = grps[grpId];
  let currentGroupIndex = getIndexOf(grpId, docArray);
  if (currentGroupIndex === false) {
    return { updateObject };
  }

  // Find next group
  let nextGroupIndex = currentGroupIndex + 1;
  let nextGroup = typeof docArray[nextGroupIndex] != 'undefined' ? docArray[nextGroupIndex] : false;

  // Find position of next pargraph
  let newGroupPosition = getPosBetween(currentGroup, nextGroup);

  // Create new group after current and before next
  let newGroupParams = currentGroup;
  newGroupParams.pos = newGroupPosition;
  let newGroup = getNewGroup(newGroupParams);
  grps[newGroup.id] = newGroup;
  updateObject.prev.grps[newGroup.id] = { id: newGroup.id, deleted: true };
  updateObject.next.grps[newGroup.id] = cloneObject(grps[newGroup.id]);

  // Move rows to new group
  for (let group of docArray) {
    if (group.id === currentGroup.id && typeof group.rows !== 'undefined' && group.rows.length) {
      for (let currentRow of group.rows) {
          updateObject.prev.rows[currentRow] = cloneObject(currentRow);

          let newRowParams = cloneObject(currentRow);
          newRowParams.grpId = newGroup.id;
          let newRow = getNewRow(newRowParams);
          rows[newRow.id] = newRow;

          updateObject.next.rows[newRow.id] = cloneObject(newRow);
      }
    }
  }

  return { updateObject };
}
