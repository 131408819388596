import { getApp } from 'firebase/app';
import { getFirestore, doc, updateDoc } from "firebase/firestore";

export const saveTranspose = ({uid, songId, transpose}) => {
    const firebase = getApp();
    if (uid && songId) {
        try {
            const firestore = getFirestore(firebase);
            let ref = doc(firestore, "users/"+uid+ "/docs/song-" + songId);
            updateDoc(ref, {transpose: transpose || 0});
        } catch(err) { }
    }

}