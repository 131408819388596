import React, { useEffect, useState } from "react";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { HelpBubble } from "./../HelpBubble";
import { newSongModel } from '../../lib/utils'; 
import { getSongsFromLocalCache, addToLocalCache } from '../Storage';
import { handle_api_error, api_post } from '../../API'; // api_get
//import UserContext from "../../UserContext";

export const NewSongModal = (props) => {
  //const context = useContext(UserContext);

  const { t } = useTranslation();

  let inputRef = null;
  let [typeBubble, setTypeBubble] = useState(false);
  let [languageInfoBubble, setLanguageInfoBubble] = useState(false);

  let [titleOfCopied, setTitleOfCopied] = useState("");
  let [title, setTitle] = useState(props.title || i18next.t("NewSong.DefaultSongTitle"));
  let [songType, setSongType] = useState(props.songType && props.songType !== '' ? props.songType : "lyrics");
  let [language, setLanguage] = useState(props.language || "");

  useEffect(() => {
    inputRef.focus();
    if (props.copyId) {
      getSongsFromLocalCache()
      .then(songs => {
        if (typeof songs[props.copyId] !== 'undefined') {
          setTitleOfCopied(songs[props.copyId].title);
          setTitle(songs[props.copyId].title + " " + i18next.t("NewSong.CopyOfInDuplicateTitle"));
        }
      });
    }
  }, []); // eslint-disable-line

  const createSong = () => {

    let songData = {};
    let apiUrl = '';

    if (props.copyId) {
      songData.title = title || i18next.t("NewSong.DefaultSongTitle");
      songData.language = language || i18next.language.substr(0, 2) || "en";
      apiUrl = "/songs/"+props.copyId+"/duplicate";
    } else {
      songData = newSongModel(
        {
          title: title || i18next.t("NewSong.DefaultSongTitle"),
          language: language || i18next.language.substr(0, 2) || "en",
        },
        { songType }
      );
      apiUrl = "/songs";
    }

    api_post(apiUrl, songData)
      .then((resp) => {
        resp.flags.push("new");
        addToLocalCache("song", resp).then(() => {
          props.redirect("/song/" + resp.id);
        });
      })
      .catch(handle_api_error);
  };

  //console.log(songType);

  return (
    <div className="modal-create-new">
      <div>
        <input
          ref={(node) => {
            inputRef = node;
          }}
          type="text"
          placeholder={t("NewSong.SongTitle")}
          name="title"
          value={title}
          onChange={(event) => { setTitle(event.target.value); }}
          spellCheck="false"
        />
      </div>

      {props.copyId ? (
        <div className="new-song-copy-of">
          <p>
            <span>{t("NewSong.CopyOf")}</span> {titleOfCopied}
          </p>
        </div>
      ) : (
        <React.Fragment>
          <div>
            <h2>
              {t("NewSong.SelectTypeStart")}
              <div className="help-mark">
                <i
                  className="far fa-question-circle "
                  onMouseEnter={() => {
                    setTypeBubble(true);
                  }}
                  onMouseLeave={() => {
                    setTypeBubble(false);
                  }}
                ></i>
                <HelpBubble
                  visible={typeBubble}
                  html={t("NewSong.SelectTypeStartDescription")}
                  arrowPos={20}
                  style={{ transform: "translateX(-25px)" }}
                />
              </div>
            </h2>
          </div>

          <div className="new-song-type">
            <div
              className={
                !songType || songType === "lyrics"
                  ? "selected"
                  : ""
              }
              name="songType"
              value="lyrics"
              onClick={() => { setSongType("lyrics"); }}
            >
              <h3>{t("NewSong.Lyrics")}</h3>
              <div>
              </div>
            </div>
            <div 
              className={songType === "chordbars" ? "selected" : ""}
              name="songType"
              value="chordbars"
              onClick={() => { setSongType("chordbars"); }}
            >
              <h3>{t("NewSong.BarsAndChords")}</h3>
              <div>
              </div>
            </div>
          </div>

          {songType === "lyrics" ? (
            <div className="new-song-language-holder">
              <div>
                <h2>
                  {t("NewSong.SelectLyricsLanguage")}
                  <div className="help-mark">
                    <i
                      className="far fa-question-circle "
                      onMouseEnter={() => {
                        setLanguageInfoBubble(true);
                      }}
                      onMouseLeave={() => {
                        setLanguageInfoBubble(false);
                      }}
                    ></i>
                    <HelpBubble
                      visible={languageInfoBubble}
                      html={t("NewSong.LyricsLanguageDescription")}
                      arrowPos={20}
                      style={{ transform: "translateX(-25px)" }}
                    />
                  </div>
                </h2>
              </div>
              <div className="new-song-language">
                <div className="language-button">
                  <div
                    className={
                      !language || language === "en"
                        ? "selected"
                        : ""
                    }
                    name="language"
                    value="en"
                    onClick={() => { setLanguage("en"); }}
                  >
                    <span>English</span>
                  </div>
                  <div
                    className={language === "sv" ? "selected" : ""}
                    name="language"
                    value="sv"
                    onClick={() => { setLanguage("sv"); }}
                  >
                    <span>Svenska</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      )}

      <div className="new-song-button-holder">
        <button
          className="highlighted"
          onClick={() => {
            createSong();
            /*
            if (props.copyId) {
              props.duplicateSong(props.copyId, true);
            } else {
              props.newSong(true);
            }
            */
          }}
        >
          {t("NewSong.CreateNewSongButton")}
        </button>
      </div>
    </div>
  );
};
