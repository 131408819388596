import React from 'react'; 
import { ChordEditor } from './../ChordEditor';
import { Chord } from './../Chord';
import { transposeChords } from '../../../lib/transposeChords';
import uniqid from 'uniqid';

export const getFragmentClass = (fragments) => {
  let childrenLength = typeof fragments === 'object' && Array.isArray(fragments) ? fragments.length : 0;
  return childrenLength ? 'c-' + childrenLength : '';
}

export const fragmentIsChord = (fragment) => {
  return typeof fragment.fragments === 'object' && Array.isArray(fragment.fragments) ? false : true;
}

export class BarFragment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      dragover: false,
      editChord: false,
      chord: props.fragment.chord || ''
    };
    this.openChordEditor = this.openChordEditor.bind(this);
    //this.closeChordEditor = this.closeChordEditor.bind(this);
    this.updateChord = this.updateChord.bind(this);
    this.devideFragment = this.devideFragment.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.fragmentId = uniqid();
  }
  componentDidMount() {
    let newState = {};

    if (fragmentIsChord(this.props.fragment) && this.props.fragment.chord !== this.state.chord) {
      this.setState({ chord: this.props.fragment.chord || '' });
    }

    if (this.props.chordEditorIsOpenAtPos === this.props.findex && !this.state.editChord) {
      newState.editChord = true;
    } else if (this.props.chordEditorIsOpenAtPos !== this.props.findex && this.state.editChord) {
      newState.editChord = false;
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }
  componentDidUpdate(prevProps) {
    let newState = {};

    //console.log("BarFragmentUpdate", this.props.findex, this.props.fragment.chord);

    if (fragmentIsChord(this.props.fragment) && this.props.fragment.chord !== this.state.chord && prevProps.barRowUpdated !== this.props.barRowUpdated) {
      //console.log("BarFragmentUpdate UPDATE", this.props.findex, this.props.fragment.chord);
      newState.chord = this.props.fragment.chord;
    }

    if (this.props.chordEditorIsOpenAtPos === this.props.findex && !this.state.editChord) {
      newState.editChord = true;
    } else if (this.props.chordEditorIsOpenAtPos !== this.props.findex && this.state.editChord) {
      newState.editChord = false;
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }
  openChordEditor(e) {
    e.stopPropagation();
    this.props.openChordEditorAt(0, this.props.rowid, this.props.findex);
  }
  updateChord(chord) {
    this.setState({ chord: chord }, () => {
      this.props.updateBar({barId: this.props.barId, findex: this.props.findex, content: { chord: chord }});
    });
  }
  devideFragment() {
    this.props.devideFragment({
      findex: this.props.findex,
      chord: this.state.chord,
      barId: this.props.barId,
      currentLength: this.props.currentLength
    });
  }
  

  handleMouseEnter() {
    //this.setState({hover: true});
  }
  handleMouseLeave() {
    //this.setState({hover: false});
  }

  render() {
    

    if (fragmentIsChord(this.props.fragment)) {

      let chordDisplayed = this.state.chord;
      if (this.props.transpose !== 0 && typeof this.props.transpose === 'number' && chordDisplayed) {
        chordDisplayed = transposeChords(chordDisplayed, this.props.transpose, this.props.songKey);
      }


      return (
        <div 
          className={"bf " + (this.state.hover ? 'c-2' : '')} 
          onMouseEnter={this.handleMouseEnter} 
          onMouseLeave={this.handleMouseLeave}
          >
          
          <div className="bf">
            <span
              className={this.state.dragover ? "dragover" : ""}
              onDragEnter={(e) => {
                this.setState({dragover: true});
              }}
              onDragLeave={(e) => {
                this.setState({dragover: false});
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => {
                this.props.handleChordDroppedOnBar(e, this.props.rowid, this.props.findex);
                this.setState({dragover: false});
              }}>
              <Chord 
                chord={chordDisplayed || ''} 
                onClick={this.openChordEditor} 
                id={this.fragmentId} 
                rowid={this.props.rowid} 
                findex={this.props.findex} 
                letterindex={false} 
                editing={this.state.editChord && this.props.editActive ? true : false}
                />
            </span>
          </div>

          {this.state.editChord && this.props.editActive ? (
            <ChordEditor
              parentId={this.fragmentId}
              chord={this.state.chord}
              jumpto={(goto) => {  
                this.props.openChordEditorAt(goto, this.props.rowid, this.props.findex);
              }}
              close={this.props.closeChordEditor}
              updateChord={this.updateChord}
            />
          ) : (
            ''
          )}

        </div>
      );

    } else if (Array.isArray(this.props.fragment.fragments)) {

      return (
        <div className={"bf " + getFragmentClass(this.props.fragment.fragments)}>
          {
          this.props.fragment.fragments.map((subFragment, index) => {
            if (typeof subFragment.chord === 'undefined' && typeof subFragment.fragments === 'undefined' && typeof subFragment.fragments[0] === 'undefined') {
              subFragment = { chord: '' };
            }
            return (
                <BarFragment
                  rowid={this.props.rowid} 
                  updateBar={this.props.updateBar}
                  devideFragment={this.props.devideFragment} 
                  fragment={subFragment}
                  chordsUpdated={this.props.chordsUpdated}
                  barId={this.props.barId}
                  findex={this.props.findex + '.' + index}
                  depth={this.props.depth+1}
                  currentLength={this.props.fragment.fragments.length}
                  editActive={this.props.editActive}
                  songKey={this.props.songKey}
                  transpose={this.props.transpose}
                  barRowUpdated={this.props.barRowUpdated}
                  chordEditorIsOpenAtPos={this.props.chordEditorIsOpenAtPos}
                  openChordEditorAt={this.props.openChordEditorAt}
                  closeChordEditor={this.props.closeChordEditor}
                  handleChordDroppedOnBar={this.props.handleChordDroppedOnBar}
                  key={index}
                />
            );
          })
        }
      </div>
      );

    }
  }
}
