import React from 'react';
import { getCursorOffsetInElement } from '../../lib/getCursorOffsetInElement';
import { focusElementAtPosition } from '../../lib/focusElementAtPosition';
import { stripTags } from '../../lib/stripTags';

export class GroupLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { labelActivated: false };
    this.updateTimer = null;
    this.lastEmittedText = '';
    this.cursorStartPos = false;
    this.editorRef = React.createRef();
    this.activateEdit = this.activateEdit.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.emitChanges = this.emitChanges.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
  }

  activateEdit() {
    this.setState({ labelActivated: true }, () => {
      this.editorRef.current.focus();
    });
  }

  componentDidMount() {
    this.lastEmittedText = this.props.label;
  }

  
  shouldComponentUpdate(nextProps, nextState) {
    if (!this.editorRef.current) {
      return true;
    } else if (nextState.labelActivated !== this.state.labelActivated) {
      return true;
    } else if (nextProps.labelActivated !== this.props.labelActivated) {
      return true;
    } else if (this.editorRef.current && nextProps.label !== this.editorRef.current.innerHTML) {
      return true;
    } else {
      return false;
    }
  }
  
  componentDidUpdate(prevProps) {
    if (this.editorRef.current&& this.props.label !== this.editorRef.current.innerHTML) {
      this.editorRef.current.innerHTML = this.props.label;
      this.lastEmittedText = this.props.label;
    }
    if (!prevProps.labelActivated && this.props.labelActivated) {
      this.editorRef.current.focus();
    }
  }

  handleKeyDown(event) {
    if (typeof event.key == 'undefined') {
      event.preventDefault();
    }
    if (event.key === 'ArrowDown' || event.key === 'Enter') {
      event.preventDefault();
      return;
    }
  }

  handleKeyUp(event) {
    let cursorPos = getCursorOffsetInElement(this.editorRef.current);

    if (event.key === 'ArrowLeft') {
      this.emitChanges();
      return;
    } else if (event.key === 'ArrowRight') {
      this.emitChanges();
      return;
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      return;
    } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
      this.props.jumpToGroup(this.props.grpId, cursorPos);
    } else if (event.key === 'Backspace') {
    } else if (event.key === 'Delete') {
    }

    if (this.cursorStartPos === false) {
      this.cursorStartPos = cursorPos;
    }
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
    this.updateTimer = setTimeout(() => {
      this.emitChanges();
    }, 1000);
  }

  emitChanges() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
    var newHtml =
      this.editorRef.current && typeof this.editorRef.current != 'undefined' ? this.editorRef.current.innerHTML : '';
    if (newHtml !== this.lastEmittedText) {
      this.props.updateGroup(this.props.grpId, {label: newHtml});
    }
    this.lastEmittedText = newHtml;
  }

  handleBlur() {
    //console.log("blur");
    if (this.editorRef.current.innerHTML !== this.lastEmittedText) {
      this.emitChanges();
    }
    if (this.editorRef.current.innerHTML === '') {
      this.setState({labelActivated: false});
      this.props.deactivateLabel();
    }
  }

  handlePaste(e) {
    e.stopPropagation();
    e.preventDefault();
    let clipboardData = e.clipboardData || window.clipboardData;
    let pasteText = stripTags(clipboardData.getData('Text'));

    let cursorPos = getCursorOffsetInElement(this.editorRef.current);
    let currentTitle = this.editorRef.current.innerHTML;

    let htmlBeforeCursor = currentTitle.substr(0, cursorPos);
    let htmlAfterCursor = currentTitle.substr(cursorPos);

    let newTitle = htmlBeforeCursor + pasteText + htmlAfterCursor;
    this.editorRef.current.innerHTML = newTitle;
    focusElementAtPosition(cursorPos + pasteText.length, this.editorRef.current);
    this.emitChanges();
  }

  render() {
    return (
      <div className={(this.props.label !== '' || this.state.labelActivated || this.props.labelActivated) ? "grouplabel active" : "grouplabel"}>
        {this.props.label !== '' || this.state.labelActivated || this.props.labelActivated ? (
            <h2
              ref={this.editorRef}
              onKeyDown={this.handleKeyDown}
              onKeyUp={this.handleKeyUp}
              onBlur={this.handleBlur}
              onPaste={this.handlePaste}
              contentEditable={this.props.editActive ? true : false}
              dangerouslySetInnerHTML={{ __html: this.props.label }}
              spellCheck="false"
            />
        ) : (
          null
          /*
          <span className="icon" onClick={this.activateEdit}>
            <i className="fas fa-tag" />
          </span>
          */
        )}
      </div>
    );
  }
}
