import React from 'react';
import {Translation} from 'react-i18next';
import { SmallEditorPortal } from '../../SmallEditorPortal';

export class BarOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      page: 1
    };
    this.close = this.close.bind(this);
    this.setBeatPattern = this.setBeatPattern.bind(this);
    this.setRehearsal = this.setRehearsal.bind(this);
    this.setRepeatEnding = this.setRepeatEnding.bind(this);
  }
  componentDidMount() {
    if (this.props.editBarOptionsFor) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  }
  componentDidUpdate() {
    if (this.props.editBarOptionsFor && !this.state.open) {
      this.setState({ open: true });
    } else if (!this.props.editBarOptionsFor && this.state.open) {
      this.setState({ open: false });
    }
  }
  close() {
    this.props.openBarOptions(false);
  }
  setBeatPattern(pattern) {
    let content = {};
    if (pattern === '2/4') {
      content.fragments = [
        {chord: ''}, {chord: ''}
      ];
    } else if (pattern === '4/4') {
      content.fragments = [
        {chord: ''}, {chord: ''}, {chord: ''}, {chord: ''}
      ];
    } else if (pattern === '2/4+4/8') {
      content.fragments = [
        {chord: ''}, {chord: ''}, {fragments: [{chord: ''}, {chord: ''}]}, {fragments: [{chord: ''}, {chord: ''}]}
      ];
    } else if (pattern === '4/8+2/4') {
      content.fragments = [
        {fragments: [{chord: ''}, {chord: ''}]}, {fragments: [{chord: ''}, {chord: ''}]}, {chord: ''}, {chord: ''}
      ];
    } else if (pattern === '8/8') {
      content.fragments = [
        {chord: ''}, {chord: ''}, {chord: ''}, {chord: ''}, {chord: ''}, {chord: ''}, {chord: ''}, {chord: ''}
      ];
    } else if (pattern === '3/4') {
      content.fragments = [
        {chord: ''}, {chord: ''}, {chord: ''}
      ];
    }
    this.props
      .updateBar({
        barId: this.props.barId,
        content: content
      })
      .then(() => {
        this.close();
      });
  }
  setRehearsal(rehearsal) {
    this.props
      .updateBar({
        barId: this.props.barId,
        content: {
          rehearsal: rehearsal
        }
      })
      .then(() => {
        this.close();
      });
  }
  setRepeatEnding(number) {
    this.props
      .updateBar({
        barId: this.props.barId,
        content: {
          repeatEnding: number
        }
      })
      .then(() => {
        this.close();
      });
  }

  render() {
    return this.state.open ? (
      <SmallEditorPortal parentId={this.props.rowid + '-' + this.props.barId} close={this.close}>
        <Translation>
          {(t) => (
            <React.Fragment>
              <ul className="dropdown-list">
                <li
                  onClick={() => {
                    this.props.newBar({ barId: this.props.barId, location: 'right' });
                  }}
                >
                  <span>
                    <i className="fas fa-plus" />
                    {t('Bars.AddBar')}
                  </span>
                </li>
                {
                  this.props.barIndex===0
                    ? (
                      <li
                    onClick={() => {
                      this.props.newBar({ barId: this.props.barId, location: 'left' });
                    }}
                  >
                    <span>
                      <i className="fas fa-plus" />
                      {t('Bars.AddBarBefore')}
                    </span>
                  </li>
                  ) : ('')
                }
                <li
                  onClick={() => {
                    this.props.removeBar(this.props.barId);
                  }}
                >
                  <span>
                    <i className="far fa-trash-alt" />
                    {t('Bars.RemoveBar')}
                  </span>
                </li>
              </ul>
              <div className="sm-editor-tabs">
                <div>
                  <span
                    className={this.state.page === 1 ? 'active' : ''}
                    onClick={() => {
                      this.setState({ page: 1 });
                    }}
                  >
                    2/4
                  </span>
                </div>
                <div>
                  <span
                    className={this.state.page === 2 ? 'active' : ''}
                    onClick={() => {
                      this.setState({ page: 2 });
                    }}
                  >
                    <span className="rehearsal-mark">A</span>
                  </span>
                </div>
                <div>
                  <span
                    className={this.state.page === 3 ? 'active' : ''}
                    onClick={() => {
                      this.setState({ page: 3 });
                    }}
                  >
                    <span className="repeat-ending">1.</span>
                  </span>
                </div>
              </div>

              <div className="above-bar-editor">
                {this.state.page === 1 && <Page1 setBeatPattern={this.setBeatPattern} t={t} />}
                {this.state.page === 2 && <Page2 setRehearsal={this.setRehearsal} t={t} />}
                {this.state.page === 3 && <Page3 setRepeatEnding={this.setRepeatEnding} t={t} />}
              </div>
            </React.Fragment>
          )}
        </Translation>
      </SmallEditorPortal>
    ) : (
      ''
    );
  }
}

const Page1 = (props) => {
  return (
    <React.Fragment>
      <p>{props.t("Bars.BeatPatternsDescription")}</p>
      <div>
        <button onClick={() => { props.setBeatPattern('2/4') }}>| ⁃ ⁃ |</button>
        <button onClick={() => { props.setBeatPattern('4/4') }}>| ⁃ ⁃ ⁃ ⁃ |</button>
      </div>
{/*       
      <div>
        <button onClick={() => { props.setBeatPattern('2/4+4/8') }}>| ⁃ ⁃ ⁃⁃⁃⁃ |</button>
        <button onClick={() => { props.setBeatPattern('4/8+4/8') }}>| ⁃⁃⁃⁃⁃ ⁃  |</button>
      </div> */}

      <div>
        <button onClick={() => { props.setBeatPattern('8/8') }}>| ⁃⁃⁃⁃⁃⁃⁃⁃ |</button>
        <button onClick={() => { props.setBeatPattern('3/4') }}>| ⁃ ⁃ ⁃ |</button>
      </div>
    </React.Fragment>
  );
};

const Page2 = (props) => {
  return (
    <React.Fragment>
      <p>{props.t("Bars.RehearsalMarksDescription")}</p>
      <div>
        <button
          onClick={() => {
            props.setRehearsal('');
          }}
        />
        <button
          onClick={() => {
            props.setRehearsal('A');
          }}
        >
          A
        </button>
        <button
          onClick={() => {
            props.setRehearsal('B');
          }}
        >
          B
        </button>
        <button
          onClick={() => {
            props.setRehearsal('C');
          }}
        >
          C
        </button>
      </div>

      <div>
        <button
          onClick={() => {
            props.setRehearsal('D');
          }}
        >
          D
        </button>
        <button
          onClick={() => {
            props.setRehearsal('E');
          }}
        >
          E
        </button>
        <button
          onClick={() => {
            props.setRehearsal('F');
          }}
        >
          F
        </button>
        <button
          onClick={() => {
            props.setRehearsal('G');
          }}
        >
          G
        </button>
      </div>
    </React.Fragment>
  );
};

const Page3 = (props) => {
  return (
    <React.Fragment>
      <p>{props.t("Bars.RepeatEndingDescription")}</p>
      <div>
        <button
          onClick={() => {
            props.setRepeatEnding('1.');
          }}
        >
          <span className="repeat-ending">1.</span>
        </button>
        <button
          onClick={() => {
            props.setRepeatEnding('2.');
          }}
        >
          <span className="repeat-ending">2.</span>
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            props.setRepeatEnding('3.');
          }}
        >
          <span className="repeat-ending">3.</span>
        </button>
        <button
          onClick={() => {
            props.setRepeatEnding('1.,2.');
          }}
        >
          <span className="repeat-ending">1.,2.</span>
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            props.setRepeatEnding('');
          }}
        />
      </div>
    </React.Fragment>
  );
};
