const minorequals = {
    'A': 'F#m',
    'B': 'G#m',
    'C': 'Am',
    'D': 'Bm',
    'E': 'C#m',
    'F': 'Dm',
    'G': 'Em',
    'Ab': 'Fm',
    'Bb': 'Gm',
    'Cb': 'Abm',
    'Db': 'Bbm',
    'Eb': 'Cm',
    'Fb': 'Dbm',
    'Gb': 'Ebm',
    'A#': 'Gm',
    'B#': 'Am',
    'C#': 'A#m',
    'D#': 'B#m',
    'E#': 'C#m',
    'F#': 'D#m',
    'G#': 'E#m'
};
export default minorequals;