import React from "react";
import { Link } from 'react-router-dom';
import { ToolbarDropdown } from "./ToolbarDropdown";
import { DropdownButton } from "../DropdownButton";
import UserContext from "../../UserContext";
import { Translation } from "react-i18next";
import { ToolbarHelp } from './ToolbarHelp';
import { ToolbarUser } from './ToolbarUser';
import { ToolbarSearchMusic } from './ToolbarSearchMusic';


export class ListToolbar extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = { closeDropdown: 0, focusSearch: 0 };
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className="toolbar">
            <div className="tb-left">
              <DropdownButton
                className="highlighted"
                closeDropdown={this.state.closeDropdown}
                button={
                  <React.Fragment>
                    <span className="d-none d-md-block" onClick={() => { this.props.openNewSongModal(); }}>{t("ListToolbar.NewSongButton")}</span>
                    <span className="d-block d-md-none" onClick={() => { this.props.openNewSongModal(); }}>{t("ListToolbar.NewSongButtonShort")}</span>
                  </React.Fragment>
                }
              >
                <ul>
                  <li
                    onMouseEnter={() => {
                      this.setState({ showBubbleNewLyrics: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ showBubbleNewLyrics: false });
                    }}
                  >
                    <span onClick={() => { this.props.openNewSongModal("lyrics"); }}>
                      <i className="icon-plus blue"></i> {t("NewSong.SongWithLyrics")}
                    </span>
                    {this.state.showBubbleNewLyrics ? (
                      <div>
                        <p>
                        {t("NewSong.SongWithLyricsDescription")}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                  <li
                    onMouseEnter={() => {
                      this.setState({ showBubbleNewBars: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ showBubbleNewBars: false });
                    }}
                  >
                    <span onClick={() => { this.props.openNewSongModal("chordbars"); }}>
                      <i className="icon-plus blue"></i> {t("NewSong.SongWithBars")}
                    </span>
                    {this.state.showBubbleNewBars ? (
                      <div>
                        <p>{t("NewSong.SongWithBarsDescription")}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                  <li
                    onMouseEnter={() => {
                      this.setState({ showBubbleNewPlaylist: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ showBubbleNewPlaylist: false });
                    }}
                  >
                    <span
                      onClick={() => {
                        this.props.openNewListModal();
                        this.setState({
                          closeDropdown: this.state.closeDropdown + 1,
                        });
                      }}
                    >
                      <i className="icon-plus blue"></i> {t("ListToolbar.Playlist")}
                    </span>
                    {this.state.showBubbleNewPlaylist ? (
                      <div>
                        <p>
                        {t("ListToolbar.PlaylistDescription")}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>

                  { /* TODO: Add this back after demo */
                    false
                    ? (
                  <li
                    onMouseEnter={() => {
                      this.setState({ showBubbleImportSong: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ showBubbleImportSong: false });
                    }}
                  >
                    <span onClick={() => { 
                      this.setState({
                        closeDropdown: this.state.closeDropdown + 1,
                        focusSearch: this.state.focusSearch + 1,
                      });
                     }}>
                      <i className="icon-plus blue"></i> {t("ListToolbar.ImportSong")}
                    </span>
                    {this.state.showBubbleImportSong ? (
                      <div>
                        <p>
                        {t("ListToolbar.ImportSongDescription")}
                        {
                          /*<br /><img src="/images/musixmatch-branding.svg" alt="Musixmatch" /> */
                        }
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                    ) : null
                  }
                </ul>
              </DropdownButton>

               <ToolbarSearchMusic redirect={this.props.redirect} focusSearch={this.state.focusSearch} />
            </div>

            <div className="tb-center">
              <Link to="/" className="branding">{t("SiteName")}<span className="beta-flag">{t("Common.Beta")}</span></Link>
            </div>
            <div className="tb-right">

              <ToolbarDropdown
                withText={true}
                justifyRight={true}
                label={<span><i className="material-icons md-18">help_outlined</i></span>} 
                >
                <ToolbarHelp />
              </ToolbarDropdown>

              <ToolbarUser t={t} user={this.context.user} loggedIn={this.context.loggedIn} />

            </div>
          </div>
        )}
      </Translation>
    );
  }
}
