import React, {useState, useEffect} from 'react';

export const Chord = (props) => {

    //const inputEl = useRef(null);

    //let [chord, setChord] = useState(props.chord);
    let [chordBase, setChordBase] = useState('');
    let [chordColors, setChordColors] = useState('');
    let [chordSlash, setChordSlash] = useState('');

    useEffect(() => {
        let chordBase;
        let chordColors;
        let chordSlash;
        let chordArray = [];
        let chord = props.chord;

        chordArray = chord.match(/(\/[A-GH]([♯#b♭])?)$/);
        if (chordArray && typeof chordArray[1] !== 'undefined' && chordArray[1]) {
            chordSlash = chordArray[1];
            setChordSlash(chordSlash);
            chord = props.chord.substring(0, props.chord.length-chordArray[1].length);
        } else {
            setChordSlash('');
        }

        chordArray = chord.match(/^([A-GH]([♯#b♭])?)/);
        if (chordArray) {
            let chordBaseLength = (chordArray && typeof chordArray[0] !== 'undefined') ? chordArray[0].length : false;
            if (chordBaseLength) {
                chordColors = chord.substring(chordBaseLength);
                if (chordColors.indexOf("maj") !== 0 && chordColors.indexOf("m") === 0) {
                    chordBaseLength++;
                }
                chordBase = chord.substring(0,chordBaseLength);
                chordColors = chord.substring(chordBaseLength);
                setChordBase(chordBase);
                setChordColors(chordColors);
            }
        } else {
            setChordBase('');
            setChordColors('');
        }

        
        
        
    }, [props.chord]);

   const dragstartHandler = (e) => {
        
        //e.target.style.display = 'none';
        e.dataTransfer.setData("chord", props.chord);
        e.dataTransfer.setData("rowid", props.rowid || '');
        e.dataTransfer.setData("letterindex", props.letterindex || "");
        e.dataTransfer.setData("findex", props.findex || "");
        e.dataTransfer.setData("altKey", e.altKey);
        e.dataTransfer.dropEffect = "move";
    }

    return (
        <i 
            draggable="true" 
            onDragStart={dragstartHandler} 
            className={"chord"}
            onClick={props.onClick} 
            id={props.id} 
            data-findex={props.findex || ''}
            >
                {chordBase}<i>{chordColors}</i>{chordSlash}
        </i>
    );
}