import React, { useEffect, useRef, useState } from "react";
import { api_get } from "../../../API";
import { Loading } from "../../Loading";
import localforage from "localforage";

export const TitleSuggestions = (props) => {

  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(false);
  let wrapperRef = useRef();
  let initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return; 
    initialized.current = true;

    const handleClickOutside = (e) => {
      let clickedOutside = (wrapperRef.current && !wrapperRef.current.contains(e.target)) ? true : false;
      if (clickedOutside) {
        props.close();
      }
    }
    document.addEventListener('mousedown', handleClickOutside, false);

    load();

    return () => { document.removeEventListener('mousedown', handleClickOutside, false); }
  }, []); // eslint-disable-line


  const load = async () => {

    let wizardData = await localforage.getItem("wizardData");
    if (wizardData && typeof wizardData.titles !== 'undefined' && wizardData.titles[props.songId] !== 'undefined' && Array.isArray(wizardData.titles[props.songId])) {
      //console.log("From cache", wizardData.titles[props.songId])
      setList(wizardData.titles[props.songId]);
      return;
    } else {
      wizardData = { titles : {} };
    }

    setLoading(true);
    //console.log("Fetching");
    let resp = await api_get("/wizard/title/" + props.songId);
    if (typeof resp.titles !== 'undefined' && Array.isArray(resp.titles)) {
      //console.log(resp);
      setList(resp.titles);
      wizardData.titles[props.songId] = resp.titles;
      localforage.setItem("wizardData", wizardData);
    }
    setLoading(false); 
  }

  return (
    <div
      ref={wrapperRef}
      id="word-browser"
      className={
        'word-browser centered wb-expanded'
      }
    >
      <div className="word-result">
        {
          loading
          ? (
            <div><Loading text="Analyserar texten..." /></div>
          )
          : (
            list && list.length
              ? <React.Fragment>
                <div className="suggestion-description">
                  <p>Här är några förslag. Alla kanske inte är toppen men förhoppningsvis kan de i alla fall ge lite inspiration.</p>
                </div>
                <div className="sentences">
                  {
                    list.map((title, i) => {
                      return (
                        <div onClick={() => {
                          props.setTitle(title);
                        }} key={title + i}><span>{title}</span></div>
                      );
                    })
                  }
                </div>
              </React.Fragment>
            : null
          )
        }
        
      </div>
    </div>
  );
}