import React from "react";
import ReactDOM from 'react-dom'

export class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.placeModal = this.placeModal.bind(this);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    window.addEventListener('resize', this.placeModal, false);
    document.getElementById('modal-root').appendChild(this.el);
    this.placeModal();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.placeModal, false);
    document.getElementById('modal-root').removeChild(this.el);
  }

  placeModal() {
    let rect = ReactDOM.findDOMNode(document.getElementById('modal')).getBoundingClientRect();
    let winHeight = document.documentElement.clientHeight;
    let modal = document.getElementById('modal');
    if (rect.height+30 < winHeight) {
      modal.style.top = '50%'
      modal.style.transform = 'translateY(-50%)'
    } else {
      modal.style.top = '0'
      modal.style.transform = 'none'
    }
  }
  
  close() {
    if (typeof this.props.close !== 'undefined') {
      this.props.close();
    }
  }

  render() {
    return ReactDOM.createPortal(
      (
        <div>
          <div className="modal-overlay"></div>
          <div className="modal-wrapper" onClick={this.close}>
            <div onClick={(e) => { e.stopPropagation(); }} id="modal" className={"modal " + (this.props.className || "")}>
              <div onClick={this.close} className="modal-close"><i className="far fa-times-circle"></i></div>
              {this.props.children}
            </div>
          </div>
        </div>
      ),
      document.getElementById('modal-root')
    );
  }
}