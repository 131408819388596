import { getApp } from 'firebase/app';
import { getFirestore, doc, updateDoc } from "firebase/firestore";

export const saveDocumentViewedByUser = ({uid, docType, id}) => {
    const firebase = getApp();
    if (uid && docType && id) {
        try {
            let vt = Math.floor(Date.now() / 1000);
            setTimeout(() => {
                const firestore = getFirestore(firebase);
                let ref = doc(firestore, "users/"+uid+ "/docs/" + docType + '-'+ id);
                updateDoc(ref, {vt: vt}).catch(() => { });
            }, 3000);
        } catch(err) { }
    }
}