import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

export class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownList: null,
      dropdownCSS: {}
    };
    this.dropdownRef = React.createRef();
    this.closeDropdown = this.closeDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  componentDidUpdate(prevProps) {
    if (this.props.closeDropdown > 0 && prevProps.closeDropdown !== this.props.closeDropdown) {
      this.setState({ dropdownOpen: false });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }
  handleClickOutside(e) {
    if (!this.props.usePortal) {
      if (this.dropdownContainerRef && !this.dropdownContainerRef.contains(e.target)) {
        this.closeDropdown();
      }
    }
  }
  closeDropdown() {
    this.setState({ dropdownOpen: false });
  }
  toggleDropdown(e) {
    e.stopPropagation();
    let isOpening = !this.state.dropdownOpen;
    this.setState({ dropdownOpen: isOpening }, () => {
      if (isOpening) {
        let dropdownRect = ReactDOM.findDOMNode(this.dropdownRef).getBoundingClientRect();
        if (dropdownRect.right > window.innerWidth) {
          this.setState({ dropdownCSS: {right: 0} });
        }
      }
    });
  }

  render() {
    

    let dropdownList = this.props.usePortal ? (
      <DropdownList 
        children={this.props.children} 
        portalRoot={this.props.portalRoot || 'dropdown-root'} 
        parentRef={this.dropdownContainerRef} 
        closeDropdown={this.closeDropdown} />
      ) : (
        this.props.children
      );
    
    return (
      <div
        className={
          this.state.dropdownOpen 
            ? 'dropdown open ' + (this.props.className || '') + (this.props.inactive ? ' inactive' : '')
            : 'dropdown ' + (this.props.className || '') + (this.props.inactive ? ' inactive' : '')
        }
        ref={(node) => {
          this.dropdownContainerRef = node;
        }}
      >
        <label onClick={this.toggleDropdown}>
          {this.props.label}
        </label>
        {this.state.dropdownOpen && !this.props.inactive 
          ? <div  ref={(node) => { this.dropdownRef = node; }} style={this.state.dropdownCSS}>{dropdownList}</div> : ''}
      </div>
    );
  }
}

function DropdownList(props) {

  let dropdownRef = null;

  useEffect(() => {
    function handleClickOutside(e) {
      if (dropdownRef && !dropdownRef.contains(e.target) && !props.parentRef.contains(e.target)) {
        props.closeDropdown();
      }
    }

    document.addEventListener('mousedown', handleClickOutside, false);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  });

  let sub_x = 0;
  let sub_y = 0;
  if (props.portalRoot === 'dropdown-root-flow') {
    let parentPos = ReactDOM.findDOMNode(document.getElementById("flow")).getBoundingClientRect();
    sub_x = parentPos.x - document.getElementById("flow").scrollLeft;
    sub_y = parentPos.y - document.getElementById("flow").scrollTop;
  }
  
  let parentRect = ReactDOM.findDOMNode(props.parentRef).getBoundingClientRect();

  let leftPos = parentRect.x + 20 - sub_x;
  let topPos = parentRect.y - sub_y;

  // TODO: Check height and top and move up if under fold
  //let dropdownRect = props.children;
  //console.log(dropdownRect);

  let css = {
    position: 'absolute',
    left: leftPos,
    top: topPos,
  }
  let portal = ReactDOM.createPortal(<div style={css} ref={el => { dropdownRef = el; }}>{props.children}</div>, document.getElementById(props.portalRoot));



  return portal;
}
