import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import transEN from "./lang/en.json";
import transSV from "./lang/sv.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: {
      en: { translations: transEN },
      sv: { translations: transSV },
    },
    //lng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'cookie',  'navigator', 'sessionStorage'], // 'querystring', 'cookie', 'localStorage', 'sessionStorage', 
    }
  }
);

export default i18n;
