import React from 'react';
import { Translation } from 'react-i18next';
import UserContext from '../../UserContext';
import { api_get, api_post, api_patch, api_delete, handle_api_error } from '../../API';
import { ToolbarCollabInvite } from './ToolbarCollabInvite';
import { ToolbarCollabMember } from './ToolbarCollabMember';
import { Dropdown } from '../Dropdown';
import { Loading } from '../Loading';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon
} from "react-share";
// import { isDev } from '../../lib/dev'; // log

export class ToolbarCollab extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      editId: '',
      editData: {},
      owner: '',
      invites: [],
      team: {},
      closeDropdown: 0,
      loading: false,
    };
    this.sendInvite = this.sendInvite.bind(this);
    this.saveTeamMember = this.saveTeamMember.bind(this);
    this.saveInvite = this.saveInvite.bind(this);
    this.cancel = this.cancel.bind(this);
    this.removeInvite = this.removeInvite.bind(this);
    this.removeTeamMember = this.removeTeamMember.bind(this);
    this.hostName = '';
  }
  componentDidMount() {
    this.hostName = window.location.host;
    this.setState({ loading: true });
    api_get('/songs/' + this.props.songId + '/team')
          .then((resp) => {
            if (resp.team) {
              this.setState({ 
                owner: this.props.owner,
                team: resp.team,
                invites: resp.invites,
                loading: false
              });
            }
          })
          .catch(handle_api_error);
  }

  sendInvite(email) {
    api_post('/songs/' + this.props.songId + '/invites', {
      email: email
    })
      .then((resp) => {
        let invites = this.state.invites;
        invites.push(resp);
        this.setState({ invites: invites, edit: false });
      })
      .catch(handle_api_error);
  }
  saveTeamMember(id, read, write) {}
  saveInvite(id, read, write) {
    api_patch('/songs/' + this.props.songId + '/invites/' + id, {
      read: read,
      write: write,
    })
      .then((resp) => {
        this.setState({ edit: false });
      })
      .catch(handle_api_error);
  }

  removeInvite(id) {
    api_delete('/songs/' + this.props.songId + '/invites/' + id)
      .then((resp) => {
        let invites = this.state.invites;
        for (let i in invites) {
          if (invites[i].id === id) {
            invites.splice(i, 1);
          }
        }
        this.setState({ invites: invites, edit: false });
      })
      .catch(handle_api_error);
  }
  removeTeamMember(id) {
    api_delete('/songs/' + this.props.songId + '/team/' + id)
      .then((resp) => {
        let team = this.state.team;
        delete team[id];
        this.setState({ 
            edit: false, 
            teamIds: Object.keys(team),
            team: team
          });
      })
      .catch(handle_api_error);
  }
  cancel() {
    this.setState({ edit: false });
  }

  render() {
    if (this.state.edit === 'invite') {
      return (
        <ToolbarCollabInvite
          sendInvite={this.sendInvite}
          saveInvite={this.saveInvite}
          cancel={this.cancel}
          removeInvite={this.removeInvite}
          edit={this.state.edit}
          editId={this.state.editId}
          editData={this.state.editData}
          owner={this.props.owner}
        />
      );
    } else if (this.state.edit === 'member' && this.state.editId !== this.props.owner) {
      return (
        <ToolbarCollabMember
          saveTeamMember={this.saveTeamMember}
          saveInvite={this.saveInvite}
          cancel={this.cancel}
          removeTeamMember={this.removeTeamMember}
          edit={this.state.edit}
          editId={this.state.editId}
          editData={this.state.editData}
          owner={this.props.owner}
        />
      );
    } else {
      return (
        <Translation>
          {(t) => (
            <div className="collab-editor">
              <h2>{t('CollabEditor.Title')}</h2>

              <h3>{t('CollabEditor.ShareDescription')}</h3>
              <div className="collab-share-option">
                <Dropdown label={
                  this.props.public 
                  ? <React.Fragment><i className="fas fa-globe-americas color-green"></i> {t("CollabEditor.Public")}</React.Fragment>
                  : <React.Fragment><i className="fas fa-lock grayed"></i> {t("CollabEditor.Restricted")}</React.Fragment>
                } closeDropdown={this.state.closeDropdown}>
                  <ul>
                    <li onClick={() => { this.props.setPublic(false); this.setState({closeDropdown:this.state.closeDropdown+1}); }} className={this.props.public ? '' : 'active'}>{t("CollabEditor.Restricted")} - <span className="grayed">{t("CollabEditor.RestrictedDescription")}</span></li>
                    <li onClick={() => { this.props.setPublic(true); this.setState({closeDropdown:this.state.closeDropdown+1}); }} className={this.props.public ? 'active' : ''}>{t("CollabEditor.Public")} - <span className="grayed">{t("CollabEditor.PublicDescription")}</span></li>
                  </ul>
                </Dropdown>
                {
                  this.props.public
                  ? <div>
                      <p>https://{this.hostName}/song/{this.props.songId}</p>
                      <div className="collab-share-buttons">
                        <FacebookShareButton 
                          url={"https://" + this.hostName + "/song/" + this.props.songId}
                          quote={this.props.title} //  + ' - ' + t("SiteName")
                          ><FacebookIcon /></FacebookShareButton>
                        <TwitterShareButton 
                          title={this.props.title}
                          url={"https://" + this.hostName + "/song/" + this.props.songId}
                          ><TwitterIcon /></TwitterShareButton>
                        <EmailShareButton 
                          subject={this.props.title}
                          body={"https://" + this.hostName + "/song/" + this.props.songId}
                          url={"https://" + this.hostName + "/song/" + this.props.songId}
                          ><EmailIcon /></EmailShareButton>
                        <RedditShareButton 
                          title={this.props.title}
                          url={"https://" + this.hostName + "/song/" + this.props.songId}
                          ><RedditIcon /></RedditShareButton>

                      </div>
                    </div>
                  : ''
                }
              
              </div>
              

              <h3>{t('CollabEditor.CollabDescription')}</h3>
              <div className="collab-editor-list">
                {
                  this.state.loading
                  ? <Loading text="" />
                  : null
                }
                <ul className="collab-select">
                  {Object.keys(this.state.team).map((memberId) => {
                    if (typeof this.state.team[memberId] == 'undefined') {
                      return '';
                    }
                    if (memberId === this.state.owner) {
                      // owner
                      return (
                        <li key={memberId} className="inactive disabled">
                          <span>
                            {this.state.team[memberId].displayName} <i className="fas fa-crown" />
                          </span>
                        </li>
                      );
                    } else if (memberId === this.context.user.uid) {
                      // current user
                      return (
                        <li key={memberId} className="inactive disabled">
                          <span>
                            {this.state.team[memberId].displayName} <i className="fas fa-lock" />
                          </span>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={memberId}
                          onClick={() => {
                            this.setState({
                              edit: 'member',
                              editId: memberId,
                              editData: this.state.team[memberId]
                            });
                          }}
                        >
                          <span>{this.state.team[memberId].displayName}</span>
                        </li>
                      );
                    }
                  })}
                  {this.state.invites.map((invite, index) => {
                    return (
                      <li
                        key={index}
                        className="awaiting"
                        onClick={() => {
                          this.setState({ edit: 'invite', editId: invite.id, editData: invite });
                        }}
                      >
                        <span>{invite.email}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {
                //!isDev()
                //? (
                //  <div className="d-flex justify-content-center">
                //    <p>{t('Common.FeatureNotAvailable')}</p>
                //  </div>
                //)
                //: null
              }
              <div className="btn-group center">
                <button
                  className={
                    "highlighted"
                    //+ (!isDev() ? ' inactive' : '')
                  }
                  onClick={() => {
                    //if (isDev()) {
                      this.setState({ edit: 'invite', editId: '', editData: {} });
                    //}
                  }}
                >
                  <i className="icon-plus white" />
                  {t('CollabEditor.ButtonAddCollaberator')}
                </button>
              </div>
            </div>
          )}
        </Translation>
      );
    }
  }
}
