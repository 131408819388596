import React, {useContext} from 'react'; // eslint-disable-line
import UserContext from '../UserContext';
import {LoginWithEmail} from './login/LoginWithEmail';
import {LoginWithGoogle} from './login/LoginWithGoogle';
//import {LoginWithFacebook} from './login/LoginWithFacebook';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const LoginForm = () => {
  const context =  useContext(UserContext);
  const {t} = useTranslation();

  return (
    <div className="login-form">
      {
        context.loggedIn
        ? (
          <AlradyLoggedIn />
        )
        : (
          <div>
            <h1>{t('Login.LoginHeadline')}</h1>
            <p>{t('Login.LoginDescription')}</p>
            <div>
              <LoginWithGoogle />
            </div>
{/* 
            <div>
              <LoginWithFacebook />
            </div>
             */}
            <LoginWithEmail />
          </div>
      )
    }
    </div>
  );
}


const AlradyLoggedIn = () => {
  const {t} = useTranslation();
  return (
    <div>
      <h1>{t('Login.LoginHeadline')}</h1>
      <p>{t('Login.AlreadyLoggedIn')}</p>
      <div>
        <Link to="/songs" className="button highlighted">{t('Login.MySongs')}</Link>
      </div>
      <div>
      <Link to="/logout" className="button">{t('Login.SignOut')}</Link>
      </div>
    </div>
  );
}
