import React from 'react';
import i18next from 'i18next';
import localforage from 'localforage';
// import { Swipeable } from 'react-swipeable'
import { Helmet } from "react-helmet";
import uniqid from "uniqid";
//import {WebFont} from "webfontloader";
import { log } from './lib/dev.js'; // isDev
import isEqual from './lib/isEqual.js';
import { isMobile, isTablet } from './lib/deviceControl.js';
import { Storage, addToLocalCache, updateLocalCache } from './components/Storage.js';
import { EditorToolbar } from './components/toolbar/EditorToolbar.js';
import { Notification } from './components/Notification.js';
import { Contributers } from './components/Contributers.js';
import { SidebarLeft, SidebarRight } from './components/editor/Sidebar.js';
import { Group } from './components/editor/Group.js';
import { SelectedRowsToolbar } from './components/editor/SelectedRowsToolbar.js';
import { DropdownButton } from './components/DropdownButton.js';
import { Loading } from './components/Loading.js';
import { TitleEditor } from './components/editor/title/TitleEditor.js';
import { cloneObject } from './lib/cloneObject.js';
import { saveDocumentViewedByUser } from './lib/saveDocumentViewedByUser.js';
import { saveTranspose } from './lib/saveTranspose.js';
import { handleAppendPrevRowWithCurrentRow } from './lib/handlers/handleAppendPrevRowWithCurrentRow.js';
import { handleGlueToGroupAbove } from './lib/handlers/handleGlueToGroupAbove.js';
import { handleMergePastedText } from './lib/handlers/handleMergePastedText.js';
import { handleDragEnd } from './lib/handlers/handleDragEnd.js';
import { handleConvertRowToNewGroup } from './lib/handlers/handleConvertRowToNewGroup.js';
import { handleDevideRowAtPosition } from './lib/handlers/handleDevideRowAtPosition.js';
import { handleMergeTextWithNextRow } from './lib/handlers/handleMergeTextWithNextRow.js';
import { focusElementAtPosition } from './lib/focusElementAtPosition.js';
import { getNewChordPosition } from './lib/chordPositionControl.js';
import { handleGroupDuplicate } from './lib/handlers/handleGroupDuplicate.js';
import { handleGroupDelete } from './lib/handlers/handleGroupDelete.js';

import { handleChordDrop } from './lib/handlers/handleChordDrop.js';
import { getSelectedRows } from './lib/getSelectedRows.js';
import { detectKey } from './lib/detectKey.js';
//import majorequals from "./config/majorequals.js"
import defaultUpdatesObject from './config/updatesObject.js';
import songdataKeys from './config/songdataKeys.js';
import {
  isDeleted,
  isID,
  newID,
  getDeletedRowData,
  getNewGroup,
  getNewRow,
  getDefaultBars,
  composeDocArray,
  getTextFromGroup,
  songHas,
  getPosBetween,
  getRowInGroupByIndex,
  getRowIndex,
  getRowByIndex,
  getRowIdByIndex,
  getRowsInGroup,
  getLastOf,
  sortByPos,
  filterGroups
} from './lib/utils.js';
import { DragDropContext, Droppable } from 'react-beautiful-dnd'; // Draggable
import { api_get, api_post, handle_api_error } from './API.js';

import { getDatabase, ref as dbRef, onValue } from "firebase/database";

export class ViewEditor extends React.Component {
  
  constructor(props) {
    super();

    this.state = {
      readyToRender: false,
      rerender: 0,
      loading: false,
      readerMode: false,
      songId: '',
      users: {},
      docArray: [],
      editActive: false,
      transpose: 0,
      hasChords: false,
      hasText: false,
      hasBars: false,

      hasSelectedRows: false,

      clipboard: [],
      rightSidebarOpener: 0,

      chordEditorIsOpenAtRow: false,
      chordEditorIsOpenAtPos: false,
      viewUpdates: 0,

      notification: '',
      notificationClass: ''
    };

    this.defaultEnv = {
      theme: 'default',
      chordsActive: false,
      align: 'center',
      font: false,
      fontSize: '18px',
      songKey: false,
      songKeyDetected: false
    };

    this.editorId = uniqid();
    this.song = false;
    this.grps = {};
    this.rows = {};
    this.updates = { rows: {}, grps: {} };
    //this.forceRender = false;
    this.stopDatabaseListener = false;

    this.history = [];
    this.historyPosition = false;

    //this.scrollUpdateTimer = null;
    this.titleUpdateTimer = null;
    this.focused = false;
    this.selectedGroups = [];
    this.selectedRows = [];
    this.titleRef = null;
    this.rowRefs = {};

    this.db = null;
  }

  debug = () => {
    log('State', this.state);
    log('Groups', this.grps);
    log('Rows', this.rows);
    log('updates', this.updates);
    log('getSelectedRows', this.getSelectedRows());
    log('history', this.history);
    log('historyPosition', this.historyPosition);
    log('clipboard', this.state.clipboard);
  }

  componentDidMount() {
    this.db = getDatabase(this.props.firebase);
    window.addEventListener('load', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    document.addEventListener('mousedown', this.handleEditorClick, false);
    this.stopDatabaseListener = false;
    this.getSongFromDB();
    if (!this.props.songId) {
      this.setNotification(i18next.t("Notification.OnStartNewDraft"), 'slow');
    }
  }

  componentWillUnmount() {
    document.title = i18next.t('SiteName');
    window.removeEventListener('load', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
    document.removeEventListener('mousedown', this.handleEditorClick);
    if (this.stopDatabaseListener) {
      this.stopDatabaseListener();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.songId !== prevProps.songId) {
      this.getSongFromDB();
    }
  }

  rerender = () => {
    this.setState({ rerender: this.state.rerender + 1 });
  }

  getSong = () => {
    return this.song;
  }
  getDocArray = () => {
    return this.state.docArray;
  }

  setNotification = (notification, notificationClass='') => {
    this.setState({notification, notificationClass});
  }

  handleScroll = () => {
    let scrollableDiv = document.getElementById("scrollable");
    if (scrollableDiv) {
      let scrollTop = scrollableDiv.scrollTop || 0;
      let stickyElements = document.querySelectorAll(".sb-container .sb-opener, .sb-container .sidebar");
      if (stickyElements.length) {
        for (let stickyElement of stickyElements) {
          stickyElement.style.top = (scrollTop > 60) ? (scrollTop - 40) + "px" : "20px";
          if (typeof stickyElement.classList.contains !== 'undefined' && stickyElement.classList.contains("sidebar")) {
            stickyElement.style.height = (scrollTop > 60) ? (window.innerHeight - 40)+"px" : (window.innerHeight - 100 + scrollTop)+"px";
          }
        }
      }
    }
  }

  /*
  pauseLiveUpdates = () => {
    this.liveupdatesPaused = true;
  }
  unpauseLiveUpdates = () => {
    setTimeout(() => {
      this.liveupdatesPaused = false;
    }, 500);
  }
  */
  clearUpdates = () => {
    this.updates = { rows: {}, grps: {} };
  }
  updateUpdateTimes = (updates) => {
    for (let key of Object.keys(updates)) {
      if (key.indexOf("grps") >= 0) {
        let keySplit = key.split('.');
        if (typeof this.grps[keySplit[1]] !== 'undefined') {
          this.grps[keySplit[1]].ut = updates[key].ut;
        }
      }
      if (key.indexOf("rows") >= 0) {
        let keySplit = key.split('.');
        if (typeof this.rows[keySplit[1]] !== 'undefined') {
          this.rows[keySplit[1]].ut = updates[key].ut;
        }
      }
    }
  }
  hasUpdates = () => {
    if (Object.keys(this.updates).length > 2) return true;
    if (Object.keys(this.updates.rows).length) return true;
    if (Object.keys(this.updates.grps).length) return true;
    return false;
  }
  updateSongFromAPI = (dataSnapshot) => {
    // doc.metadata.hasPendingWrites
    if (typeof dataSnapshot.val === 'undefined' || this.liveupdatesPaused) { // doc.metadata.hasPendingWrites || 
      return;
    }
    let resp = dataSnapshot.val()
    
    if (resp) {
      
      let updates_done = false;
      if (resp.ue === this.editorId) {
        return;
      }
      // log("updateSongFromAPI", resp);
      
      for (let group of Object.values(resp.grps)) {
        if (typeof group.ut !== 'undefined') {
          let shouldUpdate = false;
          if (typeof this.grps[group.id] === 'undefined') {
            shouldUpdate = true;
          } else if (group.ut > this.grps[group.id].ut && group.ue !== this.editorId) {
            shouldUpdate = true;
          }
          if (shouldUpdate) { 
            this.grps[group.id] = cloneObject(group);
            updates_done = true;
          }
        }
      }
      
      for (let row of Object.values(resp.rows)) {
        if (typeof row.ut !== 'undefined') {
          let shouldUpdate = false;
          if (typeof this.rows[row.id] === 'undefined') {
            shouldUpdate = true;
          } else if (row.ut > this.rows[row.id].ut && typeof row.ue !== 'undefined' && row.ue !== this.editorId) {
            shouldUpdate = true;
          }
          if (shouldUpdate) { //  
            this.rows[row.id] = cloneObject(row);
            updates_done = true;
          }
        }
      }

      if (resp.ue !== this.editorId) {

        for (let key of songdataKeys) { // Check all updatable keys except groups and rows
          if (key === 'grps' || key === 'rows' || typeof resp[key] === 'undefined') {
            continue;
          }
          if (!isEqual(resp[key], this.song[key])) {
            this.song[key] = resp[key];
            updates_done = true;
          }
        }
        
      }
      
      if (updates_done && !this.hasUpdates()) {
        // log("updateViewe updateSongFromAPI", resp);
        this.updateView();
      }
    }
  }

  getSongFromDB = () => {
    let songId = this.props.songId;

    if (songId !== false) {

      api_get('/songs/' + songId)
        .then((resp) => {
          if (resp && resp.title) {

            this.song = cloneObject(resp);
            
            this.song.env = this.defaultEnv;
            if (typeof resp.env !== 'undefined') {
              let envkeys = Object.keys(resp.env);
              for (let x in envkeys) {
                this.song.env[envkeys[x]] = resp.env[envkeys[x]];
              }
            }

            this.loadSongIntoEditor();

            const ref = dbRef(this.db, "songdata/" + songId);
            onValue(ref, this.updateSongFromAPI);
            
          }
        })
        .catch((err) => {
          handle_api_error(err);
        });

    } else {

      this.song = {};
      
      localforage.getItem("draft")
        .then(draft => {
          this.song = draft;

          this.song.env = this.defaultEnv;
          this.loadSongIntoEditor();
          
        }).catch(err => {

          // create a new anonymous song
          let group = getNewGroup();
          let row = getNewRow({ 
            grpId: group.id,
            type: this.state.newSongType==='chordbars' ? 'chordbars' : 'lyrics'
          });
          this.song = {
            title: 'Untitled song',
            owner: false,
            team: {},
            language: i18next.language.substr(0,2) || 'en',
            snippets: {},
            grps: {},
            rows: {},
            meta: {},
            flags: [],
            isTeamMember: true
          };
          this.song.grps[group.id] = group;
          this.song.rows[row.id] = row;

          this.song.env = this.defaultEnv;

          this.loadSongIntoEditor();
        })
    }
  }

  loadSongIntoEditor = () => {
    let docArray = composeDocArray(this.song.grps, this.song.rows);
    if (docArray === false) {
      return;
    }

    let has = songHas(this.song.rows);
    this.grps = this.song.grps;
    this.rows = this.song.rows;
    this.history.push(cloneObject(defaultUpdatesObject));

    if (typeof this.song.env.fontSize !== 'undefined') {
      document.querySelector(':root').style.fontSize = this.song.env.fontSize;
    }

    document.title = this.song.title + ' | ' + i18next.t('SiteName');

    let editActive = true;
    if (this.props.listId || !this.song.isTeamMember) {
      editActive = false;
    }


    this.setState({
      readyToRender: true,
      songId: this.song.id,
      users: this.song.users || {},
      docArray: docArray,
      hasChords: has.chords,
      hasText: has.text,
      hasBars: has.bars,
      editActive: editActive,
      transpose: this.song.transpose || 0,
      readerMode: this.song.isTeamMember ? false : true
    }, () => {

      saveDocumentViewedByUser({
        uid: this.props.user.uid,
        docType: 'song',
        id: this.state.songId,
      });

      if (this.song.musixmatch) {
        this.setNotification(<React.Fragment>
          {
          i18next.t("Notification.ImportedSongInfo")
          /*
            However, you can add the chords above the lyrics, change document layout and use it in playlists, print, or share them.
            Det går däremot bra att lägga till ackord, ändra layout, lägga till den i playlists, skriva ut och dela den med vänner.
          */
          }
          {
            /*
            <div className="w-100">
              <a href="https://www.musixmatch.com" target="_blank" rel="noopener noreferrer">
              <img src="/static/images/musixmatch-branding.svg"  className="w-100" alt="Musixmatch" />
              </a>
            </div>
            */            
          }
          
        </React.Fragment>, 'slow');
      }
    });

    this.detectKey();
  }


  saveDraft = () => {
    log("saveDraft");
    let song = this.song;
    song.grps = this.grps;
    song.rows = this.song.rows;

    api_post('/songs', song)
        .then((resp) => {

          this.song.id = resp.id;
          resp.flags.push('new');
          addToLocalCache("song", resp)
            .then((songs) => {
              localforage.removeItem("draft");
              this.props.redirect('/song/' + this.song.id);
            })
          
        })
        .catch(handle_api_error);
  }

  detectKey = () => {
    let detectedKey = detectKey(this.rows);
    if (detectedKey) {
      if (!this.song.env.songKeyDetected || this.song.env.songKeyDetected !== detectedKey) {
        this.setEnvVariable("songKeyDetected", detectedKey);
      }
    }
  }

  handleEditorClick = (e) => {
    this.focused = false;
  }

  getSelectedRows = (filter) => {

    return getSelectedRows({
      selectedRows: this.selectedRows,
      grps: this.grps,
      rows: this.rows,
      filter: filter,
    });
  }

  updateMeta = (metaId, metaData) => {
    if (!isID(metaId)) {
      metaId = newID();
    }

    let meta = this.song.meta || {};
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.meta = cloneObject(meta);
    meta[metaId] = metaData;
    updateObject.next.meta = cloneObject(meta);
    this.performUpdate({ updateObject, updateType: 'update-meta' });
  }
  removeMeta = (metaId) => {
    let meta = this.song.meta || {};
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.meta = cloneObject(meta);
    if (typeof meta[metaId] != 'undefined') {
      delete meta[metaId];
    }
    updateObject.next.meta = cloneObject(meta);
    this.performUpdate({ updateObject, updateType: 'update-meta' });
  }

  addTeamMember = (email) => {}
  removeTeamMember = (memberId) => {}

  setEditActive = (editActive) => {
    let transpose = editActive ? 0 : this.state.transpose;
    this.setState({ transpose, editActive });
  }
  setTranspose = (transpose) => {
    this.setState({ transpose }, () => {
      saveTranspose({
        uid: this.props.user.uid,
        songId: this.song.id,
        transpose: transpose,
      });
    });
  }
  setPublic = (isPublic) => {
    this.song.public = isPublic;
    this.updates.public = isPublic;
    this.updateView();
  }
  setListed = (isListed) => {
    this.song.listed = isListed;
    this.updates.listed = isListed;
    this.updateView();
  }

  setEnvVariable = (key, value) => {
    let env = this.song.env;
    env[key] = value;
    //this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.updates.env = env;
        this.song.env = env;
        this.setState(
          { updateView: this.state.updateView + 1 },
          () => {
            if (key === 'fontSize') {
              document.querySelector(':root').style.fontSize = value;
            }
            //this.setState({ loading: false });
          },
          30
        );
      });
    //});
  }

  setFont = (fontName) => {
    let env = this.song.env;
    env['font'] = fontName;
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.updates.env = env;
        this.song.env = env;
        this.setState(
          { viewUpdates: this.state.viewUpdates + 1 },
          () => {
            this.setState({ loading: false });
          },
          30
        );
      });
    });
  }
  
  setRowRef = (rowid, currentRowRef) => {
    this.rowRefs[rowid] = currentRowRef;
  }

  getRowRef = (rowid) => {
    return this.rowRefs[rowid];
  }

  setTitleRef = (titleRef) => {
    this.titleRef = titleRef;
  }

  undo = () => {
    if (!this.history.length) {
      return;
    }

    let gotoHistory;
    if (this.historyPosition === false) {
      gotoHistory = this.history.length - 1;
    } else if (this.historyPosition > 0) {
      gotoHistory = this.historyPosition - 1;
    }

    if (!gotoHistory) {
      return;
    }

    if (typeof this.history[gotoHistory] != 'undefined') {
      let grps = this.history[gotoHistory].prev.grps;
      for (let id of Object.keys(grps)) {
        if (grps[id] === false || (typeof grps[id].deleted != 'undefined' && grps[id].deleted)) {
          if (typeof this.grps[id] != 'undefined') {
            delete this.grps[id];
          }
        } else {
          this.grps[id] = cloneObject(grps[id]);
        }
      }

      let rows = this.history[gotoHistory].prev.rows;
      for (let id of Object.keys(rows)) {
        if (rows[id] === false || (typeof rows[id].deleted != 'undefined' && rows[id].deleted)) {
          if (typeof this.rows[id] != 'undefined') {
            delete this.rows[id];
          }
        } else {
          this.rows[id] = cloneObject(rows[id]);
        }
      }

      if (typeof this.history[gotoHistory].prev.title !== 'undefined') {
        this.song.title = this.history[gotoHistory].prev.title;
      }

      if (typeof this.history[gotoHistory].prev.meta !== 'undefined') {
        this.song.meta = this.history[gotoHistory].prev.meta;
      }

      if (typeof this.history[gotoHistory].prev.snippets !== 'undefined') {
        this.song.snippets = this.history[gotoHistory].prev.snippets;
      }

      this.historyPosition = gotoHistory;

      this.updateView({ forceRender: true }).then(() => {}).catch(() => {});
    }
  }
  redo = () => {
    if (this.historyPosition === false) {
      return;
    }
    if (!this.history.length) {
      return;
    }

    let gotoHistory;
    if (this.historyPosition < this.history.length) {
      gotoHistory = this.historyPosition;
    }

    if (typeof this.history[gotoHistory] != 'undefined') {
      let grps = this.history[gotoHistory].next.grps;
      for (let id of Object.keys(grps)) {
        if (grps[id] === false) {
          if (typeof this.grps[id] != 'undefined') {
            delete this.grps[id];
          }
        } else {
          this.grps[id] = cloneObject(grps[id]);
        }
      }

      let rows = this.history[gotoHistory].next.rows;
      for (let id of Object.keys(rows)) {
        if (rows[id] === false) {
          if (typeof this.rows[id] != 'undefined') {
            delete this.rows[id];
          }
        } else {
          this.rows[id] = cloneObject(rows[id]);
        }
      }

      if (typeof this.history[gotoHistory].next.title !== 'undefined') {
        this.song.title = this.history[gotoHistory].next.title;
      }

      if (typeof this.history[gotoHistory].next.meta !== 'undefined') {
        this.song.meta = this.history[gotoHistory].next.meta;
      }

      if (typeof this.history[gotoHistory].next.snippets !== 'undefined') {
        this.song.snippets = this.history[gotoHistory].next.snippets;
      }

      if (gotoHistory >= this.history.length) {
        this.historyPosition = false;
      } else {
        this.historyPosition = gotoHistory + 1;
      }

      this.updateView().then(() => {}).catch(() => {});
    }
  }

  createTrailingRow = (rowType) => {
    if (this.state.docArray.length) {
      let lastGroup = this.state.docArray[this.state.docArray.length - 1];
      let lastRow = getLastOf(lastGroup.id, this.state.docArray);
      let newRowPos = getPosBetween(lastRow, false);

      let updateObject = cloneObject(defaultUpdatesObject);

      let newRow;
      if (typeof rowType !== 'undefined' && rowType === 'chordbars') {
        newRow = getNewRow({
          grpId: lastGroup.id,
          pos: newRowPos,
          type: 'chordbars',
          data: { bars: getDefaultBars() }
        });
      } else if (typeof rowType !== 'undefined' && rowType === 'lyrics') {
        newRow = getNewRow({ type: 'lyrics', grpId: lastGroup.id, pos: newRowPos });
      } else {
        newRow = getNewRow({
          grpId: lastGroup.id,
          pos: newRowPos,
          type: (lastRow.type === 'chordbars') ? 'chordbars' : 'lyrics'
        });
      }

      updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
      updateObject.next.rows[newRow.id] = cloneObject(newRow);

      this.performUpdate({ updateObject, updateType: 'new-row', gotoRow: newRow.id });
    } else {
      if (typeof rowType !== 'undefined' && rowType === 'chordbars') {
        this.createTrailingGroup(null, {
          pos: getPosBetween(false, false),
          type: 'chordbars',
          data: { bars: getDefaultBars() }
        });
      } else {
        this.createTrailingGroup(false, { type: 'lyrics', pos: getPosBetween(false, false) });
      }
    }
  }

  createTrailingGroup = (rowsToInsert, rowToCreate) => {
    let lastGroup = this.state.docArray[this.state.docArray.length - 1];

    let updateObject = cloneObject(defaultUpdatesObject);

    let newGroup = getNewGroup({ pos: getPosBetween(lastGroup, false) });
    let newRow = {};

    updateObject.prev.grps[newGroup.id] = { id: newGroup.id, deleted: true };
    updateObject.next.grps[newGroup.id] = cloneObject(newGroup);

    let gotoRowId = '';

    if (typeof rowsToInsert === 'object' && rowsToInsert && rowsToInsert.length) {
      let i = 1;
      for (let rowToInsert of rowsToInsert) {
        let clonedRow = (typeof rowToInsert === 'object') ? cloneObject(rowToInsert) : cloneObject(this.rows[rowToInsert]);
        delete clonedRow.id;
        clonedRow.grpId = newGroup.id;
        clonedRow.pos = 65535 * i;
        newRow = getNewRow(clonedRow);
        gotoRowId = newRow.id;

        updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
        updateObject.next.rows[newRow.id] = cloneObject(newRow);

        i++;
      }
    } else if (typeof rowsToInsert === 'boolean' && rowsToInsert === true) {

      let rows = this.getSelectedRows();
      let i = 1;
      for (let row of rows) {
        let clonedRow = cloneObject(row);
        delete clonedRow.id;
        clonedRow.grpId = newGroup.id;
        clonedRow.pos = 65535 * i;
        newRow = getNewRow(clonedRow);
        gotoRowId = newRow.id;

        updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
        updateObject.next.rows[newRow.id] = cloneObject(newRow);

        i++;
      }

    } else {
      if (typeof rowToCreate !== 'undefined' && rowToCreate) {
        rowToCreate.grpId = newGroup.id;
        newRow = getNewRow(rowToCreate);
      } else {
        let lastRow =
          lastGroup && typeof lastGroup.id !== 'undefined'
            ? getLastOf(lastGroup.id, this.state.docArray)
            : { type: "lyrics" };
        newRow = getNewRow({
          grpId: newGroup.id,
          type: (lastRow.type) ? lastRow.type : "lyrics"
        });
      }
      
      updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
      updateObject.next.rows[newRow.id] = cloneObject(newRow);
      gotoRowId = newRow.id;
    }

    this.performUpdate({ updateObject, updateType: 'new-group', gotoRow: gotoRowId });

    return { row: newRow, group: newGroup };
  }

  pasteChords = (row) => {
    let updateObject = cloneObject(defaultUpdatesObject);
    let rowindex = getRowIndex(row, this.state.docArray);
    let i = 0;
    for (let pasteRow of this.state.clipboard) {
      let currentRow = getRowByIndex(rowindex + i, this.state.docArray);
      updateObject.prev.rows[currentRow.id] = cloneObject(currentRow);

      let currentRowTextLength = currentRow.data.text.length;
      currentRow.data.chords = [];
      for (let chord of pasteRow.data.chords) {
        if (chord.pos < currentRowTextLength) {
          currentRow.data.chords.push(chord);
        }
      }
      currentRow.chordsUpdated = currentRow.chordsUpdated + 1;

      updateObject.next.rows[currentRow.id] = cloneObject(currentRow);
      i++;
    }

    this.performUpdate({ updateObject, updateType: 'paste-chords' });
  }
  pasteClipboard = (attr) => {
    if (typeof attr.after != 'undefined') {
      let updateObject = cloneObject(defaultUpdatesObject);

      let fromRow = attr.after;
      let rowNextIndex = fromRow.indexInGroup + 1;
      let rowNext = getRowInGroupByIndex(rowNextIndex, fromRow.grpId, this.state.docArray);

      //let i = 1;
      for (let pasteRow of this.state.clipboard) {
        let newRowPosition = getPosBetween(fromRow, rowNext);
        let newRow = getNewRow({
          grpId: fromRow.grpId,
          pos: newRowPosition,
          type: pasteRow.type,
          data: pasteRow.data,
          chordsUpdated: 0
        });

        //this.rows[newRow.id] = newRow;
        fromRow = newRow;

        updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
        updateObject.next.rows[newRow.id] = cloneObject(newRow);
        //i++;
      }

      this.performUpdate({ updateObject, updateType: 'paste' });
    }
  }
  toClipboard = (rows) => {
    let clipboard = [];

    if (typeof rows === 'object' && typeof rows.id !== 'undefined') {
      clipboard.push(rows);
    } else if (Array.isArray(rows) && rows.length > 0) {
      clipboard = rows;
    }

    if (clipboard.length > 0) {
      for (let x in clipboard) {
        let newRow = cloneObject(clipboard[x]);
        delete newRow.pos;
        delete newRow.grpId;
        delete newRow.id;
        if (typeof newRow.index != 'undefined') {
          delete newRow.index;
        }
        if (typeof newRow.indexInGroup != 'undefined') {
          delete newRow.indexInGroup;
        }
        if (typeof newRow.chordsUpdated != 'undefined') {
          delete newRow.chordsUpdated;
        }
        clipboard[x] = newRow;
      }

      this.setState({ clipboard });
    }
  }
  selectedDelete = () => {
    let updateObject = cloneObject(defaultUpdatesObject);

    let rows = this.getSelectedRows();
    for (let row of rows) {
      updateObject.prev.rows[row.id] = cloneObject(row);
      updateObject.next.rows[row.id] = getDeletedRowData(row.id);
    }

    this.performUpdate({ updateObject, updateType: 'selected-delete' });
    this.deselectAllRows();
  }
  selectedToClipboard = (filter) => {
    let rows = this.getSelectedRows(filter);
    this.toClipboard(rows);
  }
  groupToSnippets = (grpId) =>  {
    let snippets = this.song.snippets || {};
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.snippets = cloneObject(snippets);

    snippets[newID()] = getTextFromGroup(grpId, this.state.docArray);
    updateObject.next.snippets = snippets;
    this.performUpdate({
      updateObject,
      updateType: 'update-snippets',
      state: { hasSelectedRows: false, rightSidebarOpener: Math.random(9999999) }
    });
  }

  selectedToSnippets = () => {
    let snippets = this.song.snippets || {};
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.snippets = cloneObject(snippets);

    let selectedRows = this.getSelectedRows();
    let newSnippet = '';
    for (let x in selectedRows) {
      if (x > 0) {
        newSnippet += '\n';
      }
      newSnippet += selectedRows[x].data.text;
    }
    snippets[newID()] = newSnippet;

    updateObject.next.snippets = snippets;
    this.performUpdate({
      updateObject,
      updateType: 'update-snippets',
      state: { hasSelectedRows: false, rightSidebarOpener: Math.random(9999999) }
    });
  }
  saveSnippet = (snippetId, snippetValue) => {
    let snippets = this.song.snippets || {};

    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.snippets = cloneObject(snippets);

    if (snippetId === '') {
      snippetId = newID();
    }
    snippets[snippetId] = snippetValue;
    updateObject.next.snippets = snippets;
    this.performUpdate({ updateObject, updateType: 'update-snippets' });
  }
  pasteSnippet = (snippetText) => {
    let { row } = this.createTrailingGroup();
    let textArray = snippetText.split('\n');
    if (textArray.length) {
      let { updateObject, lastUpdatedRowId } = handleMergePastedText({
        textArray,
        row,
        cursorPos: 0,
        grps: this.grps,
        rows: this.rows,
        updateObject: cloneObject(defaultUpdatesObject)
      });

      this.performUpdate({ updateObject, updateType: 'paste-snippet', gotoRow: lastUpdatedRowId, gotoPos: 99999999 });
    }
  }
  deleteSnippet = (snippetId) => {
    let snippets = this.song.snippets || {};

    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.snippets = snippets;

    if (typeof snippets[snippetId] !== 'undefined') {
      delete snippets[snippetId];

      updateObject.next.snippets = snippets;
      this.performUpdate({ updateObject, updateType: 'update-snippets' });
    }
  }

  updateFocused = (newForcused) => {
    //log("updateFocused", newForcused);
    this.focused = newForcused;
  }

  jumpToGroup = (grpId, focusPos = 0) => {
    let row = getRowInGroupByIndex(0, grpId, this.state.docArray);
    this.jumpToRow(row.id, focusPos);
  }

  jumpToRow = (rowId, focusPos = 0) => {
    //log('jumpToRow:', rowId, 'focusPos:', focusPos);
    //log('this.rowRefs', this.rowRefs);
    if (typeof rowId === 'number' && rowId < 0) {
      // Focus on title
      let ref = document.getElementById('title');
      focusElementAtPosition(focusPos, ref);
      this.updateFocused(false);
    } else {
      if (typeof rowId === 'object' && typeof rowId.id != 'undefined') {
        rowId = rowId.id;
      } else if (typeof rowId === 'number') {
        rowId = getRowIdByIndex(rowId, this.state.docArray);
      } else if (typeof this.rowRefs[rowId] === 'undefined') {
        return false;
      }

      if (!rowId || typeof this.rowRefs[rowId] === 'undefined') {
        return false;
      }

      focusElementAtPosition(focusPos, this.rowRefs[rowId].current);
      this.updateFocused(rowId);
    }
  }
  jumpUp = (focusPos = 0) => {
    if (this.focused !== false) {
      let currentFocusedIndex = (typeof this.focused === 'number') ? this.focused : getRowIndex(this.focused, this.state.docArray);
      let totalRows = Object.values(this.rows).length;
      if (currentFocusedIndex > 0) {
        let rowId = false;
        for (let i = 1; i < totalRows; i++) {
          let gotoIndex = currentFocusedIndex - i;
          rowId = getRowIdByIndex(gotoIndex, this.state.docArray);
          if (rowId && this.rows[rowId].type === 'lyrics') {
            break;
          }
        }
        if (rowId) {
          this.jumpToRow(rowId, focusPos);
        }
      }
    }
  }
  jumpDown = (focusPos = 0) => {
    if (this.focused !== false) {
      let currentFocusedIndex = (typeof this.focused === 'number') ? this.focused : getRowIndex(this.focused, this.state.docArray);
      let totalRows = Object.values(this.rows).length;
      if (currentFocusedIndex < totalRows) {
        let rowId = false;
        for (let i = 1; i < totalRows; i++) {
          let gotoIndex = currentFocusedIndex + i;
          rowId = getRowIdByIndex(gotoIndex, this.state.docArray);
          if (rowId && this.rows[rowId].type === 'lyrics') {
            break;
          }
        }
        if (rowId) {
          this.jumpToRow(rowId, focusPos);
        }
      }
    }
  }

  handleGroupDuplicate = (grpId) => {
    let { updateObject } = handleGroupDuplicate({
      grpId: grpId,
      rows: this.rows,
      grps: this.grps,
      docArray: this.state.docArray,
      updateObject: cloneObject(defaultUpdatesObject)
    });

    this.performUpdate({ updateObject, updateType: 'group-duplicate' });
  }

  handleGroupDelete = (grpId) => {
    let { updateObject } = handleGroupDelete({
      grpId: grpId,
      rows: this.rows,
      grps: this.grps,
      docArray: this.state.docArray,
      updateObject: cloneObject(defaultUpdatesObject)
    });

    this.performUpdate({ updateObject, updateType: 'group-delete' });
  }

  handleGroupCopy = (grpId) => {
    let currentGroup = this.grps[grpId];
    let copiedRow = [];

    // Move rows to new group
    for (let group of this.state.docArray) {
      if (group.id === currentGroup.id && typeof group.rows !== 'undefined' && group.rows.length) {
        for (let currentRow of group.rows) {
          copiedRow.push(cloneObject(this.rows[currentRow.id]));
        }
      }
    }

    copiedRow = sortByPos(copiedRow);
    this.toClipboard(copiedRow);
  }

  handleNewRowAfter = (row) => {
    // Get position for new row
    let nextRowindex = row.indexInGroup + 1;
    let rowNext = getRowInGroupByIndex(nextRowindex, row.grpId, this.state.docArray);
    let newRowPosition = getPosBetween(row, rowNext);

    // Create new row
    let newRow = getNewRow({
      grpId: row.grpId,
      pos: newRowPosition,
      type: row.type,
      chordsUpdated: 0
    });

    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
    updateObject.next.rows[newRow.id] = cloneObject(newRow);
    this.performUpdate({ updateObject, updateType: 'row-new-after' });
  }

  handleRowDuplicate = (row) => {
    // Get position for new row
    let nextRowindex = row.indexInGroup + 1;
    let rowNext = getRowInGroupByIndex(nextRowindex, row.grpId, this.state.docArray);
    let newRowPosition = getPosBetween(row, rowNext);

    // Create new row
    let newRow = getNewRow({
      grpId: row.grpId,
      pos: newRowPosition,
      type: row.type,
      data: row.data,
      chordsUpdated: 0
    });

    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
    updateObject.next.rows[newRow.id] = cloneObject(newRow);
    this.performUpdate({ updateObject, updateType: 'row-duplicate' });
  }

  handleRowClone = (row) => {
    let nextRowindex = row.indexInGroup + 1;
    let rowNext = getRowInGroupByIndex(nextRowindex, row.grpId, this.state.docArray);
    let newRowPosition = getPosBetween(row, rowNext);

    let newRow = getNewRow({
      grpId: row.grpId,
      pos: newRowPosition,
      type: 'ref',
      data: { 
        refId: row.id
      }
    });

    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.rows[newRow.id] = getDeletedRowData(newRow.id);
    updateObject.next.rows[newRow.id] = cloneObject(newRow);
    this.performUpdate({ updateObject, updateType: 'row-duplicate' });
  }

  handleRowConvert = (row) => {
    
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.rows[row.id] = cloneObject(this.rows[row.id]);

    row = this.rows[row.id];
    if (typeof row.data.bars === 'object' && Object.keys(row.data.bars).length > 0) {
      row.data.bars = false;
      row.type = 'lyrics';
    } else {
      row.data.bars = getDefaultBars();
      row.type = 'chordbars';
    }
    updateObject.next.rows[row.id] = cloneObject(row);
    
    this.performUpdate({ updateObject, updateType: 'row-convert' });
  }

  handleRowDelete = (row) => {
    this.deleteRow(row);
  }

  handleRowToGroup = (row) => {
    let { updateObject } = handleConvertRowToNewGroup({
      row,
      rows: this.rows,
      grps: this.grps,
      docArray: this.state.docArray,
      updateObject: cloneObject(defaultUpdatesObject)
    });

    this.performUpdate({ updateObject, updateType: 'row-to-group', gotoRow: row.id });
  }

  handlePressBackspace = (newHtml, row, cursorPos) => {
    this.focused = false;

    if (newHtml === '') {
      this.deleteRow(row);
    } else if (cursorPos === 0) {
      this.handleDeleteRowWithBackspace(newHtml, row);
    }
  }

  handleDeleteRowWithBackspace = (rowText, row) => {
    this.focused = false;
    let setCursorPosAt = 0;

    let rowIndex = getRowIndex(row.id, this.state.docArray);
    let prevRowindex = rowIndex - 1;
    let prevRow = getRowByIndex(prevRowindex, this.state.docArray);
    if (!prevRow) {
      log('No previous row');
      return false;
    }

    let updateObject = cloneObject(defaultUpdatesObject);

    if (prevRow.grpId !== row.grpId) {
      // move all rows in current group to prevous group

      updateObject = handleGlueToGroupAbove({
        updateObject: cloneObject(defaultUpdatesObject),
        row: row,
        grps: this.grps,
        docArray: this.state.docArray
      });

    } else {
      // move up content to row above
      // Add text from deleted to previous row

      setCursorPosAt = prevRow.data.text.length;

      updateObject = handleAppendPrevRowWithCurrentRow({
        updateObject: cloneObject(defaultUpdatesObject),
        rows: this.rows,
        prevRow, 
        row,
        rowText
      });

      
    }

    this.performUpdate({ updateObject, updateType: 'delete-row', gotoRow: prevRow.id, gotoPos: setCursorPosAt });

  }

  handleGlueToGroupAbove = (row) => {
    this.focused = false;

    let updateObject = handleGlueToGroupAbove({
      updateObject: cloneObject(defaultUpdatesObject),
      row: row,
      grps: this.grps,
      docArray: this.state.docArray
    });

    this.performUpdate({
      updateObject,
      updateType: 'glue-to-group-above',
      gotoRow: row.id,
      gotoPos: 0
    });
  }

  handleMergeTextWithNextRow = (rowText, row) => {

    let prevRowTextLength = rowText.length;

    let updateObject = handleMergeTextWithNextRow({
      updateObject: cloneObject(defaultUpdatesObject),
      docArray: this.state.docArray,
      row,
      rows: this.rows
    });

    this.performUpdate({ updateObject, updateType: 'press-delete', gotoRow: row.id, gotoPos: prevRowTextLength });

  }

  handlePressEnter = (rowText, row, cursorPos) => {
    let { newRow, updateObject } = handleDevideRowAtPosition({
      rowText,
      row,
      cursorPos,
      rows: this.rows,
      docArray: this.state.docArray,
      updateObject: cloneObject(defaultUpdatesObject)
    });
    this.performUpdate({ updateObject, updateType: 'devide-row', gotoRow: newRow.id });

    return updateObject.prev.rows[row.id].data.text;
  }

  handlePastedText = (textArray, row, cursorPos) => {
    let { updateObject, lastUpdatedRowId } = handleMergePastedText({
      textArray,
      row,
      cursorPos,
      grps: this.grps,
      rows: this.rows,
      updateObject: cloneObject(defaultUpdatesObject)
    });

    this.performUpdate({ updateObject, updateType: 'paste', gotoRow: lastUpdatedRowId, gotoPos: 99999999 });
  }

  handleDragEnd = (result, provided) => {
    if (result.destination) {
        let { updateObject, dragType } = handleDragEnd({
          result,
          provided,
          grps: this.grps,
          rows: this.rows,
          snippets: this.song.snippets || {},
          updateObject: cloneObject(defaultUpdatesObject)
        });
        this.performUpdate({ updateObject, updateType: 'drag-' + dragType });
    }
    
  }

  handleChordDroppedOnBar = (e, toRowId, toFindex) => {

    let altKey =  e.dataTransfer.getData("altKey");
    let chord = e.dataTransfer.getData("chord");
    let fromRowId = e.dataTransfer.getData("rowid");
    let fromPos = e.dataTransfer.getData("letterindex");
    let fromFindex = e.dataTransfer.getData("findex");
    
    let updateObject = handleChordDrop({
      updateObject: cloneObject(defaultUpdatesObject),
      chord,
      altKey,
      rows: this.rows,

      fromRowId,
      fromPos,
      fromFindex,

      toRowId,
      toPos : false,
      toFindex,
    });

    this.performUpdate({ updateObject, updateType: 'drag-chords', forceRender: true});
  }

  handleChordDroppedOnLyrics = (e, toRowId, toPos) => {
    
    let altKey =  e.dataTransfer.getData("altKey");
    let chord = e.dataTransfer.getData("chord");
    let fromRowId = e.dataTransfer.getData("rowid");
    let fromPos = e.dataTransfer.getData("letterindex");
    let fromFindex = e.dataTransfer.getData("findex");

    let updateObject = handleChordDrop({
      updateObject: cloneObject(defaultUpdatesObject),
      chord, 
      altKey,
      rows: this.rows,

      fromRowId,
      fromPos,
      fromFindex,

      toRowId, 
      toPos,
      toFindex : false,
    });

    this.performUpdate({ updateObject, updateType: 'drag-chords' });
  }

  closeChordEditor = () => {
    //log('Closing chord editor');
    this.setState({ chordEditorIsOpenAtRow: false, chordEditorIsOpenAtPos: false });
  }

  openChordEditorAt = (goto, currentRowId, currentPosition) => {

    const {gotoRow, gotoPosition} = getNewChordPosition({
      goto, 
      currentRowId, 
      currentPosition,
      docArray: this.state.docArray,
      rows: this.rows,
    });

    if (gotoRow && gotoPosition !== false) {
      this.setState({ chordEditorIsOpenAtRow: gotoRow, chordEditorIsOpenAtPos: gotoPosition });
    }
  }

  updateSnippets = () => {}

  deleteRow = (row) => {
    if (typeof row === 'string' && typeof this.rows[row] !== 'undefined') {
      row = this.rows[row];
    }
    if (row && typeof row.id != 'undefined') {
      let updateObject = cloneObject(defaultUpdatesObject);
      updateObject.prev.rows[row.id] = cloneObject(this.rows[row.id]);

      if (typeof this.rows[row.id] != 'undefined') {
        delete this.rows[row.id];
      }

      updateObject.next.rows[row.id] = getDeletedRowData(row.id); //{ id: row.id, deleted: true };
      this.performUpdate({ updateObject, updateType: 'delete-row', gotoRow: row.index - 1, gotoPos: 999999 });
    }
  }

  updateRowData = (rowId, updates, updateType = 'update-row') => {
    //log("updateRowData");
    if (typeof this.rows[rowId] !== 'undefined') {
      let updateObject = cloneObject(defaultUpdatesObject);
      updateObject.prev.rows[rowId] = cloneObject(this.rows[rowId]);

      for (let k of Object.keys(updates)) {
        if (typeof this.rows[rowId].data === 'undefined') {
          this.rows[rowId].data = {};
        }
        this.rows[rowId].data[k] = updates[k];

        if (k === 'chords') {
          this.rows[rowId].chordsUpdated =
            (typeof this.rows[rowId].chordsUpdated != 'undefined') ? this.rows[rowId].chordsUpdated + 1 : 1;
        }

      }

      updateObject.next.rows[rowId] = cloneObject(this.rows[rowId]);
      this.performUpdate({ updateObject, updateType: updateType });
    }
  }
  /*
  updateRow(rowId, updates, updateType = 'update-row') {

    if (typeof this.rows[rowId] !== 'undefined') {
      let updateObject = cloneObject(defaultUpdatesObject);
      updateObject.prev.rows[rowId] = cloneObject(this.rows[rowId]);

      for (let k of Object.keys(updates)) {
        this.rows[rowId][k] = updates[k];
        if (k === 'chords') {
          this.rows[rowId].chordsUpdated =
            typeof this.rows[rowId].chordsUpdated != 'undefined' ? this.rows[rowId].chordsUpdated + 1 : 1;
        }
      }

      updateObject.next.rows[rowId] = cloneObject(this.rows[rowId]);
      this.performUpdate({ updateObject, updateType: updateType });
    }

  }
  */
  updateGroup = (grpId, updates) => {
    if (typeof this.grps[grpId] != 'undefined') {
      let updated = false;

      let updateObject = cloneObject(defaultUpdatesObject);
      updateObject.prev.grps[grpId] = cloneObject(this.grps[grpId]);

      if (typeof updates.label != 'undefined') {
        this.grps[grpId].label = updates.label;
        updated = true;
      }

      if (updated) {
        updateObject.next.grps[grpId] = cloneObject(this.grps[grpId]);
        this.performUpdate({ updateObject, updateType: 'update-group' });
        this.updateView().then(() => {});
      }
    }
  }

  handleTitleChange = (newTitle) => {
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.title = this.song.title;
    updateObject.next.title = newTitle;
    this.performUpdate({ updateObject, updateType: 'update-title' });
    updateLocalCache({title: newTitle}, this.song.id);
  }

  updateLanguage = (newLanguage) => {
    let updateObject = cloneObject(defaultUpdatesObject);
    updateObject.prev.language = this.song.language;
    updateObject.next.language = newLanguage;
    this.performUpdate({ updateObject, updateType: 'update-language' });
  }

  performUpdate = (attr) => {
    let { updateObject, updateType, gotoRow, gotoPos, state } = attr;

    if (updateObject === false) {
      return;
    }

    for (let row of Object.values(updateObject.next.rows)) {
      this.rows[row.id] = cloneObject(row);
      this.updates.rows[row.id] = cloneObject(row);
    }

    for (let group of Object.values(updateObject.next.grps)) {
      this.grps[group.id] = cloneObject(group);
      this.updates.grps[group.id] = cloneObject(group);
    }

    let { deletedGroups } = filterGroups(this.grps, this.rows);
    for (let deleted of deletedGroups) {
      if (typeof this.grps[deleted.id] === 'undefined' || typeof this.grps[deleted.id].deleted === 'undefined' || this.grps[deleted.id].deleted === false) {
        this.grps[deleted.id] = { id: deleted.id, deleted: true };
        this.updates.grps[deleted.id] = { id: deleted.id, deleted: true };
      }
    }
    

    if (typeof updateObject.next.title != 'undefined') {
      this.song.title = updateObject.next.title;
      this.updates.title = updateObject.next.title;
    }

    if (typeof updateObject.next.language != 'undefined') {
      this.song.language = updateObject.next.language;
      this.updates.language = updateObject.next.language;
    }

    if (typeof updateObject.next.meta != 'undefined') {
      this.song.meta = updateObject.next.meta;
      this.updates.meta = updateObject.next.meta;
    }

    if (typeof updateObject.next.snippets != 'undefined') {
      this.song.snippets = updateObject.next.snippets;
      this.updates.snippets = updateObject.next.snippets;
    }

    if (this.historyPosition > 0) {
      this.history.splice(this.historyPosition);
    }
    this.historyPosition = false;
    let historyObject = updateObject;
    historyObject.updateType = updateType;
    this.history.push(historyObject);

    this.updateView({ state: state || {} })
      .then(() => {
        if (typeof gotoRow !== 'undefined') {
          this.jumpToRow(gotoRow, gotoPos || 0);
        }
      })
      .catch(() => {});
  }

  updateView = (attr = {}) => {
    return new Promise((resolve) => {
      let docArray = composeDocArray(this.grps, this.rows);
      let has = songHas(this.rows);
      let state = attr.state || {};
      state.docArray = docArray;
      state.hasChords = has.chords;
      state.hasText = has.text;
      state.hasBars = has.bars;
      state.viewUpdates = this.state.viewUpdates + 1;
      this.setState(state, () => {
        this.detectKey();
        resolve();
      });
    });
  }

  deselectAllRows = () => {
    this.selectedGroups = [];
    this.selectedRows = [];
    this.setState({ selectedGroups: false, hasSelectedRows: false, updateView: this.state.updateView + 1 });
  }

  handleGroupCheckToggle = (selected, grpId) => {

    // Add
    if (selected && !this.selectedGroups.includes(grpId)) {
      this.selectedGroups.push(grpId);
      // Remove
    } else if (!selected && this.selectedGroups.includes(grpId)) {
      this.selectedGroups.splice(this.selectedGroups.indexOf(grpId), 1);
    }

    let rows = getRowsInGroup(grpId, this.state.docArray);
    for (let row of rows) {
      if (selected && !this.selectedRows.includes(row.id)) {
        this.selectedRows.push(row.id);
      } else if (!selected && this.selectedRows.includes(row.id)) {
        this.selectedRows.splice(this.selectedRows.indexOf(row.id), 1);
      }
    }

    // Change state if rows selected or not
    if (this.selectedRows.length > 0 && !this.state.hasSelectedRows) {
      this.setState({ hasSelectedRows: true });
    } else if (this.selectedRows.length === 0 && this.state.hasSelectedRows) {
      this.setState({ hasSelectedRows: false });
    }

    return { selectedGroups: this.selectedGroups, selectedRows: this.selectedRows };
  }

  handleRowCheckToggle = (selected, rowid) => {
    // Add
    if (selected && !this.selectedRows.includes(rowid)) {
      this.selectedRows.push(rowid);

      // Remove
    } else if (!selected && this.selectedRows.includes(rowid)) {
      this.selectedRows.splice(this.selectedRows.indexOf(rowid), 1);
    }

    // Change state if rows selected or not
    if (this.selectedRows.length > 0 && !this.state.hasSelectedRows) {
      this.setState({ hasSelectedRows: true });
    } else if (this.selectedRows.length === 0 && this.state.hasSelectedRows) {
      this.setState({ hasSelectedRows: false });
    }

    return { selectedGroups: this.selectedGroups, selectedRows: this.selectedRows };
  }

  render() {

    if (!this.song || !this.state.readyToRender) {
      return <div className="loading-container"><Loading text={i18next.t("Common.Loading")} /></div>;
    }

    // log('Rerendering Editor', this.state.docArray);
    //log(this.state.openChordEditorAt);
    //log("Selected rows", this.selectedRows);

    let theme = this.song.env.theme || {};
    let themeClass = '';
    let editorStyle = {};

    if (isMobile() || isTablet()) {
      editorStyle.backgroundColor = '#FFFFFF';
    } else {
      if (typeof theme === 'object') {
        if (typeof theme.backgroundImage !== 'undefined' && theme.backgroundImage !== '') {
          editorStyle.backgroundImage = 'url(' + theme.backgroundImage + ')';
        } else if (typeof theme.backgroundColor !== 'undefined' && theme.backgroundColor !== '') {
          editorStyle.backgroundColor = theme.backgroundColor;
        }
      }
    }

    if (document.querySelector(':root').style.fontSize !== this.song.env.fontSize) {
      document.querySelector(':root').style.fontSize = this.song.env.fontSize;
    }
    editorStyle.fontSize = this.song.env.fontSize;

    let rowindex = 0;

    return (
      <Storage
        editorId={this.editorId}
        song={this.song}
        grps={this.grps}
        rows={this.rows}
        updates={this.updates}
        clearUpdates={this.clearUpdates}
        updateUpdateTimes={this.updateUpdateTimes}
        updateSongFromAPI={this.updateSongFromAPI}
      >
        
        <div
          className={
            'editor' +
            themeClass +
            (this.state.hasChords ? ' has-chords' : '') +
            (this.state.hasText ? ' has-text' : '') +
            (this.state.hasBars ? ' has-bars' : '') +
            (this.state.editActive ? ' edit-active' : '') + // && !isMobile()
            (this.song.lyricsReadonly ? ' lyrics-readonly' : '') + // && !isMobile()
            (this.song.env.chordsActive ? ' chords-active' : '') +
            (this.song.env.align === 'left' ? ' align-left' : ' align-center') +
            (this.state.loading === true ? ' is-loading' : '') +
            (this.state.readerMode === true ? ' reader-mode' : '')
          }
          id="editor"
          style={editorStyle}
        >
          <DragDropContext onDragEnd={this.handleDragEnd}>
          
          <Helmet>
            <title>{this.song.title + ' | ' + i18next.t('SiteName')}</title>
            <meta property="og:title" content={this.song.title} />
            <meta property="og:locale" content={this.song.language || 'en'} />
            <meta property="og:url" content={"https://" + window.location.host + "/song/" + this.song.id} />
          </Helmet>

          <div id="scrollable" onScroll={this.handleScroll}>

          <div id="sm-editor-root" />

          {this.song.isTeamMember && !this.state.readerMode && (
            <EditorToolbar
              songId={this.state.songId}
              title={this.song.title}
              listId={this.props.listId || false}
              loggedIn={this.props.loggedIn || false}
              env={this.song.env}
              meta={this.song.meta}
              users={this.state.users}
              setNotification={this.setNotification}
              setEnvVariable={this.setEnvVariable}
              setFont={this.setFont}
              hasChords={this.state.hasChords}
              hasText={this.state.hasText}
              hasBars={this.state.hasBars}
              editActive={this.state.editActive}
              setEditActive={this.setEditActive}
              setTranspose={this.setTranspose}
              transpose={this.state.transpose}
              historyPosition={this.historyPosition}
              history={this.history}
              undo={this.undo}
              redo={this.redo}
              updateMeta={this.updateMeta}
              removeMeta={this.removeMeta}
              public={this.song.public || false}
              setPublic={this.setPublic}
              listed={this.song.listed || false}
              setListed={this.setListed}
              owner={this.song.owner}
              team={this.song.team || {}}
              addTeam={this.addTeam}
              removeTeam={this.removeTeam}
              createTrailingGroup={this.createTrailingGroup}
              selectedDelete={this.selectedDelete}
              selectedToClipboard={this.selectedToClipboard}
              selectedToSnippets={this.selectedToSnippets}
              hasSelectedRows={this.state.hasSelectedRows}
              debug={this.debug}
              saveDraft={this.saveDraft}
            />
          )}

          {(this.state.notification && <Notification html={this.state.notification} className={this.state.notificationClass} setNotification={this.setNotification} />)}
          
          {this.song.isTeamMember && !this.state.readerMode && <SidebarLeft listBar={this.props.listBar} />}

          {this.song.isTeamMember && !this.state.readerMode && (
            <SidebarRight
              opener={this.state.rightSidebarOpener}
              snippets={this.song.snippets || {}}
              deleteSnippet={this.deleteSnippet}
              saveSnippet={this.saveSnippet}
              pasteSnippet={this.pasteSnippet}
            />
          )}
{/* 
          <Swipeable
            delta={10}                             // min distance(px) before a swipe starts
            preventDefaultTouchmoveEvent={false}   // preventDefault on touchmove, *See Details*
            trackTouch={this.props.listId ? true : false}                    // track touch input
            trackMouse={false}                  // track mouse input
            onSwipedLeft={(e) => {
              //e.preventDefault()
              let currentndex = this.props.listItems.findIndex((d) => { return d.id === this.song.id; });
              let gotoIndex = (currentndex >= this.props.listItems.length-1) ? 0 : currentndex+1;
              this.setState({redirect: "/list/" + this.props.listId + '/' + (gotoIndex+1)});
            }}
            onSwipedRight={(e) => {
              //e.preventDefault()
              let currentndex = this.props.listItems.findIndex((d) => { return d.id === this.song.id; });
              let gotoIndex = (currentndex === 0) ? this.props.listItems.length-1 : currentndex-1;
              this.setState({redirect: "/list/" + this.props.listId + '/' + (gotoIndex+1)});
            }}
            id="swipeable"
          >
             */}
          <div 
            style={{
              fontFamily: this.song.env.font ? this.song.env.font : "'Quicksand', sans-serif"
            }}
            className={'document-area'} 
            id="document-area">
            
            {this.state.loading === true ? <Loading text={i18next.t("Common.Updating")} /> : ''}

            {this.state.editActive && this.state.hasSelectedRows && (
              <SelectedRowsToolbar 
                deselectAllRows={this.deselectAllRows}
                selectedToClipboard={this.selectedToClipboard}
                setNotification={this.setNotification}
                createTrailingGroup={this.createTrailingGroup}
                selectedDelete={this.selectedDelete}
                selectedToSnippets={this.selectedToSnippets}
              />
            )}

            <TitleEditor
              songId={this.state.songId}
              editActive={this.state.editActive}
              lyricsReadonly={this.song.lyricsReadonly}
              html={this.song.title}
              handleTitleChange={this.handleTitleChange}
              jumpToRow={this.jumpToRow}
              setTitleRef={this.setTitleRef}
            />

            <Contributers 
              updateMeta={this.updateMeta} 
              removeMeta={this.removeMeta} 
              isTeamMember={this.song.isTeamMember} 
              readerMode={this.state.readerMode}
              meta={this.song.meta} 
              editActive={this.state.editActive}
            />

            
              <Droppable droppableId={'groupDroppable'} direction="vertical" type="group">
                {(DroppableParagProvided, DroppableParagSnapshot) => (
                  <div
                    className="group-container"
                    ref={DroppableParagProvided.innerRef}
                    {...DroppableParagProvided.droppableProps}
                  >
                    {this.state.docArray.map((group, groupIndex) => {
                      if (isDeleted(group)) {
                        return '';
                      }

                      let currentRowindex = rowindex;
                      rowindex = rowindex + group.rows.length;

                      return (
                        <Group
                          getSong={this.getSong}
                          getDocArray={this.getDocArray}
                          //docArray={this.state.docArray}
                          setNotification={this.setNotification}
                          //forceRender={this.forceRender}
                          key={groupIndex}
                          editActive={this.state.editActive}
                          lyricsReadonly={this.song.lyricsReadonly}
                          group={group}
                          groupIndex={groupIndex}
                          rowindex={currentRowindex}
                          loading={this.state.loading}
                          env={this.song.env}
                          language={this.song.language}
                          chordsActive={this.song.env.chordsActive}
                          songKey={
                            this.song.env.songKey 
                            ? this.song.env.songKey 
                            : (this.song.env.songKeyDetected ? this.song.env.songKeyDetected : '')
                          }
                          transpose={this.state.transpose}
                          focused={this.focused}
                          selectedGroups={this.selectedGroups}
                          selectedRows={this.selectedRows}
                          pasteChords={this.pasteChords}
                          pasteClipboard={this.pasteClipboard}
                          toClipboard={this.toClipboard}
                          clipboard={this.state.clipboard}
                          handleGroupDuplicate={this.handleGroupDuplicate}
                          handleGroupDelete={this.handleGroupDelete}
                          handleGroupCopy={this.handleGroupCopy}
                          handleFocusRow={this.updateFocused}
                          handleGroupCheckToggle={this.handleGroupCheckToggle}
                          handleRowCheckToggle={this.handleRowCheckToggle}
                          handlePastedText={this.handlePastedText}
                          handleMergeTextWithNextRow={this.handleMergeTextWithNextRow}
                          handleRowToGroup={this.handleRowToGroup}
                          handlePressEnter={this.handlePressEnter}
                          handlePressBackspace={this.handlePressBackspace}
                          handleGlueToGroupAbove={this.handleGlueToGroupAbove}
                          handleNewRowAfter={this.handleNewRowAfter}
                          handleRowDuplicate={this.handleRowDuplicate}
                          handleRowClone={this.handleRowClone}
                          handleRowDelete={this.handleRowDelete}
                          handleRowConvert={this.handleRowConvert}
                          jumpToGroup={this.jumpToGroup}
                          jumpToRow={this.jumpToRow}
                          jumpUp={this.jumpUp}
                          jumpDown={this.jumpDown}
                          setRowRef={this.setRowRef}
                          getRowRef={this.getRowRef}
                          updateGroup={this.updateGroup}
                          chordEditorIsOpenAtRow={this.state.chordEditorIsOpenAtRow}
                          chordEditorIsOpenAtPos={this.state.chordEditorIsOpenAtPos}
                          closeChordEditor={this.closeChordEditor}
                          openChordEditorAt={this.openChordEditorAt}
                          handleChordDroppedOnLyrics={this.handleChordDroppedOnLyrics}
                          handleChordDroppedOnBar={this.handleChordDroppedOnBar}
                          numberOfRows={Object.keys(this.rows).length}
                          updateLanguage={this.updateLanguage}
                          updateRowData={this.updateRowData}
                          performUpdate={this.performUpdate}
                          groupToSnippets={this.groupToSnippets}
                        />
                      );
                    })}

                    {DroppableParagProvided.placeholder}
                  </div>
                )}
              </Droppable>

            <OptionsAfterRows
              createTrailingGroup={this.createTrailingGroup}
              createTrailingRow={this.createTrailingRow}
              selectedRows={this.selectedRows}
              clipboard={this.state.clipboard}
              lyricsReadonly={this.song.lyricsReadonly}
            />
            {
              /*
              this.song.musixmatch
              ? (<MusixmatchCopyright musixmatch={this.song.musixmatch} />)
              : ''
              */
            }

          </div> {/* document-area */}
          {/* </Swipeable> */}
          </div> {/* scrollable */}
          </DragDropContext>
        </div> {/* editor */}
      </Storage>
    );
  }
}

/*
function MusixmatchCopyright(props) {
  return (
    <div className="musixmatch-copyright">
      <p>{props.musixmatch.lyrics_copyright}</p>
      <img src={props.musixmatch.pixel_tracking_url} alt="Musixmatch Copyright" />
    </div>
  );
}
*/

function OptionsAfterRows(props) {
  //let [newRowButtonOpen, setNewRowButtonOpen] = useState(false);
  return (
    <div className="creation-row">
      
      <DropdownButton
        className="small highlighted"
        button={
          <span
            onClick={() => {
              if (props.lyricsReadonly ) {
                props.createTrailingRow('chordbars');
              } else {
                props.createTrailingRow();
              }
            }}
          >
            {i18next.t("DocTools.NewRow")}
          </span>
        }
      >
        <ul>
          <li className={props.lyricsReadonly ? "inactive" : ''}>
            <span
              onClick={() => {
                if (!props.lyricsReadonly) {
                  props.createTrailingRow('lyrics');
                }
              }}
            >
              <i className="icon-plus blue" /> {i18next.t("DocTools.NewRowLyrics")}
            </span>
          </li>
          <li>
            <span
              onClick={() => {
                props.createTrailingRow('chordbars');
              }}
            >
              <i className="icon-plus blue" /> {i18next.t("DocTools.NewRowBars")}
            </span>
          </li>
        </ul>
      </DropdownButton>

      <button
        className="small highlighted"
        onClick={() => {
          props.createTrailingGroup();
        }}
      >
        {i18next.t("DocTools.NewGroup")}
      </button>

      {props.selectedRows.length && !props.lyricsReadonly ? (
        <button
          className="small highlighted"
          onClick={() => {
            props.createTrailingGroup(props.selectedRows);
          }}
        >
          {i18next.t("DocTools.InsertSelected")}
        </button>
      ) : (
        ''
      )}
      {props.clipboard.length && !props.lyricsReadonly ? (
        <button
          className="small highlighted"
          onClick={() => {
            props.createTrailingGroup(props.clipboard);
          }}
        >
          {i18next.t("DocTools.PasteRows")}
        </button>
      ) : (
        ''
      )}
    </div>
  );
}
